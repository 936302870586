TransactionSplitsModule.service('TransactionSplitsService', ['$timeout', 'utils', '$i18next', '$stateParams', function($timeout, utils, $i18next, $stateParams) {
  const defaultFilters = {
    subaccount: undefined,
    is_dynamic: undefined,
    active: undefined,
    type: undefined,
  };

  const defaultFilterDisplayNames = {
    subaccount: $i18next.t('transactionSplits.list.filters.subaccount'),
    is_dynamic: $i18next.t('transactionSplits.list.filters.category.label'),
    active: $i18next.t('transactionSplits.list.filters.status.label'),
    type: $i18next.t('transactionSplits.list.filters.splitType.label'),
  };

  const filterStatuses = [
    {
      name: $i18next.t('transactionSplits.list.filters.status.all'),
      value: undefined,
    },
    {
      name: $i18next.t('transactionSplits.list.filters.status.active'),
      value: 'true',
    },
    {
      name: $i18next.t('transactionSplits.list.filters.status.disabled'),
      value: 'false',
    }
  ];

  const filterSplitTypes = [
    {
      name: $i18next.t('transactionSplits.list.filters.splitType.all'),
      value: undefined,
    },
    {
      name: $i18next.t('transactionSplits.list.filters.splitType.flat'),
      value: 'flat',
    },
    {
      name: $i18next.t('transactionSplits.list.filters.splitType.percentage'),
      value: 'percentage',
    }
  ];

  const filterCategories = [
    {
      name: $i18next.t('transactionSplits.list.filters.category.all'),
      value: undefined,
    },
    {
      name: $i18next.t('transactionSplits.list.filters.category.static'),
      value: 'false',
    },
    {
      name: $i18next.t('transactionSplits.list.filters.category.dynamic'),
      value: 'true',
    }
  ];

  const formatFilterValue = (type, value, { subaccount }) => {
    if (type === 'subaccount') {
      return (subaccount && subaccount.business_name) || value;
    }
    if (type === 'active') {
      return value === 'true' ? 'active' : 'disabled';
    }
    if (type === 'is_dynamic') {
      return value === 'true' ? 'dynamic' : 'static';
    }

    return value;
  };

  const formatPillFilters = ({ subaccount = null } = {}) => {
    const propertiesWithValue = utils.stripPropertiesWithoutValues($stateParams);

    return Object.keys(propertiesWithValue)
      .reduce((accumulator, paramKey) => {
        if (defaultFilters.hasOwnProperty(paramKey)) {
          const formattedFilterName = defaultFilterDisplayNames[paramKey];
          const formattedFilterValue = formatFilterValue(paramKey, propertiesWithValue[paramKey], { subaccount });

          return [...accumulator, {
            key: paramKey,
            name: formattedFilterName,
            value: formattedFilterValue
          }];
        }

        return accumulator;
      }, []);
  };

  const formatAccountShareBySplitType = ({ splitType, share, formatTo }) => {
    const amount = parseFloat(share);

    if (splitType === 'percentage') {
      return share;
    }

    if (formatTo === 'kobo') {
      return utils.toKobo({ amount }).amount;
    }

    if (formatTo === 'naira') {
      return utils.toNaira({ amount }).amount;
    }
  };

  const focusOnElementBySelector = (selector) => {
    $timeout(() => {
      const selectedSubaccountInput = document.querySelector(selector);
      if (selectedSubaccountInput) {
        selectedSubaccountInput.focus();
      }
    }, 0);
  };

  const formatTransactionSplitResponse = (response) => {
    const transactionSplit = response.data;
    const splitType = transactionSplit.type;
    const transactionSplitSubaccounts = transactionSplit.subaccounts || [];

    return {
      ...transactionSplit,
      subaccounts: transactionSplitSubaccounts.map(subaccount => ({
        ...subaccount.subaccount,
        share: formatAccountShareBySplitType({ splitType, share: subaccount.share, formatTo: 'naira' }),
      })),
    };
  };

  const showIntroJS = () => {
    const intro = new introJs();
    const introJSOptions = {
      showStepNumbers: false,
      showBullets: false,
      exitOnOverlayClick: true,
      tooltipClass: 'w-md',
      highlightClass: 'product-intro-highlight',
      exitOnEsc: true,
      doneLabel: $i18next.t('transactionSplits.accounts.introJS.actionButtonText')
    };

    intro.addSteps([{
      element: '#add-existing-subaccount-input-wrapper',
      intro: `
            <div class="introjs-title text-mid">${$i18next.t('transactionSplits.accounts.introJS.title')}</div>
            <div class="introjs-body">${$i18next.t('transactionSplits.accounts.introJS.body')}</div>
          `,
      position: 'left',
    }]);
    intro.setOptions(introJSOptions);

    $timeout(() => {
      intro.start();
    }, 500);
  };

  const appendSplitIdToStateParams = splitId => ({ ...$stateParams, id: splitId });

  const isStateParamsEmpty = () => Object.keys(utils.stripPropertiesWithoutValues($stateParams)).length === 0;

  const computeTransactionSplitTableHeight = () => {
    const viewHeader = document.querySelector('.view-header');
    const transactionSplitFilterContainer = document.querySelector('#transaction-split-filter-container');

    return `calc(100vh - ${viewHeader && viewHeader.offsetHeight || 0}px - ${transactionSplitFilterContainer && transactionSplitFilterContainer.offsetHeight || 0}px)`;
  };

  return {
    showIntroJS,
    filterStatuses,
    defaultFilters,
    filterSplitTypes,
    filterCategories,
    formatPillFilters,
    isStateParamsEmpty,
    focusOnElementBySelector,
    appendSplitIdToStateParams,
    computeTransactionSplitTableHeight,
    formatAccountShareBySplitType,
    formatTransactionSplitResponse
  };
}]);
