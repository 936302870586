angular.module('app.transfer-recipients', ['ui.router'])

  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('transfer-recipients', {
        abstract: true,
        title: 'Recipients',
        url: '/transfer-recipients',
        templateUrl: '/components/layout/base.html',
        redirectTo: 'transfer-recipients.list',
        data: {
          authenticable: true
        }
      })
        .state('transfer-recipients.list', {
          title: 'Recipients',
          url: '?page&use_cursor&is_blacklisted&search',
          pageTrack: '/transfer-recipients',
          templateUrl: '/modules/transfer-recipients/list.html',
          controller: 'TransferRecipientsCtrl',
          onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('transfer-recipients-root'),
        }).state('transfer-recipients.one', {
          url: '/:id',
          title: 'Recipients',
          templateUrl: 'modules/transfer-recipients/one.html',
          controller: 'TransferRecipientsCtrl',
          onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('transfer-recipients-root'),
        });
    }
  ])

  .factory('TransferRecipientData', ['API', 'URLService', function($API, URLService) {
    return {
      fetch: params => $API.one('transferrecipient').get(params),
      getTotalRecipientsCount: () => {
        const params = URLService.getQuery();
        return $API.one('transferrecipient').get({
          ...params,
          perPage: 1,
          use_cursor: false,
        }).then(response => response && response.meta && response.meta.total);
      }
    };
  }])

  .controller('TransferRecipientsCtrl', ['$scope', 'TransferRecipientData', 'DEFAULTS', ($scope, TransferRecipientData, DEFAULTS) => {
    $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

    $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
      if (mfeServiceReady) {
        window.postMessage({
          message: 'RENDER_TRANSFER_RECIPIENTS_MICROFRONTEND',
        }, '*');
      }
    });

      TransferRecipientData.getTotalRecipientsCount().then((recipientCount) => {
        $scope.recipientCount = recipientCount
      })
    }
  ]);