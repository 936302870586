FilesModule.controller('FileDisplayCtrl', [
  '$scope',
  '$state',
  'FileDisplay',
  function ($scope, $state, FileDisplay) {
    $scope.downloadSuccess = false;
    const isValidUrl = url =>
      new RegExp(
        /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
      ).test(url);

    const extractFilePath = filePathQueryString => {
      if (filePathQueryString) {
        return decodeURI(filePathQueryString);
      }
    };

    $scope.$on('$viewContentLoaded', () => {
      const filePath = extractFilePath($state.params.location);

      if (!filePath) {
        $scope.message = 'The download filename was not specified';
        $scope.$broadcast('fetchedFile', false);
        return;
      }

      const requestBody = {};

      if (isValidUrl(filePath)) {
        requestBody.url = filePath;
      } else {
        requestBody.storage_key = filePath;
      }

      function isValidHttpsUrl(stringUrl) {
        const validAmazonS3Link =
          /(https):\/\/(s3-|s3\.)?(.*)\.amazonaws\.com/g;
        return validAmazonS3Link.test(String(stringUrl).toLowerCase());
      }

      FileDisplay.getSignedURL(requestBody)
        .then(response => {
          const redirectUrl = response.data.path;

          if (isValidHttpsUrl(redirectUrl)) {
            window.location.href = response.data.path;
            $scope.message = response.message;
            $scope.$broadcast('fetchedFile', true);
            $scope.downloadSuccess = true;
          } else {
            $scope.message = 'Invalid file';
            $scope.$broadcast('fetchedFile', false);
          }
        })
        .catch(error => {
          $scope.message = error && error.data && error.data.message;
          $scope.$broadcast('fetchedFile', false);
        });
    });
  },
]);
