angular.module('app.campaignService', []).service('CampaignService', () => {
  const blackFridayCampaigns = () => {
    const baseDescription = 'Create a Paystack account to receive R1 million in fee-free processing. Offer expires 11:59 PM (SAST) on Dec 31, 2023 or when business collects R1 million, whichever comes first.';
    const brands = ['insta', 'insta_shopify', 'insta_woo', 'stacks', 'nomdeni', 'masego', 'setumo'];

    return brands.reduce((blackFridayObject, brand) => {
      const formattedBrand = brand.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
      const key = `2023blackfriday${brand.replace(/_/g, '')}`;

      blackFridayObject[key] = {
        name: `South Africa Black Friday ${formattedBrand}`,
        getDescription () {
          return baseDescription;
        },
      };

      return blackFridayObject;
    }, {});
  };

  const marketingCampaigns = {
    artwork: {
      name: 'Artwork',
      pageStyle: {
        background: '#111111',
      },
      getDescription () {
        return `${this.name} is proudly brought to you by Paystack. Create your FREE account to get all the business tools you need to grow your creative practice.`;
      },
    },
    begtodiffer: {
      name: 'I Beg to Differ',
      pageStyle: {
        background: '#07130e',
      },
      getDescription () {
        return `${this.name} is proudly supported by Paystack. Create your FREE account to get all the tools you need to start and scale your business.`;
      },
    },
    wiicreate: {
      name: 'WiiCreate',
      getDescription () {
        return "WiiCreate's “Tech Changed my Life” is proudly powered by Paystack. Create your FREE account to get all the tools you need to start and scale your business.";
      },
    },
    mit2022: {
      name: 'MIT',
      getDescription () {
        return 'The MIT Africa Innovate Conference is proudly supported by Paystack. Create your FREE account to accept payments for your Africa-focused business quickly and securely.';
      },
    },
    devconf: {
      name: 'DevConf',
      getDescription () {
        return 'DevConf 2023 is proudly sponsored by Paystack. Sign up for FREE to get your API Keys and build amazing payment experiences.';
      },
    },
    ilikegirls: {
      name: 'I Like Girls',
      getDescription () {
        return `"${this.name}" podcast is proudly sponsored by Paystack. Create your FREE account to get all the tools you need to start and scale your business.`;
      },
    },
    xeroroadshow: {
      name: 'Xero',
      getDescription () {
        return `Paystack is a proud partner of ${this.name}. Create your FREE account to get all the tools you need to start and scale your business.`;
      },
    },
    ibomair: {
      name: 'Ibom Air',
      getDescription () {
        return `Paystack is a proud partner of ${this.name}. Create your FREE account to get all the tools you need to start and scale your business.`;
      },
    },
    tabsvsspaces: {
      name: 'Tabs vs Spaces',
      getDescription () {
        return `${this.name} is proudly brought to you by Paystack. Create your FREE account to get all the tools you need to start and scale your business.`;
      },
    },
    lsw: {
      name: 'Lagos Startup Week',
      getDescription () {
        return `Paystack is a proud partner of ${this.name}. Create your FREE account to get all the tools you need to start and scale your business online.`;
      },
    },
    datafest2022: {
      name: 'DataFestAfrica',
      getDescription () {
        return `Paystack is a proud partner of ${this.name}. Create your FREE account to get all the tools you need to start and scale your business`;
      },
    },
    '2023ecommercebootcamp': {
      name: 'South Africa Ecommerce Bootcamp',
      getDescription () {
        return 'Thanks for registering for the Bootcamp! Create a Paystack account below to receive R1 million in fee-free processing. Offer expires 11:59 PM (SAST) on Dec 31, 2023 or when business collects R1 million, whichever comes first.';
      },
    },
    'thecorefashion': {
      name: 'The Core Fashion',
      getDescription () {
        return `Paystack is a proud partner of ${this.name} Event. Create your FREE account to get all the tools you need to collect payments from your customers anywhere in the world.`;
      },
    },
    '2024blackfridaystacks': {
      name: 'South Africa Black Friday Stacks',
      getDescription () {
        return `Create your free Paystack account below to enjoy discounted processing of 2.0% + R1 on local credit and debit card transactions until March 31, 2025, 11:59 pm in South Africa. Your account will go live within 24 to 48 hours, and we'll notify you via email once it's ready.`;
      },
    },
    ...blackFridayCampaigns()
  };

  return { marketingCampaigns };
});
