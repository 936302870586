UIDirectives.directive('pinInput', ['$timeout', function ($timeout) {
  return {
    restrict: 'E',
    scope: {
      length: '@',
      type: '@',
      width: '@',
      pin: '=',
      autofocus: '@',
      onEnterKeyPressed: '&',
    },
    template: `
      <div class="pin-input" ng-style="{width: width || '260px'}">
        <input id="pinInput-{{$index}}" type="{{type || 'tel'}}" onclick="this.select()" ng-enter="onEnterKeyPressed()" autocomplete="off" ng-keydown="handleKeydown($event, $index)" ng-keyup="handleKeyup($event, $index)" ng-repeat="input in inputs">
      </div>
    `,
    link: ($scope) => {
      $scope.pin = '';
      $scope.inputs = Array.from(new Array(Number($scope.length)), () => ({}));

      const getPinInput = index => document.getElementById(`pinInput-${index}`);

      $timeout(() => {
        const selector = getPinInput(0);
        if ($scope.autofocus && selector) {
          selector.focus();
        }
      }, 100);

      const handlePinInputValue = ({ selector, index }) => {
        const selectorValue = selector.value;

        if (selectorValue.length > 1) {
          if (getPinInput(index + 1)) {
            // Get the next input field and pass the sliced value
            getPinInput(index + 1).value = selectorValue.slice(1);
            getPinInput(index + 1).focus();
          }

          getPinInput(index).value = selectorValue[0];
        }
      };

      const isAlphaNumericKey = (keyEvent) => /^[a-zA-Z0-9]$/.test(keyEvent.key || String.fromCharCode(keyEvent.keyCode));

      $scope.handleKeyup = (event, index) => {
        const isAlphaNumeric = isAlphaNumericKey(event);
        const nextPinSelector = getPinInput(index + 1);

        if (isAlphaNumeric && nextPinSelector) {
          nextPinSelector.focus();
          nextPinSelector.select();
        }

        $scope.pin = $scope.inputs.map((_, index) => {
          const pinInputSelector = getPinInput(index);

          if (pinInputSelector) {
            handlePinInputValue({ selector: pinInputSelector, index });
            return pinInputSelector.value;
          }

          return '';
        }).join('');
      };

      let prevKey = null;
      $scope.handleKeydown = (event, index) => {
        const nextPinSelector = getPinInput(index + 1);
        const prevPinSelector = getPinInput(index - 1);

        const isBackspace = event.key === 'Backspace';
        const isArrowLeft = event.key === 'ArrowLeft';
        const isArrowRight = event.key === 'ArrowRight';
        const isTab = event.key === 'Tab';
        const isPrevKeyCtrlOrMeta = ['Ctrl', 'Meta'].includes(prevKey);
        const isAlphaNumeric = isAlphaNumericKey(event);

        const isAcceptableSpecialKey = isBackspace || isArrowLeft || isArrowRight || isTab;

        const handleSpecialKeysInput = () => {
          $timeout(() => {
            if (isBackspace && prevPinSelector) {
              prevPinSelector.focus();
              prevPinSelector.select();
            } else if ((isTab || isArrowRight) && nextPinSelector) {
              nextPinSelector.focus();
              nextPinSelector.select();
            } else if (isArrowLeft && prevPinSelector) {
              prevPinSelector.select();
            }
          });
        };

        if (isAcceptableSpecialKey) {
          handleSpecialKeysInput();
        } else if (isAlphaNumeric || isPrevKeyCtrlOrMeta) {
          // do nothing
        } else {
          event.preventDefault();
        }

        prevKey = event.key;
      };
    }
  };
}]);