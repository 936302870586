UIDirectives.directive('currencyInput', [() => ({
  restrict: 'E',
  replace: true,
  scope: {
    currency: '=',
    ngModel: '=',
    required: "@",
    minimum: '=',
    maximum: '=',
    minimumAmountError: "@",
    maximumAmountError: "@",
    testId: '@',
  },
  template: `
    <form name="currencyInputForm" class="currency-input-container" style="margin: 0">
      <div class="currency-input-group">
        <div class="currency-code text-muted-new">{{currency || 'NGN'}}</div>
        <input
          class="currency-input form-control"
          name="currencyInput"
          type="text"
          currency-mask
          ng-min="minimum"
          ng-max="maximum"
          ng-model="amount"
          ng-change="handleInputValueChange()"
          data-testid="{{testId}}"
          ng-required="required"
        >
      </div>

      <div class="m-t-sm text-sm" ng-if="currencyInputForm.currencyInput.$error.ngMin">
        <span class="text-danger" ng-if="minimumAmountError">{{ minimumAmountError }}</span>
        <span class="text-danger" ng-if="!minimumAmountError">{{ 'customDirectives.currencyInput.defaultMinimumAmountErrorMessage' | i18next }}</span>
      </div>

      <div class="m-t-sm text-sm" ng-if="currencyInputForm.currencyInput.$error.ngMax">
        <span class="text-danger" ng-if="maximumAmountError">{{ maximumAmountError }}</span>
        <span class="text-danger" ng-if="!maximumAmountError">{{ 'customDirectives.currencyInput.defaultMaximumAmountErrorMessage' | i18next }}</span>
      </div>
    </form>
  `,
  link: ($scope) => {
    $scope.$watch('ngModel', (value) => {
      $scope.amount = value;
    });

    $scope.handleInputValueChange = () => {
      $scope.ngModel = $scope.amount;
    };
  }
})
]);