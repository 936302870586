angular.module('app.analyticsService', []).service('AnalyticsService', [
  function () {
    const initializeMarketingInstance = (options = {}) => {
      // initialize marketing instance
    };

    const setActiveUser = (user, integration, instance = 'productInstance') => {
      // set active user
    };

    const logEvent = (event, options = {}, instance = 'productInstance') => {
      // log event
    };

    return {
      initializeMarketingInstance,
      setActiveUser,
      logEvent,
    };
  },
]);
