angular.module('app.direct-debit', ['ui.router'])

  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('direct-debit', {
        abstract: true,
        url: '/direct-debit',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'direct-debit.accounts',
        data: {
          permission: 'directdebit-*',
          authenticable: true
        }
      })
        .state('direct-debit.accounts', {
          title: 'Accounts',
          url: '/accounts',
          pageTrack: '/direct-debit-accounts',
          templateUrl: 'modules/direct-debit/views/accounts/list.html',
          controller: 'AccountsListCtrl'
        })
        .state('direct-debit.payments', {
          template: '<ui-view></ui-view>',
          redirectTo: 'direct-debit.payments.list',
        })
        .state('direct-debit.payments.list', {
          title: 'Payments',
          url: '/payments',
          pageTrack: '/direct-debit-accounts',
          templateUrl: 'modules/direct-debit/views/payments/list.html',
          controller: 'PaymentsListCtrl'
        }).state('direct-debit.payments.one', {
          title: 'Payments',
          url: '/payments/:id',
          pageTrack: '/payments',
          templateUrl: 'modules/direct-debit/views/payments/one.html',
          showBackButton: true,
          controller: 'SinglePaymentCtrl',
          resolve: {
            payment: ['API', '$stateParams', function ($API, $stateParams) {
              return $API.one('transaction', $stateParams.id).get();
            }],
          }
        });
    }
  ]);