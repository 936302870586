UIDirectives.directive('dateInput', [
  function () {
    return {
      restrict: 'E',
      scope: {
        ngModel: '=',
        dateFormat: '@',
        form: '=?',
        maximumYear: '=',
        required: '=',
      },
      template: `
        <div class="dateInput form-validation">
          <form name="dateForm">
            <select class="form-control select-v2 select-v2-plain m-r-md" ng-model="months"
              ng-options="month.value as month.name for month in allMonths" ng-required="required">
              <option value="" disabled></option>
            </select>
            <input min="1" max="{{maximumNumberOfDaysInAMonth}}" class="form-control input-v2 m-r-md" type="number" ng-model="date" placeholder="Day"
            name="date" ng-required="required">
            <input min="1900" max="{{maximumYear}}" class="form-control input-v2" type="number" ng-model="years" placeholder="Year"
            name="years" ng-required="required">
          </form>
        </div>
      `,
      link: function ($scope) {
        const getMaximumNumberOfDaysInAMonth = (month, year) => new Date(year, month + 1, 0).getDate();

        const initializeDate = () => {
          if ($scope.ngModel) {
            const { years, months, date } = moment($scope.ngModel, 'YYYY-MM-DD').toObject();
            $scope.years = years;
            $scope.months = months;
            $scope.date = date;
            $scope.maximumNumberOfDaysInAMonth = getMaximumNumberOfDaysInAMonth(months, years);
          }
        };

        $scope.$watch('ngModel', () => {
          initializeDate();
        });

        initializeDate();

        const setFieldValidity = (field) => {
          const maximumValues = {
            date: $scope.maximumNumberOfDaysInAMonth,
            years: $scope.maximumYear,
          };
          if ($scope[field] > maximumValues[field]) {
            $scope.form[field].$setValidity('required', false);
          } else {
            $scope.form[field].$setValidity('required', true);
          }
        };

        const convertDateObjectToDatePrimitive = (date, months, years) => {
          if ($scope.dateFormat) {
            $scope.ngModel = moment({ years, date, months }).format($scope.dateFormat);
          } else {
            $scope.ngModel = new Date(years, months, date);
          }
        };

        const setParam = (param, value) => {
          if (!$scope.localDate) {
            $scope.localDate = {};
          }
          $scope.localDate[param] = value;
          const { date, months, years } = $scope.localDate;
          if ((months || months === 0) && years) {
            $scope.maximumNumberOfDaysInAMonth = getMaximumNumberOfDaysInAMonth(months, years);
          }
          if (date && (months || months === 0) && years) {
            convertDateObjectToDatePrimitive(date, months, years);
          }
        };

        $scope.$watch('months', (months) => {
          if (months || months === 0) {
            setParam('months', months);
          }
        });

        $scope.$watch('date', (date) => {
          if (date) {
            setParam('date', date);
            setFieldValidity('date');
          }
        });

        $scope.$watch('years', (years) => {
          if (years) {
            setParam('years', years);
            setFieldValidity('years');
          }
        });

        $scope.allMonths = [
          { value: 0, name: 'Jan' },
          { value: 1, name: 'Feb' },
          { value: 2, name: 'Mar' },
          { value: 3, name: 'Apr' },
          { value: 4, name: 'May' },
          { value: 5, name: 'Jun' },
          { value: 6, name: 'Jul' },
          { value: 7, name: 'Aug' },
          { value: 8, name: 'Sep' },
          { value: 9, name: 'Oct' },
          { value: 10, name: 'Nov' },
          { value: 11, name: 'Dec' }
        ];
      },
    };
  },
]);
