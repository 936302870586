SubscriptionsModule.controller('SubscriptionListCtrl', [
  '$scope',
  '$stateParams',
  'SubscriptionConstants',
  function ($scope, $stateParams, SubscriptionConstants) {
    $scope.statusFilter = $stateParams.status || '';
    $scope.statusDescriptions = SubscriptionConstants.STATUS_DESCRIPTIONS;

    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
          {
            message: 'RENDER_SUBSCRIPTIONS_LIST',
          },
          '*',
        );
      }
    });
  },
]);
