UIDirectives.directive('announcements', [
  'AnalyticsService', '$rootScope', 'ngDialog', 'LocalService', '$state', 'Session', 'DEFAULTS',
  (AnalyticsService, $rootScope, ngDialog, LocalService, $state, Session, DEFAULTS) => ({
    restrict: 'AE',
    link($scope) {
      const shownAnnouncements =
        LocalService.get('shown_announcements', { parse: true }) || {};
      const { logEvent } = AnalyticsService;
      $scope.logEvent = logEvent;
      const { Business, User } = $rootScope;

      $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

      const showManagedAccountsAgreementAnnouncement =
        Business.is_live &&
        Business.agreements &&
        Business.agreements.settlement_managed_account.visible &&
        Business.agreements.settlement_managed_account.agreements &&
        !Business.agreements.settlement_managed_account.agreements.length; // not rejected or accepted

      const isBusinessRegistered = Business.business_type === 'registered';
      const showTransfersInSA =
        isBusinessRegistered && Business.attributes.country_code === 'ZA';
      const isNigerianBusiness = Business.attributes.country_code === 'NG';
      const isGhanaianBusiness = Business.attributes.country_code === 'GH';
      const isCoteDivoireBusiness = Business.attributes.country_code === 'CI';
      const isKenyanBusiness = Business.attributes.country_code === 'KE';
      const showTransfersInGH = isBusinessRegistered && isGhanaianBusiness;
      const showTransfersInKE = isBusinessRegistered && isKenyanBusiness;
      $scope.userIntegrationsWithMFAEnforced = (User.integrations || []).filter(
        ({ mfa }) => mfa && mfa.is_required,
      );
      const showEnableMFA =
        $scope.userIntegrationsWithMFAEnforced.length &&
        !User.mfa_enabled &&
        !$state.params.toggle_mfa;
      const integrationHasContactEmails =
        Business.chargeback_emails &&
        Business.chargeback_emails.length > 0 &&
        !!Business.support_email;
      const showIntegrationContactEmailsAnnouncement =
        !integrationHasContactEmails &&
        (Business.go_live_requested || Business.is_live);
      const shouldViewInstantPayouts = (Session.get('features') || {})
        .pod_enabled;
      const ptAccounts =
        Business.top_up_accounts && Array.isArray(Business.top_up_accounts)
          ? Business.top_up_accounts.filter(accounts =>
              ['fidelity-bank', 'titan-paystack'].includes(accounts.bank.slug),
            )
          : [];
      const hasOnePtAccount = ptAccounts.length === 1;
      const hasMoreThanOnePtAccount = ptAccounts.length > 1;
      const showVtAnnouncement = !isNigerianBusiness && isBusinessRegistered;

      const allAnnouncements = [
        {
          name: 'Welcome', // this is a test mode pop up
          template: 'components/modals/welcome.html',
          isActive: !Business.is_live,
          alwaysShowAfterLogin: true,
        },
        {
          name: 'InstantPayout',
          template: 'components/modals/instant-payout-announcement.html',
          className: 'ngdialog-theme-plain dialog-close-center-icon',
          isActive: shouldViewInstantPayouts,
        },
        {
          name: 'Consent',
          template: 'components/modals/consent-announcement.html',
          isActive: showManagedAccountsAgreementAnnouncement,
        },
        {
          name: 'EnableMFA',
          template: 'components/modals/enable-mfa-for-defaulting-user.html',
          className: 'ngdialog-theme-plain dialog-close-center-icon',
          isActive: showEnableMFA,
          alwaysShowAfterLogin: true,
          controller: [
            '$scope',
            $scope => {
              $scope.showBusinesses = false;
              $scope.toggleShowBusinesses = () => {
                $scope.showBusinesses = !$scope.showBusinesses;
              };
            },
          ],
        },
        {
          name: 'TransfersInSA',
          template: 'components/modals/transfers-sa-announcement.html',
          className: 'ngdialog-theme-plain dialog-close-center-icon',
          isActive: showTransfersInSA,
        },
        {
          name: 'ApplePayLaunch',
          template: 'components/modals/apple-pay-announcement.html',
          className: 'ngdialog-theme-plain',
          showClose: false,
          isActive: isGhanaianBusiness,
        },
        {
          name: 'AddIntegrationContactEmails',
          template: 'components/modals/integration-contact-announcement.html',
          showClose: false,
          isActive: showIntegrationContactEmailsAnnouncement,
        },
        {
          name: 'VTReLaunchExpandingMarkets',
          template: 'components/modals/virtual-terminal-announcement.html',
          className: 'ngdialog-theme-plain',
          showClose: false,
          isActive: showVtAnnouncement,
        },
        {
          name: 'NewPtAccount',
          template: 'components/modals/new-pt-account.html',
          isActive: hasMoreThanOnePtAccount,
          controller: [
            '$scope',
            $scope => {
              $scope.isOne = hasOnePtAccount;
              $scope.isMoreThanOne = hasMoreThanOnePtAccount;
            },
          ],
        },
        {
          name: 'WaveLaunchCiv',
          template: 'components/modals/civ-wave-announcement.html',
          className: 'ngdialog-theme-plain',
          isActive: isCoteDivoireBusiness,
        },
        {
          name: 'ApplePayLaunchCiv',
          template: 'components/modals/civ-apple-pay-announcement.html',
          className: 'ngdialog-theme-plain',
          isActive: isCoteDivoireBusiness,
        },
        {
          name: 'TransfersInGH',
          template: 'components/modals/transfers-gh-announcement.html',
          className: 'ngdialog-theme-plain dialog-close-center-icon',
          isActive: showTransfersInGH,
        },
        {
          name: 'Passkey',
          template: 'components/modals/passkey-announcement.html',
          className: 'ngdialog-theme-plain dialog-close-center-icon',
          isActive: true,
        },
        {
          name: 'TransfersInKE',
          template: 'components/modals/transfers-ke-announcement.html',
          className: 'ngdialog-theme-plain dialog-close-center-icon',
          isActive: showTransfersInKE,
        },
      ];

      // Removes announcements that don't exist on the announcement list
      const sanitizedShownAnnouncements = Object.keys(
        shownAnnouncements,
      ).reduce((accumulator, announcementName) => {
        const foundAnnouncement = allAnnouncements.find(
          announcement => announcement.name === announcementName,
        );

        if (foundAnnouncement) {
          accumulator = {
            ...accumulator,
            [announcementName]: shownAnnouncements[announcementName],
          };
        }

        return accumulator;
      }, {});

      const activeAnnouncements = allAnnouncements.filter(
        ({ isActive, name }) => isActive && !sanitizedShownAnnouncements[name],
      );
      const announcementToShow = activeAnnouncements[0];

      if (announcementToShow) {
        const {
          name,
          template,
          controller,
          showClose = true,
          className,
        } = announcementToShow;
        logEvent('Shown announcement', { name });
        ngDialog
          .openConfirm({
            template,
            showClose,
            scope: $scope,
            ...(className && { className }),
            ...(controller && { controller }),
          })
          .then(
            action => {
              logEvent('Dismissed announcement', { name, action });
            },
            () => {
              logEvent('Dismissed announcement', { name });
            },
          )
          .finally(() => {
            sanitizedShownAnnouncements[name] = true;
            LocalService.set(
              'shown_announcements',
              sanitizedShownAnnouncements,
            );
          });
      }

      $rootScope.$on('sessionEnded', () => {
        allAnnouncements
          .filter(({ alwaysShowAfterLogin }) => alwaysShowAfterLogin)
          .forEach(({ name }) => {
            if (sanitizedShownAnnouncements[name]) {
              sanitizedShownAnnouncements[name] = false;
              LocalService.set(
                'shown_announcements',
                sanitizedShownAnnouncements,
              );
            }
          });
      });
    },
  }),
]);
