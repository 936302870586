OrderModule.factory('OrderFactory', [
  'API',
  function($API) {
    const fetchPendingOrdersCount = () => $API.one('order/metrics').get()
      .then(response => response.data.pending_orders_count);

    return {
      fetchPendingOrdersCount,
    };
  }
]);
