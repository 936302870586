angular.module('app.pages', ['ui.router'])

  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('pages', {
        abstract: true,
        url: '/pages',
        title: 'Pages',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'pages.list',
        data: {
          permission: 'page-view',
          authenticable: true
        }
      }).state('pages.list', {
        url: '?from&to&inactive&page&query&create',
        pageTrack: '/pages',
        title: 'Pages',
        templateUrl: 'modules/pages/list.html',
        controller: 'PageListCtrl',
        resolve: {
          pages: ['API', '$stateParams', function ($API, $stateParams) {
            const params = angular.extend($stateParams, {
              active: $stateParams.inactive ? 'false' : null,
              search: $stateParams.query
            });
            return $API.one('page').get(params);
          }],
        },
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('payment-pages-list-root'),
      }).state('pages.one', {
        url: '/:id?edit',
        pageTrack: '/pages',
        title: 'Pages',
        templateUrl: 'modules/pages/one.html',
        controller: 'PageCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('payment-page-detail-root'),
      });
    }
  ])

  .controller('PageListCtrl', ['$scope', 'pages', 'DEFAULTS', function ($scope, pages, DEFAULTS) {
    $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
            {
              message: 'RENDER_PAYMENT_PAGES_LIST_MICROFRONTEND',
            },
            '*',
        );
      }
    });

    $scope.meta = pages.meta;
  }])

  .controller('PageCtrl', ['$scope', function ($scope) {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
            {
              message: 'RENDER_PAYMENT_PAGE_MICROFRONTEND',
            },
            '*',
        );
      }
    });
  }])

  .controller('NewPageCtrl', ['$state', '$scope', '$rootScope', 'API', 'ngDialog', 'Notification', 'Session', 'utils', 'plans', 'products', '$stateParams', 'PlansData', 'TransactionSplitsData', '$i18next', function ($state, $scope, $rootScope, $API, ngDialog, Notification, Session, utils, plans, products, $stateParams, PlansData, TransactionSplitsData, $i18next) {
    $scope.addedProducts = [];
    $scope.Notification = Notification;
    $scope.plans = plans.data;
    $scope.page = {
      type: $scope.ngDialogData.type || '',
      migrate: $rootScope.User.display_state == 'test',
      currency: Session.get('account').default_currency,
      metadata: {}
    };

    $scope.formatBusinessName = (businessName) => {
      if (businessName) {
        return utils.firstNthWords(businessName, 2);
      }

      return businessName;
    };

    $scope.pageType =
      $scope.page.type === 'product'
        ? 'Product'
        : $scope.page.type === 'payment'
          ? 'Payment'
          : 'Subscription';

    $scope.subaccounts = [];
    $scope.all_subaccounts_fetched = false;

    const subaccountsFilter = $stateParams || {};
    $API.one('subaccount').get(subaccountsFilter).then((response) => {
      $scope.subaccounts = response.data;
      $scope.all_subaccounts_fetched = response.meta.pageCount === 1;
    });

    $scope.transactionSplits = [];
    let fetchedAllTransactionSplits = false;
    TransactionSplitsData.fetch({
      active: true
    })
      .then((response) => {
        $scope.transactionSplits = response.data;
        fetchedAllTransactionSplits = response.meta.pageCount === 1;
      });

    $scope.updateTransactionSplits = (query) => {
      if (!query || fetchedAllTransactionSplits) return;

      TransactionSplitsData.fetch({
        search: query,
        active: true
      })
        .then((response) => {
          $scope.transactionSplits = response.data;
        })
        .catch((error) => {
          Notification.error('Could not search for transaction split', error);
        });
    };

    $scope.planSearchStatus = 'idle';
    $scope.setPlanSearchStatus = (status) => {
      $scope.planSearchStatus = status;
    };

    $scope.searchPlans = (pageForm) => {
      $scope.page.plan = {};
      $scope.planSearchStatus = 'searching';
      pageForm.searchPlans.$setValidity('required', false);

      PlansData.search($scope.page.searchInput)
        .then((response) => {
          $scope.plans = response.data;
          $scope.planSearchStatus = 'searched';
        }).catch((error) => {
          $scope.planSearchStatus = 'idle';
          Notification.error('Could not search plans', error);
        });
    };

    $scope.updateSubaccounts = function (query) {
      if ($scope.all_subaccounts_fetched) return;
      return $API.one('subaccount').get({
        search: query
      }).then(function (response) {
        $scope.subaccounts = response.data;
      });
    };

    $scope.setPlan = (plan = {}) => {
      $scope.page.plan = plan;
      $scope.page.searchInput = plan.name;
      $scope.planSearchStatus = 'idle';
      $scope.page.plan.amount = utils.currencySubUnitToBaseUnit(plan.amount);
    };

    if ($scope.ngDialogData.prefill) {
      $scope.page = $scope.ngDialogData.prefill;
      $scope.page.searchInput = $scope.page.plan && $scope.page.plan.name;
      $scope.page.name = $scope.page.name += ' (Copy)';
      $scope.addedProducts = $scope.page.products || [];

      if ($scope.page.amount) {
        $scope.page.amount = utils.currencySubUnitToBaseUnit($scope.page.amount);
      }
      $scope.page.fixed_amount = $scope.page.amount > 0;

      if ($scope.page.type === 'subscription') {
        $scope.page.type = 'existing-plan';
      }


      var advanced_fields = _.values(_.pick($scope.ngDialogData.prefill, ['success_message', 'redirect_url', 'notification_email', 'subaccount']));
      $scope.advanced_is_visible = _.some(advanced_fields) || $scope.page.custom_fields;
      if ($scope.advanced_is_visible) {
        $scope.custom_fields = $scope.page.custom_fields ? $scope.page.custom_fields : [{
          display_name: ''
        }];
        delete $scope.page.custom_fields;
      }
    }

    $scope.newPlan = {
      currency: $scope.page.currency
    };

    $scope.planFrequencyOptions = [{
      slug: 'hourly',
      name: 'Hourly'
    }, {
      slug: 'daily',
      name: 'Daily'
    }, {
      slug: 'weekly',
      name: 'Weekly'
    }, {
      slug: 'monthly',
      name: 'Monthly'
    }, {
      slug: 'quarterly',
      name: 'Quarterly'
    }, {
      slug: 'biannually',
      name: 'Biannually'
    }, {
      slug: 'annually',
      name: 'Annually'
    }];

    $scope.toggleAdvanced = function () {
      $scope.advanced_is_visible = !$scope.advanced_is_visible;
      $scope.page.slug = null;
      $scope.page.redirect_url = null;
      $scope.custom_fields = [{
        display_name: ''
      }];
    };

    $scope.$on('uploadCompleted', function (event, file) {
      $scope.page.metadata.image_path = file.uploadPath;
      $scope.page_image = null;
    });

    $scope.addProducts = () => {
      ngDialog.open({
        template: 'modules/pages/modals/add-page-products.html',
        controller: 'PageProductsCtrl',
        data: {
          allProducts: products.data,
          page: $scope.createdPage
        },
        showClose: true,
        closeByDocument: false,
      });
    };

    $scope.create = function () {
      var payload = utils.toKobo(angular.copy($scope.page));
      payload.custom_fields = [];

      if (!payload.name && payload.plan_id) {
        var plan = _.find(plans.data, function (plan) {
          return $scope.page.plan_id == plan.id;
        });
        payload.plan = payload.plan_id;
      }

      _.each($scope.custom_fields, function (field) {
        if (field.display_name) {
          payload.custom_fields.push({
            display_name: field.display_name,
            variable_name: field.display_name.replace(/-|\s/g, '_').toLowerCase()
          });
        }
      });

      if (payload.metadata.transaction_charge) {
        var maxAmount = $scope.page.plan ? $scope.page.plan.amount : $scope.page.amount;
        if (payload.metadata.transaction_charge > maxAmount) {
          $scope.$broadcast('pageCreated', false);
          Notification.error('Flat Fee Split Error', 'Your flat fee split cannot be more than the ' + ($scope.page.plan ? 'plan' : 'page') + 'amount');
          return;
        }
      }

      var pagePromise;

      switch ($scope.page.type) {
        case 'payment':
          pagePromise = $API.all('page').post(payload);
          break;
        case 'new-plan': {
          const newPlan = utils.toKobo($scope.newPlan);
          const newPlanWithValidValues = Object.keys(newPlan)
            .reduce((newObject, nextProp) => {
              if (newPlan[nextProp] != null) {
                newObject[nextProp] = newPlan[nextProp];
              }

              return newObject;
            }, {});

          pagePromise = $API.all('plan').post(newPlanWithValidValues).then(function (plan) {
            payload.type = 'subscription';
            payload.name = 'Subscribe to plan: ' + plan.data.name;
            payload.plan = plan.data.id;
            payload.currency = plan.data.currency;
            return $API.all('page').post(payload);
          });
          break;
        }
        case 'custom-plan':
          payload.type = 'plan';
          pagePromise = $API.all('page').post(payload);
          break;
        case 'product':
          pagePromise = $API.all('page').post(payload);
          break;
        default:
          payload.currency = payload.plan ? payload.plan.currency : Session.get('account').default_currency;
          payload.type = 'subscription';
          pagePromise = $API.all('page').post(payload);
          break;
      }

      pagePromise.then(function (response) {
        $state.go('pages.one', {id: response.data.id});
        $scope.createdPage = response.data;
        $scope.createdPage.url = settings.landingPage + '/pay/' + response.data.slug;
      }, function (error) {
        $scope.$broadcast('pageCreated', false);
        Notification.error('Could not create this page', error);
      });
    };
  }])

  .controller('PageProductsCtrl', ['$scope', 'API', 'Notification', '$state', 'ngDialog', 'ProductUtils', function($scope, $API, Notification, $state, ngDialog, ProductUtils) {
    const page = $scope.page || $scope.ngDialogData.page;
    const products = $scope.products || [];
    const pageProductIds = products.map(({ product_id }) => product_id);
    const allProducts = $scope.allProducts || $scope.ngDialogData.allProducts;
    $scope.allProducts = allProducts
      .filter(product => !pageProductIds.includes(product.id))
      .map(product => ({
        ...product,
        description: product.description ? window.striptags(product.description).substring(0, 60) : ''
      }))
      .map(product => ({
        ...product,
        description: product.description && product.description.length >= 60 ? `${product.description}...` : product.description
      }));
    $scope.addedProducts = [];
    $scope.page = page;

    $scope.alreadyAdded = product => $scope.addedProducts.find(item => item.id === product.id);

    $scope.createProduct = () => {
      const dialog = ngDialog.open({
        template: 'modules/products/views/modals/create-product.html',
        controller: 'CreateProductCtrl',
        showClose: false,
        closeByDocument: false,
        data: {
          reload: false
        }
      });

      dialog.closePromise.then((data) => {
        if (data.value && data.value.data) {
          $scope.allProducts.unshift(data.value.data);
        }
      });
    };

    $scope.addProduct = (product) => {
      $scope.addedProducts.push(product);
    };

    $scope.removeProductOnModal = (productId) => {
      const alreadyAddedIndex = $scope.addedProducts.findIndex(item => item.id === productId);
      $scope.addedProducts.splice(alreadyAddedIndex, 1);
    };

    $scope.addProducts = () => {
      const productIds = $scope.addedProducts.map(product => product.id);

      $API.one('page', $scope.page.id).post('product', {
        products: productIds,
      }).then(() => {
        $scope.$broadcast('productsAdded', true);
        Notification.success('Success', 'Added successfully');
        $state.reload();
        $scope.closeThisDialog();
      }).catch((error) => {
        $scope.$broadcast('productsAdded', false);
        Notification.error('Could not add products', error);
      });
    };

    $scope.removeProduct = (productId) => {
      $API.one('page', $scope.page.id)
        .customDELETE(`product/${productId}`).then(() => {
          Notification.success('Update successful', 'Product was removed successfully');
          $state.reload();
          $scope.closeThisDialog();
        }).catch((error) => {
          $scope.$broadcast('productRemoved', false);
          Notification.error('Could not remove product', error);
        });
    };
  }]);
