angular.module('app.audit-logs', ['ui.router'])
  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('audit-logs', {
        abstract: true,
        url: '/audit-logs',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'audit-logs.list',
        title: 'Audit Logs',
        data: {
          permission: 'settings-*',
          authenticable: true,
        }
      }).state('audit-logs.list', {
        templateUrl: '/modules/audit-logs/views/index.html',
        pageTrack: '/audit-logs',
        url: '?from&to&performed_by&action&page',
        controller: 'AuditLogsCtrl',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('audit-log-root'),
      });
    }
  ]);