angular
  .module('app.customers', ['ui.router'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('customers', {
          abstract: true,
          url: '/customers',
          templateUrl: 'components/layout/base.html',
          redirectTo: 'customers.list',
          title: 'Customers',
          data: {
            permission: 'customer-view',
            authenticable: true,
          },
        })
        .state('customers.list', {
          url: '?period&for&from&to&page&category&status&limit&limit_type&next&previous&per_page&email&account_number',
          pageTrack: '/customers',
          title: 'Customers',
          templateUrl: 'modules/customers/list.html',
          controller: 'CustomerListCtrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('customer-list-root'),
        })
        .state('customers.one', {
          title: 'Customers',
          url: '/:id',
          pageTrack: '/customers',
          templateUrl: 'modules/customers/one.html',
          redirectTo: 'customers.one.transactions',
          controller: 'SingleCustomerCtrl',
          showBackButton: true,
          reloadOnSearch: false,
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('customer-detail-root'),
        })
        .state('customers.one.cards', {
          url: '/cards',
          pageTrack: '/customers',
          templateUrl: 'modules/customers/one.html',
          controller: 'SingleCustomerCtrl',
          showBackButton: true,
        })
        .state('customers.one.transactions', {
          url: '/transactions?plan',
          pageTrack: '/customers',
          templateUrl: 'modules/customers/one.html',
          controller: 'SingleCustomerCtrl',
          showBackButton: true,
        })
        .state('customers.one.subscriptions', {
          url: '/subscriptions',
          pageTrack: '/customers',
          templateUrl: 'modules/customers/one.html',
          controller: 'SingleCustomerCtrl',
          showBackButton: true,
        })
        .state('customers.one.mandates', {
          url: '/mandates',
          pageTrack: '/customers',
          templateUrl: 'modules/customers/one.html',
          controller: 'SingleCustomerCtrl',
          showBackButton: true,
        })
        .state('customers.one.account', {
          url: '/account',
          pageTrack: '/customers',
          templateUrl: 'modules/customers/one.html',
          controller: 'SingleCustomerCtrl',
          showBackButton: true,
        })
        .state('customers.one.preauthorizations', {
          url: '/preauthorizations',
          pageTrack: '/customers',
          templateUrl: 'modules/customers/one.html',
          controller: 'SingleCustomerCtrl',
          showBackButton: true,
        });
    },
  ])
  .controller('CustomerListCtrl', [
    '$scope', 'DEFAULTS',
    function ($scope, DEFAULTS) {
      $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage(
            {
              message: 'RENDER_CUSTOMER_LIST',
            },
            '*',
          );
        }
      });
    },
  ])
  .controller('SingleCustomerCtrl', [
    '$scope',
    function ($scope) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage(
            {
              message: 'RENDER_CUSTOMER_DETAIL',
            },
            '*',
          );
        }
      });
    },
  ]);
