angular.module('app.sessions', [])
  .run(['$rootScope', '$state', 'Notification', 'LocalService', 'Session', 'SessionTimer', 'Auth', 'ngDialog',
    function ($rootScope, $state, Notification, LocalService, Session, SessionTimer, Auth, ngDialog) {

      $rootScope.$on('sessionUpdated', function (event, session) {
        $rootScope.currentSession = session;
        $rootScope.User = session.user;
        $rootScope.Business = session.account;
      });

      $rootScope.$on('sessionTimedOut', function () {
        LocalService.set('current_session_timed_out', true);
        if ($state.current.validateTimeout) {
          Auth.reauthenticate().then(function () {
            SessionTimer.reset();
          });
        }
      });

      $rootScope.$on('sessionEnded', function (event, session) {
        if ($rootScope.currentSession) {
          $rootScope.currentSession.token = null;
          $rootScope.currentSession.expiry = null;
        }

        ngDialog.closeAll();
      });

      $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParam) {
        if ($state.includes('app') && !Session.timedOut()) {
          SessionTimer.reset();
        } else {
          SessionTimer.stop();
        }
      });
    }
  ])
  .service('SessionTimer', ['$rootScope', '$timeout', 'LocalService',
    function ($rootScope, $timeout, LocalService) {
      var idleTimer = null;

      function startTimer() {
        idleTimer = $timeout(timerExpiring, 3600000); // 1 hour
      }

      function stopTimer() {
        if (idleTimer) $timeout.cancel(idleTimer);
      }

      function resetTimer() {
        stopTimer();
        startTimer();
      }

      function timerExpiring() {
        stopTimer();
        $rootScope.$broadcast('sessionTimedOut');
      }

      return {
        start: startTimer,
        stop: stopTimer,
        reset: resetTimer
      };
    }
  ])
  .factory('SessionData', [function () {
    return {
      user: null,
      account: null,
      token: null,
      expiry: null,
      features: null,
      mfa_token: null,
    };
  }])
  .service('Session', ['$rootScope', '$q', 'LocalService', 'SessionData', 'SessionTimer', 'CountryService',
    function ($rootScope, $q, LocalService, SessionData, SessionTimer, CountryService) {
      SessionData.token = LocalService.get('current_session');
      SessionData.expiry = LocalService.get('current_session_expiry');
      SessionData.mfa_token = LocalService.get('mfa_token');

      function saveUser(user) {
        const permissions = [...(user.permissions || (user.role && user.role.permissions) || []), 'onboarding-*'];

        user.permissions = permissions;

        if (SessionData.user) {
          SessionData.user = angular.extend(SessionData.user, user);
        } else {
          SessionData.user = new User(user, SessionData.account);
        }

        $rootScope.$broadcast('sessionUpdated', SessionData);
      }

      function saveBusiness(account) {
        if (SessionData.account && SessionData.account.id === account.id) {
          SessionData.account = angular.extend(SessionData.account, account);
        } else {
          SessionData.account = new Business(account);
        }
        SessionData.user.setPrimary(SessionData.account.primary_user);
        $rootScope.$broadcast('sessionUpdated', SessionData);
      }

      function saveFeatures(features = {}) {
        SessionData.features = features || angular.extend(SessionData.features || {}, features);
      }

      function saveToken(token, expiry) {
        LocalService.set('current_session', token);
        LocalService.set('current_session_expiry', expiry);
        SessionData.token = token;
        SessionData.expiry = expiry;
        $rootScope.$broadcast('sessionUpdated', SessionData);
      }

      function saveMfaToken(token) {
        LocalService.set('mfa_token', token);
        SessionData.mfa_token = token;
      }

      function clearMfaToken() {
        LocalService.unset('mfa_token');
        SessionData.mfa_token = null;
      }

      function isActive() {
        return (
          SessionData.token != null &&
          SessionData.expiry != null &&
          moment(new Date()).isBefore(new Date(Number(SessionData.expiry) * 1000))
        );
      }

      function timedOut() {
        return LocalService.get('current_session_timed_out');
      }

      function data() {
        return SessionData;
      }

      function get(key) {
        return SessionData[key];
      }

      function tokenIsExpired() {
        var currentDate = new Date();
        var expiry = LocalService.get('current_session_expiry');
        expiry = new Date(expiry * 1000);
        return currentDate > expiry;
      }

      function validate(state, event) {
        var state = angular.copy(state);
        state.data = state.data || {};
        state.permissions = _.compact([state.permission, state.data.permission]);

        if (SessionData.token === null) {
          event.preventDefault();
          return Promise.reject('Not Logged In');
        } else if (tokenIsExpired()) {
          event.preventDefault();
          return Promise.reject('Token Expired');
        } else if (!SessionData.user) {
          event.preventDefault();
          return Promise.reject('No User Information');
        } else if (state.isSetup) {
          return Promise.resolve(SessionData);
        } else if (!SessionData.account) {
          event.preventDefault();
          if (SessionData.user.integrations.length) {
            return Promise.reject('No Account Information');
          } else {
            return Promise.reject('No Businesses Available');
          }
        } else if (state.validateTimeout && timedOut()) {
          event.preventDefault();
          return Promise.reject('Session Timed Out');
        } else if (state.permissions.length && !hasPermission(state.permissions)) {
          event.preventDefault();
          return Promise.reject('Permission Denied');
        } else {
          return Promise.resolve(SessionData);
        }
      }

      function hasPermission(permissions) {
        if (SessionData.user) {
          return SessionData.user.hasPermission(permissions);
        } else {
          return false;
        }
      }

      function validatePermission(permission) {
        if (_.contains(SessionData.user.permissions, permission)) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      }

      function canAccessCurrentBusiness() {
        if (SessionData.user) {
          var allowed_integrations = _.pluck(SessionData.user.integrations, 'id');
          return _.contains(allowed_integrations, SessionData.user.current_integration);
        } else {
          return false;
        }
      }

      function end() {
        LocalService.unset('current_session');
        LocalService.unset('current_session_expiry');
        LocalService.unset('current_session_timed_out');
        SessionTimer.stop();
        SessionData = {};
        $rootScope.$broadcast('sessionEnded');
      }

      function getDefaultCurrencyByCountryCode(countryCode) {
        const { defaultCurrency } = CountryService.getCountryData(countryCode) || {};        
        return defaultCurrency;
      }

      function User(user, account) {
        angular.extend(this, user);
        this.is_primary_user = false;
        this.roleName = function () {
          return this.role.name.split('-').map(function capitalize(part) {
            return part.charAt(0).toUpperCase() + part.slice(1);
          }).join(' ');
        };
        this.setPrimary = function (primary_user) {
          this.is_primary_user = this.id === primary_user;
        };

        this.permissions = user.permissions;

        this.hasPermission = function (permissions) {
          var availablePermissions = this.permissions || [];
          var requiredPermissions = _.isArray(permissions) ? permissions : [permissions];
          return _.filter(requiredPermissions, function (requiredPermission) {
            return _.contains(availablePermissions, requiredPermission);
          }).length;
        };
        if (account) {
          this.setPrimary(account.primary_user);
        }
      }

      function Business(integration) {
        angular.extend(this, integration);
        if (_.isObject(integration.primary_user)) {
          this.primary_user = integration.primary_user.id;
        }
        
        this.default_currency = this.default_currency || getDefaultCurrencyByCountryCode(this.attributes.country_code) || 'NGN';

        if (this.starterintegration && this.starterintegration.length) {
          this.starter_integration = _.last(this.starterintegration).id;
          this.business_upgrade_requested = _.last(this.starterintegration).business_upgrade_requested;
          this.business_upgrade_requested_at = _.last(this.starterintegration).business_upgrade_requested_at;
        }
        if (this.business_type === 'starter') {
          this.can_transfer = false;
          this.registration_document_type = 'bn';
        }
      }

      function defaultCurrency() {
        return SessionData.account.default_currency;
      }

      return {
        saveUser: saveUser,
        saveBusiness: saveBusiness,
        saveFeatures: saveFeatures,
        saveToken: saveToken,
        saveMfaToken: saveMfaToken,
        clearMfaToken: clearMfaToken,
        isActive: isActive,
        timedOut: timedOut,
        isExpired: tokenIsExpired,
        validate: validate,
        hasPermission: hasPermission,
        validatePermission: validatePermission,
        canAccessCurrentBusiness: canAccessCurrentBusiness,
        defaultCurrency: defaultCurrency,
        data: data,
        get: get,
        end: end
      };
    }
  ]);
