angular.module('app.preauthorizations', ['ui.router'])  .config(['$stateProvider',
function ($stateProvider) {
  $stateProvider.state('preauthorizations', {
    abstract: true,
    url: '/preauthorizations',
    title: 'Preauthorizations',
    templateUrl: 'components/layout/base.html',
    redirectTo: 'preauthorizations.list',
    data: {
      permission: 'transaction-view',
      authenticable: true
    }
  }).state('preauthorizations.list', {
    title: 'Preauthorizations',
    url: '?reference&customer&amount&to&from&status&period&page',
    pageTrack: '/preauthorizations',
    templateUrl: 'modules/preauthorizations/views/list.html',
    controller: 'PreauthorizationCtrl',
    onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('preauthorizations-root'),
  }).state('preauthorizations.one', {
    title: 'Preauthorization',
    url: '/:id',
    pageTrack: '/preauthorizations',
    templateUrl: 'modules/preauthorizations/views/one.html',
    controller: 'PreauthorizationCtrl',
    redirectTo: 'preauthorizations.one.analytics',
    onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('preauthorizations-root'),
  }).state('preauthorizations.one.analytics', {
    url: '/analytics',
    pageTrack: '/preauthorizations',
    templateUrl: 'modules/preauthorizations/views/one.html',
    controller: 'PreauthorizationCtrl',
    onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('preauthorizations-root'),
  });
}
])
.controller('PreauthorizationCtrl', ['$scope', function ($scope) {
  $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
    if (mfeServiceReady) {
      window.postMessage({
        message: 'RENDER_PREAUTHORIZATION_MICROFRONTEND',
      }, '*');
    }
  });
}])
