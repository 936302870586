UIDirectives
  .directive('gravatar', ['$timeout', function($timeout) {
    return {
      restrict: 'E',
      scope: {
        email: '=',
        firstName: '=',
        lastName: '=',
        size: '=',
      },
      template: `
        <div class="gravatar">
          <img ng-show="imageStatus === 'fetched'" class="gravatar-img" gravatar-size="{{ size || 100 }}" gravatar-src="email" />
          <span ng-show="imageStatus === 'error'">{{firstName | limitTo: 1}}{{lastName | limitTo: 1}}</span>
        </div>
        `,
      link: function($scope, element) {
        $scope.imageStatus = 'idle';

        const handleImageStatus = (status) => {
          $timeout(() => {
            $scope.imageStatus = status;
          });
        };

        element.find('img')
          .bind('load', () => {
            handleImageStatus('fetched');
          })
          .bind('error', () => {
            handleImageStatus('error');
          });
      }
    };
  }]);