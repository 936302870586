angular.module('app')
  .directive('privateFileLink', function () {
    return {
      restrict: 'E',
      template: `<a class="text-primary" ui-sref="files.view({ location: '{{encodedPath}}' })" target="_blank">{{displayText}}</a>`,
      replace: true,
      scope: {
        path: '=',
        displayText: '@',
      },
      controller: ['$scope', function ($scope) {
        $scope.encodedPath = encodeURI($scope.path);
      }]
    };
  });
