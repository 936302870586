angular.module('app.apiFactories', ['restangular'])
  .factory('LocalAPI', ['Restangular', 'DEFAULTS', function (Restangular, DEFAULTS) {
    return Restangular.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(DEFAULTS.baseURL);
    });
  }])
  .factory('API', ['Restangular', 'DEFAULTS', 'LocalService', 'Session', '$state', function (Restangular, DEFAULTS, LocalService, Session, $state) {
    return Restangular.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(DEFAULTS.liveURL);
      RestangularConfigurer.addFullRequestInterceptor(function (element, operation, route, url, headers, params, httpConfig) {
        const mfaTokenRoutes = ['verify-mfa', 'send-otp'];
        const isMFATokenSupportedRoute = url && mfaTokenRoutes.includes(url.split('/').pop());
        const token = isMFATokenSupportedRoute ? Session.get('mfa_token') : Session.get('token');

        if (token) {
          headers.Authorization = `Bearer ${token}`;
          headers['jwt-auth'] = true;
        }

        return {
          element: element,
          headers: headers,
          params: params,
          httpConfig: httpConfig
        };
      });

      let nextState;

      RestangularConfigurer.setErrorInterceptor(function(response, deferred, responseHandler) {
        const { status, data: responseData } = response;

        if (status === 401 && (responseData && responseData.message === 'Invalid token')) {
          if (!nextState) {
            nextState = $state.current.name;
          }

          Session.end();
          deferred.reject();
          $state.go('access.login', {
            next: nextState
          });

          return false; // error handled
        }

        if (status === 403 && (responseData && responseData.data && responseData.data.enable_mfa)) {
          const shouldRedirect = !!$state.current.name && !String($state.current.name).startsWith('access.');

          $state.go("access.access-denied", {
            reason: 'no-mfa',
            ...(shouldRedirect && { next: $state.current.name })
          });

          return false; // error handled
        }

        return true; // error not handled
      });
    });
  }])
  .factory('MockAPI', ['Restangular', 'DEFAULTS', 'LocalService', 'Session', function (Restangular, DEFAULTS, LocalService, Session) {
    return Restangular.withConfig(function (RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(DEFAULTS.mockURL);
      RestangularConfigurer.addFullRequestInterceptor(function (element, operation, route, url, headers, params, httpConfig) {
        const token = Session.get('token');

        if (token) {
          headers.Authorization = `Bearer ${token}`;
          headers['jwt-auth'] = true;
        }

        return {
          element: element,
          headers: headers,
          params: params,
          httpConfig: httpConfig
        };
      });
    });
  }])
  .factory('FeedbackAPI', ['Restangular', function(Restangular) {
    return Restangular.withConfig(function(RestangularConfigurer) {
      RestangularConfigurer.setBaseUrl(settings.FEEDBACK_API_URL);
      RestangularConfigurer.addFullRequestInterceptor(function (element, operation, route, url, headers, params, httpConfig) {
        return {
          element: element,
          headers: headers,
          params: params,
          httpConfig: httpConfig
        };
      });
    });
  }]);
