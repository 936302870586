UIDirectives
  .directive('hiddenUntil', ['$rootScope', function ($rootScope) {
    return {
      transclude: 'element',
      restrict: 'A',
      link: function (scope, element, attr, ctrl, transclude) {
        var previousContent = null;

        var render = function () {
          transclude(function (content) {
            if (previousContent) previousContent.remove();
            previousContent = content;
            element.parent().append(content);
          });
        };

        $rootScope.$on(attr.hiddenUntil, render);
      }
    };
  }]);