ProductModule.factory('ProductFactory', [
  'API',
  '$rootScope',
  'Notification',
  'ProductConstants',
  'ProductUtils',
  '$stateParams',
  'utils',
  function ($API, $rootScope, Notification, ProductConstants, ProductUtils, $stateParams, utils) {
    const { currencyBaseUnitToSubUnit } = utils;

    function listProducts() {
      return $API.one('product').get();
    }

    function createProduct(product) {
      if (product.unlimited && _.has(product, 'quantity')) {
        delete product.quantity;
      }

      return $API
        .all('product')
        .post({
          ...product,
          price: currencyBaseUnitToSubUnit(product.price),
        });
    }

    function updateProduct(product, callback) {
      const payload = {
        ...product,
      };

      delete payload.url;

      if (payload.unlimited && _.has(payload, 'quantity')) {
        delete payload.quantity;
      }

      if (payload.description) {
        const stripped = window.striptags(payload.description, ProductConstants.DESCRIPTION_AVAILABLE_TAGS);
        payload.description = stripped;
      }

      const mappedFiles =
        Array.isArray(payload.files) &&
        payload.files.length &&
        payload.files.map(function (file) {
          return {
            key: file.key,
            type: file.type,
            path: file.path,
            original_filename: file.original_filename || file.key,
          };
        });

      payload.files = mappedFiles || payload.files;

      return $API
        .one('product', payload.id)
        .customPUT(payload)
        .then(
          (response) => {
            if (callback && typeof callback === 'function') {
              return callback(null, response);
            }
          },
          (error) => {
            $rootScope.$broadcast('productUpdated', false);
            Notification.error('Could not update this product', error);

            return callback(error, null);
          },
        );
    }

    function deleteProduct(productId, callback) {
      $API
        .one('product', productId)
        .customDELETE()
        .then(callback);
    }

    function restoreProduct(product, callback) {
      const payload = {
        ...product,
      };

      payload.active = true;

      $API
        .one('product', payload.id)
        .customPUT(payload)
        .then(callback);
    }

    function publishProduct(productId) {
      return $API
        .one(`product/${productId}/migrate`)
        .post();
    }

    function duplicateProduct(productId) {
      return $API
        .one(`product/${productId}/duplicate`)
        .post();
    }

    function saveOption(product, option) {
      const { id } = product;

      return $API
        .all(`product/${id}/option`)
        .post(option);
    }

    function updateOption(productId, option) {
      const { id: optionId, ...optionProps } = option;

      return $API
        .one(`product/${productId}/option/${optionId}`)
        .customPUT(optionProps);
    }

    function deleteOption(product, optionId) {
      const { id: productId } = product;

      return $API
        .one(`product/${productId}/option/${optionId}`)
        .customDELETE();
    }

    function updateOptionRank(productId, option) {
      const { id: optionId, rank } = option;

      return $API
        .one(`product/${productId}/option/${optionId}`)
        .customPUT({
          rank,
        });
    }

    function updateOptionValue(productId, optionId, optionValue) {
      const { id: valueId } = optionValue;

      return $API
        .one(`product/${productId}/option/${optionId}/value/${valueId}`)
        .customPUT(optionValue);
    }

    function deleteOptionValue(productId, optionId, optionValue) {
      const { id: valueId } = optionValue;

      return $API
        .one(`product/${productId}/option/${optionId}/value/${valueId}`)
        .customDELETE();
    }

    function getVariants() {
      return $API
        .one(`product/${$stateParams.id}/variant`)
        .get($stateParams);
    }

    function getVariantOptions() {
      return $API.one(`product/${$stateParams.id}/option`)
        .get();
    }

    function updateVariant(productId, variantId, variantValue) {
      return $API
        .one(`product/${productId}/variant/${variantId}`)
        .customPUT(variantValue);
    }

    function bulkUpdateVariants(productId, variantIds, variantField) {
      return $API
        .one(`product/${productId}/variant/bulk-update`)
        .customPUT({
          variant_ids: variantIds,
          ...variantField,
        });
    }

    function listProductOrders(orderId, params) {
      return $API.one(`order/product/${orderId}`)
        .get({
          ...params
        });
    }

    function getOrder(orderId) {
      return $API.one(`order/${orderId}`)
        .get();
    }

    function updateOrderStatus(order) {
      delete order.index;

      return $API
        .one('order', order.id)
        .customPUT(order);
    }

    function updateOrderShipment(order) {
      const payload = {};
      payload.shipping = { ...order.shipping };
      payload.shipping_method = {
        ...order.shipping_method,
      };

      return $API
        .one('order', order.id)
        .customPUT(payload);
    }

    function preloadOrder(orderId) {
      $rootScope.$broadcast('resetView');

      return $API.one(`order/${orderId}`)
        .get();
    }

    function listOrderLogs(orderId) {
      return $API.one(`order/${orderId}/log`)
        .get();
    }

    function checkSlug(slug, canceler) {
      return $API.one(`product/slug/${slug}/status`)
        .withHttpConfig({ timeout: canceler.promise })
        .get();
    }

    function exportProductOrdersToCsv(params, stateParams) {
      const { id, ...queryParams } = stateParams;
      return $API.one(`order/product/${id}/export?export_columns=${params}`)
        .get({...queryParams});
    }

    function exportOrdersToCsv(params, extraParams) {
      return $API.one(`order/export?export_columns=${params}`)
        .get({
          ...extraParams
        });
    }

    function savePostPurchaseInfo({
      id,
      redirect_url,
      success_message,
      split_code,
      notification_emails,
    }) {

      return $API.one('product', id)
        .customPUT({
          redirect_url,
          success_message,
          split_code,
          notification_emails,
        });
    }

    function addProductDigitalFiles(productId, payload) {
      return $API
        .all(`product/${productId}/digital/assets`)
        .post(payload);
    }

    function updateDigitalFile(id, fileName) {
      return $API
        .one(`product/digital/assets/${id}`)
        .customPUT({original_name: fileName});
    }

    function deleteDigitalFile(id) {
      return $API
        .one(`product/digital/assets/${id}`)
        .customDELETE();
    }

    return {
      listProducts,
      createProduct,
      updateProduct,
      deleteProduct,
      restoreProduct,
      publishProduct,
      duplicateProduct,
      saveOption,
      updateOption,
      deleteOption,
      updateOptionRank,
      updateOptionValue,
      deleteOptionValue,
      getVariants,
      getVariantOptions,
      updateVariant,
      bulkUpdateVariants,
      listProductOrders,
      updateOrderShipment,
      preloadOrder,
      getOrder,
      updateOrderStatus,
      listOrderLogs,
      checkSlug,
      exportProductOrdersToCsv,
      exportOrdersToCsv,
      savePostPurchaseInfo,
      addProductDigitalFiles,
      updateDigitalFile,
      deleteDigitalFile,
    };
  }
]);
