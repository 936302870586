ProductModule
  .controller('CreateProductCtrl', [
    '$scope',
    'Notification',
    '$state',
    '$rootScope',
    'ProductIntroService',
    '$timeout',
    'ProductFactory',
    'ngDialog',
    'MediaUploadService',
    'AnalyticsService',
    'Upload',
    'ProductUtils',
    'ProductConstants',
    function ($scope, Notification, $state, $rootScope, ProductIntroService, $timeout, ProductFactory, ngDialog, MediaUploadService, AnalyticsService, Upload, ProductUtils, ProductConstants) {
      const { logEvent } = AnalyticsService;

      $scope.createproductResponse = null;
      $scope.currencies = $rootScope.Business.allowed_currencies;
      $scope.product = {
        currency:
          $rootScope.Business.allowed_currencies &&
          $rootScope.Business.allowed_currencies[0],
        unlimited: true,
        description: '',
        files: [],
        hasPhysicalGoods: false,
        is_shippable: false,
        type: 'service',
        shipping_fields: {
          delivery_note: 'disabled',
          shipping_address: 'disabled',
          shipping_fees: []
        }
      };

      // File upload scope
      $scope.processing = false;
      $scope.filesToUpload = null;
      $scope.files = [];
      $scope.uploadedFiles = [];
      $scope.isUploading = false;
      $scope.isUpdatingProduct = false;

      $scope.toggleIsShippable = () => {
        $scope.product.is_shippable = !$scope.product.is_shippable;

        if ($scope.product.is_shippable) {
          $scope.product.shipping_fields.delivery_note = 'optional';
          $scope.product.shipping_fields.shipping_address = 'required';
          $scope.product.type = 'good';
        } else {
          $scope.product.shipping_fields.delivery_note = 'disabled';
          $scope.product.shipping_fields.shipping_address = 'disabled';
          $scope.product.type = 'service';
        }
      };

      const navigateTo = (response) => {
        const { data: { id } } = response;
        const currentPage = $state.current.pageTrack;
        const currentUrl = $state.current.url;
        const tabToNavigate = ProductIntroService.isFirstProductCreated() ? 'link' : 'orders';

        if(currentPage === '/products') {
          $state.go(`products.one.${tabToNavigate}`, {
            id,
          }).then(() => {
            showIntro();
          });
        } else if(currentPage === '/storefronts') {
          const storefrontId = $scope.storefront.id;

          if(currentUrl.includes('/products')) {
            $state.reload();
          } else {
            $state.go('storefronts.products', {
              id: storefrontId,
            });
          }
        }
      };

      const showIntro = () => {
        if (ProductIntroService.isFirstProductCreated()) {
          return;
        }

        $timeout(() => {
          introJs()
            .addSteps(ProductIntroService.ordersTabSteps)
            .setOptions(ProductIntroService.options)
            .start()
            .oncomplete(() => {
              ProductIntroService.setFirstProductIsCreated();
              ProductIntroService.setOrdersTabTourAsFinished();
            });
        }, 500);
      };

      $scope.createProduct = (storefront) => {
        if(storefront) {
          $scope.storefront = storefront;
          $scope.product.storefront = storefront.id;
        }

        ProductFactory.createProduct($scope.product)
          .then((response) => {
            Notification.success(
              'Created successfully',
              'Product has been created successfully',
            );

            return response;
          })
          .then((response) => {
            $scope.createproductResponse = response;

            // we use copyProduct here as this the variable required in
            // /modules/products/views/modals/upload.html
            $scope.copyProduct = response.data;
            $scope.copyProduct.files = [];
          })
          .catch((error) => {
            $scope.$broadcast('productCreated', false);
            Notification.error('Could not create the product', error);
          });
      };

      // File upload functions
      $rootScope.$on('isUploading', (event, isUploading) => {
        $scope.isUploading = isUploading;
      });

      $scope.resetUploadedFiles = () => {
        $scope.uploadedFiles = [];
      };

      $scope.cancelUpload = (file) => {
        file.upload.abort();
      };

      const finishUpload = () => {
        if ($scope.uploadedFiles.length !== $scope.files.length) {
          return;
        }

        $scope.isUploading = false;
        $scope.processing = false;

        const openDialogs = ngDialog.getOpenDialogs();

        if (Array.isArray(openDialogs) && !openDialogs.length) {
          ngDialog.open({
            templateUrl: '/modules/products/views/modals/upload.html',
            showClose: false,
            scope: $scope,
            closeByDocument: false,
          });
        }
      };

      const addFilesToProduct = (file, signedUrlResponse) => {
        const { key, path } = signedUrlResponse;
        const type = ProductUtils.getMediaType(file.type);
        const fileProps = {
          key,
          path,
          original_filename: file.name,
          type,
        };

        if (Array.isArray($scope.copyProduct.files)) {
          $scope.copyProduct.files.push(fileProps);
        } else {
          $scope.copyProduct.files = [fileProps];
        }
      };

      const uploadFiles = (files, signedUrlList) => {
        files.forEach((file, fileIndex) => {
          const signedUrlResponse = signedUrlList[fileIndex];

          file.upload = Upload.http({
            url: window.location.href.includes('localhost')
              ? `https://cors-anywhere.herokuapp.com/${signedUrlResponse.signed_url}`
              : signedUrlResponse.signed_url,
            method: 'PUT',
            headers: {
              'Content-Type': file.type,
            },
            data: file,
          });

          file.upload.then((response) => {
            $timeout(() => {
              file.result = response.data;
              file.status = response.status;
            });

            return response;
          }, (response) => {
            if (response.status > 0)
              $scope.errorMsg = `${response.status}: ${response.data}`;
          }, (evt) => {
            file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
          }).then((response) => {
            if (!response) {
              $scope.files = $scope.files.filter(currentFile => currentFile !== file);
              return finishUpload();
            }

            $scope.uploadedFiles.push(file);
            addFilesToProduct(file, signedUrlResponse);
            finishUpload();
          });
        });
      };

      $scope.upload = (fileList) => {
        $scope.resetUploadedFiles();
        const filesToUpload = MediaUploadService.getValidFiles(fileList, 'standard');

        if (!filesToUpload.length) {
          return;
        }

        $scope.files = MediaUploadService.removeUnnecessaryFiles(filesToUpload, $scope.copyProduct, {
          maxFileUploadCount: ProductConstants.MAX_UPLOAD_COUNT,
          fileType: 'standard'
        });
        $scope.processing = true;
        $rootScope.$broadcast('isUploading', true);
        logEvent('Media upload started');

        MediaUploadService.getSignedUrls($scope.files).then((signedUrlsResponse) => {
          uploadFiles($scope.files, signedUrlsResponse);
        });
      };

      $scope.saveUpload = () => {
        $scope.isUpdatingProduct = true;

        const payload = {
          id: $scope.copyProduct.id,
          files: $scope.copyProduct.files,
        };

        ProductFactory.updateProduct(payload, () => {
          $scope.closeThisDialog(data);
          navigateTo($scope.createproductResponse);
        });
      };

      $scope.closeUploadModal = (modalScope) => {
        modalScope.closeThisDialog();

        if($scope.product.files && $scope.product.files.length) {
          $scope.copyProduct.files = angular.copy($scope.product.files);
        }

        $state.reload();
      };

      $scope.deleteImage = (index) => {
        const newFiles = $scope.copyProduct.files
          .slice(0, index)
          .concat(
            $scope.copyProduct.files.slice(
              index + 1,
              $scope.copyProduct.files.length,
            ),
          );

        $scope.copyProduct.files = newFiles.length ? newFiles : null;
      };
    },
  ]);
