angular.module('app.compliance').controller('ComplianceAccountController', [
  '$scope',
  '$location',
  '$rootScope',
  'ComplianceAccount',
  'Notification',
  '$state',
  'AnalyticsService',
  'BankService',
  'CountryService',
  'ComplianceProfile',
  'FileService',
  'RegistrationDocuments',
  'utils',
  '$i18next',
  'Onboarding3Service',
  'DEFAULTS',
  (
    $scope,
    $location,
    $rootScope,
    ComplianceAccount,
    Notification,
    $state,
    AnalyticsService,
    BankService,
    CountryService,
    ComplianceProfile,
    FileService,
    RegistrationDocuments,
    utils,
    $i18next,
    Onboarding3Service,
    DEFAULTS,
  ) => {
    Onboarding3Service.redirectToOnboarding3IfEnabled();
    const { logEvent } = AnalyticsService;
    $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

    $scope.accountStatus = $scope.complianceStatusData.modules.find(module =>
      ['ACCOUNT-STARTER', 'ACCOUNT'].includes(module.code),
    );
    $scope.useEditMode =
      ($state.params && $state.params.edit) || !$scope.accountStatus.completed;
    const countryData = CountryService.getCountryData($scope.country);
    const { Business } = $rootScope;
    $scope.currency =
      Business.default_currency || (countryData && countryData.defaultCurrency);
    $scope.countryCode =
      (Business.attributes.country && Business.attributes.country.iso_code) ||
      Business.attributes.country_code;
    $scope.documentActions = {
      remove: $i18next.t('complianceAccount.removeFile'),
      view: $i18next.t('complianceAccount.viewFile'),
    };

    $scope.getCountryAccountDataByCurrency = ({ countryCode, currency }) =>
      CountryService.getCountryAccountDataByCurrency(countryCode, currency);

    $scope.getAccountNumberInputLabel = countryData => {
      if ($scope.payoutAccount && $scope.payoutAccount.accountType === 'mobile_money') {
        $scope.isPaybillTill = false;
        if ($scope.payoutAccount.bankCode === 'MPPAYBILL') {
          $scope.isPaybillTill = true;
          return $i18next.t('complianceAccount.labels.mpesaPaybill');
        }
        if ($scope.payoutAccount.bankCode === 'MPTILL') {
          $scope.isPaybillTill = true;
          return $i18next.t('complianceAccount.labels.mpesaTill');
        }
        return $i18next.t('complianceAccount.labels.phoneNumber');
      }
      const countryAccountDataByCurrency = $scope.getCountryAccountDataByCurrency(countryData);
      const accountNumberInputLabel = countryAccountDataByCurrency && countryAccountDataByCurrency.accountNumberInputLabel;
      return $i18next.t(
        `complianceAccount.labels.${
          accountNumberInputLabel || 'accountNumber'
        }`,
      );
    };
    
    $scope.getAccountNameLabel = (countryData) => {
      const countryAccountDataByCurrency = $scope.getCountryAccountDataByCurrency(countryData);
      const accountNameInputLabel = countryAccountDataByCurrency && countryAccountDataByCurrency.accountNameInputLabel;

      return $i18next.t(
        `complianceAccount.labels.${
          accountNameInputLabel || 'accountName'
        }`,
      );
    }

    $scope.getAccountNumberErrorMessage = countryData => {
      if ($scope.payoutAccount && $scope.payoutAccount.accountType === 'mobile_money') {
        if (['MPPAYBILL', 'MPTILL'].includes($scope.payoutAccount.bankCode)) {
          return $i18next.t('complianceAccount.errors.invalid.tillOrPaybillNumber');
        }
        return $i18next.t('complianceAccount.errors.invalid.phoneNumber');
      }
      const countryAccountDataByCurrency = $scope.getCountryAccountDataByCurrency(countryData);
      const accountNumberInputLabel = countryAccountDataByCurrency && countryAccountDataByCurrency.accountNumberInputLabel;
      return $i18next.t(
        `complianceAccount.errors.invalid.${accountNumberInputLabel}` ||
          'complianceAccount.errors.invalid.accountNumber',
      );
    };

    $scope.getAccountNumberTooltip = countryData =>
      $scope.getCountryAccountDataByCurrency(countryData) &&
      $scope.getCountryAccountDataByCurrency(countryData).accountNumberTooltip;

    $scope.getAccountNumberPattern = countryData => {
      const countryAccountDataByCurrency = $scope.getCountryAccountDataByCurrency(countryData);
      return countryAccountDataByCurrency && countryAccountDataByCurrency.accountNumberPattern;
    }

    ComplianceProfile.fetchSavedBusinessProfile()
      .then(businessProfileData => {
        $scope.businessProfileData = businessProfileData;
        $scope.isRegistered = businessProfileData.businessType === 'registered';
        $scope.country = businessProfileData && businessProfileData.country;
        $scope.isGhanaianBusiness = $scope.country === 'GH';
        $scope.isKenyaianBusiness = $scope.country === 'KE';
        $scope.isGhanaOrKenya = $scope.isGhanaianBusiness || $scope.isKenyaianBusiness;
        return Promise.all([
          ComplianceAccount.fetchPayoutAccount(),
          BankService.init({ currency: $scope.currency }),
        ]);
      })
      .then(([payoutAccount, banks]) => {
        $scope.payoutAccount = (payoutAccount && payoutAccount.data) || {};
        $scope.payoutAccountMeta = (payoutAccount && payoutAccount.meta) || {};
        $scope.banks = banks;
        $scope.payoutBank =
            $scope.banks && $scope.banks.find(({ code }) => code === $scope.payoutAccount.bankCode);
        if ($scope.payoutBank) {
          const isMobileMoneyPayoutBankType = ['mobile_money', 'mobile_money_business'].includes($scope.payoutBank.type);
          $scope.payoutAccount.accountType = isMobileMoneyPayoutBankType ? 'mobile_money' : $scope.payoutBank.type;
        }
        logEvent('page_load', {
          pageName: $state.current.name,
          state: 'succeeded',
        });
        $scope.$broadcast('accountPageReady');
      })
      .catch(error => {
        logEvent('page_load', {
          pageName: $state.current.name,
          state: 'failed',
        });
        Notification.error('Could not fetch payout account', error);
      });

    const ABSA_ACCOUNT_NUMBER_STARTS_WITH_ZERO = /^0\d{6,7}$/;
    const ABSA_ACCOUNT_NUMBER_REGEX = /^(\d{7}|\d{8})$/;
    const ABSA_BANK_ID = 76;
    const MPESATILLPATTERN = /^(?!522522|329329|303030|880100|982800|400200|547700|986500|100229|570425|972900|508400|972700|862862|600100|111777|700201|498100|907950|529901|910200|842100|344500|542542|552800|100400|200999|516600|961610|111999|247247|222111|985050|919700|342342|559900|328585|899790|888880)[0-9]{6,}$/

    $scope.$watch('payoutAccount.bankCode', bankCode => {
      if (bankCode) {
        $scope.payoutBank =
          $scope.banks && $scope.banks.find(({ code }) => code === bankCode);
        $scope.payoutBankTypeIsMobileMoney = $scope.payoutBank && ($scope.payoutBank.type === 'mobile_money' || $scope.payoutBank.type === 'mobile_money_business');
        $scope.isGhanaianAbsaBankSelected =
          $scope.payoutBank && $scope.payoutBank.id === ABSA_BANK_ID;
        const isBranchCodeRequired =
            $scope.payoutAccount.type !== 'mobile_money' &&
            ($scope.payoutBank.type === 'kepss' || $scope.payoutAccount.type === 'ghipss');
        $scope.payoutAccountMeta.branchCode = !!($scope.isGhanaianAbsaBankSelected || isBranchCodeRequired);

        if ($scope.payoutBank && ['MPTILL', 'MPPAYBILL'].includes($scope.payoutBank.code)) {
          $scope.accountPattern = MPESATILLPATTERN;
        } else {
          const accountNumberPattern = $scope.getAccountNumberPattern({
            countryCode: $scope.countryCode.toString(),
            currency: $scope.currency.toString()
          });
          const accountPattern = accountNumberPattern && accountNumberPattern.pattern;
          $scope.accountPattern = accountPattern || $scope.payoutAccountMeta.inputPattern;
        }

        if ($scope.isGhanaianBusiness && $scope.isGhanaianAbsaBankSelected) {
          BankService.getBankBranches($scope.payoutBank.id)
            .then(bankBranches => {
              $scope.bankBranches = bankBranches;
            })
            .catch(() => {
              $scope.bankBranches = [];
            });
        }
      }
    });

    $scope.$watch('payoutAccount.accountType', accountType => {
      const accountTypeIsMobileMoney = accountType === 'mobile_money';
      $scope.isBankSelected = !!(accountType && accountType !== 'mobile_money');
      if (accountType) {
        BankService.init({ currency: $scope.currency }).then(
          banks => {
            $scope.banks = banks.filter(bank =>
                accountTypeIsMobileMoney
                    ? bank.type === 'mobile_money' || bank.type === 'mobile_money_business'
                    : bank.type === accountType
            );
          },
        );
      }
    });

    $scope.clearSelectedBankName = function () {
      // Clear the bank code and branch code input fields.
      this.payoutAccount.bankCode = '';
      this.payoutAccount.branchCode = '';

      // Set the selected bank to null.
      $scope.isGhanaianAbsaBankSelected = null;
    };

    const getValidAbsaAccountNumber = () => {
      const isIncompleteAbsaAccountNumber =
        $scope.payoutAccount &&
        $scope.payoutAccount.accountNumber &&
        ABSA_ACCOUNT_NUMBER_REGEX.test($scope.payoutAccount.accountNumber);
      const zeroStartsAbsaAccountNumber =
        $scope.payoutAccount &&
        $scope.payoutAccount.accountNumber &&
        ABSA_ACCOUNT_NUMBER_STARTS_WITH_ZERO.test(
          $scope.payoutAccount.accountNumber,
        );
      const branchCode = $scope.payoutAccount.branchCode.slice(-2);

      if (zeroStartsAbsaAccountNumber) {
        const modifiedAbsaAcccountNumber =
          $scope.payoutAccount.accountNumber.replace(/^0/, branchCode);
        return `${modifiedAbsaAcccountNumber}`;
      }

      if (isIncompleteAbsaAccountNumber) {
        return `${branchCode}${$scope.payoutAccount.accountNumber}`;
      }

      return $scope.payoutAccount.accountNumber;
    };

    $scope.accountResolutionStatus = null;
    $scope.verifyAccountNumber = () => {
      const { accountNumber, bankCode } = $scope.payoutAccount;
      const isAbsaBank =
        $scope.payoutBank && $scope.payoutBank.id === ABSA_BANK_ID;

      $scope.accountResolutionStatus = 'pending';

      if ($scope.isGhanaianBusiness && isAbsaBank) {
        $scope.ghanaianAbsaAccountNumber = getValidAbsaAccountNumber();
      }

      if (accountNumber && bankCode) {
        logEvent('compliance-account_verify-account-number', {
          state: 'started',
        });
        const accountNumberToBeResolved =
          $scope.ghanaianAbsaAccountNumber || accountNumber;
        BankService.validate(accountNumberToBeResolved, bankCode)
          .then(() =>
            BankService.resolve(
              accountNumberToBeResolved,
              bankCode,
              $scope.currency,
            ),
          )
          .then(response => {
            if (response === 'unconfirmed') {
              $scope.accountResolutionStatus = null;
              logEvent('compliance-account_verify-account-number', {
                state: 'failed',
              });
            } else {
              $scope.payoutAccount.accountName =
                response && response.account_name;
              $scope.accountResolutionStatus = 'success';
              logEvent('compliance-account_verify-account-number', {
                state: 'succeeded',
              });
            }
          });
      }
    };

    const saveAllDocuments = () =>
      $scope.payoutAccount.documents.map(
        ({ type, code, documentData: { locations, value } }) => {
          const payload = { code };
          const isFileDocument = type === 'file';

          if (isFileDocument) {
            payload.locations =
              FileService.extractFilePathFromUploader(locations);
          } else {
            payload.value = value;
          }

          return RegistrationDocuments.saveDocument(payload);
        },
      );

    const validatePayoutAccount = (accountNumber, bankCode) => {
      let formattedAccountNumber = '';
      if ($scope.countryCode === 'CI') {
        if (!utils.validateRIB(accountNumber)) {
          return {
            isValid: false,
            error: $i18next.t('complianceAccount.errors.invalid.rib'),
          };
        }
        if (!accountNumber.toLowerCase().includes(bankCode.toLowerCase())) {
          return {
            isValid: false,
            error: $i18next.t('complianceAccount.errors.invalid.ribBank'),
          };
        }
      }
      if ($scope.countryCode === 'EG') {
        formattedAccountNumber = accountNumber.replace(/\s/g, '');
        if (!utils.validateIBAN(formattedAccountNumber)) {
          return {
            isValid: false,
            error: $i18next.t('complianceAccount.errors.invalid.iban'),
          };
        }
      }
      return {
        isValid: true,
        error: '',
        validAccountNumber: formattedAccountNumber || accountNumber,
      };
    };

    $scope.savePayoutAccount = () => {
      const { accountNumber, bankCode } = $scope.payoutAccount;

      const payoutAccountNumber =
        $scope.ghanaianAbsaAccountNumber || accountNumber;

      const { isValid, error, validAccountNumber } = validatePayoutAccount(
        payoutAccountNumber,
        bankCode,
      );
      if (!isValid) {
        $rootScope.$broadcast('savedPayoutAccount', false);
        Notification.error('Could not save payout account', error);
        return;
      }
      logEvent('Attempted to save compliance module', {
        module: $scope.accountStatus.code,
      });
      const payload = {
        ...$scope.payoutAccount,
        accountNumber: validAccountNumber,
        currency: $scope.currency,
        // update the account type to mobile_money_business for mobile money bank accounts
        ...(['MPTILL', 'MPPAYBILL'].includes($scope.payoutAccount.bankCode) && { accountType: 'mobile_money_business' }),
        // send a fake branch code for mobile money bank accounts till the API restriction is removed
        ...($scope.payoutBankTypeIsMobileMoney && { branchCode: '00000000' }),
      };
      Promise.all([
        ComplianceAccount.savePayoutAccount(payload),
        ...saveAllDocuments(),
      ])
        .then(() => {
          $rootScope.$broadcast('savedPayoutAccount');
          logEvent('Successfully saved compliance module', {
            module: $scope.accountStatus.code,
          });
          $scope.reloadWithParams({ edit: null });
        })
        .catch(error => {
          $rootScope.$broadcast('savedPayoutAccount', false);
          const message = error && error.message;
          logEvent('Error saving compliance module', {
            module: $scope.accountStatus.code,
            message,
          });
          Notification.error('Could not save payout account', error);
        });
    };

    $scope.switchToEditMode = () => {
      $scope.reloadWithParams({ edit: 'true' });
    };

    $scope.$watch('accountStatus.completed', value => {
      if (!value) {
        $location.search('edit', 'true');
      }
    });
  },
]);
