angular.module('app.fileService', [])
  .service('FileService', () => {

    const extractFileNameFromDocumentLocation = (location) => !location ? '' : location.split('/').slice(-1)[0];

    const formatDocumentLocationsForFileUploader = (locations = []) => !locations ? [] : locations.map((location) => ({
        path: location,
        name: extractFileNameFromDocumentLocation(location),
      }));

    const extractFilePathFromUploader = (locations = []) => !locations ? [] : locations.map(({ path }) => path);

    return { 
      extractFileNameFromDocumentLocation,
      formatDocumentLocationsForFileUploader,
      extractFilePathFromUploader,
    };
  });
