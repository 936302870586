UIDirectives
  .filter('capitalize', () => (input) => {
    if (!input) return null;
    const trimSpaces = w => w.replace(/\s\s+/g, ' ');
    const text = trimSpaces(input.toString());
    const firstWord = '(^\\w{1})';
    const afterSpace = '(\\s{1}\\w{1})';
    const afterDash = '(-{1}\\w{1})';
    const firstWords = new RegExp(`${firstWord}|${afterSpace}|${afterDash}`, 'g');
    return text.toLowerCase().replace(firstWords, word => word.toUpperCase());
  });
