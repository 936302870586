angular.module('app.compliance').factory('ComplianceModulesRouteMappings', [
  () => ({
    PROFILE: {
      route: 'profile',
    },
    CONTACT: {
      route: 'contact',
    },
    'CONTACT-STARTER': {
      route: 'contact',
    },
    OWNER: {
      route: 'owner',
    },
    ACCOUNT: {
      route: 'account',
    },
    'ACCOUNT-STARTER': {
      route: 'account',
    },
    DOCUMENTS: {
      route: 'documents',
    },
    SERVICE_AGREEMENT: {
      route: 'agreement',
    },
  }),
]);
