SubscriptionsModule.constant('SubscriptionConstants', {
  STATUSES: {
    CARD_EXPIRES_THIS_MONTH: 'Card expires this month',
    LAST_PAYMENT_ATTEMPT_FAILED: 'Last payment attempt failed',
    OTHER_ATTENTION: ''
  },
  PLAN_INTERVAL: {
    hourly: {
      singular: 'hour',
      plural: 'hours'
    },
    daily: {
      singular: 'day',
      plural: 'days',
    },
    weekly: {
      singular: 'week',
      plural: 'weeks',
    },
    monthly: {
      singular: 'month',
      plural: 'months',
    },
    quarterly:  {
      singular: 'month',
      plural: 'months',
    },
    annually:  {
      singular: 'month',
      plural: 'months',
    },
    biannually:  {
      singular: 'month',
      plural: 'months',
    },
  },
  CANCELLABLE_SUBSCRIPTION_STATUSES: [
    'active',
    'attention'
  ],
  NOT_CANCELLABLE_SUBSCRIPTION_STATUSES: [
    'cancelled',
    'complete',
    'non-renewing'
  ],
  STATUS_DESCRIPTIONS: {
    active: 'Active Subscriptions',
    'active-renewing': 'Active Recurring Subscriptions',
    'active-non-renewing': 'Active Non-recurring Subscriptions',
    complete: 'Completed Subscriptions',
    cancelled: 'Cancelled Subscriptions',
    attention: 'Subscriptions Requiring Attention'
  }
});