angular.module('app.compliance').directive('compliancePerson', [
  'AnalyticsService', 'ngDialog', '$state', 'RegistrationDocuments', '$rootScope', 'Notification',
  function (AnalyticsService, ngDialog, $state, RegistrationDocuments, $rootScope, Notification) {
    return {
      restrict: 'E',
      scope: {
        personData: '=',
        role: '=',
        showPercentage: '=',
        onEdit: '=',
        customFields: '=',
        dataTestid: '='
      },
      templateUrl: '/modules/compliance/views/person.html',
      link: ($scope) => {
        const { logEvent } = AnalyticsService;
        $scope.logEvent = logEvent;
        $scope.canEditModule = $state.params && $state.params.edit;
        $scope.openPersonModal = (role) => {
          $scope.onEdit && $scope.onEdit();
          const { phone, ...otherPersonData } = $scope.personData;
          $scope.personData = {
            ...otherPersonData,
            phone: {
              calling_code: (phone && phone.callingCode) || '',
              number: (phone && phone.number) || ''
            }
          };
          ngDialog.open({
            template:
              'modules/compliance/views/documents/modals/add-person.html',
            controller: 'ComplianceDocumentsController',
            showClose: false,
            closeByDocument: true,
            scope: $scope,
            data: { role, showPercentage: $scope.showPercentage },
          });
        };

        $scope.deletePerson = (personData) => {
          RegistrationDocuments.deletePerson(personData)
            .then(() => {
              $rootScope.$broadcast('deletedPerson');
              $state.reload();
            })
            .catch((error) => {
              $rootScope.$broadcast('deletedPerson', false);
              Notification.error('Could not delete person', error);
            });
        };
      },
    };
  },
]);
