UIDirectives
  .directive('loadingUntil', ['$rootScope', function ($rootScope) {
    return {
      transclude: 'element',
      restrict: 'A',
      link: function (scope, element, attr, ctrl, transclude) {
        var previousContent = null;
        var loader = document.createElement('div');
        loader.className = 'content-loader';

        var showLoader = function () {
          if (previousContent) previousContent.remove();
          transclude(function (content) {
            element.parent().append(loader);
          });
        };

        showLoader();

        var render = function () {
          transclude(function (content) {
            loader.remove();
            if (previousContent) previousContent.remove();
            previousContent = content;
            element.parent().append(content);
          });
        };

        scope.$on(attr.loadingUntil, render);
        scope.$on(attr.resetOn, showLoader);
      }
    };
  }]);