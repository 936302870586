UIDirectives.directive('fileread', ['$rootScope', function ($rootScope) {
  return {
    link: function (scope, element, attrs) {
      element.bind('change', function (event) {
        var r = new FileReader();
        r.onload = function (e) {
          var contents = e.target.result;
          $rootScope.$broadcast('uploaded_' + attrs.fileread, contents);
        };

        r.readAsText(event.target.files[0]);
      });

      scope.$watch(attrs.fileread, function (fileread) {
        if (!fileread) element.val('');
      });
    }
  };
}]);