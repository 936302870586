angular.module('app.transactions', ['ui.router'])

  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('transactions', {
        abstract: true,
        url: '/transactions',
        title: 'Transactions',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'transactions.list',
        data: {
          permission: 'transaction-view',
          authenticable: true
        }
      }).state('transactions.list', {
        title: 'Transactions',
        url: '?amount&status&from&to&graph&page&subaccount&currency&error&period&for&channel&customer&payment_page&terminal_id&settlement&subaccount_settlement&authorization_code&split_code&receipt_number&use_cursor&next&previous&per_page',
        pageTrack: '/transactions',
        reloadOnSearch: false,
        templateUrl: 'modules/transactions/list-next.html',
        controller: 'TransactionListNextCtrl',
        onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('transaction-list-root'),
      }).state('transactions.disputes', {
        title: 'Disputes',
        url: '/disputes',
        pageTrack: '/disputes',
        controller: 'TransactionDisputesCtrl',
        templateUrl: 'modules/transactions/pending-disputes-next.html',
        resolve: {
          disputes: ['PendingDisputes', function (PendingDisputes) {
            return PendingDisputes.fetch();
          }],
        },
        permission: 'transaction-dispute',
        onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('pending-transaction-disputes-root'),
      }).state('transactions.one', {
        title: 'Transactions',
        url: '/:id?disputeId&collect_recipient_details&refund_id&connect_platform_id&connect_account_id',
        pageTrack: '/transactions',
        templateUrl: 'modules/transactions/one-next.html',
        showBackButton: true,
        reloadOnSearch: false,
        controller: 'TransactionDetailNextCtrl',
        redirectTo: 'transactions.one.analytics',
        onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('transaction-detail-root'),
      }).state('transactions.one.analytics', {
        url: '/analytics',
        pageTrack: '/transactions',
        templateUrl: 'modules/transactions/one-next.html',
        controller: 'TransactionDetailNextCtrl',
        showBackButton: true
      }).state('transactions.one.refunds', {
        url: '/refunds',
        pageTrack: '/transactions',
        templateUrl: 'modules/transactions/one-next.html',
        controller: 'TransactionDetailNextCtrl',
        showBackButton: true
      }).state('transactions.one.disputes', {
        url: '/disputes',
        pageTrack: '/transactions',
        templateUrl: 'modules/transactions/one-next.html',
        controller: 'TransactionDetailNextCtrl',
        showBackButton: true
      });
    }
  ])
  .controller('TransactionListNextCtrl', ['$scope', 'DEFAULTS',
    function ($scope, DEFAULTS) {
    $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

      $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_TRANSACTION_LIST',
          }, '*');
        }
      });
    }])

  .controller('TransactionDisputesCtrl', ['$scope', 'PendingDisputes', function ($scope, PendingDisputes) {
    $scope.meta = PendingDisputes.meta;

    $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
      if (mfeServiceReady) {
        window.postMessage({
          message: 'RENDER_PENDING_TRANSACTION_DISPUTES',
        }, '*');
      }
    });
  }])
  .controller('TransactionDetailNextCtrl', ['$scope',
    function ($scope) {
      $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_TRANSACTION_DETAIL',
          }, '*');
        }
      });
    }]);
