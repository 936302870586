const TransactionSplitsModule = angular.module('app.transaction-splits', [
  'ui.router',
]);

TransactionSplitsModule.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('transaction-splits', {
        abstract: true,
        url: '/transaction-splits',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'transaction-splits.list',
        data: {
          permission: 'subaccount-view',
          authenticable: true,
        },
      })
      .state('transaction-splits.list', {
        url: '?type&name&active&subaccount&page&is_dynamic',
        title: 'Transaction Splits',
        pageTrack: '/transaction-splits',
        templateUrl: 'modules/transaction-splits/list.html',
        controller: 'TransactionSplitsListCtrl'
      })
      .state('transaction-splits.list.one', {
        url: '/:id',
        pageTrack: '/transaction-splits',
        templateUrl: 'modules/transaction-splits/one.html',
        redirectTo: 'transaction-splits.list.one.accounts'
      })
      .state('transaction-splits.list.one.accounts', {
        url: '/accounts',
        pageTrack: '/transaction-splits',
        templateUrl: 'modules/transaction-splits/one/accounts.html'
      })
      .state('transaction-splits.list.one.transactions', {
        url: '/transactions',
        pageTrack: '/transaction-splits',
        templateUrl: 'modules/transaction-splits/one/transactions.html'
      });
  },
]);

TransactionSplitsModule.controller('TransactionSplitsListCtrl', ['$scope', '$rootScope', '$state', 'Notification', 'ngDialog', 'TransactionSplitsData', 'TransactionSplitsService', 'AnalyticsService', 'LocaleService', '$i18next', 'DEFAULTS',
  function ($scope, $rootScope, $state, Notification, ngDialog, TransactionSplitsData, TransactionSplitsService, AnalyticsService, LocaleService, $i18next, DEFAULTS) {
    $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

    const { logEvent } = AnalyticsService;
    $scope.transactionSplits = [];
    $scope.filteredSubaccount = null;
    $scope.filters = $state.params;
    $scope.filterSplitTypes = TransactionSplitsService.filterSplitTypes;
    $scope.filterStatuses = TransactionSplitsService.filterStatuses;
    $scope.filterCategories = TransactionSplitsService.filterCategories;
    const integration = $rootScope.Business;

    $scope.appendSplitIdToStateParams = splitId => TransactionSplitsService.appendSplitIdToStateParams(splitId);

    $scope.transactionSplitTypeMapping = {
      percentage: $i18next.t('transactionSplits.list.splitTypeMapping.percentage'),
      flat: $i18next.t('transactionSplits.list.splitTypeMapping.flat')
    };

    $scope.updateTransactionSplits = (transactionSplits) => {
      $scope.transactionSplits = transactionSplits;
    };

    $scope.clearFilters = () => {
      $scope.filters = { ...TransactionSplitsService.defaultFilters };
    };

    $scope.filterTransactionSplits = (query) => {
      const queryWithSubaccount = { ...query, subaccount: $scope.filteredSubaccount && $scope.filteredSubaccount.subaccount_code };
      logEvent('Clicked to filter transaction splits', queryWithSubaccount);
      $state.go('transaction-splits.list', queryWithSubaccount, { reload: true,});
    };

    $scope.isStateParamsEmpty = TransactionSplitsService.isStateParamsEmpty();

    $scope.searchTransactionSplits = () => {
      $state.go('transaction-splits.list', { name: $scope.transactionSplits.searchTerm }, { reload: true });
      logEvent('Searched for a split group');
    };

    if ($scope.filters.subaccount) {
      TransactionSplitsData.fetchSubaccount($scope.filters.subaccount)
        .then((response) => {
          $scope.filteredSubaccount = response.data;
          $scope.formattedPillFilters = TransactionSplitsService.formatPillFilters({ subaccount: $scope.filteredSubaccount });
        })
        .catch((error) => {
          Notification.error('Could not fetch subaccount', error);
          $scope.formattedPillFilters = TransactionSplitsService.formatPillFilters();
        });
    } else {
      $scope.formattedPillFilters = TransactionSplitsService.formatPillFilters();
    }

    $scope.removeFilter = (filter) => {
      const filters = Object.keys($scope.filters).reduce((accumulator, currentValue) => {
        if (currentValue !== filter.key) {
          accumulator = { ...accumulator, [currentValue]: $scope.filters[currentValue] };
        }
        return accumulator;
      }, {});
      $state.go('transaction-splits.list', filters, { inherit: false });
    };

    $scope.computeTransactionSplitTableHeight = () => TransactionSplitsService.computeTransactionSplitTableHeight();

    const { id, ...stateParamsWithoutId } = $state.params;
    TransactionSplitsData.fetch(stateParamsWithoutId)
      .then((response) => {
        $scope.transactionSplits = response.data;
        $scope.meta = response.meta;
        $scope.$broadcast('fetchedTransactionSplits');
      })
      .catch((error) => {
        Notification.error('Could not fetch transaction splits', error);
        $scope.$broadcast('fetchedTransactionSplits', false);
      });

    $scope.subaccountSearchResults = [];
    $scope.subaccountSearchStatus = 'idle';

    $scope.resetSubAccountSearchForm = () => {
      $scope.filters.subaccount = '';
      $scope.subaccountSearchStatus = 'idle';
    };

    $scope.setFilteredSubaccount = (filteredSubaccount) => {
      $scope.filteredSubaccount = filteredSubaccount;
    };

    $scope.unsetFilteredSubaccount = () => {
      $scope.filteredSubaccount = null;
    };

    $scope.searchSubaccounts = (searchTerm) => {
      $scope.subaccountSearchStatus = 'searching';
      TransactionSplitsData.searchSubaccounts(searchTerm)
        .then((response) => {
          logEvent('Searched for an existing subaccount', { context: 'filter' });
          $scope.subaccountSearchStatus = 'searched';
          $scope.subaccountSearchResults = response.data;
        })
        .catch((error) => {
          Notification.error('Could not search subaccounts', error);
        });
    };

    $scope.openNewTransactionSplitModal = () => {
      logEvent('Create new split group started');

      ngDialog.open({
        width: 450,
        scope: $scope,
        showClose: true,
        controller: 'NewTransactionSplitCtrl',
        className: 'ngdialog-theme-plain dialog-close-center-icon',
        template: '/modules/transaction-splits/modals/new-transaction-split.html',
      });
    };

    LocaleService.fetch()
      .then((response) => {
        const internationalEnabled = integration.allow_international_cards;
        $scope.transactionSplitCurrencies = response.allowed_currencies;

        if (!internationalEnabled) {
          $scope.transactionSplitCurrencies = $scope.transactionSplitCurrencies.filter(localeAllowedCurrency => localeAllowedCurrency !== 'USD');
        }

        $scope.$broadcast('fetchedLocales');
      })
      .catch((error) => {
        Notification.error('Could not fetch data', error);
        $scope.$broadcast('fetchedLocales', false);
      });
  },
]);

TransactionSplitsModule.controller('NewTransactionSplitCtrl', ['$scope', '$rootScope', '$state', 'LocalService', 'TransactionSplitsService',
  function ($scope, $rootScope, $state, LocalService, TransactionSplitsService) {
    $scope.newTransactionSplit = {
      path: 'create'
    };

    $scope.$on('fetchedLocales', () => {
      $scope.newTransactionSplit.currency = $rootScope.Business.default_currency;
    });

    $scope.saveNewTransactionSplit = () => {
      $scope.closeThisDialog();

      const isSameRoute = $state.params.id === 'create';
      $state.go('transaction-splits.list.one.accounts', { id: $scope.newTransactionSplit.path }, { reload: isSameRoute });

      if ($scope.transactionSplits.length === 0) {
        TransactionSplitsService.showIntroJS();
      }
      LocalService.set('newTransactionSplit', $scope.newTransactionSplit);
    };
  },
]);

TransactionSplitsModule.controller('SingleTransactionSplitCtrl', ['$scope', '$rootScope', '$state', 'CountryService', 'LocaleService', 'BankService', 'TransactionSplitsData', 'TransactionSplitsService', 'AnalyticsService', 'LocalService', 'Notification', 'ngDialog', '$i18next', 'utils',
  function($scope, $rootScope, $state, CountryService, LocaleService, BankService, TransactionSplitsData, TransactionSplitsService, AnalyticsService, LocalService, Notification, ngDialog, $i18next, utils) {
    const { logEvent } = AnalyticsService;
    const integration = $rootScope.Business;
    const integrationCountryCode = integration.attributes && integration.attributes.country && integration.attributes.country.iso_code;
    $scope.countryData = CountryService.getCountryData(integrationCountryCode);
    $scope.transactionSplitStatus = 'idle';

    $scope.subaccountActions = {};
    $scope.currentTransactionSplit = {
      subaccounts: []
    };
    $scope.transactionSplitTypeMapping = {
      percentage: $i18next.t('transactionSplits.one.accounts.percentage'),
      flat: $i18next.t('transactionSplits.one.accounts.flat')
    };

    const focusOnSelectedSubaccountInput = () => TransactionSplitsService.focusOnElementBySelector('[data-selected-subaccount-input]');

    $scope.setSubaccountAction = ({ id, type }) => {
      $scope.subaccountActions[id] = type;

      if (type === 'edit') {
        focusOnSelectedSubaccountInput();
      }
    };

    $scope.updateBearerType = (bearerType, subaccount) => {
      const payload = {
        bearer_type: bearerType,
        ...(subaccount && { bearer_subaccount: subaccount.subaccount_code })
      };

      TransactionSplitsData.updateSplit($scope.currentTransactionSplit.id, payload)
        .then(() => {
          Notification.success('Split configuration updated');
        }).catch((error) => {
          Notification.error('Could not update split', error);
        });
    };

    TransactionSplitsData.fetchSettlementAccount()
      .then((response) => {
        $scope.settlementAccount = response.data.find(settlementAccount => settlementAccount.currency === $scope.currentTransactionSplit.currency);
      })
      .catch((error) => {
        Notification.error('Could not fetch settlement account', error);
      });

    $scope.activateSplit = () => {
      logEvent('Clicked to activate split group');
      TransactionSplitsData.activateSplit($scope.currentTransactionSplit.id)
        .then(() => {
          $state.go($state.current, $state.params, { reload: true });
          Notification.success('Split activated successfully');
        })
        .catch((error) => {
          Notification.error('Could not activate split', error);
        });
    };

    $scope.openDisableSplitModal = () => {
      ngDialog.open({
        width: 450,
        scope: $scope,
        showClose: true,
        className: 'ngdialog-theme-plain dialog-close-center-icon',
        template: '/modules/transaction-splits/modals/disable-split.html',
      });
    };

    $scope.deactivateSplit = () => {
      logEvent('Clicked to deactivate split group');
      TransactionSplitsData.deactivateSplit($scope.currentTransactionSplit.id)
        .then(() => {
          $state.go($state.current, $state.params, { reload: true });
          Notification.success('Split deactivated successfully');
        })
        .catch((error) => {
          Notification.error('Could not deactivate split', error);
        });
    };

    $scope.subaccountPayload = {};
    $scope.openCreateOrUpdateSubaccountModal = ({ intent = 'create', subaccount } = {}) => {
      $scope.intent = intent;

      if (intent === 'update') {
        logEvent('Update subaccount in split started');

        const subaccountUpdatePayloadCurrency = $scope.subaccountPayload.currency || $scope.currentTransactionSplit.currency;
        $scope.subaccountPayload = { ...subaccount, currency: subaccountUpdatePayloadCurrency };
      } else if (intent === 'create') {
        logEvent('Add new subaccount to split group started');
      }

      $scope.getBanksByCurrency = (currency) => {
        $scope.fetchingBanks = true;
        BankService.init({currency}).then((response) => {
          $scope.settlementBanks = response;
          $scope.fetchingBanks = false;
        }).catch(() => {
          $scope.fetchingBanks = false;
        });
      };

      $scope.getBanksByCurrency($scope.currentTransactionSplit.currency);

      ngDialog.open({
        width: 450,
        scope: $scope,
        showClose: true,
        className: 'ngdialog-theme-plain dialog-close-center-icon',
        template: '/modules/transaction-splits/modals/create-update-subaccount.html',
      });

      $scope.verifyAccountNumber = () => {
        $scope.accountVerification = null;

        const accountNumber = $scope.subaccountPayload.account_number;
        const bankId = $scope.subaccountPayload.settlement_bank;

        if (accountNumber) {
          BankService.validate(accountNumber, bankId).then(() => {
            $scope.accountVerification = 'pending';
            return BankService.resolve(accountNumber, bankId, $scope.subaccountPayload.currency);
          }).then((response) => {
            if (!$scope.subaccountPayload.business_name && response.account_name) {
              $scope.subaccountPayload.business_name = utils.toTitleCase(utils.firstNthWords(response.account_name, 2)).trim();
            }
            $scope.accountVerification = response;
          });
        }
      };

      $scope.saveSubaccountAndAddToGroup = ({ intent }) => {
        if (intent === 'create') {
          TransactionSplitsData.createSubaccount({ ...$scope.subaccountPayload, percentage_charge: 50 })
            .then((response) => {
              const subaccount = response.data;
              $scope.addSubaccountToList(subaccount);
              logEvent('Add new subaccount to split group complete');
            })
            .catch((error) => {
              Notification.error('Could not create subaccount', error);
            });
        } else if (intent === 'update') {
          const {
            id, account_number, settlement_bank, business_name
          } = $scope.subaccountPayload;

          TransactionSplitsData.updateSubaccount(id, {
            account_number,
            settlement_bank,
            business_name
          })
            .then((response) => {
              const subaccount = response.data;
              $scope.addSubaccountToList(subaccount);
              $scope.unsetSubaccountWithoutCurrency();
              logEvent('Update subaccount in split complete');
            })
            .catch((error) => {
              Notification.error('Could not update subaccount', error);
            });
        }
      };
    };

    const populateTransactionSplitFromCache = () => {
      const transactionSplitInCache = LocalService.get('newTransactionSplit');

      if (transactionSplitInCache) {
        const currentTransactionSplit = { ...transactionSplitInCache, id: transactionSplitInCache.path, subaccounts: [] };

        $scope.transactionSplitStatus = 'fetched';
        $scope.currentTransactionSplit = currentTransactionSplit;

        let fetchedTransactionSplits = false;

        $scope.$on('fetchedTransactionSplits', () => {
          fetchedTransactionSplits = true;
          $scope.updateTransactionSplits([currentTransactionSplit, ...$scope.transactionSplits]);
        });

        // Makes sure to update transaction split list without a reload
        if (!fetchedTransactionSplits) {
          $scope.updateTransactionSplits([currentTransactionSplit, ...$scope.transactionSplits]);
        }
      } else {
        $state.go('transaction-splits.list', {}, { reload: true });
      }
    };

    const fetchCurrentSplitTransactions = () => {
      $scope.fetchingSplitTransactions = true;
      TransactionSplitsData.fetchTransactionsBySplitCode($scope.currentTransactionSplit.split_code)
        .then((response) => {
          $scope.transactions = response.data;
          $scope.fetchingSplitTransactions = false;
        })
        .catch((error) => {
          Notification.error('Could not fetch transactions', error);
          $scope.fetchingSplitTransactions = false;
        });
    };

    const populateTransactionSplitFromAPI = () => {
      $scope.transactionSplitStatus = 'fetching';
      TransactionSplitsData.fetchSingleSplit($state.params.id)
        .then((response) => {
          $scope.currentTransactionSplit = TransactionSplitsService.formatTransactionSplitResponse(response);
          $scope.transactionSplitStatus = 'fetched';
          fetchCurrentSplitTransactions();
        })
        .catch((error) => {
          Notification.error('Could not fetch transaction split', error);
          $state.go('transaction-splits.list', {}, { reload: true });
        });
    };

    const shouldCreateTransactionSplit = $state.params.id === 'create';

    if (shouldCreateTransactionSplit) {
      populateTransactionSplitFromCache();
    } else {
      populateTransactionSplitFromAPI();
    }

    $scope.subaccountSearchStatus = 'idle';
    $scope.setSubaccountSearchStatus = (status) => {
      $scope.subaccountSearchStatus = status;
    };

    $scope.subaccountSearchResults = [];
    $scope.searchSubaccounts = () => {
      const searchTerm = $scope.currentTransactionSplit.searchSubaccountInput;

      $scope.setSubaccountSearchStatus('searching');
      TransactionSplitsData.searchSubaccounts(searchTerm)
        .then((response) => {
          logEvent('Searched for an existing subaccount');
          $scope.setSubaccountSearchStatus('searched');
          $scope.subaccountSearchResults = response.data;
        })
        .catch((error) => {
          Notification.error('Could not fetch subaccounts', error);
        });
    };

    $scope.resetSubAccountSearchForm = () => {
      $scope.currentTransactionSplit.searchSubaccountInput = '';
      $scope.setSubaccountSearchStatus('idle');
    };

    $scope.unsetSubaccountWithoutCurrency = () => {
      $scope.subaccountWithoutCurrency = null;
    };

    const isSubaccountInSplit = ({ subaccount_code }) => !!$scope.currentTransactionSplit.subaccounts.find(subaccount => subaccount.subaccount_code === subaccount_code);

    $scope.addSubaccountToList = (subaccount) => {
      if (!subaccount.currency) {
        $scope.subaccountWithoutCurrency = subaccount;
        $scope.resetSubAccountSearchForm();
        return;
      }

      if (isSubaccountInSplit(subaccount)) {
        Notification.error(null, 'Subaccount already exist on this transaction split');
      } else {
        $scope.currentTransactionSplit.subaccounts = [subaccount, ...$scope.currentTransactionSplit.subaccounts];
        $scope.setSubaccountAction({ id: subaccount.id, type: 'edit' });
        focusOnSelectedSubaccountInput();
        $scope.resetSubAccountSearchForm();
      }
    };

    $scope.removeSubaccountFromList = (subaccount) => {
      $scope.currentTransactionSplit.subaccounts = $scope.currentTransactionSplit.subaccounts.filter(selectedSubaccount => selectedSubaccount !== subaccount);
    };

    $scope.computeMainAccountPercentageShare = () => {
      const mainAccountPercentageShare = $scope.currentTransactionSplit.subaccounts
        .reduce((accumulator, { share }) => {
          accumulator = accumulator - parseFloat(share || 0);
          return accumulator;
        }, 100);

      return mainAccountPercentageShare >= 0 ? mainAccountPercentageShare : 0;
    };

    $scope.computedMinimumTransactionAmount = () => $scope.currentTransactionSplit.subaccounts
      .reduce((accumulator, { share }) => {
        accumulator = accumulator + parseFloat(share || 0);
        return accumulator;
      }, 0);

    const getCompleteTransactionSplits = () => $scope.transactionSplits.filter(transactionSplit => transactionSplit.path !== 'create');

    const createTransactionSplit = () => {
      const subaccountPayload = $scope.currentTransactionSplit.subaccounts[0];
      const subaccountShare = TransactionSplitsService.formatAccountShareBySplitType({ share: subaccountPayload.share, splitType: $scope.currentTransactionSplit.type, formatTo: 'kobo' });

      const subaccount = {
        share: subaccountShare,
        subaccount: subaccountPayload.subaccount_code
      };

      const payload = {
        subaccounts: [subaccount],
        name: $scope.currentTransactionSplit.name,
        type: $scope.currentTransactionSplit.type,
        currency: $scope.currentTransactionSplit.currency
      };

      TransactionSplitsData.createSplit(payload)
        .then((response) => {
          logEvent('Create new split group complete');
          LocalService.unset('newTransactionSplit');
          $scope.subaccountActions = {};
          $scope.currentTransactionSplit = TransactionSplitsService.formatTransactionSplitResponse(response);
          const completeTransactionSplits = getCompleteTransactionSplits();

          $scope.updateTransactionSplits([$scope.currentTransactionSplit, ...completeTransactionSplits]);
          $state.go('transaction-splits.list.one.accounts', { id: response.data.id });
        })
        .catch((error) => {
          Notification.error('Could not create split', error);
        });
    };

    const addSubaccountToTransactionSplit = ({ subaccount_code, share }) => {
      const payload = {
        subaccount: subaccount_code,
        share: TransactionSplitsService.formatAccountShareBySplitType({ share, splitType: $scope.currentTransactionSplit.type, formatTo: 'kobo' })
      };

      TransactionSplitsData.addSubaccountToSplit({ splitId: $scope.currentTransactionSplit.id, payload })
        .then((response) => {
          $scope.currentTransactionSplit = TransactionSplitsService.formatTransactionSplitResponse(response);
          $scope.subaccountActions = {};
        })
        .catch((error) => {
          Notification.error('Could not update split', error);
        });
    };

    $scope.addSubaccount = (subaccount) => {
      const shouldCreateTransactionSplit = $state.params.id === 'create';

      if (shouldCreateTransactionSplit) {
        createTransactionSplit();
      } else {
        addSubaccountToTransactionSplit(subaccount);
      }
    };

    $scope.deleteSubaccount = (subaccount) => {
      const payload = {
        subaccount: subaccount.subaccount_code
      };

      TransactionSplitsData.removeSubaccountFromSplit({ splitId: $scope.currentTransactionSplit.id, payload })
        .then(() => {
          $scope.removeSubaccountFromList(subaccount);
          Notification.success('Subaccount removed from transaction split');
          $state.go('transaction-splits.list.one.accounts', { id: $scope.currentTransactionSplit.id }, { notify: false });
        })
        .catch((error) => {
          Notification.error('Could not remove subaccount from transaction split', error);
        });
    };

    $scope.$on('$stateChangeStart', (event, nextState, nextParams) => {
      $scope.restoredCachedTransactionSplit = () => {
        LocalService.set('newTransactionSplit', $scope.currentTransactionSplit);
      };

      $scope.leaveTransactionSplitPage = () => {
        const completeTransactionSplits = getCompleteTransactionSplits();
        ngDialog.close();

        if (nextState.name === $state.current.name && nextParams.id === 'create') {
          const newTransactionSplit = LocalService.get('newTransactionSplit');

          $scope.currentTransactionSplit = { ...newTransactionSplit, subaccounts: [] };
          $scope.updateTransactionSplits([newTransactionSplit, ...completeTransactionSplits]);
        } else {
          $scope.currentTransactionSplit = {};
          $scope.updateTransactionSplits(completeTransactionSplits);
          LocalService.unset('newTransactionSplit');
        }
        $state.go(nextState.name, nextParams);
      };

      if ($scope.currentTransactionSplit.path === 'create' && $state.current.name !== nextState.redirectTo) {
        event.preventDefault();

        ngDialog.open({
          width: 450,
          scope: $scope,
          showClose: true,
          className: 'ngdialog-theme-plain dialog-close-center-icon',
          template: '/modules/transaction-splits/modals/exit-page-warning.html',
        });
      }
    });
  }]);
