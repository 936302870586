angular.module('app.charges', ['ui.router'])

  .config(['$stateProvider',
    function($stateProvider) {
      $stateProvider.state('app.charges', {
        url: '/charges?status',
        pageTrack: '/charges',
        title: 'Bulk Charges',
        templateUrl: 'modules/charges/list.html',
        controller: 'ChargeListCtrl',
        redirectTo: 'transactions.list',
        permission: 'customer-*',
        resolve: {
          charges: ['API', '$stateParams', function($API, $stateParams) {
            return $API.one('bulkcharge').get($stateParams);
          }]
        }
      }).state('app.charges.one', {
        url: '/:id',
        title: 'charges',
        templateUrl: 'modules/charges/one.html',
        controller: 'ChargeCtrl',
        resolve: {
          charge: ['API', '$stateParams', function($API, $stateParams) {
            return $API.one('bulkcharge', $stateParams.id).get();
          }]
        }
      });
    }
  ])

  .controller('ChargeListCtrl', ['$state', '$scope', '$stateParams', '$filter', 'charges', 'ngDialog', function($state, $scope, $stateParams, $filter, charges, ngDialog) {
    $scope.meta = charges.meta;
    $scope.charges = charges.data;

    $scope.createCharge = function() {
      ngDialog.open({
        template: 'newCharge',
        controller: 'NewChargeCtrl',
        trapFocus: false
      });
    };
  }])

  .controller('ChargeCtrl', ['$state', '$scope', '$stateParams', 'API', 'charge', 'ngDialog', 'Notification', function($state, $scope, $stateParams, $API, charge, ngDialog, Notification) {
    $scope.charge = charge.data;

    $scope.update = function() {
      var payload = [];
      $API.one('bulkcharge', $scope.charge.batch_code).customPUT(payload).then(function() {
        Notification.success('Charge paused', 'The rest of the transactions will not be charged');
        $state.reload();
      }, function(error) {
        $scope.$broadcast('chargeUpdated', false);
        Notification.error('Could not update this charge', error);
      });
    };

    $scope.fetchTransactions = function(page) {
      $API.one('bulkcharge/' + $stateParams.id, 'charges').get().then(function(response) {
        $scope.transactions = response.data;
        $scope.meta = response.meta;
        $scope.loading = false;
      }, function() {
        $scope.loading = false;
      });
    };

    $scope.pause = function() {
      $API.all('bulkcharge/pause/' + $stateParams.id).post().then(function(response) {
        Notification.success('Charge paused', 'The rest of the transactions will not be charged');
        $state.reload();
      }, function() {
        $scope.$broadcast('chargePaused', false);
        Notification.error('Could not pause this charge', error);
      });
    };

    $scope.resume = function() {
      $API.all('bulkcharge/resume/' + $stateParams.id).post().then(function(response) {
        Notification.success('Charge resume', 'The rest of the transactions will now be charged');
        $state.reload();
      }, function() {
        $scope.$broadcast('chargeResumed', false);
        Notification.error('Could not resume this charge', error);
      });
    };
  }])

  .controller('NewChargeCtrl', ['$state', '$scope', 'API', 'ngDialog', 'Notification', function($state, $scope, $API, ngDialog, Notification) {
    $scope.$on('uploaded_charges', function(event, file) {
      $scope.charges = processData(file);
      validate($scope.charges);
      $scope.$apply();
    });

    var processData = function(allText) {
      var allTextLines = allText.split(/\r\n|\n/);
      var headers = allTextLines[0].split(',');
      var lines = [];

      for (var i = 0; i < allTextLines.length; i++) {
        // split content based on comma
        var data = allTextLines[i].split(',');
        if (data.length == headers.length) {
          var tarr = [];
          for (var j = 0; j < headers.length; j++) {
            tarr.push(data[j]);
          }
          lines.push(tarr);
        }
      }
      return lines;
    };

    var validate = function(data) {
      var invalid = [];
      var parsed = [];
      try {
        _.each(data, function(arr) {
          if (arr.length < 2 || arr.length > 2) invalid.push('Extra rows found.');
          if (typeof arr[0] !== 'string') invalid.push('Invalid authorization code found.');
          if (isNaN(arr[1])) invalid.push('Invalid amount found.');
          parsed.push({
            authorization: arr[0],
            amount: arr[1]
          });
        });
      } catch (err) {
        invalid = ['Unknown problem while parsing.'];
      }

      if (invalid.length) {
        $scope.charges = null;
        Notification.error('Error: ' + invalid[0], 'Please check your csv and try again');
      } else {
        $scope.sendCharge(parsed);
      }
    };

    $scope.sendCharge = function(payload) {
      $API.all('bulkcharge').post(payload).then(function(response) {
        $state.go('app.charges', {}, { reload: true }).then(function() {
          $scope.closeThisDialog();
          $state.go('app.charges.one', { id: response.data.batch_code });
          Notification.success('Bulk upload successful', 'The authorizations will now be charged');
        });
      }, function(error) {
        $scope.$broadcast('chargeCreated', false);
        Notification.error('Could not perform this charge', error);
      });
    };
  }]);
