angular.module('app.countryService', []).service('CountryService', [
  'LocalAPI',
  $LocalAPI => {
    let countries;
    const supportedCountries = {
      NG: {
        bankType: 'nuban',
        country: 'Nigeria',
        countryCode: 'NG',
        defaultCurrency: 'NGN',
        accountNumberPattern: {
          exactMatch: true,
          pattern: /^(\d){10}$/,
        },
        supportedCurrencies: {
          NGN: {
            accountVerificationRequired: true,
            accountNumberInputLabel: 'accountNumber',
            accountNumberPattern: {
              exactMatch: true,
              pattern: /^(\d){10}$/,
            },
          },
          USD: {
            requiredFields: ['bank', 'account_number'],
            accountVerificationRequired: true,
            accountNumberInputLabel: 'accountNumber',
            accountNumberPattern: {
              exactMatch: true,
              pattern: /^(\d){10}$/,
            },
            notices: [
              'USD can currently only be settled into Zenith Bank USD accounts.',
              "You'll receive USD settlements 7 working days after payment has been made.",
              'In case of fraudulent transactions involving international payments, Paystack will reverse the transaction and deduct the amount from your balance.',
            ],
          },
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '802 123 4567',
      },
      GH: {
        bankType: 'ghipss',
        country: 'Ghana',
        countryCode: 'GH',
        defaultCurrency: 'GHS',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^(\d){7}/,
        },
        supportedCurrencies: {
          GHS: {
            accountVerificationRequired: true,
            accountNumberInputLabel: 'accountNumber',
            accountNumberPattern: {
              exactMatch: true,
              pattern: /^(\d){7}/,
            },
          },
        },
        features: ['mobile_money'],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '050 123 456',
      },
      US: {
        countryCode: 'US',
        country: 'United States of America',
        defaultCurrency: 'USD',
        accountNumberPattern: {
          exactMatch: true,
          pattern: /^(\d){10}$/,
        },
        supportedCurrencies: {
          USD: {
            accountVerificationRequired: true,
            accountNumberInputLabel: 'accountNumber',
            accountNumberPattern: {
              exactMatch: true,
              pattern: /^(\d){10}$/,
            },
          },
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '832 123 4567',
      },
      ZA: {
        bankType: 'basa',
        country: 'South Africa',
        countryCode: 'ZA',
        defaultCurrency: 'ZAR',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^[0-9]{9,12}$/,
        },
        supportedCurrencies: {
          ZAR: {
            accountVerificationRequired: true,
            accountNameInputLabel: 'accountHolderName',
            accountNumberInputLabel: 'accountNumber',
            accountNumberPattern: {
              exactMatch: false,
              pattern: /^[0-9]{9,12}$/,
            },
          },
        },
        features: ['eft'],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '050 123 456',
      },
      KE: {
        bankType: 'kepss',
        country: 'Kenya',
        countryCode: 'KE',
        defaultCurrency: 'KES',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^[0-9][0-9]{8,15}$/,
        },
        supportedCurrencies: {
          KES: {
            accountVerificationRequired: false,
            accountNumberInputLabel: 'accountNumber',
            accountNumberPattern: {
              exactMatch: false,
              pattern: /^[0-9][0-9]{8,15}$/,
            },
          },
          USD: {
            requiredFields: [
              'bank',
              'account_number',
              'branch_code',
              'account_name',
            ],
            accountNumberPattern: {
              exactMatch: false,
              pattern: /^[0-9][0-9]{8,15}$/,
            },
            accountVerificationRequired: false,
            accountNumberInputLabel: 'accountNumber',
            notices: [
              "You'll receive USD settlements 7 working days after payment has been made.",
              'In case of fraudulent transactions involving international payments, Paystack will reverse the transaction and deduct the amount from your next payout.',
            ],
          },
        },
        features: ['mobile_money', 'USD'],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '070 123 456',
      },
      CI: {
        bankType: 'bceao',
        country: "Côte d'Ivoire",
        countryCode: 'CI',
        defaultCurrency: 'XOF',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^[a-zA-Z]{2}[0-9]{22}/,
        },
        supportedCurrencies: {
          XOF: {
            accountVerificationRequired: false,
            accountNumberPattern: {
              exactMatch: false,
              pattern: /^[a-zA-Z]{2}[0-9]{22}/,
            },
            accountNumberInputLabel: 'rib',
            accountNumberTooltip: 'RIB should be 24 characters.',
          },
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '05 01 234 567',
      },
      EG: {
        bankType: 'iban',
        country: 'Egypt',
        countryCode: 'EG',
        defaultCurrency: 'EGP',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^EG[0-9]{27}$/,
        },
        supportedCurrencies: {
          EGP: {
            accountVerificationRequired: false,
            accountNumberPattern: {
              exactMatch: false,
              pattern: /^EG[0-9]{27}$/,
            },
            accountNumberInputLabel: 'iban',
            accountNumberTooltip: 'IBAN should be 29 characters with no spaces in between.',
          },
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '80 56 020 617',
      },
      RW: {
        bankType: 'bnr',
        country: 'Rwanda',
        countryCode: 'RW',
        defaultCurrency: 'RWF',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^[0-9]{10,25}$/,
        },
        supportedCurrencies: {
          RWF: {
            accountVerificationRequired: false,
            accountNumberInputLabel: 'accountNumber',
            accountNumberPattern: {
              exactMatch: false,
              pattern: /^[0-9]{10,25}$/,
            },
          },
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '802 123 4567',
      },
    };

    const supportedCurrencies = {
      NGN: {
        bankType: 'nuban',
        country: 'Nigeria',
        countryCode: 'NG',
        defaultCurrency: 'NGN',
        accountNumberPattern: {
          exactMatch: true,
          pattern: /^(\d){10}$/,
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '802 123 4567',
      },
      GHS: {
        bankType: 'ghipss',
        country: 'Ghana',
        countryCode: 'GH',
        defaultCurrency: 'GHS',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^(\d){7}/,
        },
        features: ['mobile_money'],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '050 123 456',
      },
      USD: {
        countryCode: 'US',
        country: 'United States of America',
        defaultCurrency: 'USD',
        accountNumberPattern: {
          exactMatch: true,
          pattern: /^(\d){10}$/,
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '832 123 4567',
      },
      ZAR: {
        bankType: 'basa',
        country: 'South Africa',
        countryCode: 'ZA',
        defaultCurrency: 'ZAR',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^[0-9][0-9]{8,14}$/,
        },
        features: ['eft'],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '050 123 456',
      },
      KES: {
        bankType: 'kepss',
        country: 'Kenya',
        countryCode: 'KE',
        defaultCurrency: 'KES',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^[0-9][0-9]{8,15}$/,
        },
        features: ['mobile_money'],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '070 123 456',
      },
      XOF: {
        bankType: 'bceao',
        country: "Côte d'Ivoire",
        countryCode: 'CI',
        defaultCurrency: 'XOF',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^[a-zA-Z]{2}[0-9]{22}/,
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '05 01 234 567',
      },
      EGP: {
        bankType: 'iban',
        country: 'Egypt',
        countryCode: 'EG',
        defaultCurrency: 'EGP',
        accountNumberPattern: {
          exactMatch: false,
          pattern: /^EG[0-9]{27}$/,
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '80 56 020 617',
      },
      RWF: {
        bankType: 'bnr',
        country: 'Rwanda',
        countryCode: 'RW',
        defaultCurrency: 'RWF',
        accountNumberPattern: {
          exactMatch: true,
          pattern: /^(\d){10}$/,
        },
        features: [],
        phoneNumberPattern: '^[0-9]{10}$',
        placeholder: '802 123 4567',
      },
    };

    return {
      getCountries: () => {
        if (countries) {
          return Promise.resolve(countries);
        }

        return $LocalAPI
          .one('countries.json')
          .get()
          .then(response => {
            countries = response.map(country => {
              const supportedCountryCodes = Object.keys(supportedCountries);
              if (supportedCountryCodes.includes(country.countryCode)) {
                country.metadata = supportedCountries[country.countryCode];
              }
              return country;
            });

            return countries;
          });
      },
      getCountryAccountDataByCurrency: (country, currency) =>
        supportedCountries[country].supportedCurrencies[currency],
      getCurrencyData: currency => supportedCurrencies[currency],
      getCountryData: country => supportedCountries[country],
      currencyHasFeature: (currency, feature) =>
        supportedCurrencies[currency].features.includes(feature),
      countryHasFeature: (country, feature) =>
        supportedCountries[country].features.includes(feature),
      getCountryByName: countryName =>
        countries.find(country => country.name === countryName),
      getCountryByISOCode: countryCode =>
        countries.find(country => country.countryCode === countryCode),
      getCountryByCallingCode: callingCode =>
        countries.find(country => country.callingCodes[0] === callingCode),
      // Some country calling codes are just "+" for now, so the first check is to ensure that it has an actual calling code
      getCountryByPhoneNumber: phoneNumber =>
        countries.find(
          country =>
            country.callingCodes[0].length > 1 &&
            String(phoneNumber).startsWith(country.callingCodes[0]),
        ),
    };
  },
]);
