angular.module('app.addressService', []).service('AddressService', [
  'API',
  'Session',
  function ($API, Session) {
    const stringifyAddress = (address) => {
      if (!address) return '';
      const fields = ['lineOne', 'lineTwo', 'city', 'postCode', 'state', 'country'];

      const addressArray = fields.reduce((acc, field) => {
        if (address[field]) {
          return [...acc, address[field]];
        }
        return acc;
      }, []);

      const addressString = addressArray.join(', ');
      return addressString;
    };

    const formatAddress = (address) => {
      if (!address) return '';
      const {
        lineOne, lineTwo, city, postCode, state, country
      } = address;
      return `
        ${lineTwo ? `${lineTwo} `: ''}${lineOne}<br/>
        ${city}, ${postCode ? `${postCode}, `: ''}${state}<br/>
        ${country}
      `;
    };

    return {
      stringifyAddress,
      formatAddress,
    };
  },
]);
