angular
  .module('app.setup', ['ui.router'])

  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('setup', {
          templateUrl: 'components/layout/setup.html',
          url: '',
          abstract: true,
          isSetup: true,
          data: {
            authenticable: true,
          },
        })
        .state('setup.new', {
          title: 'Create New Business',
          url: '/new-business?pilot',
          pageTrack: '/new-business',
          templateUrl: 'modules/setup/new.html',
          controller: 'SetupNewBusinessCtrl',
          isSetup: true,
          resolve: {
            options: ['API', $API => $API.one('create_business/options').get()],
            industries: ['API', $API => $API.one('industry').get()],
          },
        });
    },
  ])

  .controller('SetupProfileCtrl', [
    '$scope',
    '$state',
    '$rootScope',
    'Auth',
    'PopularDomains',
    'options',
    'API',
    'Notification',
    'AnalyticsService',
    'industries',
    function (
      $scope,
      $state,
      $rootScope,
      Auth,
      PopularDomains,
      options,
      $API,
      Notification,
      AnalyticsService,
      industries,
    ) {
      $scope.options = options;
      $scope.industries = industries.data;
      $scope.user = {};
      $scope.business = {};

      const { setActiveUser, logEvent } = AnalyticsService;

      $scope.save = function () {
        const phoneNumberWithCallingCode = `${$scope.user.phone.calling_code}${$scope.user.phone.subscriber_number}`;

        Auth.update('account', {
          primary_contact_email: $rootScope.User.email,
          primary_contact_name:
            $scope.user.firstName + ' ' + $scope.user.lastName,
          primary_contact_phone: phoneNumberWithCallingCode,
          calling_code: $scope.user.phone.calling_code,
          industry: $scope.business.industry,
          integration_type: $scope.business.integrationType.code,
          international_requested: $scope.business.internationalRequested,
        })
          .then(function () {
            return Auth.update('user', {
              first_name: $scope.user.firstName,
              last_name: $scope.user.lastName,
              phone: phoneNumberWithCallingCode,
              calling_code: $scope.user.phone.calling_code,
              job_title: $scope.user.jobTitle,
              is_developer: $scope.user.isDeveloper,
            });
          })
          .then(function () {
            const domain = $rootScope.User.email.replace(/.*@/, '');
            const isCustomDomain = PopularDomains.indexOf(domain) <= -1;
            const isLargeBusiness = $scope.business.businessSize === 'large';

            return $API.all('integration/complete_setup').post({
              integration: $rootScope.Business.id,
              business_size: $scope.business.businessSize,
              integration_type: $scope.business.integrationType.id,
              send_slack_notification: isLargeBusiness || isCustomDomain,
            });
          })
          .then(function () {
            $rootScope.Business.setup_complete = true;
            // track complete setup
            setActiveUser(
              $rootScope.User,
              $rootScope.Business,
              'marketingInstance',
            );
            logEvent('Onboarding complete', {}, 'marketingInstance');

            // repeat the same for the product instance
            setActiveUser($rootScope.User, $rootScope.Business);
            logEvent('Onboarding complete');
            $state.go('app.onboarding');
          })
          .catch(function (error) {
            $rootScope.$broadcast('profileSaved', false);
            Notification.error('Could not save your profile', error);
          });
      };
    },
  ])

  .controller('SetupNewBusinessCtrl', [
    '$state',
    'LocalService',
    'Onboarding3Service',
    '$rootScope',
    '$scope',
    'API',
    'Notification',
    'Auth',
    'AnalyticsService',
    'options',
    'DEFAULTS',
    function (
      $state,
      LocalService,
      Onboarding3Service,
      $rootScope,
      $scope,
      $API,
      Notification,
      Auth,
      AnalyticsService,
      options,
      DEFAULTS
    ) {
      const { User, Business } = $rootScope;
      $scope.Business = Business;
      $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

      $scope.countries = options.data.countries.filter(
        country =>
          !settings.pilotCountriesForNewBusinesses.includes(country.value) ||
          country.value === $state.params.pilot,
      );
      $scope.businessTypes = options.data.businessTypes;
      const { logEvent } = AnalyticsService;
      $scope.logEvent = logEvent;

      const getIntegrations = () => {
        $API
          .one('session')
          .get()
          .then(response => {
            $scope.existingBusinesses = response.data.user.integrations;
          })
          .catch(() => {
            $scope.existingBusinesses = [];
          });
      };

      getIntegrations();

      $scope.duplicateExistingBusiness = false;
      $scope.newBusinessFormData = {
        businessName: '',
        country:
          Business &&
          Business.attributes &&
          Business.attributes.country.iso_code,
      };

      const getBusinessTypesForCountry = (
        businessCountryCode,
        countries,
        businessTypesData,
      ) => {
        const countryData =
          countries.find(country => country.value === businessCountryCode) ||
          {};
        const DEFAULT_BUSINESS_TYPES = ['starter', 'registered'];
        return (countryData.businessTypes || DEFAULT_BUSINESS_TYPES).map(
          businessType => ({
            ...businessTypesData[businessType],
            value: businessType,
          }),
        );
      };

      $scope.setBusinessTypes = () => {
        $scope.supportedBusinessTypes = getBusinessTypesForCountry(
          $scope.newBusinessFormData.country,
          $scope.countries,
          $scope.businessTypes,
        );
        $scope.newBusinessFormData.businessType =
          $scope.supportedBusinessTypes.length === 1
            ? $scope.supportedBusinessTypes[0].value
            : undefined;
      };

      $scope.setBusinessTypes();

      const switchIntegration = data =>
        Auth.switchIntegration(data)
          .then(() => {
            if (Onboarding3Service.isOnboarding3Enabled()) {
              $state.go('onboardingv3.businessProfile');
            } else {
              $state.go('app.compliance');
            }
          })
          .catch(error => {
            $scope.$broadcast('accountCreated', false);
            Notification.error('Could not create business', error);
          });

      const getIntegrationById = integrationId =>
        $scope.existingBusinesses.find(
          existingBusiness => existingBusiness.id === integrationId,
        ) || {};

      const createRequestBody = ({ businessName, businessType, country }) => {
        const requestBody = {
          business_name: businessName,
          country_code: country,
          email: Business ? Business.email : User.email,
          business_type: businessType,
        };
        if ($scope.duplicateExistingBusiness) {
          requestBody.duplicate_integration_id =
            $scope.newBusinessFormData.selectedBusiness;
          const duplicateIntegrationSource = getIntegrationById(
            $scope.newBusinessFormData.selectedBusiness,
          );
          requestBody.country_code = duplicateIntegrationSource.country_code;
          requestBody.business_type = duplicateIntegrationSource.business_type;
        }
        return requestBody;
      };

      $scope.createNewBusiness = () => {
        const requestBody = createRequestBody($scope.newBusinessFormData);
        const analyticsEventProperties = {
          is_duplicated: $scope.duplicateExistingBusiness,
          country_code: requestBody.country_code,
          business_type: requestBody.business_type,
        };
        $API
          .all('integration')
          .post(requestBody)
          .then(response => {
            logEvent('new-business_created', analyticsEventProperties);
            switchIntegration(response.data);
          })
          .catch(error => {
            logEvent('new-business_create-failed', {
              ...analyticsEventProperties,
              error,
            });
            Notification.error(
              'Could not create business',
              error && error.data && error.data.message,
            );
            $scope.$broadcast('accountCreated', false);
          });
      };
    },
  ]);
