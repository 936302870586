angular.module('app.payouts-next', ['ui.router'])

  .factory('Settlements', ['API', function (API) {
    var module = {
      data: [],
      meta: {},
      fetch: function (params) {
        return API.one('settlement').get(params).then(function (response) {
          module.data = _.map(response.data, function(settlement) {
            if (!angular.isDefined(settlement.effective_amount)) {
              settlement.effective_amount = settlement.total_amount;
            }
            return settlement;
          });
          module.meta = response.meta;
          return response;
        });
      }
    };

    return module;
  }])

  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('payouts-next', {
        abstract: true,
        url: '/payouts-next',
        title: 'Payouts',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'payouts-next.list',
        data: {
          permission: 'settlement-view',
          authenticable: true
        }
      }).state('payouts-next.list', {
        url: '?page&subaccount&status&from&to',
        pageTrack: '/payouts-next',
        title: 'Payouts',
        templateUrl: 'modules/payouts/list-next.html',
        controller: 'PayoutsNextCtrl',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('payouts-list-next-root'),
      }).state('payouts-next.one', {
        url: '/:id?page',
        pageTrack: '/payouts-next',
        title: 'Payouts',
        templateUrl: 'modules/payouts/one-next.html',
        controller: 'PayoutNextCtrl',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('payout-detail-root'),
      });
    }
  ])

  .controller('PayoutsNextCtrl', ['$scope', ($scope) => {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage({
          message: 'RENDER_PAYOUTS_LIST_NEXT_MICROFRONTEND',
        });
      }
    });
  }])

  .controller('PayoutNextCtrl', ['$scope', function ($scope) {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_PAYOUT_DETAIL_MICROFRONTEND',
          });
        }
      });
  }]);