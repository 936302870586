UIDirectives.directive('badge', function () {
  return {
    restrict: 'E',
    replace: true,
    template: '<span class="badge text-white text-capitalize" ng-class="labelClass">{{status.replace("-", " ")}}</span>',
    link: function ($scope, element, attrs, controller) {
      $scope.status = attrs['status'] ? attrs['status'].toLowerCase() : 'Unknown';
      $scope.labelClass = attrs['class'] || '';

      const successStates = ['verified', 'paid', 'active', 'success', 'subscribed', 'approved', 'complete', 'processed', 'available'];
      const failureStates = ['failed', 'unpaid', 'inactive', 'unverified', 'unsubscribed', 'expired', 'archived', 'overdue'];
      const warningStates = ['reversal-pending', 'processing']
      const reversedStates = ['reversed'];
      const abandonedStates = ['out of stock', 'pending'];

      if (successStates.includes($scope.status)) {
        $scope.labelClass += ' btn-success';
      } else if (failureStates.includes($scope.status)) {
        $scope.labelClass += ' btn-danger';
      } else if (reversedStates.includes($scope.status)) {
        $scope.labelClass += ' btn-primary';
      } else if (warningStates.includes($scope.status)) {
        $scope.labelClass += ' btn-warning-v2';
      } else if (abandonedStates.includes($scope.status)) {
        $scope.labelClass += ' btn-muted';
      } else {
        $scope.labelClass += ' btn-info';
      }
    }
  };
});