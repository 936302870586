angular.module('app.connect').controller('ConnectAccountListCtrl', [
  '$scope',
  function ($scope) {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
          {
            message: 'RENDER_CONNECT_ACCOUNT_LIST_MICROFRONTEND',
          },
          '*',
        );
      }
    });
  },
]);
