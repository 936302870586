angular
  .module('app.settings', ['ui.router'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('app.settings', {
          url: '/settings',
          pageTrack: '/settings',
          templateUrl: '/modules/settings/base.html',
          redirectTo: 'app.settings.profile',
          controller: 'SettingsCtrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('settings-root'),
        })
        .state('app.settings.profile', {
          url: '/profile?toggle_mfa',
          pageTrack: '/settings/profile',
          controller: 'SettingsCtrl',
          title: 'Profile',
        })
        .state('app.settings.business', {
          url: '/business',
          pageTrack: '/settings/business',
          controller: 'SettingsCtrl',
          title: 'Business',
          permission: 'settings-view',
          redirectTo: 'app.settings.compliance',
        })
        .state('app.settings.accounts', {
          url: '/accounts',
          title: 'Accounts',
          permission: 'accounts-view',
          controller: 'SettingsCtrl',
        })
        .state('app.settings.team-list', {
          url: '/team',
          title: 'Team',
          permission: 'user-view',
          pageTrack: '/settings/team',
          controller: 'SettingsCtrl',
        })
        .state('app.settings.team-roles', {
          url: '/team/roles',
          pageTrack: '/settings/team/roles',
          title: 'Roles',
          controller: 'RolesCtrl',
          permission: 'user-*',
          templateUrl: '/modules/settings/roles.html',
        })
        .state('app.settings.preferences', {
          url: '/preferences',
          pageTrack: '/settings/preferences',
          title: 'Preferences',
          controller: 'SettingsCtrl',
        })
        .state('app.settings.developers', {
          url: '/developers',
          pageTrack: '/settings/developers',
          title: 'Developers',
          controller: 'SettingsCtrl',
          permission: 'api-key-*',
        })
        .state('app.settings.compliance', {
          url: '/compliance?utm_medium',
          pageTrack: '/settings/compliance?utm_medium',
          title: 'Compliance',
          controller: 'SettingsCtrl',
          permission: 'settings-*',
        })
        .state('app.settings.apple-pay', {
          url: '/apple-pay',
          pageTrack: '/settings/apple-pay',
          title: 'Apple Pay',
          controller: 'SettingsCtrl',
        })
        .state('app.settings.contact', {
          url: '/contact',
          title: 'Contact',
          controller: 'SettingsCtrl',
        })
        .state('app.settings.sso', {
          url: '/sso',
          pageTrack: '/settings/sso',
          templateUrl: '/modules/settings/sso.html',
          controller: 'SettingsCtrl',
          title: 'SSO',
        });
    },
  ])
  .factory('SettlementAccountData', [
    'API',
    '$rootScope',
    function ($API, $rootScope) {
      return {
        fetch: () =>
          $API
            .one('integration/view_settlement_accounts', $rootScope.Business.id)
            .get(),
      };
    },
  ])
  .controller('SettingsCtrl', [
    '$scope',
    function ($scope) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_SETTINGS_MICROFRONTEND',
          }, '*');
        }
      });
    },
  ])
  .controller('RolesCtrl', [
    '$scope',
    $scope => {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_SETTINGS_MANAGE_ROLES_MICROFRONTEND',
          }, '*');
        }
      });
    },
  ])
  .controller('SSOCtrl', [
    '$scope',
    $scope => {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_SETTINGS_SSO_MICROFRONTEND',
          });
        }
      });
    },
  ]);