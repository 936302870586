angular.module('app.utilities', [])
  .service('utils', ['$filter', function($filter) {
    const capitalize = word => (`${word.charAt(0).toUpperCase()}${word.slice(1)}`);
    const capitalizeObjectKeys = (source) => {
      const entries = Object.entries(source);
      const uppercaseEntries = entries.map(([key, val]) => [
        capitalize(key),
        val,
      ]);
      return uppercaseEntries.reduce((object, [key, value]) => {
        object[key] = value;
        return object;
      }, {});
    };

    const concatenateObjectValues = (object = {}) => {
      const objectCopy = angular.copy(object);
      const cleanObject = Object.keys(objectCopy).reduce((acc, key) => {
        if (objectCopy[key]) {
          return { ...acc, [key]: objectCopy[key] };
        }
        return acc;
      }, {});
      return Object.values(cleanObject).join(', ');
    };

    const getElementOffset = (el) => {
      const rect = el.getBoundingClientRect();
      const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft
      };
    };

    const respondOnTimeout = (callback, timeInMs) => {
      const timeout = setTimeout(() => {
        callback();
      }, timeInMs);

      const resolve = () => {
        clearTimeout(timeout);
      };

      return {
        resolve,
      };
    };

    /**
     * Validate RIB for CIV
     * https://fr.m.wikipedia.org/wiki/Cl%C3%A9_RIB
     * @param {string} rib Statement of banking identity (RIB)
     * @returns {boolean} True if `rib` is valid.
     */
    const validateRIB = (rib) => {
      const mapping = {
        1: ['a', 'j', 's'],
        2: ['b', 'k', 't'],
        3: ['c', 'l', 'u'],
        4: ['d', 'm', 'v'],
        5: ['e', 'n', 'w'],
        6: ['f', 'o', 'x'],
        7: ['g', 'p', 'y'],
        8: ['h', 'q', 'z'],
        9: ['i', 'r'],
      };
      const fixedRib = rib.toLowerCase().split('').map((i) => {
        if (i.match(/[a-z]/i)) {
          const match = Object.entries(mapping).find(([, value]) => value.includes(i));
          if (match) {
            return match[0];
          }
        }
        return i;
      }).join('');
      const bankCode = parseInt(fixedRib.slice(0, 5), 10);
      const branchCode = parseInt(fixedRib.slice(5, 10), 10);
      const accountNumberFirstPart = parseInt(fixedRib.slice(10, 16), 10);
      const accountNumberSecondPart = parseInt(fixedRib.slice(16, 22), 10);
      const key = parseInt(fixedRib.slice(22), 10);
      const calculatedKey = 97 - ((17 * bankCode + 53 * branchCode + 81 * accountNumberFirstPart + 3 * accountNumberSecondPart) % 97);
      return calculatedKey === key;
    };

    const MAX_IBAN_LENGTH = 29;

    /**
     * Validate IBAN for EG
     * https://www.notion.so/paystack/Validating-an-IBAN-5bca9653f4284a869c4c1cdbcc94377e
     * @param {string} iban
     * @returns {boolean} True if `iban` is valid.
     */
    const validateIBAN = (iban) => {
      // check length
      if (iban.length !== MAX_IBAN_LENGTH) {
        return false;
      }

      // keep only alphanumeric characters
      const formattedIban = iban.toUpperCase().replace(/[^A-Z0-9]/g, '');

      // match and capture (1) the country code, (2) the check digits, and (3) the rest
      const [, countryCode, checkDigits, restOfAccountNumber] = formattedIban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) || [];

      // Rearrange country code and check digits, and convert chars to integers.
      // Replace each letter in the string with two digits, thereby expanding the string, where A = 10, B = 11, ..., Z = 35
      // So each letter.charCodeAt(0) - 55
      const digits = (restOfAccountNumber + countryCode + checkDigits)
        .replace(/[A-Z]/g, letter => (letter.charCodeAt(0) - 55).toString());

      return computeIBANChecksum(digits) === 1;
    };

    /**
     * Compute the checksum of an IBAN
     * @param {string} digits
     * @returns {number}
     */
    const computeIBANChecksum = (digits) => {
      let checksum = digits.slice(0, 2);
      let fragment;
      for (let offset = 2; offset < digits.length; offset += 7) {
        fragment = String(checksum) + digits.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
      }
      return checksum;
    };

    return {
      // Sometimes this function can be called with an undefined value hence the default value
      toKobo: ({amount, ...payload} = {}) => ({
        ...payload,
        amount: Math.round(amount * 100),
      }),
      toNaira: ({amount, ...payload} = {}) => ({
        ...payload,
        amount: amount / 100,
      }),
      currencyBaseUnitToSubUnit: amount => Math.round(amount * 100),
      currencySubUnitToBaseUnit: amount => amount / 100,
      firstNthWords: (sentence, count) => sentence.split(' ').slice(0, count).join(' '),
      toTitleCase: (value) => {
        if (!(value && typeof value === 'string')) {
          return null;
        }
        return value
          .toLowerCase()
          .trim()
          .split(' ')
          .reduce(
            (accumulator, stringChunk) => `${accumulator} ${stringChunk[0] ? stringChunk[0].toUpperCase() : ''}${stringChunk.substring(
              1,
              stringChunk.length,
            )}`,
            '',
          );
      },
      format: function(date){
        if (!date) return null;
        return $filter('date')(new Date(date), 'MM-dd-yyyy');
      },
      formatTransactionChannel: (channel) => {
        const channelTransformationMap = {
          ussd: 'USSD',
          'bank_transfer_ussd': 'USSD',
          qr: 'QR',
        };

        return channelTransformationMap[channel] || channel.replace('_', ' ');
      },
      jsonParse: (value, defaultValue) => {
        if (typeof value === 'string' && value.trim()) {
          return JSON.parse(value);
        }
        return defaultValue;
      },
      stripPropertiesWithoutValues: object => Object.keys(object)
        .reduce((accumulator, key) => {
          const currentValue = object[key];

          if (currentValue) {
            accumulator = { ...accumulator, [key]: currentValue };
          }

          return accumulator;
        }, {}),
      getGpsAddressRegex: function() {
        return /^[0-9a-zA-Z]{2}-\d{3,5}-\d{3,5}$/;
      },
      getAlphaNumericWithHyphensRegex: function() {
        return /^[a-zA-Z0-9\s-.]+$/;
      },
      getEmailRegexPattern: function() {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      },
      isEmail: function(email) {
        var re = this.getEmailRegexPattern();
        return re.test(String(email).toLowerCase());
      },
      arrayIntersection: function (arrayA, arrayB) {
        const setIntersection = new Set();
        const setA = new Set(arrayA);
        const setB = new Set(arrayB);

        for (const element of setB) {
          if (setA.has(element)) {
            setIntersection.add(element);
          }
        }
        return [...setIntersection];
      },
      isObject: value => value && !Array.isArray(value) && typeof value === 'object',
      formatPhoneNumber(phoneNumber, calling_code) {
        const subscriberNumber = this.isObject(phoneNumber) ? phoneNumber.subscriber_number : phoneNumber;

        if (subscriberNumber) {
          const regex = /^(\d{3})(\d{3})(\d+)$/g;
          const output = '$1 $2 $3';

          return `${calling_code ? calling_code : ''} ${subscriberNumber.replace(regex, output)}`;
        }
      },
      compose: (...fns) => x => fns.reduceRight((acc, fn) => fn(acc), x),
      composeAsync: (...fns) => input => fns.reduceRight((chain, func) => chain.then(func), Promise.resolve(input)),
      pipe: (...fns) => x => fns.reduce((acc, fn) => fn(acc), x),
      pipeAsync: (...fns) => input => fns.reduce((chain, func) => chain.then(func), Promise.resolve(input)),
      trace: () => (value) => {
        console.log({ value }); // eslint-disable-line no-console
        return value;
      },
      sideEffect: fn => (value) => {
        fn(value);
        return value;
      },
      capitalize,
      capitalizeObjectKeys,
      concatenateObjectValues,
      getElementOffset,
      respondOnTimeout,
      validateRIB,
      validateIBAN
    };
  }])
  .filter('money', ['$filter', function($filter) {
    return function(amount, currency) {
      var currency = currency ? currency + ' ' : '';
      var input = amount ? (amount / 100) : 0;
      return $filter('currency')(input, currency, 2);
    };
  }])
  .filter('seconds', [function() {
    return function(seconds) {
      return new Date(1970, 0, 1).setSeconds(seconds);
    };
  }])
  .filter('percentage', ['$filter', function($filter) {
    return function(input, decimals) {
      return $filter('number')(input * 100, decimals) + '%';
    };
  }]);
