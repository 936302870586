UIDirectives.directive('customSelect', [
  function () {
    /**
     * Custom Select directive
     * @param dropDownData {Array[object]}
     * @param displayField {String} // what field of the object should this directive display?
     * @param selectField {String} // what field of the object should this directive set on the ng-model?
     * @param ngModel {ngModel} // what model should we set the value of the selected field to?
     * @param onOptionChange {Function} // called whenever an option is selected
     * @param placeholder {string} // placeholder for the search input
     * @param required {Boolean} // is search input required
     * @param translate {string} // should translate select options (yes/no)
     */
    return {
      restrict: 'E',
      scope: {
        dropDownData: '=',
        displayField: '@',
        selectField: '@',
        ngModel: '=',
        onOptionChange: '&',
        placeholder: '@',
        required: '=',
        translate: '=',
      },
      template: `
      <div class="custom-dropdown-input">
        <div
          data-dropdown-group
          uib-dropdown 
          uib-keyboard-nav
          is-open="showDropdownInput"
          on-toggle="handleDropdownToggle(open)"
        >
          <button
            type="button"
            uib-dropdown-toggle
            class="flex flex-justify--space-between flex-align--center w-full bg-white form-control"
            aria-haspopup="true"
            aria-expanded="false"
            data-testid="toggle-button"
          >
            <span translate="{{translate}}">{{selectedItem ? selectedItem[displayField]: 'Choose an option'}}</span>
            <span class="caret" ng-style="{'transform': showDropdownInput ? 'rotate(180deg)': 'rotate(0deg)'}"></span>
          </button>
          <div class="search-container" ng-show="showDropdownInput" ng-click="preventClickPropagation($event)">
            <input
              class="form-control input-v2"
              ng-model="searchValue"
              ng-blur="handleBlur($event)"
              type="text"
              autocomplete="off"
              placeholder="{{placeholder || defaultPlaceholder}}"
              data-dropdown-input
              data-testid="search-input"
            />
            <span class="no-bg search-icon">
              <i class="icon-magnifier text-xs m-t-xxs text-muted"></i>
            </span>
          </div>
          <div class="no-arrow" uib-dropdown-menu ng-if="dropDownData && dropDownData.length">
              <ul class="searched-item padder">
                <li class="list-unstyled item-container" role="menuitem" ng-repeat="item in dropDownData | filter: ignoreAccentedCharactersOnSearch">
                  <a ng-click="handleOptionSelect(item)" class="flex flex-justify--space-between m-t-xs m-b-xs" data-testid="select-item-{{$index}}">
                    <span class="flex-item--fill flex item-name" translate="{{translate}}">
                      {{item[displayField]}}
                    </span>
                  </a>
                </li>
              </ul>
            <div class="text-muted padder padder-v-sm" ng-if="!dropDownData || dropDownData.length === 0"> No options available</div>
          </div>
        </div>
    </div>
    `,
      link: function ($scope, $element) {
        $scope.defaultPlaceholder = 'Loading...';

        $scope.preventClickPropagation = ($event) => {
          $event.preventDefault();
          $event.stopPropagation();
        };

        const focusInputField = () => {
          const inputDOMNode = angular.element(
            $element[0].querySelector('[data-dropdown-input]'),
          )[0];

          if (inputDOMNode) {
            inputDOMNode.focus();
          }
        }

        $scope.handleDropdownToggle = function(open) {
          if (open) {
            focusInputField();
          }
        };
        
        const isSameString = (text, compare) =>
          text && compare && text.toString() === compare.toString();

        $scope.setSelection = item => {
          if ($scope.ngModel === item[$scope.selectField]) {
            setSearchValue($scope.ngModel);
          } else {
            $scope.ngModel = item[$scope.selectField];
          }
          $scope.selectedItem = item;
          $scope.searchValue = '';
        };

        const setSearchValue = value => {
          if (value === '') {
            $scope.selectedItem = null;
          } else {
            const selectedItem =
              $scope.dropDownData &&
              $scope.dropDownData.find(item =>
                isSameString(item[$scope.selectField], value),
              );

            if (selectedItem) {
              $scope.searchValue =
                selectedItem[$scope.displayField || $scope.selectField];
            }
          }
        };

        $scope.selectActiveDropdownItem = () => {
          const selectedItem =
            $scope.dropDownData &&
            $scope.dropDownData.find(item => isSameString(item[$scope.displayField], $scope.ngModel) || isSameString(item[$scope.selectField], $scope.ngModel));

          if (selectedItem) {
            $scope.setSelection(selectedItem);
          }
        }

        $scope.handleOptionSelect = (item) => {
          $scope.onOptionChange && $scope.onOptionChange(item);
          $scope.setSelection(item);
        };

        $scope.ignoreAccentedCharactersOnSearch = function(item) {               
          if (!$scope.searchValue) {
            return true;
          }
          /**
           * NFD Unicode normalization form decomposes combined graphemes into the combination of simple ones.
           * For example: è => e + `
           * https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
           */
          const normalizedDisplayText = item[$scope.displayField].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          const searchText = $scope.searchValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          return normalizedDisplayText.includes(searchText);
        };

        $scope.handleBlur = $event => {
          const dropdownGroupDOMNode = angular.element(
            $element[0].querySelector('[data-dropdown-group]'),
          )[0];

          if (!dropdownGroupDOMNode) return;

          const blurHappenedOutsideElement =
            $event.relatedTarget != null && !dropdownGroupDOMNode.contains($event.relatedTarget);

          if (blurHappenedOutsideElement) {
            $scope.showDropdownInput = false;
          }
        };

        if ($scope.ngModel) {
          $scope.selectActiveDropdownItem();
        }

        $scope.$watch('dropDownData', value => {
          if (value !== null) {
            $scope.defaultPlaceholder = 'Search';
            setSearchValue($scope.ngModel);
          }

          if ($scope.ngModel) {
            $scope.selectActiveDropdownItem();
          }
        });

        $scope.toggleDropdown = () => {
          $scope.showDropdownInput = !$scope.showDropdownInput;
        };
      },
    };
  },
]);
