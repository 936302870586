UIDirectives.directive('logo', [
  '$rootScope', 'API', '$http', 'Auth', 'Notification', '$state',
  function ($rootScope, $API, $http, Auth, Notification, $state) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        url: '=',
        alt: '=',
      },
      template: `
    <div class="c-logo">
      <a ng-click="triggerUpload()">
        <img class="c-img" src="{{logoUrl}}" alt="{{alt}}" />
      </a>
      <input id="logoFileInput" type="file" accept="image/*" style="visibility:hidden" file-pick />
    </div>
  `,
      link: ($scope) => {
        $scope.logoUrl = $rootScope.Business && $rootScope.Business.logo_path;
        $scope.triggerUpload = () => {
          document.getElementById('logoFileInput').click();
        };

        const getSignedUrl = file => $API
          .all('upload_url')
          .post({
            upload_filename: file.name || `image-${uuidv4()}`,
            upload_path: '/integration-logos'
          })
          .then(response => response.data);

        const uploadFileToSignedURL = (data, file) => {
          const signed_url = data.signed_url;
          return $http.put(signed_url, file, { headers: { 'Content-Type': file.type }})
            .then(() => data);
        };

        $scope.$on('value', (event, files) => {
          const file = files && files[0];
          getSignedUrl(file).then(response => uploadFileToSignedURL(response, file)).then(response => Auth.update('account', {
            logo_path: response.path,
          })).then(() => {
            $state.reload();
          }).catch((error) => {
            Notification.error('Could not update logo', error);
          });
        });
      },
    };
  },
]);
