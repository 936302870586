angular.module('app.authService', [])
  .run(['$rootScope', '$state', 'Notification', 'LocalService', 'ngDialog',
    function ($rootScope, $state, Notification, LocalService, ngDialog) { }
  ])
  .service('Auth', ['LocalService', '$q', '$state', '$rootScope', 'API', 'Notification', 'Session', 'SessionTimer', 'ngDialog',
    function (LocalService, $q, $state, $rootScope, $API, Notification, Session, SessionTimer, ngDialog) {
      function refreshToken() {
        var token = Session.get('token');
        return $API.all('refresh_token').post({
          token: token
        }).then(({ data }) => {
          Session.saveToken(data.token, data.expiry);
        });
      }

      function getFeatureFlags() {
        return $API
          .one('feature-flags')
          .get()
          .then(function (response) {
            Session.saveFeatures(response.data);
          });
      }

      function refreshSession() {
        if (!Session.isActive()) {
          return Promise.reject();
        }

        return $API.one('session').get().then(function (response) {
          return saveSession(response.data);
        });
      }

      function saveSession({user, token, expiry}) {
        const userCanAccessCurrentBusiness = _.contains(_.pluck(user.integrations, 'id'), user.current_integration);

        Session.saveToken(token, expiry);

        if (user.integrations.length === 0) {
          return Promise.resolve(Session.saveUser(user));
        }

        if (!userCanAccessCurrentBusiness) {
          const [destinationBusiness] = user.integrations;
          return switchIntegration(destinationBusiness);
        }

        Session.saveUser(user);

        return Promise.all([refreshBusiness(user.current_integration), getFeatureFlags()]);
      }

      function attemptLogin(credentials) {
        return $API.all('login').post(credentials).then((response) => {
          if (response.data.mfa_required) {
            Session.saveMfaToken(response.data.token);
            return response.data;
          }

          return saveSession(response.data);
        });
      }

      function refreshUser() {
        return $API.one('user', 'profile').get().then(function (response) {
          Session.saveUser(response.data);
        });
      }

      function updateUser(payload = {}) {
        const {
          id, truecaller_verified, is_primary_user, display_state, ...userUpdatePayload
        } = payload;

        return $API
          .all('user/profile')
          .customPUT(userUpdatePayload)
          .then(function (response) {
            Session.saveUser(response.data);
          });
      }

      function refreshBusiness(id) {
        const businessId = id || Session.get('account').id;
        return $API
          .one('integration', businessId)
          .get({
            include: 'top-up,connect-status',
          })
          .then(function (response) {
            Session.saveBusiness(response.data);
          })
          .catch(error => {
            Notification.error('Could not refresh business', error);

            /**
             * We are throwing the error to bubble it up to the caller,
             * to prevent any '.then' chained to this function from getting called
             */
            throw error;
          });
      }

      function updateBusiness(payload) {
        return $API.all('integration/' + Session.get('account').id).customPUT(payload).then(function (response) {
          Session.saveBusiness(response.data);
          return response;
        });
      }

      function switchState(current_state, to_state) {
        if (to_state == 'live' && !Session.get('account').is_live) {
          ngDialog.open({
            template: 'components/modals/activate.html'
          });
          return;
        }

        SessionTimer.stop();
        return $API.all('user/switch_state').post({
          display_state: to_state
        }).then(function () {
          const updatedUser = Object.assign({}, Session.get('user'), { display_state: to_state });
          Session.saveUser(updatedUser);
        }).catch(function (error) {
          SessionTimer.reset();
          Notification.error('Could not switch to live state', error);
        });
      }

      function switchIntegration(account) {
        SessionTimer.stop();
        $rootScope.switchingAccount = account;
        $rootScope.showLoadingIcon = true;
        return $API.all('user/switch_integration').post({
          integration: account.id
        })
        .then(() => {
          window.postMessage({
            message: 'MFE_ROOT_RESET_ALL_QUERIES',
          }, '*');
        })
        .then(getFeatureFlags)
        .then(refreshUser)
        .then(() => refreshBusiness(account.id))
        .then(function () {
          $rootScope.switchingAccount = null;
          $rootScope.showLoadingIcon = false;
        })
        .catch(function (error) {
          $rootScope.switchingAccount = null;
          $rootScope.showLoadingIcon = false;
          Notification.error('Could not switch integration', error);
        });
      }

      function reauthenticate() {
        var credentials = {
          email: Session.get('user').email
        };

        return ngDialog.open({
          template: 'modules/access/modals/re-authenticate.html',
          closeByEscape: false,
          closeByNavigation: false,
          closeByDocument: false,
          showClose: false,
          controller: ['$scope', '$state', 'Session', function ($scope, $state, Session) {
            $scope.credentials = credentials;
            $scope.logout = function () {
              Session.end();
              $state.go('access.login', {
                next: $state.current.name,
              });
            };
            $scope.login = function () {
              attemptLogin($scope.credentials).then(function (res) {
                LocalService.set('current_session_timed_out', false);
                $scope.confirm();
              }).catch(function (error) {
                $scope.credentials.password = '';
                $scope.$broadcast('reauthenticated', false);
                Notification.error('Could not authenticate your account', error);
              });
            };
          }]
        });
      }

      function sendOTP() {
        return $API.all('send-otp').post();
      }

      return {
        login: attemptLogin,
        refreshToken: refreshToken,
        refreshSession: refreshSession,
        refreshBusiness: refreshBusiness,
        save: saveSession,
        update: function (model, payload) {
          var intent = payload === undefined || payload === null ? 'refresh' : 'update';
          if (model == 'user' && intent == 'refresh') return refreshUser();
          if (model == 'user' && intent == 'update') return updateUser(payload);
          if (model == 'account' && intent == 'refresh') return refreshBusiness();
          if (model == 'account' && intent == 'update') return updateBusiness(payload);
        },
        switchState: switchState,
        switchIntegration: switchIntegration,
        reauthenticate: reauthenticate,
        getFeatureFlags,
        sendOTP,
      };
    }
  ]);