angular
  .module('app.compliance')
  .factory('ComplianceAccount', [
    'API',
    'Session',
    ($API, Session) => {
      const getIntegrationId = () => {
        const integration = Session.get('account');
        return integration && integration.id;
      };
      return {
        fetchPayoutAccount: () => $API.one(`integration/${getIntegrationId()}/payout`).get().catch(() => {}),
        savePayoutAccount: updatePayload => $API.one(`integration/${getIntegrationId()}/payout`)
          .patch(updatePayload),
      };
    },
  ]);
