angular.module('app.compliance').directive('complianceDocumentInput', [
  function () {
    return {
      restrict: 'E',
      scope: {
        documentType: '=',
        documentData: '=',
        required: '@',
      },
      templateUrl: '/modules/compliance/views/document-input.html',
      link: ($scope) => {
        $scope.instructions = {
          'NG-VOTERS': {
            file: "Upload a copy of your Voter's card",
            text: "Voter's ID number",
          },
          PASSPORT: {
            file: "Upload a copy of your passport's data page",
            text: 'Passport Number',
          },
          'NG-DRIVERS': {
            file: "Upload a copy of your Driver's License",
            text: "Driver's License ID number",
          },
          'NG-NIN': {
            file: "Upload a copy of your National ID card or slip",
            text: "National Identity Number",
          },
          'EG-ID': {
            file: "Upload a copy of your National ID card",
            text: "National ID Number",
          },
          'CI-ID': {
            file: "Upload a copy of your National ID card",
            text: "National ID Number",
          }, 
          'RW-ID': {
            file: "Upload a copy of your National ID card",
            text: "National ID Number",
          },
          'GH-ID': {
            file: "Upload a copy of your Ghana Card",
            text: "Ghana Card PIN",
          },
        };

        /**
         * Add '-' automatically when typing Ghana card id
         * Prevents user from entering '-'.
         * Ghana Card format is ^GHA-[0-9]{9}-[0-9]{1}$
         */
        $scope.handleKeyDownOnGHCardInput = (event) => {
          const { code } = event;
          if (code === 'Backspace') {
            return;
          }

          let { value } = event.target;
          if (value.length > 0) {
            value = value.replace(/(-)/g, '');
            if (value.length > 2) {
              value = `${value.slice(0, 3)}-${value.slice(3, 12)}${value.length >= 12 ? `-${value.slice(12, value.length)}`: ''}`
            }
            $scope.documentData.value = value;
            if (code === 'Minus') {
              event.preventDefault();
            }
          }
        }
      },
    };
  },
]);
