(function() {
  'use strict';

  UIDirectives.directive('psTitle', PsTitleDirective);

  function PsTitleDirective() {
    return {
      restrict: 'E',
      templateUrl: getTemplate,
      replace: true,
      transclude: true,
      scope: {},
    };
  }

  function getTemplate(element, attrs) {
    if (!_.has(attrs, 'level')) {
      return templates.h6;
    }
    return templates[attrs.level];
  }

  var templates = {
    h6: 'components/directives/typography/h6.html',
    h5: 'components/directives/typography/h5.html',
    h4: 'components/directives/typography/h4.html',
  };
})();
