angular
  .module('app.mandates', ['ui.router'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('mandates', {
          abstract: true,
          title: 'Mandates',
          url: '/mandates',
          templateUrl: '/components/layout/base.html',
          redirectTo: 'mandates.list',
          data: {
            authenticable: true,
          },
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('mandates-root'),
        })
        .state('mandates.list', {
          title: 'Mandates',
          url: '',
          pageTrack: '/mandates',
          templateUrl: '/modules/mandates/list.html',
          controller: 'MandatesCtrl'
        })
        .state('mandates.list.one', {
          url: '/:code',
          title: 'Mandates',
          pageTrack: '/mandates',
          templateUrl: 'modules/mandates/list.html',
          controller: 'MandatesCtrl'
        })
        .state('mandates.amend', {
          url: '/:code/edit',
          title: 'Mandates',
          pageTrack: '/mandates',
          templateUrl: 'modules/mandates/list.html',
          controller: 'MandatesCtrl'
        });
    },
  ])
  .controller('MandatesCtrl', [
    '$scope','$state', 'Session',
    function ($scope, $state, Session) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        const features = Session.get('features') || {};
        if (!features.debit_orders_enabled) {
          $state.go('app.404')
        }
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_DEBIT_ORDERS_MICROFRONTEND',
          }, '*');
        }
      });
    },
  ]);
