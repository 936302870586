UIDirectives.directive('changelog', function () {
  return {
    restrict: 'A',
    link: function (scope, element, attrs, controller) {
      if (window.HW_config) return;

      window.HW_config = {
        selector: '.changelog',
        account: '07qAN7',
        translations: {
          title: "What we've been up to",
          readMore: 'Read more',
        }
      };
      var s = document.createElement('script');
      s.src = 'https://cdn.headwayapp.co/widget.js';
      document.body.appendChild(s);
    }
  };
});