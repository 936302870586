angular
  .module('onboardingv3', ['ui.router'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('onboardingv3', {
          url: '/onboarding',
          template: '<ui-view></ui-view>',
          redirectTo: 'onboardingv3.getStarted.home',
          data: {
            authenticable: true,
          },
        })
        .state('onboardingv3.businessProfile', {
          title: 'Business Profile',
          url: '/business-profile',
          templateUrl: 'modules/onboarding/v3/onboarding-root.html',
          controller: 'OnboardingV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('onboarding-root'),
        })
        .state('onboardingv3.businessType', {
          title: 'Business Type',
          url: '/business-type',
          templateUrl: 'modules/onboarding/v3/onboarding-root.html',
          controller: 'OnboardingV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('onboarding-root'),
        })
        .state('onboardingv3.businessRegistration', {
          title: 'Business Registration',
          url: '/business-registration?referrer',
          templateUrl: 'modules/onboarding/v3/onboarding-root.html',
          controller: 'OnboardingV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('onboarding-root'),
        })
        .state('onboardingv3.getStarted', {
          url: '/get-started',
          templateUrl: '/components/layout/base.html',
          redirectTo: 'onboardingv3.getStarted.home',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('onboarding-root'),
        })
        .state('onboardingv3.getStarted.home', {
          title: 'Get Started',
          url: '',
          templateUrl: 'modules/onboarding/v3/onboarding-root.html',
          controller: 'OnboardingV3GetStartedCtrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('onboarding-root'),
        });
    },
  ])
  .controller('OnboardingV3Ctrl', [
    '$scope',
    'Onboarding3Service',
    'Auth',
    '$state',
    function ($scope, Onboarding3Service, Auth, $state) {
      $scope.showNavbar = false;
      // Onboarding 3 is enabled for NG registered businesses only on the next stack and we need to transition between legacy and next based
      // on the onboarding 3 feature flag. The onboarding section has inputs for country and business type which can cause onboarding 3 to become
      // disabled. We reload the feature flags and determine whether we need to transition to the next app or the legacy app.
      Auth.getFeatureFlags()
        .then(() => {
          if (Onboarding3Service.isOnboarding3Enabled()) {
            $scope.$watch('mfeServiceReady', mfeServiceReady => {
              if (mfeServiceReady) {
                window.postMessage(
                  {
                    message: 'RENDER_ONBOARDING_MICROFRONTEND',
                  },
                  '*',
                );
              }
            });
          }
        })
        .catch(() => {
          $state.go('app.onboarding');
        });
    },
  ])
.controller('OnboardingV3GetStartedCtrl', ['$scope', 'Onboarding3Service', 'Auth', function ($scope, Onboarding3Service, Auth) {
  Auth.refreshBusiness()
  .then(() => {
    Onboarding3Service.redirectToLegacyGetStartedIfOnboarding3Disabled();
    $scope.showNavbar = true;
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage({
          message: 'RENDER_ONBOARDING_MICROFRONTEND',
        }, '*');
      }
    });
  }).catch(() => {});
}]);
