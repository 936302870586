const ProductModule = angular.module('app.products', ['ui.router']);

ProductModule.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('products', {
        abstract: true,
        reloadOnSearch: false,
        url: '/products',
        title: 'Products',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'products.list',
        data: {
          permission: 'product-view',
          authenticable: true,
        },
      })
      .state('products.list', {
        url: '?search&from&to&active&unlimited&quantityAmount&quantityCondition&quantitySoldAmount&quantitySoldCondition&priceAmount&priceCondition',
        pageTrack: '/products',
        title: 'Products',
        templateUrl: 'modules/products/views/one/list-next.html',
        controller: 'ProductListNextCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('product-list-root'),
      })
      .state('products.one', {
        url: '/:id?search',
        pageTrack: '/products',
        title: 'Products',
        templateUrl: 'modules/products/views/one/product-detail-next.html',
        redirectTo: 'products.one.orders',
        controller: 'ProductDetailCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('product-detail-root'),
      })
      .state('products.one.after-purchase', {
        url: '/after-purchase',
        pageTrack: '/products',
        title: 'Products',
      })
      .state('products.one.shipping', {
        url: '/delivery',
        pageTrack: '/products',
        title: 'Products',
      })
      .state('products.one.orders', {
        url: '/orders?email&region&from&to&status&delivered&refunded&delivered_at&current&page&index&products&search&is_viewed',
        pageTrack: '/products',
        reloadOnSearch: false,
      })
      .state('products.one.orders.one', {
        url: '/:orderId',
        pageTrack: '/products',
        reloadOnSearch: false,
        redirectTo: 'products.one.orders.one.overview',
      })
      .state('products.one.orders.one.overview', {
        url: '/overview',
        pageTrack: '/products',
        reloadOnSearch: false,
      })
      .state('products.one.orders.one.shipping', {
        url: '/shipping',
        pageTrack: '/products',
        reloadOnSearch: false,
      })
      .state('products.one.inventory', {
        url: '/inventory',
        pageTrack: '/products',
        title: 'Products',
      })
      .state('products.one.link', {
        url: '/link?view',
        pageTrack: '/products',
      })
      .state('products.one.discount', {
        url: '/discount',
        pageTrack: '/products',
        title: 'Products',
      })
      .state('products.one.discount_detail', {
        url: '/discount/:unique_code',
        pageTrack: '/products',
        title: 'Products',
      });
  },
]);
