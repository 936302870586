angular.module('app.compliance').directive('complianceFormSocial', [
  'AnalyticsService',
  function (AnalyticsService) {
    return {
      restrict: 'E',
      scope: {
        name: '=',
        handle: '=',
      },
      templateUrl: '/modules/compliance/views/social-form.html',
      link: ($scope) => {
        $scope.isEditing = false;
        const { logEvent } = AnalyticsService;
        $scope.logEvent = logEvent;
        $scope.toggleEditor = () => {
          $scope.isEditing = !$scope.isEditing;
        };
        $scope.updateHandle = (value) => {
          $scope.handle = value;
        };
      },
    };
  }
]);
