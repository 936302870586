UIDirectives
  .directive('addressInput', ['Addresses', '$rootScope', function(Addresses, $rootScope) {
    return {
      restrict: 'AE',
      scope: {
        address: '=',
        country: '@',
      },
      template: `
      <div>
          <select name="country" class="form-control select-form-caret"
              ng-options="country for country in countries track by country"
              ng-model="address.country" data-testid="office-address-country"
              ng-change="fetchStates()" required>
          </select>
          <input type="text" placeholder="{{'address.street' | i18next}}" data-testid="office-address-street"
              class="form-control m-t-sm" ng-model="address.street_line_one" required>
          <input type="text" placeholder="{{'address.building' | i18next}}" ng-model="address.street_line_two"
              class="form-control m-t-sm">
          <div class="row m-t-sm">
              <div class="col-xs-6 padder-right-xs">
                  <input type="text" placeholder="{{'address.city' | i18next}}" data-testid="office-address-city" ng-model="address.city" class="form-control" required>
              </div>
              <div class="col-xs-6 padder-left-xs">
                  <input type="text" placeholder="{{'address.postcode' | i18next}}" ng-model="address.post_code" class="form-control">
              </div>
          </div>
          <select name="state" class="form-control select-form-caret m-t-sm"
            ng-model="address.state" data-testid="office-address-state"
            ng-options="state for state in states track by state"
            ng-required="states.length">
          </select>
      </div>
    `,
      link: function ($scope, element, attrs, controller) {
        const session = $rootScope.currentSession;
        const country = session.account.attributes.country;
        const isNigerianBusiness = country && country.iso_code === 'NG';

        $scope.fetchStates = (countryName) => {
          const selectedCountry = countryName || $scope.address.country;
          Addresses.fetchStates(selectedCountry).then((response) => {
            $scope.states = response.map(({ name }) => name);
          }).catch(() => {
            $scope.states = [];
          });
        };

        if ($scope.country) {
          $scope.address['country'] = $scope.country;
          $scope.fetchStates($scope.country);
        }

        Addresses.fetchCountries().then((response) => {
          $scope.countries = isNigerianBusiness ? ['Nigeria'] : response.map(({ name }) => name);
          return $scope.fetchStates();
        }).catch(() => {
          $scope.states = [];
        });
      }
    };
  }]);
