angular.module('app.payouts', ['ui.router'])

  .factory('Settlements', ['API', function (API) {
    var module = {
      data: [],
      meta: {},
      fetch: function (params) {
        return API.one('settlement').get(params).then(function (response) {
          module.data = _.map(response.data, function(settlement) {
            if (!angular.isDefined(settlement.effective_amount)) {
              settlement.effective_amount = settlement.total_amount;
            }
            return settlement;
          });
          module.meta = response.meta;
          return response;
        });
      }
    };

    return module;
  }])

  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('payouts', {
        abstract: true,
        url: '/payouts',
        title: 'Payouts',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'payouts.list',
        data: {
          permission: 'settlement-view',
          authenticable: true
        }
      }).state('payouts.list', {
        url: '?page&subaccount&status&from&to&instant',
        pageTrack: '/payouts',
        title: 'Payouts',
        templateUrl: 'modules/payouts/list.html',
        controller: 'PayoutsCtrl',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('payouts-list-root'),
      }).state('payouts.one', {
        url: '/:id?page',
        pageTrack: '/payouts',
        title: 'Payouts',
        templateUrl: 'modules/payouts/one.html',
        controller: 'PayoutCtrl',
        resolve: {
          payouts: ['Settlements', '$stateParams', function (Settlements, $stateParams) {
            return Settlements.fetch($stateParams);
          }],
        },
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('payout-detail-root'),
      });
    }
  ])

  .controller('PayoutsCtrl', ['$scope', 'Session', '$stateParams', 'DEFAULTS', ($scope, Session, $stateParams, DEFAULTS) => {
    $scope.instantPayoutEnabled = Session.get('features').pod_enabled;
    $scope.isRequestedPayoutView = $stateParams.instant;
    $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage({
          message: 'RENDER_PAYOUTS_LIST_MICROFRONTEND',
        }, '*');
      }
    });
  }])

  .controller('PayoutCtrl', ['$state', '$scope', 'payouts', function ($state, $scope, payouts) {
    const payout = payouts.data.find(({ id }) => id == $state.params.id);

    if (payout) {
      $scope.payout = {
        title: moment(payout.settlement_date).format('MMM D YYYY'),
      };
    }

    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage({
          message: 'RENDER_PAYOUT_DETAIL_MICROFRONTEND',
        }, '*');
      }
    });
  }]);