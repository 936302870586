ProductModule.constant('ProductConstants', {
  AVAILABLE_FORMATS: ['.gif', '.jpg', '.jpeg', '.png', 'video/*'],
  MAX_FILE_SIZE: 20485760,
  MAX_DESCRIPTION_CHARACTERS: 8000,
  DESCRIPTION_CHARACTERS_WARNING_AT: 8000,
  MAX_PRODUCT_NAME_CHARACTERS: 100,
  MAX_UPLOAD_COUNT: 6,
  MAX_VARIANT_OPTIONS: 3,
  MAX_VARIANT_OPTION_VALUES: 10,
  MIN_SIZE: {
    image: 10000,
    video: 100000,
  },
  DESCRIPTION_AVAILABLE_TAGS: [
    'div',
    'ul',
    'li',
    'ol',
    'strong',
    'em',
    'br',
    'b',
    'h2',
    'h3',
    'p',
    'br',
    'a',
    'blockquote',
  ],
  ORDER_STATUSES: {
    paid_an_order: {
      field: 'paid',
      description: 'Received',
      action: 'paid_an_order',
    },
    delivered_an_order: {
      field: 'delivered',
      description: 'Delivered',
      action: 'delivered_an_order',
    },
    cancelled_an_order: {
      field: 'cancelled',
      description: 'Cancelled',
      action: 'cancelled_an_order',
    },
    refunded_an_order: {
      field: 'refunded',
      description: 'Posted a refund',
      action: 'refunded_an_order',
    },
    order_has_been_partially_refunded: {
      field: 'partially_refunded',
      description: 'Refunded',
      action: 'refunded_an_order',
    },
    order_has_been_refunded: {
      field: 'refunded',
      description: 'Payment refunded',
      action: 'refunded_an_order',
    },
    updated_an_order_shipping_address: {
      field: 'updated',
      description: 'Address updated',
      action: 'updated_an_order_shipping_address',
    },
  },
});
