ProductModule.factory('ProductIntroService', [
  'LocalService',
  '$timeout',
  'utils',
  function(LocalService, $timeout, utils) {
    const options = {
      showStepNumbers: false,
      showBullets: false,
      exitOnOverlayClick: false,
      exitOnEsc: false,
      tooltipClass: 'product-intro',
      highlightClass: 'product-intro-highlight',
      nextLabel: 'Next',
      prevLabel: 'Back',
      skipLabel: 'Close',
      doneLabel: 'Got it'
    };
    const { getElementOffset } = utils;

    const isFirstProductCreated = () => LocalService.get('firstProductCreated');
    const isOrdersTabTourFinished = () => LocalService.get('product_orders_tab_visited');
    const isInventoryTabTourFinished = () => LocalService.get('product_inventory_tab_visited');
    const isShippingTabTourFinished = () => LocalService.get('product_shipping_tab_visited');
    const isProductLinkTabTourFinished = () => LocalService.get('product_link_tab_visited');
    const isAfterPurchaseTabTourFinished = () => LocalService.get('product_after_purchase_tab_visited');

    const setFirstProductIsCreated = () => {
      LocalService.set('firstProductCreated', true);
    };

    const setOrdersTabTourAsFinished = () => {
      LocalService.set('product_orders_tab_visited', true);
    };

    const setInventoryTabTourAsFinished = () => {
      LocalService.set('product_inventory_tab_visited', true);
    };

    const setShippingTabTourAsFinished = () => {
      LocalService.set('product_shipping_tab_visited', true);
    };

    const setProductLinkTabTourAsFinished = () => {
      LocalService.set('product_link_tab_visited', true);
    };

    const setAfterPurchaseTabTourAsFinished = () => {
      LocalService.set('product_after_purchase_tab_visited', true);
    };

    const calculateIntroElementPosition = (elementId) => {
      const element = document.getElementById(elementId);

      if (!element) {
        return 'bottom';
      }

      const referencePoint = window.innerHeight / 2;
      const elementPosition = getElementOffset(element).top;
      const elementPopupPosition = elementPosition > referencePoint ? 'top' : 'bottom';

      return elementPopupPosition;
    };

    const ordersTabSteps = [{
      element: '#navOrders',
      intro:
          '<div class="introjs-title">Orders tab</div><div class="introjs-body">Track and manage all orders.</div>',
      position: 'bottom',
    }];

    const setupPostPurchaseTabSteps = () => {
      const navPostPurchasePopupPosition = calculateIntroElementPosition('navPostPurchase');

      return [
        {
          element: '#navPostPurchase',
          intro:
          '<div class="introjs-title">After Purchase tab</div><div class="introjs-body">Optimize your customers\' after purchase experience with a delightful success message and more.</div>',
          position: navPostPurchasePopupPosition,
        }
      ];
    };

    const setupInventoryTabSteps = () => {
      const variantNamePopupPosition = calculateIntroElementPosition('variantName');

      return [
        {
          element: '#variantName',
          intro:
              '<div class="introjs-title">Inventory overview</div><div class="introjs-body">Update product price, quantity in stock, and more</div>',
          position: variantNamePopupPosition,
        },
      ];
    };

    const setupShippingTabSteps = () => {
      const shippingFieldsPopupPosition = calculateIntroElementPosition('shippingFields');
      const addShippingFeePopupPosition = calculateIntroElementPosition('addShippingFee');

      return [
        {
          element: '#shippingFields',
          intro:
              '<div class="introjs-title">Delivery fields</div><div class="introjs-body">Enable delivery address for delivery and let customers leave a note on their orders.</div>',
          position: shippingFieldsPopupPosition,
        },
        {
          element: '#addShippingFee',
          intro:
              '<div class="introjs-title">Delivery fees</div><div class="introjs-body">Add delivery fees based on delivery locations</div>',
          position: addShippingFeePopupPosition,
        },
      ];
    };

    const setupProductLinkTabSteps = () => {
      const shareableLinkPopupPosition = calculateIntroElementPosition('shareableLink');
      const descriptionButtonPopupPosition = calculateIntroElementPosition('descriptionButton');
      const mediaPopupPosition = calculateIntroElementPosition('descriptionButton');

      return [
        {
          element: '#descriptionButton',
          intro:
              '<div class="introjs-title">Product description</div><div class="introjs-body">Write a compelling description for your product</div>',
          position: descriptionButtonPopupPosition,
        },
        {
          element: '#shareableLink',
          intro:
              '<div class="introjs-title">Shareable link</div><div class="introjs-body">Customize your Product Link to something memorable.</div>',
          position: shareableLinkPopupPosition,
        },
        {
          element: '#media',
          intro:
              '<div class="introjs-title">Media</div><div class="introjs-body">Personalize your Product Link with a background colour, photos and videos</div>',
          position: mediaPopupPosition,
        },
      ];
    };

    return {
      isFirstProductCreated,
      setFirstProductIsCreated,
      isOrdersTabTourFinished,
      isInventoryTabTourFinished,
      isShippingTabTourFinished,
      isProductLinkTabTourFinished,
      isAfterPurchaseTabTourFinished,
      setOrdersTabTourAsFinished,
      setInventoryTabTourAsFinished,
      setShippingTabTourAsFinished,
      setProductLinkTabTourAsFinished,
      setAfterPurchaseTabTourAsFinished,
      options,
      calculateIntroElementPosition,
      ordersTabSteps,
      setupPostPurchaseTabSteps,
      setupInventoryTabSteps,
      setupShippingTabSteps,
      setupProductLinkTabSteps,
    };
  }
]);