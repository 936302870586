angular.module('app.compliance').directive('complianceSocialLink', [
  function () {
    return {
      restrict: 'E',
      scope: {
        name: '=',
        handle: '=',
      },
      templateUrl: '/modules/compliance/views/social-link.html',
      link: ($scope) => {
        const socialNetworks = {
          facebook: 'https://facebook.com/',
          twitter: 'https://twitter.com/',
          instagram: 'https://instagram.com/',
        };

        const baseUrl = socialNetworks[$scope.name];

        $scope.url = `${baseUrl}${$scope.handle}`;
      },
    };
  },
]);
