angular.module('app.direct-debit')
  .controller('PaymentsListCtrl', ['$state', '$scope', '$rootScope', '$stateParams', '$filter', 'PaymentsData', 'ngDialog', 'API', function ($state, $scope, $rootScope, $stateParams, $filter, PaymentsData, ngDialog, $API) {

    PaymentsData.fetch().then(function (response) {
      $scope.payments = response.data;
      $scope.meta = response.meta;
    });

  }])


  .controller('SinglePaymentCtrl', ['$state', '$scope', '$rootScope', '$stateParams', 'ngDialog', 'payment', function ($state, $scope, $rootScope, $stateParams, ngDialog, payment) {

    var payment = payment.data;
    payment.has_authorization = payment.authorization && !_.isEmpty(payment.authorization);
    $scope.payment = payment;

  }]);