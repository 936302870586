angular.module('app.compliance').controller('ComplianceAgreementController', [
  '$scope',
  '$rootScope',
  'Notification',
  '$state',
  'ComplianceContact',
  'ComplianceProfile',
  'ComplianceOwner',
  'Onboarding3Service',
  'ServiceAgreementApi',
  (
    $scope,
    $rootScope,
    Notification,
    $state,
    ComplianceContact,
    ComplianceProfile,
    ComplianceOwner,
    Onboarding3Service,
    ServiceAgreementApi,
  ) => {
    Onboarding3Service.redirectToOnboarding3IfEnabled();
    $scope.agreementStatus = $scope.complianceStatusData.modules.find(module =>
      ['SERVICE_AGREEMENT'].includes(module.code),
    );

    $scope.reload = function () {
      $state.go($state.current, $state.params, {
        reload: true,
        inherit: true,
        notify: true,
      });
    };
    // calling_code
    $scope.signed = $scope.agreementStatus.completed;
    $scope.payload = {
      fullName: '',
      phoneNumber: { calling_code: '', phoneNumber: '' },
      email: '',
      jobTitle: '',
      acceptAgreement: false,
    };

    Promise.all([
      ComplianceContact.fetchContact(),
      ComplianceProfile.fetchSavedBusinessProfile(),
      ComplianceOwner.fetchOwner(),
    ])
      .then(([contactData, businessProfileData, complianceOwner]) => {
        $scope.payload.phoneNumber.calling_code =
          contactData.phone.calling_code;
        $scope.contactData = contactData || {};
        $scope.businessProfileData = businessProfileData;
        $scope.owner = complianceOwner;
        $scope.isStarter =
          $scope.businessProfileData.businessType === 'starter';
        $scope.address = '';
        $scope.countryCode = businessProfileData.country.toUpperCase();

        if (contactData.address.main) {
          const addressLineOne = contactData.address.main.lineOne || '';
          const addressLineTwo = contactData.address.main.lineTwo || '';
          const city = contactData.address.main.city || '';
          const postCode = contactData.address.main.postCode || '';
          const state = contactData.address.main.state || '';

          $scope.address = `${addressLineOne} ${addressLineTwo} ${city} ${postCode} ${state}`;
        }
        if ($scope.signed) {
          ServiceAgreementApi.fetchServiceAgreement().then(agreement => {
            $scope.signedAgreementMetaData = agreement;
            $scope.$broadcast('signedAgreementMetaDataReady');
          });
        }
      })
      .catch(error => {
        Notification.error('Could not fetch owner information', error);
      })
      .finally(() => {
        $scope.$broadcast('agreementPageReady');
      });

    $scope.signAgreement = () => {
      const payload = {
        ...$scope.payload,
        phoneNumber: `${$scope.payload.phoneNumber.calling_code}${$scope.payload.phoneNumber.number}`,
      };
      ServiceAgreementApi.signServiceAgreement(payload)
        .then(response => {
          $rootScope.$broadcast('signAgreement');
          $state.reload();
        })
        .catch(error => {
          Notification.error('Could not save business profile', error);
          $rootScope.$broadcast('signAgreement', false);
        });
    };

    $scope.downloadAgreement = () => {
      const location = JSON.parse($scope.signedAgreementMetaData.locations);
      const hasMsaV2Index = location.findIndex(msaLocation =>
        msaLocation.includes('V2'),
      );
      const document =
        hasMsaV2Index > -1 ? [location[hasMsaV2Index]] : location;
      const url = `#/files?location=${document}`;
      window.open(url, '_blank');
    };
  },
]);
