angular.module('app.settings').factory('Addresses', [
  'API',
  function ($API) {
    let countries;
    const states = {};
    const module = {
      fetchCountries: () => {
        if (countries) {
          return Promise.resolve(countries);
        }
        return $API
          .one('address/country')
          .get()
          .then((response) => {
            countries = response.data;
            return response.data;
          });
      },
      /**
       * @param {string}
       * parameter could be countryCode or country name
       * TODO: figure out why the caller of this function is calling with differnt parameters
       */
      fetchStates: (param) => {
        if (states[param]) {
          return Promise.resolve(states[param]);
        }
        if (!countries) {
          return Promise.reject();
        }
        const { id } = countries.find(country => country.iso2 === param || country.name === param) || {};
        if (!id) {
          return Promise.reject();
        }
        return $API
          .one(`address/country/${id}/state`)
          .get()
          .then((response) => {
            states[param] = [...new Set(response.data.sort((current, next) => current.name.localeCompare(next.name)))];
            return states[param];
          });
      },
    };
    return module;
  },
]);
