angular.module('app.utilities')
  .service('DeviceService', () => {
    const userAgent = navigator.userAgent.toLowerCase();
    let platform;
    if (userAgent.includes('iphone')) {
      platform = 'iOS';
    }
    if (userAgent.includes('android')) {
      platform = 'Android';
    }
    return {
      userAgent,
      platform,
    };
  });
