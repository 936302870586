angular
  .module('app.compliance')
  .directive('complianceMenuItem', ['$state', 'AnalyticsService', '$rootScope', function ($state, AnalyticsService, $rootScope) {
    return {
      restrict: 'E',
      scope: {
        module: '=',
        isLast: '=',
      },
      templateUrl: '/modules/compliance/views/menuItem.html',
      link: ($scope) => {
        const { logEvent } = AnalyticsService;
        $scope.logEvent = logEvent;

        const computeTemplateData = () => {
          $scope.isCurrentModule = $scope.module.link === $state.current.name;
          $scope.currentModuleIsBeingEdited = ($scope.module.link === $state.current.name) && !!$state.params.edit;
          const isCompleted = $scope.module.completed;
          const isPending = $scope.module.completion === 0;
          $scope.status = ($scope.currentModuleIsBeingEdited && 'editing') || (isPending && 'pending') || (isCompleted && 'complete');
        };

        computeTemplateData();

        $rootScope.$on('$stateChangeSuccess', () => {
          computeTemplateData();
        });
      },
    };
  }]);
