angular.module('app.compliance').factory('RegistrationDocuments', [
  'API',
  'Session',
  ($API, Session) => {
    const getIntegrationId = () => {
      const integration = Session.get('account');
      return integration && integration.id;
    };
    return {
      fetchDocuments: () => $API
        .one(`integration/${getIntegrationId()}/documents`)
        .get(),
      saveDocument: updatePayload => $API.all(`integration/${getIntegrationId()}/documents`).post(updatePayload),
      savePerson: personData => $API.one(`integration/${getIntegrationId()}/person`).patch(personData),
      deletePerson: ({ integration, id: personId }) => $API.one(`integration/${integration}/person/${personId}`).customDELETE(),
      fetchOwners: () => $API.one(`integration/${getIntegrationId()}/owners?documents=true`).get(),
      fetchDirectors: () => $API.one(`integration/${getIntegrationId()}/directors?documents=true`).get(),
    };
  },
]);
