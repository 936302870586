ProductModule.constant('DigitalProductConstants', {
  AVAILABLE_FORMATS: [
    '.gif',
    '.jpg',
    '.jpeg',
    '.png',
    '.svg',
    'video/*',
    'application/pdf',
    '.mobi',
    '.epub',
    '.wav',
    '.csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    '.mid',
    '.midi',
    '.ogg',
    'audio/aac',
    'audio/mpeg',
    'audio/x-m4a',
    'audio/flac',
    'audio/alac',
    'audio/aif',
    'audio/aiff',
    'application/zip',
    'application/x-zip-compressed',
    '.pkg',
    '.rar',
    'application/postscript',
    'image/vnd.adobe.photoshop',
    '.tiff',
    '.indd',
    '.3ds',
    '.3dm',
    '.key',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.oasis.opendocument.text',
    'text/rtf',
    'text/plain',
    '.wpd',
    '.xmp',
    '.lrtemplate',
    '.fxp',
    '.aep',
    'text/html',
    '.obj',
    '.fbx',
    '.ply',
    '.stl',
    '.wrl'
  ],
  MAX_FILE_SIZE_BYTES: 1024 * 1024 * 1024 * 4, // 4GB
  MAX_FILE_SIZE_KB: 1024 * 1024 * 4, // 4GB
  MAX_FILE_UPLOAD_COUNT: 10,
});
