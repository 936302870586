var AccessModule = angular.module('app.access', []);

const signupQueryParams = [
  'email',
  'first_name',
  'last_name',
  'country_code',
  'business_name',
  'calling_code',
  'number',
  '_id',
  'pilot',
  'ref'
]

AccessModule
  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('access', {
        url: '',
        abstract: true,
        templateUrl: 'components/layout/access.html'
      }).state('access.login', {
        title: 'Login',
        url: '/login?next&prefill',
        pageTrack: '/login',
        templateUrl: '/modules/access/login-next.html',
        controller: 'LoginNextCtrl',
        requiresLogout: true,
        onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('login-root'),
      }).state('access.mfa', {
        title: 'MFA',
        url: '/login/mfa?type&next',
        pageTrack: '/login/mfa',
        templateUrl: '/modules/access/mfa-next.html',
        controller: 'LoginNextCtrl',
        requiresLogout: true,
        onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('mfa-root'),
      }).state('access.signup', {
        title: 'Signup',
        url: `/signup?${signupQueryParams.join('&')}`,
        pageTrack: '/signup',
        templateUrl: '/modules/access/signup.html',
        controller: 'SignupCtrl',
        requiresLogout: true,
        resolve: {
          userIp: ['API', $API => $API.one('decision/ip').get()],
          signUpOptions: ['API', $API => $API.one('create_business/options').get()],
        }
      }).state('access.request-password-reset', {
        title: 'Request Password',
        url: '/request-password-reset',
        pageTrack: '/request-password-reset',
        templateUrl: '/modules/access/request-password-reset.html',
        controller: 'RequestPasswordResetCtrl',
        requiresLogout: true
      }).state('access.set-password', {
        title: 'Set Password',
        url: '/set-password/:hash/:integration',
        pageTrack: '/set-password',
        templateUrl: '/modules/access/set-password.html',
        controller: 'ResetPasswordCtrl',
        resolve: {
          verification: ['$state', '$stateParams', '$rootScope', 'API', 'Notification', function ($state, $stateParams, $rootScope, $API, Notification) {
            return $API.all('verify_reset_hash').post({
              hash: $stateParams.hash,
              integration: $stateParams.integration,
              is_invite: false
            }).then(function (response) {
              return response;
            }).catch(function (error) {
              $state.go('access.login');
              Notification.error('Failed to set password', error);
            });
          }]
        },
        requiresLogout: true
      }).state('access.reset-password', {
        title: 'Reset Password',
        url: '/reset-password/:hash',
        pageTrack: '/reset-password',
        templateUrl: '/modules/access/reset-password.html',
        controller: 'ResetPasswordCtrl',
        resolve: {
          verification: ['$state', '$stateParams', '$rootScope', 'API', 'Notification', function ($state, $stateParams, $rootScope, $API, Notification) {
            return $API.all('verify_reset_hash').post({
              hash: $stateParams.hash,
              is_invite: false
            }).then(function (response) {
              return response;
            }).catch(function (error) {
              $state.go('access.request-password-reset', {
                hash: error.data.message.hash
              });
              Notification.error('Failed to reset password', error);
            });
          }]
        },
        requiresLogout: true
      }).state('access.join-team', {
        title: 'Join Team',
        url: '/join-team/:hash',
        pageTrack: '/join-team',
        templateUrl: '/modules/access/join-team.html',
        controller: 'JoinTeamCtrl',
        resolve: {
          verification: ['$state', '$stateParams', '$rootScope', 'API', 'Notification', function ($state, $stateParams, $rootScope, $API, Notification) {
            return $API.all('verify_reset_hash').post({
              hash: $stateParams.hash,
              is_invite: true
            }).then(function (response) {
              return response;
            }).catch(function (error) {
              $state.go('access.login');
              if (error.data && error.data.message == 'Password reset hash not found') error.data.message = 'Verification hash was not found';
              Notification.error('There was a problem with your invitation link', error);
            });
          }]
        },
        requiresLogout: true
      }).state('access.business-switcher-home', {
        title: 'Paystack',
        url: '/business/:id',
        redirectTo: 'access.business-switcher'
      }).state('access.business-switcher', {
        title: 'Paystack',
        url: '/business/:id/*path',
        pageTrack: '/business',
        templateUrl: '/modules/access/switch-business.html',
        controller: 'BusinessSwitcherCtrl',
        resolve: {
          currentBusiness: ['Auth', '$state', '$stateParams', '$rootScope', 'LocalService', function (Auth, $state, $stateParams, $rootScope, LocalService) {
            return Auth.refreshSession().catch(function () {
              LocalService.set('redirectParams', $stateParams);
              $state.go('access.login', {
                next: 'access.business-switcher'
              });
            });
          }],
          destinationBusiness: ['$stateParams', 'currentBusiness', 'Session', 'Notification', function ($stateParams, currentBusiness, Session, Notification) {
            var user = Session.get('user');
            var allowed_integrations = _.pluck(user.integrations, 'id');
            var destinationBusiness = {
              id: $stateParams.id
            };

            if (user.current_integration == destinationBusiness.id) {
              destinationBusiness.sameAsCurrent = true;
              return Promise.resolve(destinationBusiness);
            } else if (_.contains(allowed_integrations, parseInt(destinationBusiness.id))) {
              destinationBusiness = _.find(user.integrations, function (integration) {
                return integration.id == $stateParams.id;
              });
              return Promise.resolve(destinationBusiness);
            } else {
              return Promise.resolve(null);
            }
          }]
        }
      }).state('access.access-denied', {
        title: 'Paystack',
        url: '/access-denied?reason&next',
        templateUrl: '/modules/access/access-denied.html',
        controller: 'AccessDeniedCtrl',
        data: {
          authenticable: true
        },
        onExit: ['ngDialog', (ngDialog) => ngDialog.closeAll()],
        resolve: {
          currentUser: ['API', '$state', '$stateParams', ($API, $state, $stateParams) => $API.one('session').get().then(({ data }) => {
            const { user } = data;

            const currentIntegrationRequiresMFA = !!user.integrations.find(({ id, mfa }) => id === user.current_integration && mfa && mfa.is_required);
            const userShouldEnableMFA = $stateParams.reason === 'no-mfa' && currentIntegrationRequiresMFA && !user.mfa_enabled;
            
            if (!userShouldEnableMFA) {
              const nextState = (!$state.current.name || String($state.current.name).startsWith('access.')) ? 'app.home.dashboard' : $state.current.name;
              $state.go(nextState);
            } else {
              return user
            }
          })]
        },
      }).state('access.confirm-signup-email', {
        title: 'Confirm Signup Email Address',
        url: '/confirm-email/:hash',
        pageTrack: '/confirm-email',
        resolve: {
          verification: ['$state', '$stateParams', 'API', 'Notification', 'LocalService', 'AnalyticsService', function ($state, $stateParams, $API, Notification, LocalService, AnalyticsService) {
            const {
              logEvent,
            } = AnalyticsService;

            return $API.all('confirm_email').post({
              hash: $stateParams.hash
            }).then(() => {
              logEvent('Signup email confirmation successful');
              LocalService.unset('userSignupEmail');
              $state.go('access.login');
              Notification.success('Your email address was confirmed successfully', 'You may login to your account now');
            }).catch((error) => {
              logEvent('Signup email confirmation failed');

              $state.go('access.login');
              Notification.error('There was a problem with your email confirmation link', error);
            });
          }]
        },
        requiresLogout: true
      }).state('access.verify-signup-email', {
        title: 'Verify Email Address',
        url: '/verify-email-address',
        pageTrack: '/verify-email-address',
        templateUrl: '/modules/access/verify-signup-email.html',
        controller: ['$scope', 'LocalService', '$state', 'API', 'Notification', 'AnalyticsService', ($scope, LocalService, $state, $API, Notification, AnalyticsService) => {
          $scope.email = LocalService.get('userSignupEmail');
          const { logEvent } = AnalyticsService;

          $scope.resendVerificationEmail = () => {
            logEvent('signup_send-verify-email', { state: 'started' });
            $API.all('send-signup-verification-email').post()
              .then(() => {
                logEvent('signup_send-verify-email', { state: 'succeeded' });
                Notification.success('Verification email request sent', 'Verification email resent successfully');
              })
              .catch((error) => {
                logEvent('signup_send-verify-email', { state: 'failed' });
                Notification.error('Could not resend signup verification email', error);
              });
          };

          if (!$scope.email) {
            $state.go('access.login');
          }
        }]
      }).state('404', {
        title: '404',
        url: '/404',
        templateUrl: '/modules/access/404.html',
        resolve: {
          loggedIn: ['Session', '$state', function (Session, $state) {
            if (Session.isActive()) {
              return Promise.reject().catch(function () {
                return $state.go('app.404');
              });
            }
          }]
        }
      });
    }
  ])

  .controller('LoginCtrl', ['$scope', '$rootScope', '$state', 'Auth', 'API', 'Notification', 'Session', 'LocalService', '$stateParams', '$i18next', 'AnalyticsService', 'ngDialog', 'Onboarding3Service', '$interval',
    function ($scope, $rootScope, $state, Auth, $API, Notification, Session, LocalService, $stateParams, $i18next, AnalyticsService, ngDialog, Onboarding3Service, $interval) {
      $scope.credentials = {};
      $scope.mfa = {};
      $scope.mfaType = $stateParams.type;

      $scope.otpTimerInSeconds = 60;
      $scope.timerText = $i18next.t('mfa.resendOTPTimer', { time: '1:00' });
      $scope.canResendOTP = false;

      const startResendOTPTimer = () => {
        $scope.canResendOTP = false;
        $scope.timerText = $i18next.t('mfa.resendOTPTimer', { time: '1:00' });
        $scope.otpTimerInSeconds = 60;

        const countdown = $interval(() => {
          $scope.otpTimerInSeconds--;
          if ($scope.otpTimerInSeconds > 0) {
            const minutes = Math.floor($scope.otpTimerInSeconds / 60);
            const seconds = $scope.otpTimerInSeconds % 60;
            $scope.timerText = $i18next.t(`mfa.resendOTPTimer`, { time: `${minutes}:${seconds < 10 ? '0' : ''}${seconds}` });
          } else {
            $scope.canResendOTP = true;
            $interval.cancel(countdown);
          }
        }, 1000);
      }

      if ($scope.mfaType === 'otp') {
        startResendOTPTimer();
      }

      $scope.resendOTP = function () {
        $scope.resendingOTP = true;
        Auth.sendOTP().then(() => {
          Notification.success('OTP Resent Successfully');
          startResendOTPTimer();
        }).catch((error) => {
          Notification.error('Could not resend OTP', error);
        }).finally(() => {
          $scope.resendingOTP = false;
        });
      };

      const {
        setActiveUser,
        logEvent,
      } = AnalyticsService;

      const handleSuccessfulLogin = () => {
        const isLive = $rootScope.Business && $rootScope.Business.is_live;
        const goLiveStatus = ($rootScope.Business && $rootScope.Business.go_live_status) || '';

        let nextState;

        if (Onboarding3Service.isOnboarding3Enabled()) {
          if (!Onboarding3Service.isOnboardingComplete()) {
            // Bugfix: https://paystack.atlassian.net/browse/NGOPS-246
            const shownAnnouncements = LocalService.get('shown_announcements');
            LocalService.set('shown_announcements', {...shownAnnouncements, Welcome: true});
            nextState = 'onboardingv3.businessProfile';
          } else if (!isLive && goLiveStatus !== 'requested') {
            nextState = 'onboardingv3.getStarted';
          } else {
            nextState = 'app.home.dashboard';
          }
        } else {
          if (isLive) {
            nextState = 'app.home.dashboard';
          } else if (!isLive) {
            nextState = 'app.compliance';
          } else {
            nextState = 'app.onboarding';
          }
        }

        const isDirectDebitUser = $rootScope.Business && $rootScope.Business.business_type === 'direct_debit';
        const integration = Session.get('account');
        const user = Session.get('user');

        // Switch active user
        setActiveUser(user, integration);
        logEvent('Login complete');

        if ($state.params.next) {
          $state.go($state.params.next, LocalService.get('redirectParams')).then(null, () => {
            $state.go(nextState);
          });
          LocalService.set('redirectParams', null);
        } else if (isDirectDebitUser) {
          $state.go('direct-debit.accounts');
        } else {
          $state.go(nextState);
        }
      };

      $scope.verifyTotp = () => {
        $scope.verifyingMFA = true;
        $API.all('verify-mfa').post($scope.mfa)
          .then((response) => {
            Auth.save(response.data).then(() => {
              Session.clearMfaToken();
              handleSuccessfulLogin();
            }).catch(() => {
              $scope.verifyingMFA = false;
            })
          })
          .catch((error) => {
            $scope.verifyingMFA = false;
            Notification.error('Could not verify Two Factor Authentication code', error);
            if (
              error &&
              error.data &&
              error.data.message === 'Authentication token expired'
            ) {
             $rootScope.logout();
            }
          });
      };

      $scope.login = () => {
        Notification.clear();
        logEvent('Login started');
        const payload = {
          ...$scope.credentials,
          ...($scope.organizationData && {
            organization: $scope.organizationData.id,
          })
        };

        Auth.login(payload).then((response) => {
          if (response && response.mfa_required) {
            $state.go('access.mfa', { ...$state.params, type: response.mfa_type });
          } else {
            handleSuccessfulLogin();
          }
        })
          .catch((error) => {
            $scope.$broadcast('loggedIn', false);
            $scope.loginFailed = true;

            let errorMessage = error && error.data && error.data.message;

            if (typeof errorMessage === 'object' && errorMessage !== null) {
              errorMessage = errorMessage.text;
              if (errorMessage && errorMessage.includes('Your password has expired')) {
                $state.go('access.request-password-reset', {
                  hash: error.data.message.hash
                });
              }
            }

            logEvent('Login failed', {
              Error: errorMessage,
            });

            Notification.error('Could not login to your account', errorMessage);
        });
      };

      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_PASSKEYS_MICRO_FRONTEND',
          });
        }
      });

      const messageEventListener = ({ data: loginWithPassKeySuccessResponse }) => {
        if (loginWithPassKeySuccessResponse.message === 'PASSKEY_LOGIN') {
          $scope.$broadcast('loggedIn', true);
      
          LocalService.set('current_session', loginWithPassKeySuccessResponse.data.token);
          LocalService.set('current_session_expiry', loginWithPassKeySuccessResponse.data.expiry.toString());
      
          Auth.save(loginWithPassKeySuccessResponse.data).then(() => {
            handleSuccessfulLogin();
          }).catch(() => {})
        }
      };
      
      window.addEventListener('message', messageEventListener);
  
      $scope.$on('$destroy', () => {
        window.removeEventListener('message', messageEventListener);
      });
    }
  ])
  .controller('LoginNextCtrl', ['$scope', '$rootScope', '$state', 'Auth', 'Session', 'LocalService','AnalyticsService', 'Onboarding3Service',
    function ($scope, $rootScope, $state, Auth, Session, LocalService, AnalyticsService, Onboarding3Service) {

      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_LOGIN_MICRO_FRONTEND',
          }, '*');
          window.postMessage({
            message: 'RENDER_MFA_MICRO_FRONTEND',
          }, '*');
        }
      });

      const {
        setActiveUser,
        logEvent,
      } = AnalyticsService;

      const handleSuccessfulLogin = () => {
        const isLive = $rootScope.Business && $rootScope.Business.is_live;
        const goLiveStatus = ($rootScope.Business && $rootScope.Business.go_live_status) || '';

        let nextState;

        if (Onboarding3Service.isOnboarding3Enabled()) {
          if (!Onboarding3Service.isOnboardingComplete()) {
            // Bugfix: https://paystack.atlassian.net/browse/NGOPS-246
            const shownAnnouncements = LocalService.get('shown_announcements');
            LocalService.set('shown_announcements', {...shownAnnouncements, Welcome: true});
            nextState = 'onboardingv3.businessProfile';
          } else if (!isLive && goLiveStatus !== 'requested') {
            nextState = 'onboardingv3.getStarted';
          } else {
            nextState = 'app.home.dashboard';
          }
        } else {
          if (isLive) {
            nextState = 'app.home.dashboard';
          } else if (!isLive) {
            nextState = 'app.compliance';
          } else {
            nextState = 'app.onboarding';
          }
        }

        const isDirectDebitUser = $rootScope.Business && $rootScope.Business.business_type === 'direct_debit';
        const integration = Session.get('account');
        const user = Session.get('user');

        // Switch active user
        setActiveUser(user, integration);
        logEvent('Login complete');

        if ($state.params.next) {
          $state.go($state.params.next, LocalService.get('redirectParams')).then(null, () => {
            $state.go(nextState);
          });
          LocalService.set('redirectParams', null);
        } else if (isDirectDebitUser) {
          $state.go('direct-debit.accounts');
        } else {
          $state.go(nextState);
        }
      };

      const messageEventListener = ({data: eventData}) => {
        if (eventData.message === 'LOGIN_FINISHED') {
          const loginResponseData = eventData.data;
          Auth.save(loginResponseData).then(() => {
            window.postMessage(
              { message: 'SAVE_SESSION', status: 'SUCCESS' },
              '*',
            );
            handleSuccessfulLogin();
          }).catch(() => {
            window.postMessage(
              { message: 'SAVE_SESSION', status: 'FAILED'},
              '*',
            );
          })
        }

        if (eventData.message === 'SESSION_ENDED') {
          $rootScope.history = [];
          Session.end();
        }
      };
      window.addEventListener('message', messageEventListener);
      
      $scope.$on('$destroy', () => {
        window.removeEventListener('message', messageEventListener);
      });
    }
  ])
  .controller('SignupCtrl', ['$scope', '$rootScope', '$state', 'API', 'Notification', 'PasswordValidator', 'AnalyticsService', 'userIp', 'LocalService', 'CountryService', 'Session', 'signUpOptions',
    function ($scope, $rootScope, $state, $API, Notification, PasswordValidator, AnalyticsService, userIp, LocalService, CountryService, Session, signUpOptions) {
      const { initializeMarketingInstance, logEvent } = AnalyticsService;
      // we need to initialize analytics with the deviceid passed from the marketing website so the session can continue
      const analyticsOptions = {
        deviceId: $state.params._id
      };
      initializeMarketingInstance(analyticsOptions);

      const privacyPolicyUrlsByCountry = {
        NG: 'https://paystack.com/privacy/merchant?localeUpdate=true',
        GH: 'https://paystack.com/gh/privacy/merchant?localeUpdate=true',
        ZA: 'https://paystack.com/za/privacy/merchant?localeUpdate=true',
        CI: 'https://paystack.com/merchant-privacy-civ',
        KE: 'https://paystack.com/merchant-privacy-ke',
        EG: 'https://paystack.com/merchant-privacy-eg',
        RW: 'https://paystack.com/merchant-privacy-rw',
      };

      const termsOfAcceptableUseUrlByCountry = {
        NG: 'modules/access/terms.html',
        GH: 'modules/access/terms.html',
        ZA: 'modules/access/terms.html',
        KE: 'modules/access/terms.html',
        CI: 'modules/access/terms.html',
        EG: 'modules/access/terms-pilot.html',
        RW: 'modules/access/terms-pilot.html',
      };

      $scope.getTermsOfAcceptableUseUrl = countryCode => termsOfAcceptableUseUrlByCountry[countryCode] || termsOfAcceptableUseUrlByCountry.NG;
      $scope.getPrivacyPolicyUrl = countryCode => privacyPolicyUrlsByCountry[countryCode] || privacyPolicyUrlsByCountry.NG;
      $scope.countries = signUpOptions.data.countries.filter(country => !settings.pilotCountriesForNewBusinesses.includes(country.value) || country.value === $state.params.pilot);
      $scope.businessTypes = signUpOptions.data.businessTypes;
      $scope.emailSuggestion = null;

      const userIpAddress = userIp.data.ip_address;
      const campaign = $state.params.ref;
      $scope.marketingCampaign = $scope.getMarketingCampaign();

      $scope.credentials = {
        email: $state.params.email,
        first_name: $state.params.first_name,
        last_name: $state.params.last_name,
        business_name: $state.params.business_name,
        country_code: $state.params.country_code,
        phone: {
          calling_code: $state.params.calling_code,
          number: $state.params.number
        },
        ...(campaign && { campaign }),
      };

      // get the countryCode based on tge users' IP address
      $API.one(`decision/resolve_ip/${userIpAddress}`).get()
        .then((response) => {
          if (response && response.data) {
            const countryCode = response.data.countryCode;
            $scope.credentials.country_code = $scope.credentials.country_code || countryCode;
            $scope.setBusinessTypes();
            CountryService.getCountries().then(() => {
              const countryObject = CountryService.getCountryByISOCode(countryCode);
              const callingCode = countryObject && countryObject.callingCodes[0]
              $scope.credentials.phone.calling_code = $scope.credentials.phone.calling_code || callingCode
            });
          }
        });

      const getBusinessTypesForCountry = (businessCountryCode, countries, businessTypesData) => {
        const countryData = countries.find((country) => country.value === businessCountryCode) || {};
        const DEFAULT_BUSINESS_TYPES = ['starter', 'registered'];
        return (countryData.businessTypes || DEFAULT_BUSINESS_TYPES).map((businessType) => ({ ...businessTypesData[businessType], value: businessType }));
      }

      $scope.setBusinessTypes = () => {
        $scope.supportedBusinessTypes = getBusinessTypesForCountry($scope.credentials.country_code, $scope.countries, $scope.businessTypes);
        $scope.credentials.businessType = $scope.supportedBusinessTypes.length === 1 ? $scope.supportedBusinessTypes[0].value : undefined;
      }

      $scope.logSignupStarted = () => {
        if ($scope.logSignupStarted.done) {
            return;
        }
        $scope.logSignupStarted.done = true;
        logEvent('Signup started');
      }

      $scope.validatePassword = function (passwordField) {
        $scope.passwordChecker = PasswordValidator.validate($scope.credentials.password);
        /* here we use the $setValidity function on an input field to set the validtiy of the
          input based on the criteria set from the Passwordvalidator factory so we have custom validation */
        passwordField.$setValidity(passwordField.$name, $scope.passwordChecker.isValid);
      };

      $scope.signup = () => {
        Notification.clear();

        const { phone, businessType, ...otherSignupCrendentials } = $scope.credentials;
        const payload = {
          ...otherSignupCrendentials,
          ...($scope.organizationData && {
            organization: $scope.organizationData.id,
          }),
          phone: {
            subscriber_number: phone.number,
            calling_code: phone.calling_code,
          },
          business_type: businessType,
        };

        $API.all('register').post(payload).then(({ data }) => {
          logEvent('Signup complete', {}, 'marketingInstance');
          Session.saveToken(data.token);
          LocalService.set('userSignupEmail', payload.email);
          logEvent('Signup complete');
          $state.go('access.verify-signup-email');
        }, function (error) {
          $scope.$broadcast('accountCreated', false);
          Notification.error('Account could not be created', error);
        });
      };
    }
  ])
  .controller('RequestPasswordResetCtrl', ['$scope', '$rootScope', '$state', 'Auth', 'API', 'Notification', '$i18next', 'AnalyticsService', function ($scope, $rootScope, $state, Auth, $API, Notification, $i18next, AnalyticsService) {
    const { logEvent } = AnalyticsService;
    $scope.request = function () {
      $API.all('reset_password').post({
        email: $scope.email
      }).then(function (result) {
        logEvent('Password reset requested');
        $state.reload();
        Notification.success('Reset Link Sent', result.message);
      }, function (error) {
        $scope.$broadcast('requested', false);
        Notification.error('Could not initiate a password reset', error);
      });
    };
  }])

  .controller('ResetPasswordCtrl', ['$scope', '$rootScope', '$state', 'Auth', 'API', 'Notification', 'verification', 'PasswordValidator', '$i18next', 'AnalyticsService',
    function ($scope, $rootScope, $state, Auth, $API, Notification, verification, PasswordValidator, $i18next, AnalyticsService) {
      $scope.user = verification.data;
      $scope.organization = verification.data.organization;

      $scope.validatePassword = function (passwordField) {
        $scope.passwordChecker = PasswordValidator.validate($scope.new_password);
        passwordField.$setValidity(passwordField.$name, $scope.passwordChecker.isValid);
      };

      const { logEvent } = AnalyticsService;

      $scope.reset = function () {
        $API.all('set_new_password').post({
          hash: $state.params.hash,
          is_invite: false,
          password: $scope.new_password
        }).then(function (result) {
          logEvent('Password reset complete');
          Notification.success('Password reset successful', result.message);
          $state.go('access.login');
        }, function (error) {
          logEvent('Password reset failed', {
            Error: error,
          });
          $scope.$broadcast('reset', false);
          Notification.error('Password could not be reset', error);
        });
      };
    }
  ])

  .controller('JoinTeamCtrl', ['$scope', '$rootScope', '$state', 'Auth', 'API', 'Notification', 'verification', 'PasswordValidator', '$i18next',
    function ($scope, $rootScope, $state, Auth, $API, Notification, verification, PasswordValidator, $i18next) {
      $scope.validatePassword = function (passwordField) {
        $scope.passwordChecker = PasswordValidator.validate($scope.user.password);
        passwordField.$setValidity(passwordField.$name, $scope.passwordChecker.isValid);
      };

      $scope.user = verification.data;

      $scope.join = function () {
        $API.all('set_new_password').post({
          hash: $state.params.hash,
          is_invite: true,
          first_name: $scope.user.first_name,
          last_name: $scope.user.last_name,
          job_title: $scope.user.job_title,
          is_developer: $scope.user.is_developer,
          phone: $scope.user.phone,
          password: $scope.user.password
        }).then(function (result) {
          return Auth.save(result.data);
        }).then(function () {
          const phoneNumber = $scope.user.phone ? `${$scope.user.phone.calling_code}${$scope.user.phone.subscriber_number}` : '';
          // If this is the first admin, set business info
          const account = $rootScope.Business;
          const admins = account.users.filter(user => user.role === 'admin');
          if (!admins.length) {
            Auth.update('account', {
              primary_contact_name: $scope.user.first_name + ' ' + $scope.user.last_name,
              primary_contact_email: $scope.user.email,
              primary_contact_phone: phoneNumber
            });
          }

          return Promise.resolve();
        }).then(function () {
          var account = $rootScope.Business;
          $state.go(account.is_live ? 'app.home.dashboard' : 'app.onboarding');
          Notification.success('Welcome to Paystack', 'Your account was completed successfully');
        }).catch(function (error) {
          $scope.$broadcast('joined', false);
          Notification.error('Account could not be completed', error);
        });
      };
    }
  ])

  .controller('BusinessSwitcherCtrl', ['$scope', '$rootScope', '$state', '$location', 'Auth', 'API', 'Notification', 'Session', 'destinationBusiness', '$i18next',
    function ($scope, $rootScope, $state, $location, Auth, $API, Notification, Session, destinationBusiness, $i18next) {
      var user = Session.get('user');
      var setupDestinationBusiness = new Promise(function (resolve, reject) {
        if (destinationBusiness && destinationBusiness.sameAsCurrent) {
          resolve();
        } else if (destinationBusiness) {
          Auth.switchIntegration(destinationBusiness).then(function () {
            Notification.success('Accounts Switched', 'You are now using the ' + destinationBusiness.business_name + ' account');
            resolve();
          });
        } else {
          reject();
        }
      });

      setupDestinationBusiness.then(function () {
        $location.path($state.params.path || 'dashboard');
        $rootScope.switchingAccount = null;
        $rootScope.showLoadingIcon = false;
      }).catch(function () {
        $scope.unauthorized = true;
      });

      $scope.salutation = user.first_name || user.last_name || i18next.t('switchBusiness.there');
    }
  ])

  .controller('AccessDeniedCtrl', ['$scope', '$rootScope', '$state', 'Auth', 'Notification', 'currentUser', 'ngDialog', ($scope, $rootScope, $state, Auth, Notification, currentUser, ngDialog) => {
    $scope.user = currentUser;
    $scope.userIntegrationsExcludingCurrentIntegration = $scope.user.integrations.filter(({ id }) => id !== $scope.user.current_integration);

    $scope.mfaEnabled = currentUser.mfa_enabled;
    $scope.currentIntegration = $scope.user.integrations.find(({ id }) => id === $scope.user.current_integration);
    $scope.userShouldEnableMFA = $state.params.reason === 'no-mfa' && $scope.currentIntegration.mfa.is_required && !$scope.mfaEnabled;

    const setUserShouldEnableMFA = value => {
      $scope.userShouldEnableMFA = value;
    };

    const goToDashboard = () => {
      Auth.refreshSession().then(() => {
        $state.go($state.params.next || 'app.home.dashboard');
        $rootScope.switchingAccount = null;
      }).catch(() => {});
    };

    $scope.goToDashboard = goToDashboard;

    $scope.switchIntegration = (integration) => {
      Auth.switchIntegration(integration)
        .then(() => {
          goToDashboard();
          Notification.success('Accounts Switched', 'You are now using the ' + integration.business_name + ' account');
        }).catch((error) => {
          Notification.error('Could not switch business', error);
        })
    };

    $scope.showEnableMFAModal = () => {
      ngDialog.open({
        closeByEscape: false,
        className: 'ngdialog-theme-plain dialog-close-center-icon',
        template: 'enableMFA',
        scope: $scope,
        resolve: {
          mfaRequest: ['API', ($API) => $API.one('request-mfa').get()]
        },
        controller: ['$scope', '$state', 'mfaRequest', 'Notification', 'AnalyticsService', 'Auth', 'API',  ($scope, $state, mfaRequest, Notification, AnalyticsService, Auth, $API) => {
          $scope.mfaRequest = mfaRequest.data;
          const { logEvent } = AnalyticsService;

          const setMFASuccessModalParameters = (backupCodes) => {
            $scope.mfaEnabled = true;
            $scope.backupCodes = backupCodes;
            const formattedBackupCodes = $scope.backupCodes.join('\n');
            const file = new Blob([formattedBackupCodes], { type: 'text/plain'});
            $scope.backupCodeLink = URL.createObjectURL(file);

            $scope.showProceedButton = true;
            $scope.proceedButtonText = 'Go to dashboard';
            $scope.proceedToNextStep = () => {
              goToDashboard();
            };
          };

          $scope.enableMFA = (totp) => {
            $scope.enablingMFA = true;
            $API.all('enable-mfa').post({ totp })
              .then((response) => {
                setUserShouldEnableMFA(false);
                logEvent('Enabled 2FA from access-denied page');
                setMFASuccessModalParameters(response.data.backup_codes);
                Auth.update('user');
              })
              .catch((error) => {
                Notification.error('Could not enable Two Factor Authentication', error);
                logEvent('Could not enable 2FA from access-denied page', {
                  errorStatus: error && error.statusCode,
                  errorMessage: error && error.data && error.data.message
                })
              });
          };
        }]
      })
    };

  }]);
