SubscribersModule.controller('SubscriberListCtrl', [
  '$scope',
  function ($scope) {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
          {
            message: 'RENDER_SUBSCRIBERS_LIST',
          },
          '*',
        );
      }
    });
  },
]);
