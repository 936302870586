angular.module('app.compliance', ['ui.router']).config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('app.compliance', {
        url: '/compliance',
        pageTrack: '/compliance',
        templateUrl: '/modules/compliance/views/index.html',
        resolve: {
          complianceStatusData: [
            'ComplianceStatus',
            ComplianceStatus => ComplianceStatus.getComplianceStatus(),
          ],
          featureFlags: ['Auth', Auth => Auth.getFeatureFlags()],
        },
        redirectTo: 'app.compliance.profile',
        controller: [
          '$state',
          '$scope',
          '$rootScope',
          'complianceStatusData',
          ($state, $scope, $rootScope, complianceStatusData) => {
            $scope.complianceStatusData = complianceStatusData;
            const business = $rootScope.Business || { attributes: {} };
            const {
              is_live,
              primary_contact_email,
              attributes: {
                compliance_documents_submitted,
                duplicated_from_integration,
                compliance_status,
                country: {
                  iso_code,
                }
              },
            } = business;
            const inEditMode = $state.params && $state.params.edit;
            const showActivationStatusMessage =
              compliance_documents_submitted && !inEditMode && !is_live;
            const showDuplicatedBusinessMessage =
              duplicated_from_integration &&
              !is_live &&
              compliance_status === 'NEW';
            
            const getBusinessActivationStatusMessage = () => {
              const BUSINESS_ACTIVATION_I18N_MESSAGE_KEY = {
                default: 'activateBusiness.statusMessage.default',
                ZA: 'activateBusiness.statusMessage.ZA',
                NG: 'activateBusiness.statusMessage.NG',
              };
              const businessActivationMessageKey = BUSINESS_ACTIVATION_I18N_MESSAGE_KEY[iso_code] || BUSINESS_ACTIVATION_I18N_MESSAGE_KEY.default;

              return primary_contact_email
              ? i18next.t(`${businessActivationMessageKey}.messageWithEmail`, {
                  email: primary_contact_email,
                })
              : i18next.t(`${businessActivationMessageKey}.messageWithoutEmail`);
            };

            const activateBusinessStatusMessage = getBusinessActivationStatusMessage();

            const statusMessagePriority = [
              [showActivationStatusMessage, activateBusinessStatusMessage],
              [
                showDuplicatedBusinessMessage,
                i18next.t('activateBusiness.duplicateBusinessMessage'),
              ],
            ];

            const setStatusMessage = statusMessagePriority => {
              const [showStatusMessage, statusMessage] =
                statusMessagePriority.find(
                  statusMessage => statusMessage[0],
                ) || [false, ''];
              $scope.showStatusMessage = showStatusMessage;
              $scope.statusMessage = statusMessage;
            };

            setStatusMessage(statusMessagePriority);
          },
        ],
      })
      .state('app.compliance.profile', {
        url: '/profile?edit',
        pageTrack: '/profile',
        templateUrl: '/modules/compliance/views/profile/index.html',
        controller: 'ComplianceProfileController',
      })
      .state('app.compliance.contact', {
        url: '/contact?edit',
        pageTrack: '/contact',
        templateUrl: '/modules/compliance/views/contact/index.html',
        controller: 'ComplianceContactController',
      })
      .state('app.compliance.owner', {
        url: '/owner?edit',
        pageTrack: '/owner',
        templateUrl: '/modules/compliance/views/owner/index.html',
        controller: 'ComplianceOwnerController',
      })
      .state('app.compliance.account', {
        url: '/account?edit',
        pageTrack: '/account',
        templateUrl: '/modules/compliance/views/account/index.html',
        controller: 'ComplianceAccountController',
      })
      .state('app.compliance.agreement', {
        url: '/agreement',
        pageTrack: '/agreement',
        templateUrl: '/modules/compliance/views/serviceAgreement/index.html',
        controller: 'ComplianceAgreementController',
      })
      .state('app.compliance.documents', {
        url: '/documents?edit',
        pageTrack: '/documents',
        templateUrl: '/modules/compliance/views/documents/index.html',
        controller: 'ComplianceDocumentsController',
      });
  },
]);
