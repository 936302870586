angular.module('app.timezoneService', [])
  .service('TimezoneService', ['LocalAPI', ($LocalAPI) => {
    let timezones = [];

    return {
      getTimezones:() => {
        if (timezones.length) {
          return Promise.resolve(timezones);
        }

        return $LocalAPI.one('timezones.json').get().then((response) => {
          timezones = response;
          return response;
        });
      },
      getTimezoneDetailsByRegion: region => timezones.find(({ timezone }) => region === timezone)
    };
  }]);
