angular.module('app.disputes', ['ui.router'])

  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('disputes', {
        abstract: true,
        url: '/disputes',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'disputes.list',
        data: {
          permission: 'transaction-dispute',
          authenticable: true
        }
      }).state('disputes.list', {
        url: '?status&from&to&page&period&for&transaction&category&resolution&use_cursor&next&previous&per_page',
        pageTrack: '/disputes',
        title: 'Disputes',
        templateUrl: 'modules/disputes/list.html',
        controller: 'DisputeListCtrl',
        onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('dispute-list-root'),
      });
    }
  ])
  .factory('PendingDisputes', ['API', 'Session', function ($API, Session) {
    var module = {
      data: [],
      meta: {},
      promise: null,
      fetching: false,
      fetch: function () {
        if (!Session.get('account')) return Promise.reject();
        if (!Session.hasPermission('transaction-dispute')) return Promise.reject();

        if (!module.fetching) {
          var publicLaunchDate = moment(new Date('January 29 2018'));
          module.fetching = true;
          module.promise = $API.one('dispute').get({
            status: 'awaiting-merchant-feedback',
            ignore_resolved: 'yes',
            from: publicLaunchDate.format('DD MMM Y')
          }).then(function (response) {
            module.data = response.data;
            module.meta = response.meta;
            module.fetching = false;
          }).catch(function () {
            module.fetching = false;
          });
        }

        return module.promise;
      }
    };

    return module;
  }])
  .controller('DisputeListCtrl', ['$scope',
    function ($scope) {
      $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_DISPUTE_LIST',
          }, '*');
        }
      });
    }]);