angular.module('app.direct-debit')
  .controller('AccountsListCtrl', ['$state', '$scope', '$rootScope', '$stateParams', '$filter', 'AccountsData', 'ngDialog', 'API', 'Notification', function ($state, $scope, $rootScope, $stateParams, $filter, AccountsData, ngDialog, $API, Notification) {

    AccountsData.fetch().then(function (response) {
      $scope.provisionedAccounts = response.data;
      $scope.meta = response.meta;
    });

    $scope.balance = {};

    $scope.refreshAccountBalance = function (account_code) {
      $API.one('directdebit/' + account_code + '/balance').get().then(function(response) {
        var balance = response.data.balance;
        $scope.provisionedAccounts.map(function (account) {
          if (account.account_code === account_code) {
            account.balance = balance;
          }
          return account;
        });
        Notification.success('Balance retrieved successfully');
      }).catch(function(error){
        Notification.error('Error Occured', error);
      });
    };

  }]);