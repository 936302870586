angular.module('app.roleService', [])
  .service('RoleService', ['API', 'LocalAPI', function($API, LocalAPI) {
    var roles, rolesPromise;

    var module = {
      fetch: function() {
        rolesPromise = $API.one('integration/roles').get().then(function(response){
          roles = _.map(response.data, function(role) {
            role.full_name = role.name.split('-').map(function capitalize(part) {
              return part.charAt(0).toUpperCase() + part.slice(1);
            }).join(' ');

            switch(role.name) {
              case 'admin': { role.priority = 0; break; }
              case 'operations': { role.priority = 1; break; }
              case 'developer-support': { role.priority = 2; break; }
              case 'customer-support': { role.priority = 3; break; }
              default: { role.priority = 4; break; }
            }

            return role;
          });
          return response;
        });

        return rolesPromise;
      },
      data: function() {
        return _.sortBy(roles, 'priority');
      },
      id: function(role_name) {
        var role = _.findWhere(roles, {
          is_custom: 0,
          name: role_name
        }) || {};
        return role.id;
      },
      permissions: [],
    };

    LocalAPI.all('permissions').getList().then(function(permissionGroups) {
      module.permissions = permissionGroups;
    });

    return module;
  }]);