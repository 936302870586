angular.module('app.notificationService', ['toaster'])
  .factory('Notification', ['toaster', function(toaster) {
    return {
      pop: function(options) {
        toaster.pop(options);
      },
      // The third parameter modifies the timeout config of the notification component. Use `0` to make the notification persist until dismissed by user action.
      success: function(title, body, timeout = 5000) {
        toaster.pop({
          type: 'success',
          title: title || 'Action completed succesfully',
          body: body,
          timeout
        });
      },
      error: function(title, error, timeout = 5000, bodyOutputType) {
        if (!error) return;
        var message;
        if (typeof error === 'string') {
          switch(error) {
            case 'Networking Error':
              message = 'Could not connect to the upload server';
              break;
            default:
              message = error;
          }
        } else if (error.data) {
          var response = error.data;
          if (response.errors) {
            if (response.message) title = response.message;
            var first_error = _.values(response.errors)[0];
            var required = _.findWhere(first_error, {
              rule: 'required'
            });

            if (required) message = required.message;
            else message = first_error[0].message;
          }
          if (!message && response.message) message = response.message;
          if (!message && response.data) message = response.data.summary;
          if (!message) message = 'Unknown error occurred';
        } else if (error.message) {
          message = error.message;
        } else {
          message = 'Could not connect to the server. Please check your connection and try again';
        }

        toaster.pop({
          type: 'error',
          title: title || 'Action could not be performed',
          body: message,
          timeout,
          bodyOutputType
        });
      },
      clear: function() {
        toaster.clear();
      }
    };
  }]);
