UIDirectives.directive('multiEmailInput', ['$i18next', 'utils', ($i18next, utils) => ({
  restrict: 'E',
  replace: true,
  scope: {
    ngModel: '=',
    ngRequired: '=',
    placeholder: '@',
    highlightInitialEmail: '=',
    initialEmailTooltipText: '@',
    limit: '=',
    testId: '@'
  },
  templateUrl: 'components/directives/multi-email-input.html',
  link: ($scope, elem) => {
    $scope.entries = $scope.ngModel;
    $scope.inputValue = '';
    $scope.indexOfEntryBeingEdited = null;

    $scope.emailInputPattern = utils.getEmailRegexPattern();

    const emailInputField = elem[0].querySelector('#email-input-field');
    emailInputField.style.display = $scope.entries.length ? 'none' : 'block';

    $scope.deleteEntry = (entryToDelete) => {
      $scope.entries = $scope.entries.filter((existingEntry) => existingEntry !== entryToDelete);
      $scope.multiEmailInputForm.$setDirty();
    };

    const addEntry = (entry) => {
      if ($scope.entries.includes(entry)) {
        $scope.existingEntryErrorMessage = $i18next.t('customDirectives.multiEmailInput.existingEntryErrorMessage');
        return;
      };

      $scope.entries = [...$scope.entries, entry];
      resetInputField();
    };

    const editEntry = (newEntry, oldEntryIndex) => {
      if ($scope.entries.includes(newEntry)) {
        $scope.existingEntryErrorMessage = $i18next.t('customDirectives.multiEmailInput.existingEntryErrorMessage');
        return;
      };

      $scope.entries[oldEntryIndex] = newEntry;
      emailInputField.style.display = 'none';
      resetInputField();
    };

    $scope.handleKeyupEvent = (event) => {
      if (!$scope.inputValue) {
        return;
      };

      if (event.code !== 'Enter') {
        $scope.existingEntryErrorMessage = '';
        $scope.preSelectDropdownItem = true;
        return;
      };

      if ($scope.indexOfEntryBeingEdited == null) {
        addEntry(event.target.value);
      }

      if ($scope.indexOfEntryBeingEdited != null) {
        editEntry(event.target.value, $scope.indexOfEntryBeingEdited)
      }

      if ($scope.entries.length >= $scope.limit) {
        emailInputField.style.display = 'none';
      }
    };

    $scope.setDetailsOfEntryToEdit = (entry, index) => {
      $scope.inputValue = entry;
      $scope.indexOfEntryBeingEdited = index;
      $scope.showAndFocusInputField();
    }

    $scope.showAndFocusInputField = () => {
      if ($scope.indexOfEntryBeingEdited == null && ($scope.entries.length >= $scope.limit)) {
        $scope.maximumEntryErrorMessage = $i18next.t('customDirectives.multiEmailInput.maximumEntryErrorMessage', { limit: $scope.limit });
        return;
      };

      emailInputField.style.display = 'block';
      emailInputField.focus();
      $scope.maximumEntryErrorMessage = '';
    };

    const resetInputField = () => {
      $scope.inputValue = '';

      if ($scope.multiEmailInputForm.emailInputField) {
        $scope.multiEmailInputForm.emailInputField.$setPristine();
      }

      $scope.indexOfEntryBeingEdited = null;
    };

    $scope.handleDropdownListItemClick = (event) => {
      event.stopPropagation();
      addEntry($scope.inputValue);
      resetInputField();
    };


    $scope.handleClickOutside = () => {
      if (!$scope.multiEmailInputForm || (!$scope.entries.length && !$scope.inputValue)) {
        return;
      };

      if ($scope.inputValue) {
        addEntry($scope.inputValue)
      };

      resetInputField();
      $scope.existingEntryErrorMessage = '';
      $scope.maximumEntryErrorMessage = '';
      emailInputField.style.display = 'none';
    };

    $scope.$watch('ngModel', (value) => {
      if (!value.length) {
        emailInputField.style.display = 'block';
      };

      $scope.entries = value;
    });

    $scope.$watchGroup(['entries', 'ngRequired'], ([entries, isRequired]) => {
      $scope.ngModel = entries;
      $scope.shouldHighlightInitialEmail = $scope.highlightInitialEmail && $scope.entries.length > 1;

      if (isRequired && !entries.length) {
        $scope.multiEmailInputForm.$setValidity('multiEmailInputForm', false);
        return;
      };

      $scope.multiEmailInputForm.$setValidity('multiEmailInputForm', true);
    });
  },
})
]);
