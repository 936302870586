AccessModule
  .factory('PasswordValidator', [function () {
    var module = {
      hasLowerCase: function (password) {
        return /[a-z]/.test(password);
      },
      hasUpperCase: function (password) {
        return /[A-Z]/.test(password);
      },
      hasSpecialCharacter: function (password) {
        return /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/.test(password);
      },
      hasNumber: function (password) {
        return /[0-9]/.test(password);
      },
      hasValidLength: function (password) {
        return /.{8,}$/.test(password);
      },
      isValid: function (password) {
        return /^(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}$/.test(password);
      },
      validate: function (password) {
        return {
          hasLowerCase: module.hasLowerCase(password),
          hasUpperCase: module.hasUpperCase(password),
          hasSpecialCharacter: module.hasSpecialCharacter(password),
          hasNumber: module.hasNumber(password),
          hasValidLength: module.hasValidLength(password),
          isValid: module.isValid(password),
        };
      }
    };

    return module;
  }]);