angular.module('app.support', ['ui.router'])

  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('support', {
      templateUrl: 'components/layout/base.html',
      redirectTo: 'support.home',
      pageTrack: '/support',
      url: '/support',
      data: {
        authenticable: true,
      },
    }).state('support.home', {
      title: 'Support',
      templateUrl: 'modules/support/index.html',
      controller: 'SupportCtrl',
      onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('support-home-root'),
    });
  }])

  .controller('SupportCtrl', ['$scope', ($scope) => {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage({
          message: 'RENDER_SUPPORT_HOME_PAGE_MICROFRONTEND',
        },'*');
      }
    });
  }]);