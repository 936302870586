angular.module('app.invoices', ['ui.router', 'autocomplete'])

  .config(['$stateProvider',
    function($stateProvider) {
      $stateProvider.state('invoices', {
        abstract: true,
        url: '/invoices',
        title: 'Invoices',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'invoices.list',
        data: {
          permission: 'paymentrequest-view',
          authenticable: true
        }
      }).state('invoices.list', {
        url: '?page&search&customer&status&id&from&to&type',
        pageTrack: '/invoices',
        title: 'Invoices',
        templateUrl: 'modules/invoices/list.html',
        controller: 'PaymentRequestsCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('invoice-list-root'),
        resolve: {
          requests: ['API', '$stateParams', function($API, $stateParams) {
            var params = angular.extend($stateParams, {
              has_invoice: $stateParams.type === 'has_invoice' ? true : $stateParams.type === 'no_invoice' ? 'false' : null
            });
            return $API.one('paymentrequest').get(params);
          }],
        }
      }).state('invoices.one', {
        url: '/:id',
        pageTrack: '/invoices',
        title: 'Invoices',
        templateUrl: 'modules/invoices/one.html',
        controller: 'PaymentRequestCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('invoice-detail-root'),
      }).state('invoices.edit', {
        url: '/edit/:id',
        pageTrack: '/invoices',
        title: 'Invoices',
        templateUrl: 'modules/invoices/edit.html',
        controller: 'EditRequestCtrl',
        permission: 'paymentrequest-*',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('invoice-edit-root'),
      });
    }
  ])

  .controller('PaymentRequestsCtrl', ['$scope', 'requests', 'DEFAULTS', function($scope, requests, DEFAULTS) {
    $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
            {
              message: 'RENDER_INVOICE_LIST_MICROFRONTEND',
            },
            '*',
        );
      }
    });

    $scope.meta = requests.meta;
  }])

  .controller('PaymentRequestCtrl', ['$scope', function($scope) {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
            {
              message: 'RENDER_INVOICE_DETAIL_MICROFRONTEND',
            },
            '*',
        );
      }
    });
  }])

  .controller('EditRequestCtrl', ['$scope', function($scope) {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
            {
              message: 'RENDER_INVOICE_EDIT_MICROFRONTEND',
            },
            '*',
        );
      }
    });
  }])
