const PlansModule = angular.module('app.plans', ['ui.router']);

PlansModule.config([
  '$stateProvider', function ($stateProvider) {
    $stateProvider
      .state('plans', {
        abstract: true,
        url: '/plans',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'plans.list',
        title: 'Plans',
        data: {
          permission: 'plan-view',
          authenticable: true,
        }
      })
      .state('plans.list', {
        url: '?search&status&interval&from&to&query&subscriptions&amount&revenue&page&index',
        pageTrack: '/plans',
        title: 'Plans',
        templateUrl: 'modules/plans/views/list.html',
        controller: 'PlansListCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('plans-list-root'),
      })
      .state('plans.one', {
        url: '/:id',
        pageTrack: '/plans',
        title: 'Plans',
        templateUrl: 'modules/plans/views/one/one.html',
        controller: 'PlanCtrl',
        reloadOnSearch: false,
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('plan-detail-root'),
      })
      .state('plans.one.subscriptions', {
        url: '/subscriptions',
        pageTrack: '/plans',
      })
      .state('plans.one.subscribers', {
        url: '/subscribers',
        pageTrack: '/plans',
      })
      .state('plans.one.pages', {
        url: '/pages',
        pageTrack: '/plans',
      });
  }
]);