angular.module('app.connect', ['ui.router', 'autocomplete']).config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('connect', {
        abstract: true,
        url: '/connect',
        title: 'Connect',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'connect.account-list',
        data: {
          authenticable: true
        }
      })
      .state('connect.account-list', {
        title: 'Connected Accounts',
        controller: 'ConnectAccountListCtrl',
        templateUrl: 'modules/connect/views/accounts.html',
        url: '/accounts',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('connect-accounts-root'),
      })
      .state('connect.refunds', {
        title: 'Connected Refunds',
        controller: 'ConnectRefundsCtrl',
        templateUrl: 'modules/connect/views/refunds.html',
        url: '/refunds',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('connect-refunds-root'),
      })
      .state('connect.transactions', {
        title: 'Connect Transactions',
        controller: 'ConnectTransactionsCtrl',
        templateUrl: 'modules/connect/views/transactions.html',
        url: '/transactions',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('connect-transactions-root'),
      })
      .state('connect.transactions.list', {
        url: '?page&channel&currency&status&from&to&connect_account',
        pageTrack: '/transactions',
        templateUrl: 'modules/connect/views/transactions.html',
        controller: 'ConnectTransactionsCtrl',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('connect-transactions-root'),
      })
      .state('connect.payouts', {
        title: 'Connect Payouts',
        controller: 'ConnectPayoutsCtrl',
        templateUrl: 'modules/connect/views/payouts.html',
        url: '/payouts',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('connect-payouts-root'),
      })
      .state('connect.disputes', {
        title: 'Connect Disputes',
        controller: 'ConnectDisputesCtrl',
        templateUrl: 'modules/connect/views/disputes.html',
        url: '/disputes',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('connect-disputes-root'),
      })
      .state('connect.customize', {
        title: 'Customize',
        controller: 'ConnectCustomizeCtrl',
        templateUrl: 'modules/connect/views/customize.html',
        url: '/customize',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('connect-customize-root'),
      })
      .state('connect.account-detail', {
        title: 'Account Detail',
        controller: 'ConnectAccountDetailCtrl',
        templateUrl: 'modules/connect/views/account-detail.html',
        url: '/accounts/:id',
      })
      .state('connect.account-detail.transactions', {
        title: 'Transactions',
        controller: 'ConnectAccountDetailCtrl',
        templateUrl: 'modules/connect/views/account-detail.html',
        url: '/transactions',
      })
      .state('connect.account-detail.payouts', {
        title: 'Payouts',
        controller: 'ConnectAccountDetailCtrl',
        templateUrl: 'modules/connect/views/account-detail.html',
        url: '/payouts',
      })
      .state('connect.account-detail.disputes', {
        title: 'Disputes',
        controller: 'ConnectAccountDetailCtrl',
        templateUrl: 'modules/connect/views/account-detail.html',
        url: '/disputes',
      })
      .state('connect.account-detail.scopes', {
        title: 'Scopes',
        controller: 'ConnectAccountDetailCtrl',
        templateUrl: 'modules/connect/views/account-detail.html',
        url: '/scopes',
      });
  },
]);
