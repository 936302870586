angular
  .module('app.compliance')
  .factory('ComplianceProfile', [
    'API',
    'Session',
    ($API, Session) => {
      const businessProfileData = {};
      const getIntegrationId = () => {
        const integration = Session.get('account');
        return integration && integration.id;
      };

      return {
        fetchBusinessProfile: (country) => {
          if (businessProfileData[country]) {
            return Promise.resolve(businessProfileData[country]);
          }

          return $API.one(`integration/${getIntegrationId()}/business_profile_data`).get({ country })
            .then((response) => {
              businessProfileData[country] = response.data;
              return businessProfileData[country];
            });
        },
        saveBusinessProfile: updatePayload => $API.one(`integration/${getIntegrationId()}/business_profile`)
          .patch(updatePayload),
        fetchSavedBusinessProfile: () => $API.one(`integration/${getIntegrationId()}/business_profile`).get()
          .then(response => response.data),
      };
    },
  ]);
