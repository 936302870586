ProductModule.factory('ProductUtils', [
  'LocalService',
  function(LocalService) {
    function checkColor(c) {
      var c = c.substring(1);      // strip #
      const rgb = parseInt(c, 16);   // convert rrggbb to decimal
      const r = (rgb >> 16) & 0xff;  // extract red
      const g = (rgb >>  8) & 0xff;  // extract green
      const b = (rgb >>  0) & 0xff;  // extract blue
      const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

      if (luma < 150) {
        return '#ffffff';
      } else {
        return '#57584E';
      }
    }

    function isAnyFileUploadedBefore() {
      const firstFileUploaded = LocalService.get('firstFileUploaded');
      return firstFileUploaded;
    }

    function capitalize(s) {
      if (typeof s !== 'string') {
        return '';
      }

      return s.charAt(0).toUpperCase() + s.slice(1);
    }

    function getMediaType(type) {
      return type.includes('image') ? 'image' : 'video';
    }

    function isFilesChanged(firstProduct, secondProduct) {
      const first = angular.copy(firstProduct.files);
      const second = angular.copy(secondProduct.files);

      return JSON.stringify(first) !== JSON.stringify(second);
    }

    function formatTagDate(date, format = 'MMMM D, YYYY') {
      return moment(date).format(format);
    }

    function getSubaccountByProduct(subaccounts, product) {
      return subaccounts.find(subaccount => subaccount.subaccount_code === product.split_code);
    }

    function bytesToSize(size) {
      const supportedSizes = ['B', 'KB', 'MB', 'GB', 'TB'];
      const sizeTypeIndex = Math.floor(Math.log(size) / Math.log(1024));
      const formattedDisplayAmount = (size / 1024 ** sizeTypeIndex).toFixed(2) * 1;
      const displaySize = supportedSizes[sizeTypeIndex];
      return `${formattedDisplayAmount} ${displaySize}`;
    }

    return {
      checkColor,
      isAnyFileUploadedBefore,
      capitalize,
      getMediaType,
      isFilesChanged,
      formatTagDate,
      getSubaccountByProduct,
      bytesToSize,
    };
  }]);
