UIDirectives
  .directive('disableWithoutPermission', ['$filter', '$parse', 'Session', function ($filter, $parse, Session) {
    return {
      restrict: 'A',
      compile: function (tElem, attrs) {
        return function (scope, element, attrs) {
          var hasPermission = Session.hasPermission(attrs['disableWithoutPermission']);
          if (!hasPermission) {
            var tagName = element[0].tagName;

            if (_.contains(['A', 'BUTTON'], tagName)) {
              element.addClass('btn-not-permitted');
              element.find('i').remove();
              element.prepend('<i class="fa fa-lock m-r-xs"></i>');
              element.prop('disabled', true);
              element.attr('title', 'Your current role (' + Session.get('user').role.full_name + ') does not have the permissions for this action.');
            }

            if (_.contains(['A'], tagName)) {
              element.removeAttr('href');
              element.removeAttr('ui-sref');
            }

            if (_.contains(['DIV', 'FORM'], tagName)) {
              element.find('input').prop('disabled', true);
              element.find('input').attr('placeholder', '-');
              element.find('select').prop('disabled', true);
            }
          }
        };
      }

    };
  }]);