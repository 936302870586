const OrderModule = angular.module('app.orders', ['ui.router']);

OrderModule.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('orders', {
        url: '/orders',
        templateUrl: 'components/layout/base.html',
        abstract: true,
        redirectTo: 'orders.list',
        data: {
          permission: 'product-view',
          authenticable: true,
        },
        onEnter: function () {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'hidden';
        },
        onExit: function () {
          document.body.style.overflow = null;
          window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('orders-root');
        },
      })
      .state('orders.list', {
        url: '?email&region&from&to&status&delivered&refunded&delivered_at&current&page&index&products&search&is_viewed',
        pageTrack: '/orders',
        reloadOnSearch: false,
        templateUrl: 'modules/orders/views/orders.html',
        controller: 'OrdersCtrl',
      })
      .state('orders.list.one', {
        url: '/:id',
        pageTrack: '/orders',
        reloadOnSearch: false,
        redirectTo: 'orders.list.one.overview',
        templateUrl: 'modules/orders/views/orders.html',
        controller: 'OrdersCtrl',
      })
      .state('orders.list.one.overview', {
        url: '/overview',
        pageTrack: '/orders',
        reloadOnSearch: false,
        templateUrl: 'modules/orders/views/orders.html',
        controller: 'OrdersCtrl',
      })
      .state('orders.list.one.shipping', {
        url: '/shipping',
        pageTrack: '/orders',
        reloadOnSearch: false,
        templateUrl: 'modules/orders/views/orders.html',
        controller: 'OrdersCtrl',
      });
  },
]);
