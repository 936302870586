angular.module('app.compliance').directive('complianceAddress', [
  'AnalyticsService',
  'Addresses',
  'Session',
  '$i18next',
  'utils',
  '$rootScope',
  'DEFAULTS',
  function (AnalyticsService, Addresses, Session, $i18next, utils, $rootScope, DEFAULTS) {
    return {
      restrict: 'E',
      scope: {
        address: '=',
        customFields: '=',
        country: '=?',
        required: '@',
        isBusiness: '=?',
      },
      templateUrl: '/modules/compliance/views/address.html',
      link: $scope => {
        const integration = Session.get('account');
        const { logEvent } = AnalyticsService;
        $scope.logEvent = logEvent;
        $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

        $scope.address = $scope.address || {};
        $scope.isGhanaSelectAddress = false;


        const integrationCountry =
          integration &&
          integration.attributes &&
          integration.attributes.country_code;

        $scope.country =
          $scope.country ||
          ($scope.address && $scope.address.country) ||
          integrationCountry;

        $scope.address.countryName = $scope.address.country;
        $scope.isFetchingStates = false;
        $scope.postalCodeRules = null;
        $scope.gpsAddressRegex = utils.getGpsAddressRegex();

        const getCountryCode = (countries, countryName) => {
          const currentAddressCountry = countries.find(
            country => country.name === countryName,
          );
          return currentAddressCountry && currentAddressCountry.iso2;
        };

        const getAvailableCountries = countries => {
          const restrictedCountries =
            ($scope.customFields &&
              $scope.customFields.restricted_countries &&
              $scope.customFields.restricted_countries[integrationCountry]) ||
            [];

          if (restrictedCountries.length === 0) {
            return countries;
          }

          const currentCountryCode = getCountryCode(
            countries,
            $scope.address.country,
          );

          if (
            currentCountryCode &&
            !restrictedCountries.includes(currentCountryCode)
          ) {
            restrictedCountries.push(currentCountryCode);
          }

          return countries.filter(country =>
            restrictedCountries.includes(country.iso2),
          );
        };
        $scope.stateLabel =
          $scope.country === 'EG'
            ? $i18next.t('address.governorate')
            : $i18next.t('address.state');

        Addresses.fetchCountries()
          .then(countries => {
            $scope.countries = getAvailableCountries(countries);

            if ($scope.countries.length === 1) {
              const countryData = $scope.countries[0];
              $scope.address.country = countryData.iso2;
              $scope.address.countryName = countryData.name;
            }

            if ($scope.address.country) {
              fetchStates($scope.address.country);
            }
            $scope.$broadcast('fetchedCountries');
          })
          .catch(() => {
            $scope.countries = [];

            $scope.$broadcast('fetchedCountries', false);
          });

        const fetchStates = country => {
          $scope.isFetchingStates = true;
          $scope.states = [];
          $scope.isGhanaSelectAddress =
            country === 'GH' && integrationCountry === 'GH';
          $rootScope.$emit('showDocumentSection', true);
          if ($scope.isGhanaSelectAddress) {
            $rootScope.$emit('showDocumentSection', false);
          }

          Addresses.fetchStates(country)
            .then(states => {
              $scope.states = states;
            })
            .catch(() => {
              $scope.states = [];
            })
            .finally(() => {
              $scope.isFetchingStates = false;
            });
        };

        const loadPostalCodeRules = countryCode => {
          if (!$scope.customFields) return;

          const postalCode = $scope.customFields.postal_code;

          $scope.postalCodeRules = null;

          // If there are no rules defined for the country, we hide the field completely
          if (!postalCode || !postalCode[countryCode]) return;

          $scope.postalCodeRules = postalCode[countryCode];
        };

        $scope.setAddressState = value => {
          $scope.address.state = value;
        };

        $scope.proofTypeOptions = [
          { value: 'doc', label: 'Document' },
          { value: 'gps', label: 'GPS Address' },
        ];

        $scope.selectProof = $scope.proofTypeOptions[1];

        $scope.setSelectProof = option => {
          $rootScope.$emit('showDocumentSection', false);
          $scope.selectProof = option;
          if (option.value === 'doc') {
            $rootScope.$emit('showDocumentSection', true);
          }
        };

        loadPostalCodeRules($scope.address.country);

        $scope.$watch(
          'address',
          (oldAddress, newAddress) => {
            if (oldAddress.country != newAddress.country) {
              fetchStates($scope.address.country);
              loadPostalCodeRules($scope.address.country);
            }
          },
          true,
        );
      },
    };
  },
]);
