angular.module('app.bankService', [])
  .service('BankService', ['API', 'Session', function($API, Session) {
    var banks, banksPromise;

    function getCode(bank_name_or_code) {
      if (!bank_name_or_code) return null;
      var bank = _.find(banks, function (bank) {
        return _.contains([bank.name, bank.code, bank.id], bank_name_or_code);
      });
      return bank.code;
    }

    return {
      init: function(params) {
        if (!params.currency) {
          params.currency = Session.get('account').default_currency;
        }

        if (['GBP'].includes(params.currency)) {
          params.currency = 'NGN';
        }

        if(!params.transferRecipient) {
          params.transferRecipient = false;
        }

        banksPromise = $API.one('bank').get(params).then(function(response){
          banks = response.data;
          return banks;
        });
        return banksPromise;
      },
      getBanks: function() {
        return banksPromise;
      },
      getBank: function(bank_code_or_slug) {
        return _.find(banks, function (bank) {
          return [bank.slug, bank.code].indexOf(bank_code_or_slug) > -1;
        });
      },
      validate: function(account_number, bank) {
        if (!banksPromise) Promise.reject('Bank Service not initialized');

        return banksPromise.then(function() {
          if (!account_number) return Promise.reject('No Account Number');
          if (!bank) return Promise.reject('No Bank Provided');
          if (!getCode(bank)) return Promise.reject('Invalid Bank');

          return Promise.resolve();
        });
      },
      resolve: function(account_number, bank, currency) {
        return $API.one('bank', 'resolve').get({
          bank_code: getCode(bank),
          account_number: account_number,
          currency: currency
        }).then(function(response) {
          return response.data;
        }).catch(function(){
          return 'unconfirmed';
        });
      },
      resolveBvn: function(bvn) {
        return $API.one('dashboard/resolve_bvn', bvn).get()
          .then(function(response) {
            return response.data;
          }).catch(function(){
            return 'unconfirmed';
          });
      },
      getBankBranches: function(bankId) {
        return $API.one('bank/branches').get({bank: bankId}).then(({data}) => data)
      }
    };
  }]);