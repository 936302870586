angular.module('app.historyService', [])
  .run(['$rootScope', '$location', '$state', '$stateParams', 'DEFAULTS', 'toaster',
    function($rootScope, $location, $state, $stateParams, DEFAULTS, toaster) {
      $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
        // Save previous state
        $rootScope.history = $rootScope.history || [];
        $rootScope.lastState = $rootScope.fromState;

        if (toState != fromState && fromState.name) {
          if ($rootScope.usedBackButton) {
            $rootScope.usedBackButton = false;
          } else {
            var recentHistory = _.last($rootScope.history);
            if (!recentHistory || (recentHistory.state != fromState)) {
              $rootScope.history.push({
                state: fromState,
                params: fromParams
              });
            }
            if ($rootScope.history.length > 10) $rootScope.history.shift();
          }
        }

        String.prototype.capitalize = function() {
          return this.charAt(0).toUpperCase() + this.slice(1);
        };
      });

      // Back function
      $rootScope.previousState = function(root) {
        $rootScope.usedBackButton = true;
        var history = angular.copy($rootScope.history);
        var previousState, previousStateParams;

        if (history.length) {
          var lastEntry = _.last(history);
          previousState = lastEntry.state.name;
          previousStateParams = lastEntry.params;
          $rootScope.history = _.without(history, lastEntry);
        } else if (root) {
          previousState = root;
        } else {
          var currentStateParts = $state.current.name.split('.');
          previousState = _.first(currentStateParts, 2).join('.');
        }

        $state.go(previousState, previousStateParams);
      };
    }
  ]);
