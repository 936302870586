FilesModule.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('files.view', {
        url: '?location',
        title: 'Display File',
        templateUrl: 'modules/files/display-file/views/index.html',
        controller: 'FileDisplayCtrl',
      });
  },
]);
