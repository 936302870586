UIDirectives.directive('export', ['API', 'Notification', 'AnalyticsService', function ($API, Notification, AnalyticsService) {
  return {
    restrict: 'A',
    scope: {
      model: '=exportModel',
      api: '=exportApi',
      from: '=exportFrom',
      to: '=exportTo',
      status: '=exportStatus',
      page: '=exportPage',
      payment_page: '=exportPaymentPage',
      invoice: '=exportInvoice',
      settlement: '=exportSettlement',
      subaccount_settlement: '=exportSubaccountSettlement',
      amount: '=exportAmount',
      recipient: '=exportRecipient',
      error: '=exportError',
      exportSuccessCallback: '=exportSuccessCallback',
      subaccount: '=exportSubaccount',
      currency: '=exportCurrency',
      channel: '=exportChannel',
      customer: '=exportCustomer',
      timeout: '=exportTimeout',
      type: '=exportType',
      category: '=exportCategory',
      limit: '=exportLimit',
      limit_type: '=exportLimitType',
      exportScope: '=exportScope',
    },
    link: function (scope, element, attrs, controller) {
      const { logEvent } = AnalyticsService;
      scope.logEvent = logEvent;

      var model = scope.model || 'transaction';
      var store = scope.api ? $API.one(scope.api) : $API.one(model, 'export');
      if (scope.timeout) store = store.withHttpConfig({
        timeout: scope.timeout
      });

      var parameters = {
          from: scope.from,
          to: scope.to,
          settlement: scope.settlement,
          subaccount_settlement: scope.subaccount_settlement,
          status: scope.status,
          payment_page: scope.payment_page,
          payment_request: scope.invoice,
          amount: scope.amount,
          recipient: scope.recipient,
          error: scope.error,
          subaccount: scope.subaccount,
          currency: scope.currency,
          channel: scope.channel,
          customer: scope.customer,
          page: scope.page,
          type: scope.type,
          model: scope.model,
          category: scope.category,
          limit: scope.limit,
          limit_type: scope.limit_type,
        }

        if (scope.exportScope === 'transaction statement') {
          parameters.use_ledger = true;
        }

        // Filter out empty values
        parameters = _.pick(parameters, _.identity);

      element.on('click', () => {
        logEvent('Data Export started', { model });
        element.prop('disabled', true).addClass('disabled');
        store.get(parameters).then((response) => {
          var link = document.createElement('a');
          var successCallback = scope.exportSuccessCallback;
          link.download = name;
          link.href = response.data.path;
          link.click();
          element.prop('disabled', false).removeClass('disabled');
          logEvent('Data Export completed', { model });

          if (successCallback && typeof successCallback === 'function') {
            successCallback();
          }
        }).catch((error) => {
          /**
           * Don't change it to !error.status.
           * Restangular will return a status of 0 if it's due to a timeout which is different from when the API returns a false status.
           */
          const isTimeoutError = error && error.status === 0;

          logEvent('Data Export failed', {
            model,
            errorStatus: error && error.status,
            errorMessage: isTimeoutError ? 'Export timed out' : error && error.data && error.data.message
          });

          if (model === 'transaction' && isTimeoutError) {
            Notification.error('Export timed out', 'Your download is taking longer than usual - please use the "Send CSV to email" option');
          } else {
            Notification.error('Error exporting data', error);
          }

          element.prop('disabled', false).removeClass('disabled');
        });
      });
    }
  };
}]);