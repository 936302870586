UIDirectives.directive('customFields', [function () {
  return {
    restrict: 'E',
    scope: {
      customFields: '='
    },
    template: `
      <div class="wrapper b-a r pos-rlt custom-fields m-b-sm" ng-repeat="field in customFields">
        <div class="close-button" style="top: -10px; right: -6px" ng-click="removeField($index)" ng-if="customFields.length > 1">
            <i class="fa fa-times-circle fa-2x"></i>
        </div>
        <span class="field m-b-xs">
          <div class="single-select select-xs" style="width: 60%">
            <select ng-model="field.type" class="form-control text-xs select">
              <option value="" disabled>Field type</option>
              <option value="text">Text field</option>
              <option value="dropdown">Dropdown</option>
              <option value="checkbox">Checkboxes</option>
            </select>
          </div>
          <input type="text" class="form-control input" ng-model="field.display_name" placeholder="Field name">
        </span>
        <p class="m-b-sm info" ng-if="field.type === 'checkbox'">Your customers will be able to choose <b>one or more</b> of the options you add below</p>
        <p class="m-b-sm info" ng-if="field.type === 'dropdown'">Your customers will be able to choose any <b>one</b> of the options you add below</p>
        <div class="input-group m-b-xs" style="height: 28px" ng-repeat="option in field.options">
          <span class="input-group-addon option-id">Option {{option.id}}</span>
          <input id="slug" type="text" class="form-control" style="cursor: default; background: white; height: 29px" ng-model="field.options[$index].value">
          <a class="input-group-addon clickable" ng-click="removeOption($parent.$index, $index)"><i class="fa fa-times"></i></a>
        </div>
        <div class="m-b-md" ng-if="['checkbox', 'dropdown'].includes(field.type)">
          <a class="text-primary text-xs text-muted" ng-click="addOption($index)"><i class="fa fa-plus m-r-xs"></i> {{field.options.length >= 1 ? 'Add another option': 'Add an option'}}</a>
        </div>
        <label class="select-label">
          <input type="checkbox" ng-model="field.required"> <span class="text-xs">This field is required</span>
        </label>
      </div>
      <div class="clear text-center text-xs">
        <a class="text-primary text-sm" ng-click="addField()"><i class="fa fa-plus m-r-xs"></i> Add Another Field</a>
      </div>      `,
    link: function ($scope, element, attrs, controller) {
      $scope.addField = () => {
        $scope.customFields.push({
          display_name: '',
          options: [],
          required: false,
        });
      };

      $scope.removeField = (index) => {
        if ($scope.customFields.length > 1) {
          $scope.customFields.splice(index, 1);
        }
      };

      $scope.addOption = (index) => {
        const options = $scope.customFields[index].options;
        options.push({
          id: options.length + 1,
        });
      };

      $scope.removeOption = (fieldIndex, optionIndex) => {
        $scope.customFields[fieldIndex].options.splice(optionIndex, 1);
      };

      $scope.onTypeChange = (type, index) => {
        const typesWithOptions = ['checkbox', 'dropdown'];
        $scope.customFields[index].options = [];

        if (typesWithOptions.includes(type)) {
          $scope.customFields[index].options.push({
            id: 1,
            value: ''
          });
        }
      };
    }
  };
}]);