UIDirectives
  .directive('showPassword', [function() {
    return {
      restrict: 'A',
      scope: {
        showPassword: '='
      },
      link: function($scope, element) {
        $scope.$watch('showPassword', () => {
          if ($scope.showPassword) {
            element.attr('type', 'password');
          } else {
            element.attr('type', 'text');
          }
        });
      }
    };
  }]);