angular.module('app.compliance').directive('complianceMenu', [
  'AnalyticsService',
  'ComplianceModulesRouteMappings',
  (AnalyticsService, ComplianceModulesRouteMappings) => ({
    restrict: 'E',
    scope: {
      modules: '=',
      currentModule: '=',
    },
    templateUrl: '/modules/compliance/views/menu.html',
    link: $scope => {
      const { logEvent } = AnalyticsService;
      $scope.logEvent = logEvent;
      $scope.activeModules = $scope.modules.map(item => {
        const moduleCode = item.code;
        const isActive =
          $scope.currentModule === 'PROFILE' ? moduleCode === 'PROFILE' : true;
        const link = `app.compliance.${ComplianceModulesRouteMappings[moduleCode].route}`;
        return { ...item, link, isActive };
      });
    },
  }),
]);
