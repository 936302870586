UIDirectives
  .directive('phoneInput', ['Session', 'CountryService', function (Session, CountryService) {
    return {
      restrict: 'E',
      scope: {
        ngModel: '=',
        required: '@?'
      },
      template: `
      <div class="flex">
        <div class="flex" style="width: 100px; margin-right: 10px;">
          <phone-code-dropdown calling-code="ngModel.calling_code" />
        </div>
        <div class="flex flex-grow--1" style="width: 100%;">
          <input name="phone" type="tel" ng-model="number" ng-pattern="pattern" placeholder="{{placeholder}}"
            class="form-control input-v2 number-input" ng-required="required">
        </div>
      </div>
    `,
      link: ($scope) => {
        const integration = Session.get('account');
        $scope.number = $scope.ngModel && $scope.ngModel.number;

        CountryService.getCountries().then(() => {
          $scope.$watch('ngModel.calling_code', (value) => {
            if (value) {
              const country = CountryService.getCountryByCallingCode(value);
              const countryCode = country.countryCode || (integration && integration.attributes && integration.attributes.country_code);
              const countryData = CountryService.getCountryData(countryCode);
              $scope.placeholder = (countryData && countryData.placeholder) || '8056020617';
              $scope.pattern = countryCode === 'CI' ? /^[0-9]{8,11}$/ : /^[0-9]{9,11}$/;
            }
          });
        });



        $scope.$watch('number', (value) => {
          if (value) {
            if (value.startsWith(0)) {
              $scope.ngModel.number = value.replace(/^0+/, '');
            } else {
              $scope.ngModel.number = value;
            }
          }
        });
      },
    };
  },
  ]);
