UIDirectives.directive('validateSlug', ['API', '$timeout', function ($API, $timeout) {
  return {
    restrict: 'A',
    require: ['^form', 'ngModel'],
    scope: {
      skip: '='
    },
    link: function (scope, element, attrs, ctrls) {
      var request;
      var ngForm = ctrls[0];
      var ngModel = ctrls[1];
      var valueToSkip = scope.skip;

      var shouldProcess = function (value) {
        var otherRulesInValid = false;
        for (var p in ngModel.$error) {
          if (ngModel.$error[p] && p != 'validateSlug') {
            otherRulesInValid = true;
            break;
          }
        }
        return !(ngModel.$pristine || otherRulesInValid || value == valueToSkip);
      };

      var setValidation = function (response) {
        var i = 0,
          l = response.length,
          isValid = true;

        for (; i < l; i++) {
          if (!response[i].data.isValid) {
            isValid = false;
          }
        }

        ngModel.$setValidity('validateSlug', isValid);
        ngModel.$processing = ngModel.$pending = ngForm.$pending = false;
      };

      var handleChange = function (value) {
        if (typeof value === 'undefined' || value === '') {
          if (request) {
            $timeout.cancel(request);
          }
          ngModel.$setPristine();
          ngModel.$setValidity('validateSlug', true);
          return;
        }

        if (!shouldProcess(value)) {
          return setValidation([{
            data: {
              isValid: true,
              value: value
            }
          }]);
        }

        if (!ngModel.$pending) {
          ngModel.$processing = ngModel.$pending = ngForm.$pending = true;
        }

        if (request) {
          $timeout.cancel(request);
        }

        request = $timeout(function () {
          var isPage = attrs.validateSlug == 'page/check_slug_availability';
          if (isPage && value.substring(0, 4) === 'PRQ_') {
            setValidation([{
              data: {
                isValid: false,
                value: value
              }
            }]);
            return;
          }

          $API.one(attrs.validateSlug, value).get().then(function (response) {
            setValidation([{
              data: {
                isValid: true,
                value: value
              }
            }]);
          }, function (error) {
            setValidation([{
              data: {
                isValid: false,
                value: value
              }
            }]);
          });
        }, 0);
      };

      scope.$watch(function () {
        return ngModel.$viewValue;
      }, handleChange);
    }
  };
}]);