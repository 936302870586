angular.module('app').service('MicroFrontendService', [
  'uiLoad',
  uiLoad => ({
    load: () => {
      const host = `${settings.dashboardNextURL}/`;
      const cacheBust = new Date().getTime();

      // Set global variable so chunks know where to be fetched
      window.chunkURL = host;

      return fetch(`${host}static/manifest.json?v=${cacheBust}`)
        .then(response => response.json())
        .then(data =>
          uiLoad.load(data.entrypoints.map(path => `${host}${path}`)),
        )
        .catch(error => Promise.reject(error));
    },
  }),
]);
