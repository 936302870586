angular.module('app.compliance').controller('ComplianceContactController', [
  '$scope',
  '$rootScope',
  'ComplianceContact',
  'ComplianceProfile',
  'Notification',
  '$state',
  'AnalyticsService',
  'AddressService',
  '$location',
  'Onboarding3Service',
  'utils',
  '$i18next',
  (
    $scope,
    $rootScope,
    ComplianceContact,
    ComplianceProfile,
    Notification,
    $state,
    AnalyticsService,
    AddressService,
    $location,
    Onboarding3Service,
    utils,
    $i18next,
  ) => {
    Onboarding3Service.redirectToOnboarding3IfEnabled();
    $scope.contactStatus = $scope.complianceStatusData.modules.find(module =>
      ['CONTACT-STARTER', 'CONTACT'].includes(module.code),
    );

    $scope.agreementStatus = $scope.complianceStatusData.modules.find(module =>
      ['SERVICE_AGREEMENT'].includes(module.code),
    );

    $scope.useEditMode =
      ($state.params && $state.params.edit) || !$scope.contactStatus.completed;
    const { logEvent } = AnalyticsService;
    const { stringifyAddress, formatAddress } = AddressService;

    $scope.formatAddress = formatAddress;
    $scope.emailRegexPattern = utils.getEmailRegexPattern();
    const alphaNumericWithHyphensRegex =
      utils.getAlphaNumericWithHyphensRegex();

    Promise.all([
      ComplianceContact.fetchContact(),
      ComplianceProfile.fetchSavedBusinessProfile(),
    ])
      .then(([contactData, businessProfileData]) => {
        $scope.contactData = contactData;

        $scope.contactData.email.disputesEmails = contactData.email
          .chargeback_emails.length
          ? contactData.email.chargeback_emails
          : contactData.email.disputes
          ? [contactData.email.disputes]
          : [];

        $scope.disputesEmailsText =
          $scope.contactData.email.disputesEmails.join(', ');

        $scope.disputesEmailsLimit = 5;

        $scope.contactData.supportEmailIsSameAsGeneral =
          contactData.email.support === contactData.email.general;
        const disputesEmailsContainsOnlyGeneralEmail =
          contactData.email.chargeback_emails.length === 1 &&
          contactData.email.chargeback_emails[0] === contactData.email.general;
        $scope.showContactEmailsAsBusinessEmail =
          $scope.contactData.supportEmailIsSameAsGeneral &&
          disputesEmailsContainsOnlyGeneralEmail;
        $scope.businessProfileData = businessProfileData;
        $scope.country = businessProfileData && businessProfileData.country;
        $scope.isStarterBusiness =
          businessProfileData && businessProfileData.businessType === 'starter';
        $scope.contactData.useRegisteredAddress =
          stringifyAddress(contactData.address.registered) ===
          stringifyAddress(contactData.address.main);
        $scope.socialNetworks = Object.keys(contactData.social);
        logEvent('page_load', {
          pageName: $state.current.name,
          state: 'succeeded',
        });
        $scope.$broadcast('contactPageReady');
      })
      .catch(error => {
        logEvent('page_load', {
          pageName: $state.current.name,
          state: 'failed',
        });
        Notification.error('Could not fetch contact data', error);
        $scope.$broadcast('contactPageReady');
      });

    const controlAddOrRemoveGeneralEmailCheckbox = (
      disputesEmails,
      generalEmail,
    ) => {
      $scope.disputesEmailsContainsGeneral =
        disputesEmails.includes(generalEmail);
      $scope.disableAddOrRemoveGeneralEmailCheckbox =
        disputesEmails.length >= $scope.disputesEmailsLimit &&
        !$scope.disputesEmailsContainsGeneral;
    };

    $scope.$watch(
      'contactData.email.disputesEmails',
      disputesEmails => {
        if (!disputesEmails) return;
        controlAddOrRemoveGeneralEmailCheckbox(
          disputesEmails,
          $scope.contactData.email.general,
        );
      },
      true,
    );

    $scope.$watch('contactData.email.general', generalEmail => {
      if (!generalEmail) {
        $scope.disputesEmailsContainsGeneral = false;
        $scope.disableAddOrRemoveGeneralEmailCheckbox = true;
        return;
      }
      controlAddOrRemoveGeneralEmailCheckbox(
        $scope.contactData.email.disputesEmails,
        generalEmail,
      );
    });

    function validateInputValue(value) {
      if (value && !alphaNumericWithHyphensRegex.test(value)) {
        return $i18next.t('complianceContact.validation.invalidCharacterError');
      }
      return '';
    }

    $scope.validateWebsiteInput = function (event) {
      const { value } = event.target;
      $scope.websiteInputError = validateInputValue(value);
    };

    $scope.AddOrRemoveGeneralEmail = () => {
      const generalEmail = $scope.contactData.email.general;
      const disputesEmails = $scope.contactData.email.disputesEmails;

      if ($scope.disputesEmailsContainsGeneral) {
        $scope.contactData.email.disputesEmails = disputesEmails.filter(
          disputeEmail => disputeEmail !== generalEmail,
        );
      } else {
        $scope.contactData.email.disputesEmails = [
          ...disputesEmails,
          generalEmail,
        ];
      }
      $scope.disputesEmailsContainsGeneral =
        !$scope.disputesEmailsContainsGeneral;
    };

    $scope.saveContact = () => {
      logEvent('Attempted to save compliance module', {
        module: $scope.contactStatus.code,
      });
      const {
        supportEmailIsSameAsGeneral,
        useRegisteredAddress,
        phone,
        ...formData
      } = $scope.contactData;

      const payload = {
        ...formData,
        email: {
          general: formData.email.general,
          support: supportEmailIsSameAsGeneral
            ? formData.email.general
            : formData.email.support,
          disputes:
            formData.email.chargeback_emails[0] || formData.email.disputes,
          chargeback_emails: formData.email.disputesEmails,
        },
        phone: {
          callingCode: phone.calling_code,
          number: phone.number,
        },
        address: {
          ...formData.address,
          ...(!$scope.isStarterBusiness &&
            useRegisteredAddress && { main: formData.address.registered }),
        },
        ...(formData.website && {
          website: $scope.formatWebsite(formData.website),
        }),
      };

      ComplianceContact.saveContactData(payload)
        .then(() => {
          $rootScope.$broadcast('savedContact');
          logEvent('Successfully saved compliance module', {
            module: $scope.contactStatus.code,
          });
          $scope.reloadWithParams({ edit: null });
        })
        .catch(error => {
          $rootScope.$broadcast('savedContact', false);
          const message = error && error.message;
          let toasterBodyOutputType;

          const meta = error.data.meta;
          let errorMessages;
          if (meta && meta.errors && meta.errors.length) {
            errorMessages = meta.errors
              .map(
                errorMessage =>
                  `<li>${errorMessage.error.replace(/_/g, ' ')}</li>`,
              )
              .join('');
            errorMessages = `<ul class="padder-left-md">${errorMessages}</ul>`;
            toasterBodyOutputType = 'trustedHtml';
          }
          const errorBody = errorMessages || error;

          logEvent('Error saving compliance module', {
            module: $scope.contactStatus.code,
            message,
          });
          Notification.error(
            'Could not save contact data',
            errorBody,
            5000,
            toasterBodyOutputType,
          );
        });
    };

    $scope.urlPattern =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,24}(:[0-9]{1,5})?(\/.*)?$/;

    $scope.formatWebsite = url => {
      if (!url) return '';
      if (['http://', 'https://'].some(prefix => url.includes(prefix))) {
        return url;
      }
      return `http://${url}`;
    };

    $scope.switchToEditMode = () => {
      $scope.reloadWithParams({ edit: 'true' });
    };

    $scope.$watch('contactStatus.completed', value => {
      if (!value) {
        $location.search('edit', 'true');
      }
    });
  },
]);
