AccessModule.directive('emailSuggestion', [function () {
  return {
    restrict: 'A',
    require: ['ngModel'],
    link: function (scope, element, attrs, ctrls) {
      var ngModel = ctrls[0];
      var suggestionModel = attrs['emailSuggestion'];
      Mailcheck.defaultTopLevelDomains.push('com.ng', 'ng', 'io', 'me');
      Mailcheck.defaultDomains.push('stripe.com')

      var handleChange = function (value) {
        Mailcheck.run({
          email: value,
          secondLevelDomains: ['yahoo', 'hotmail', 'mail', 'live', 'outlook'],
          suggested: function (suggestion) {
            scope[suggestionModel] = suggestion.full;
          },
          empty: function () {
            scope[suggestionModel] = null;
          }
        });
      };

      scope.$watch(function () {
        return ngModel.$viewValue;
      }, handleChange);
    }
  };
}]);