angular.module('app.compliance').directive('complianceDocument', [
  'AnalyticsService',
  'RegistrationDocuments',
  'API',
  '$http',
  'Notification',
  '$state',
  '$rootScope',
  'FileService',
  function (
    AnalyticsService,
    RegistrationDocuments,
    $API,
    $http,
    Notification,
    $state,
    $rootScope,
    FileService,
  ) {
    return {
      restrict: 'E',
      scope: {
        requirement: '=',
        onUpload: '&',
        displayText: '='
      },
      templateUrl: '/modules/compliance/views/document.html',
      link: ($scope) => {
        const { logEvent } = AnalyticsService;
        $scope.logEvent = logEvent;
        $scope.reviewStatus = $scope.requirement.documents[0] && $scope.requirement.documents[0].status;
        $scope.reviewNote = $scope.requirement.documents[0] && $scope.requirement.documents[0].reviewNote;
        $scope.canEditModule = $state.params && $state.params.edit;
        $scope.isEditMode = true;
        $scope.showDocumentData = false;
        $rootScope.disableSaveButtonIfInputIsInvalid = false;

        $scope.toggleShowDocumentData = () => {
          $scope.showDocumentData = !$scope.showDocumentData;
        }

        $scope.$watch('requirement.documentData.value', function(newVal, oldVal) {
          if (newVal != oldVal) {
            $rootScope.disableSaveButtonIfInputIsInvalid = !$scope.documentFormInput.$valid;
          }
        });

        const getDocumentDetails = (documents) => {
          if (!documents || !documents.length || !documents[0].locations) {
            return [];
          }
          return FileService.formatDocumentLocationsForFileUploader(documents[0].locations);
        }

        const documentTextValue = $scope.requirement.documents && $scope.requirement.documents[0] && $scope.requirement.documents[0].value;
        $scope.requirement.documentData = { locations: getDocumentDetails($scope.requirement.documents), value: documentTextValue };

        $scope.toggleEditMode = () => {
          $scope.isEditMode = !$scope.isEditMode;
        }
      },
    };
  },
]);
