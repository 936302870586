const SubscriptionsModule = angular.module('app.subscriptions', ['ui.router']);

SubscriptionsModule.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('subscriptions', {
        abstract: true,
        url: '/subscriptions',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'subscriptions.list',
        reloadOnSearch: false,
        data: {
          permission: 'plan-view',
          authenticable: true,
        },
      })
      .state('subscriptions.list', {
        url: '?search&status&plan&amount&from&to&currency&customer&page&index&amountCondition&cardsExpiryType&cardExpiryStartDateMonth&cardExpiryStartDateYear&cardExpiryEndDateMonth&cardExpiryEndDateYear&cardsExpiryTo&cardsExpiryFrom',
        pageTrack: '/subscriptions',
        templateUrl: 'modules/subscriptions/views/list.html',
        controller: 'SubscriptionListCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('subscriptions-list-root'),
      })
      .state('subscriptions.list.one', {
        url: '/:id',
        pageTrack: '/subscriptions',
        templateUrl: 'modules/subscriptions/views/list.html',
        controller: 'SubscriptionListCtrl',
      });
  },
]);
