StorefrontsModule.controller('StorefrontListCtrl', [
  '$scope',
  function ($scope) {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage({
          message: 'RENDER_STOREFRONT_LIST',
        }, '*');
      }
    });
  },
]);
