var uploadModule = angular.module('app.uploadService', []);

uploadModule.service('validateService', ['$i18next', function($i18next) {
  return {
    process: function(files, preset) {
      var validation_results = _.map(files, function(file) {
        var result = {
          is_extension_valid: false,
          is_size_valid: true,
          error: null,
        };

        preset.formats.forEach(function(ext) {
          if (file.type.match(ext)) {
            result.is_extension_valid = true;
          } else {
            const detectFileExtension = /(?:\.([^.]+))?$/;
            const fileNameBasedExtension = `.${detectFileExtension.exec(file.name)[1]}`;
            if (fileNameBasedExtension.match(ext)) {
              result.is_extension_valid = true;
            } else {
              result.error = $i18next.t('digitalProducts.fileExtensionNotSupported');
            }
          }
        });

        if (preset.maxSize) {
          var filesizeInKb = (file.size / 1024).toFixed(2);
          if (filesizeInKb > preset.maxSize) {
            result.is_size_valid = false;
            result.error = 'File size is too large. Please choose a file less than ' + preset.maxSize + 'kb';
          }
        }

        result.is_valid = result.is_extension_valid && result.is_size_valid;
        return result;
      });

      if (files.length === 1) {
        return validation_results[0];
      } else {
        var all_files_valid = _.every(validation_results, 'is_valid');
        return {
          is_valid: all_files_valid,
          error: all_files_valid ? null : 'One of the files is not valid. Please review',
        };
      }
    }
  };
}]);

uploadModule.directive('upload', ['API', '$timeout', 'validateService', '$q', '$http', 'Session', 'FileService', 'AnalyticsService', function($API, $timeout, validateService, $q, $http, Session, FileService, AnalyticsService) {
  return {
    restrict: 'AE',
    require: 'ngModel',
    transclude: true,
    replace: true,
    templateUrl: 'components/directives/upload.html',
    scope: {
      preset: '=',
      multiple: '=',
      ngModel: '=',
      onUpload:'&',
      required: '@?',
      buttonText: '=',
      displayText: '=',
      displayErrorBorder: '='
    },
    link: (scope, el, attrs, ngModel) => {
      const { logEvent } = AnalyticsService;
      const getPreset = (presetName) => {
        const integration = Session.get('account');
        const presets = {
          logo: { bucketName: '/integration-logos', formats: ['image/*'], maxSize: 512 },
          image: { bucketName: 'pstk-integration-docs', formats: ['image/*'] },
          document: { bucketName: 'pstk-integration-docs', formats: ['image/*', 'application/pdf'] },
          csv: { bucketName: 'pstk-integration-docs', formats: ['.csv'] },
          paymentPageSEOImage: { bucketName: '/payment-page-url-previews', formats: ['image/*'] },
          compliance: { bucketName: `compliance-docs/${integration.id}`, formats: ['image/*', 'application/pdf'] },
        };
        return presets[presetName] || presets.document;
      }

      scope.uploader = {
        id: uuidv4(),
        preset: getPreset(scope.preset),
        processing: false,
        error: null,
        value: scope.ngModel,
        progress: 0,
        queue: [],
      };

      let cancelFileUpload = $q.defer();

      if (scope.multiple) {
        scope.uploader.value = scope.uploader.value || [];
      }

      scope.$on('reset', () => {
        scope.uploader.error = null;
        scope.uploader.file = null;
      });

      scope.$on('value', (event, files) => {
        const validation = validateService.process(files, scope.uploader.preset);
        if (!validation.is_valid) {
          scope.showError(validation.error);
          return;
        }
        scope.uploader.progress = 0;
        scope.uploader.processing = true;
        scope.normalizeProgressBarMovement();
        if (scope.multiple) {
          scope.uploadMultiple(files);
        } else {
          const file = files[0];
          scope.fileName = file.name;
          scope.uploadSingle(file);
        }
        scope.$apply();
      });

      scope.setProgressBar = (progress) => {
        scope.uploader.progress = Math.max(scope.uploader.progress, progress);
      };

      scope.normalizeProgressBarMovement = () => {
        let simulatedProgress = 0;
        const moveProgress = (delay) => {
          $timeout(() => {
            if (simulatedProgress > 95) return;
            const increment = Math.random() * 10;
            simulatedProgress += increment;
            simulatedProgress = Math.max(simulatedProgress, scope.uploader.progress);
            scope.setProgressBar(simulatedProgress);
            moveProgress(delay + 500);
          }, delay);
        };
        moveProgress(0);
      };

      scope.uploadMultiple = (files) => {
        const filesToUpload = files.length;
        scope.uploader.queue = [];

        const updateProgressBar = () => {
          const uploadsInQueue = scope.uploader.queue.length;
          const uploadsCompleted = filesToUpload - uploadsInQueue;
          const progress = uploadsCompleted / filesToUpload * 100;
          scope.setProgressBar(progress);
          if (!uploadsInQueue) {
            scope.uploader.processing = false;
          }
        };

        Array.from(files).forEach((file) => {
          logEvent('multiple-file-upload_upload-files', { uploadPreset: scope.preset, state: 'started' });
          scope.queueFile(file, (err, data) => {
            if (err) {
              logEvent('multiple-file-upload_upload-files', { uploadPreset: scope.preset, state: 'failed' });
              scope.uploader.error = 'Some files could not be uploaded';
            } else {
              logEvent('multiple-file-upload_upload-files', { uploadPreset: scope.preset, state: 'succeeded' });
              scope.uploader.value.push({
                name: FileService.extractFileNameFromDocumentLocation(data.key),
                path: data.path,
                uploaded: true
              });
              ngModel.$setViewValue(scope.uploader.value);
              scope.noFilesUploaded = false;
            }
            updateProgressBar();
          });
        });
      };

      scope.remove = (file) => {
        scope.uploader.value = scope.uploader.value.filter(item => item !== file);
        ngModel.$setViewValue(scope.uploader.value);
        scope.noFilesUploaded = !scope.uploader.value.length;
      };

      scope.$watch('ngModel', (value) => {
        scope.uploader.value = scope.multiple && !value ? [] : value;
      });

      scope.uploadSingle = (file) => {
        logEvent('single-file-upload_upload-file', { uploadPreset: scope.preset, state: 'started' });
        scope.queueFile(file, (err, data) => {
          scope.uploader.processing = false;
          if (err) {
            logEvent('single-file-upload_upload-file', { uploadPreset: scope.preset, state: 'failed' });
            scope.showError(err.message);
            scope.uploader.progress = 0;
          } else {
            logEvent('single-file-upload_upload-file', { uploadPreset: scope.preset, state: 'succeeded' });
            scope.uploader.progress = 100;
            scope.uploader.value = data.path;
            ngModel.$setViewValue(data.path);
            scope.onUpload && scope.onUpload();
          }
        });
        scope.normalizeProgressBarMovement();
      };

      scope.queueFile = (file, callback) => {
        scope.uploader.queue = [...scope.uploader.queue, file];

        const removeFileFromQueue = () => {
          scope.uploader.queue = scope.uploader.queue.filter(item => item !== file);
        };

        const uploadFileToSignedURL = (data) => {
          $http.put(data.signed_url, file, { headers: { 'Content-Type': file.type }})
            .then(() => {
              removeFileFromQueue();
              callback(null, data);
            })
            .catch((err) => {
              removeFileFromQueue();
              callback(err, null);
            });
        };

        $API.all('upload_url').post({ upload_path: scope.uploader.preset.bucketName, upload_filename: file.name })
          .then((response) => {
            uploadFileToSignedURL(response.data);
          })
          .catch((err) => {
            removeFileFromQueue();
            callback(err, null);
          });
      };

      scope.cancel = () => {
        cancelFileUpload.resolve();
        $timeout(() => {
          cancelFileUpload = $q.defer();
          scope.uploader.progress = 0;
          scope.uploader.processing = false;
          scope.uploader.queue = [];
        }, 0);
      };

      scope.showError = (error) => {
        $timeout(() => {
          scope.uploader.error = error;
        }, 100);
      };

      scope.getfileName = () => {
        if (!scope.uploader.value) return null;
        if (scope.fileName) {
          return scope.fileName;
        }
        const lastUrlSegment = _.last(scope.uploader.value.split('/'));
        return decodeURI(lastUrlSegment);
      };
    }
  };
}]);

uploadModule.directive('fileUploader', ['$rootScope', '$timeout', '$q', function ($rootScope, $timeout, $q) {
  return {
    restrict: 'E',
    replace: true,
    require: 'ngModel',
    scope: {
      id: '=',
      preset: '=',
      multiple: '=',
      ngModel: '=',
      formField: '=',
      onUpload: '&',
      onDelete: '&'
    },
    template: `
      <div class="c-uploader c-uploader--document">
        <div class="c-uploader__preview">
          <div class="c-uploader__document" ng-show="file.path" ng-repeat="file in uploader.value">
            <div class="c-uploader__document-icon">
                <i class="icon-doc"></i>
            </div>
            <div class="c-uploader__document-name text-ellipsis">{{file.userFileName || file.fileName || file.path}}</div>
            <div class="c-uploader__document-link">
                <a ng-href="{{file.url}}" rel="noreferrer" target="_blank">View</a>
            </div>
            <a data-testid="file-uploader-remove-button" ng-click="removeFile(file)" class="text-danger pull-right m-l" ng-if="multiple">
              <i class="fa fa-minus-circle"></i>
            </a>
          </div>
        </div>
        <div class="c-uploader__body">
          <div class="c-uploader__trigger">
              <input required ng-if="multiple" multiple="true" class="c-uploader__input" id="{{uploader.id}}" accept="{{accept}}" type="file" placeholder="+ Change Files" file-pick />
              <input required ng-if="!multiple" class="c-uploader__input" id="{{uploader.id}}" accept="{{accept}}" type="file" placeholder="+ Choose File" file-pick />
              <label ng-if="multiple" for="{{uploader.id}}" class="c-uploader__label">{{ uploader.value.length === 0 ? '+ Choose Files' : '+ Add Files' }}</label>
              <label ng-if="!multiple" for="{{uploader.id}}" class="c-uploader__label">+ Choose File</label>
              <div class="c-uploader__progress" ng-if="uploader.processing">
                  <div class="c-uploader__progress-level" ng-style="{ 'width': uploader.progress + '%' }"></div>
                  <a class="c-uploader__cancel" ng-click="cancel()" ng-if="uploader.progress < 100">Cancel</a>
              </div>
          </div>
          <div class="c-uploader__message">
              <span class="text-muted" ng-if="uploader.processing">
                  Upload Progress: {{ uploader.progress | number:0 }}%
              </span>
              <span class="text-danger" ng-if="uploader.error">
                  <i class="fa fa-warning m-r-xs"></i> {{ uploader.error }}
              </span>
          </div>
        </div>
      </div>
    `,
    link: ($scope, element, attrs, controller) => {
      const presets = {
        document: 'image/*, application/pdf',
      };

      $scope.uploader = {
        id: $scope.id || uuidv4(),
        value: $scope.ngModel,
        progress: 0,
        error: null,
        processing: false
      };

      $scope.accept = presets[$scope.preset];

      let deferredFileUpload = $q.defer();

      const someFileExceedMaxSize = (files, maximumSize = 5242880) => [...files].some(file => file.size > maximumSize);

      $scope.$on('value', (event, files) => {
        if (someFileExceedMaxSize(files)) {
          $scope.uploader.error = $scope.multiple ? 'One or more files exceeds the maximum size limit of 5 MB' : 'File size exceeds 5 MB';
        } else {
          $rootScope.$emit('fileUploadQueue', files);
          $rootScope.$emit('deferFileUpload', deferredFileUpload);
          $scope.uploader.processing = true;
          $scope.normalizeProgressBarMovement();
          $scope.onUpload && $scope.onUpload();
        }
      });

      $scope.cancel = () => {
        deferredFileUpload.resolve();
        $timeout(() => {
          deferredFileUpload = $q.defer();
          $scope.uploader.progress = 0;
          $scope.uploader.processing = false;
        }, 0);
      };

      $scope.$on(`fileUploaded${$scope.id}`, (event, files) => {
        if (!files || files.length === 0) {
          $scope.uploader.error = $scope.multiple ? 'Some files could not be uploaded' : 'File could not be uploaded';
        } else {
          $scope.uploader.value = files;
        }
        controller.$setViewValue($scope.uploader.value);
        $scope.uploader.processing = false;
      });

      $scope.$on('fileDeleted', (event, deletedFile) => {
        $scope.uploader.value = $scope.uploader.value.filter(file => file !== deletedFile);
      });

      let moveProgress;

      $scope.normalizeProgressBarMovement = () => {
        let simulatedProgress = 0;
        $timeout.cancel(moveProgress);
        moveProgress = (delay) => {
          $timeout(() => {
            if (simulatedProgress > 95) return;
            const increment = Math.random() * 10;
            simulatedProgress += increment;
            simulatedProgress = Math.max(simulatedProgress, $scope.uploader.progress);
            $scope.uploader.progress = simulatedProgress;
            moveProgress(delay + 500);
          }, delay);
        };
        moveProgress(0);
      };

      $scope.removeFile = (file) => {
        $scope.onDelete && $scope.onDelete({ file });
      };

      $scope.$on('reset', () => {
        $scope.uploader.value = [];
        $scope.uploader.progress = 0;
        $scope.uploader.error = null;
      });

      controller.$setValidity('fileUploader', false);

      $scope.$watch('ngModel', () => {
        if ($scope.ngModel && Array.isArray($scope.ngModel) && $scope.ngModel.length > 0) {
          $scope.uploader.value = $scope.ngModel;
          controller.$setViewValue($scope.uploader.value);
          controller.$setValidity('fileUploader', true);
        } else {
          controller.$setValidity('fileUploader', false);
          $scope.uploader.value = [];
        }
      });
    },
  };
},
]);

uploadModule.directive('filePick', function() {
  return (scope, element) => {
    element.bind('click', () => {
      element.val(null);
      scope.$emit('reset');
    });
    element.bind('change', (evt) => {
      scope.$emit('value', evt.target.files);
    });
  };
});
