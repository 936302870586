ProductModule
  .controller('ProductListNextCtrl', [
    '$state',
    '$scope',
    function (
      $state,
      $scope,
    ) {
      $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_PRODUCT_LIST',
          }, '*');
        }
      });
    },
  ]);
