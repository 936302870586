/**
  In HTML, table rows cannot be links, but we need them to work like links and support
  opening a new tab with [CMD + Click]. To achieve this, this directive includes a link
  in every table cell with class "is-overlay" which positions it to cover the cell.
*/

UIDirectives.directive('tableLink', () => ({
  restrict: 'A',
  scope: true,
  compile: (tElem, attrs) => {
    const { tableLink } = attrs;
    if (tableLink) {
      const cells = Array.from(tElem.find('td'));
      cells.forEach((cell) => {
        const element = angular.element(cell);
        if (element.hasClass('link-disabled')) return;
        const content = element.html();
        element.html(`<a class="table-link" ui-sref="${tableLink}"></a>${content}`);
      });
      tElem.addClass('is-clickable');
    }
    tElem.removeAttr('table-link');
  },
}));