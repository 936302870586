angular.module('app.audit-logs')
  .controller('AuditLogsCtrl', ['$scope',
    function ($scope) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage(
              {
                message: 'RENDER_AUDIT_LOG_MICROFRONTEND',
              },
              '*',
          );
        }
      });
    }
  ]);