OrderModule.controller('OrdersCtrl', [
  '$scope',
  function ($scope) {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
          {
            message: 'RENDER_ORDERS_MICROFRONTEND',
          },
          '*',
        );
      }
    });
  },
]);
