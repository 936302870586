angular
  .module('app.refunds', ['ui.router'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('refunds', {
          abstract: true,
          title: 'Refunds',
          url: '/refunds',
          templateUrl: '/components/layout/base.html',
          redirectTo: 'refunds.list',
          data: {
            authenticable: true,
          },
        })
        .state('refunds.list', {
          title: 'Refunds',
          url: '?search&status&amount&from&to',
          pageTrack: '/refunds',
          templateUrl: '/modules/refunds/list.html',
          controller: 'RefundsCtrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('refunds-root'),
        })
        .state('refunds.one', {
          url: '/:id',
          title: 'Refunds',
          templateUrl: 'modules/refunds/one.html',
          controller: 'RefundsCtrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('refunds-root'),
        });
    },
  ])
  .controller('RefundsCtrl', [
    '$scope',
    function ($scope) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_REFUNDS_MICROFRONTEND',
          }, '*');
        }
      });
    },
  ]);
