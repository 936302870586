UIDirectives.directive('checkFeatureFlag', [
  'Session',
  function (Session) {
    return {
      restrict: 'A',
      compile: function (tElem, attrs) {
        return function (scope, element, attrs) {
          const features = Session.get('features') || {};
          const featureFlag = attrs['checkFeatureFlag'];
          const featureEnabled = features[featureFlag];
          if (!featureEnabled) {
            element.remove();
          }
        };
      },
    };
  },
]);
