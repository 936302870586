angular.module('app.compliance').directive('complianceProgress', [
  'AnalyticsService',
  'BusinessActivation',
  '$rootScope',
  '$window',
  'Notification',
  'Session',
  '$state',
  function (
    AnalyticsService,
    BusinessActivation,
    $rootScope,
    $window,
    Notification,
    Session,
    $state,
  ) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        status: '=',
      },
      templateUrl: '/modules/compliance/views/progress.html',
      link: $scope => {
        const { logEvent } = AnalyticsService;
        const integration = Session.get('account') || {};
        const { attributes, is_live } = integration;
        const { modules = [], completed } = $scope.status;
        $scope.inEditMode = $state.params && $state.params.edit;
        $scope.totalNumberOfModules = modules.length;
        $scope.completedModules = modules.reduce((completedModules, module) => {
          if (module.completed) {
            return completedModules + 1;
          }
          return completedModules;
        }, 0);
        const percentageCompleted =
          ($scope.completedModules / $scope.totalNumberOfModules) * 100;
        $scope.isComplete = completed;
        $scope.isLive = is_live;
        $scope.activateBusiness = () => {
          const is_duplicated = !!attributes.duplicated_from_integration;
          logEvent('Attempted to activate business', { is_duplicated });
          BusinessActivation.activate()
            .then(() => {
              $rootScope.$broadcast('activatedBusiness');
              logEvent('Business successfully activated', { is_duplicated });
              Notification.success('All done!', 'Refreshing your Dashboard...');
              $window.location.reload();
            })
            .catch(error => {
              $rootScope.$broadcast('activatedBusiness', false);
              const message = error && error.message;
              logEvent('Error activating business', { message, is_duplicated });
              Notification.error('Could not activate business', error);
            });
        };
        $scope.isSubmitted =
          attributes && attributes.compliance_documents_submitted;
        $scope.showComplianceBadge = $scope.isSubmitted && !$scope.inEditMode;
        $scope.progressWidthStyle = {
          width: `${percentageCompleted}%`,
        };
      },
    };
  },
]);
