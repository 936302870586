const ConsentModule = angular.module('app.consent', ['ui.router']);

ConsentModule.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider.state('app.consent', {
      url: '/consent?type&referrer',
      templateUrl: 'modules/consent/views/index.html',
      controller: 'ConsentCtrl',
      title: 'Consent',
      data: {
        authenticable: true,
      },
      onExit: () =>
        window.cleanupMicroFrontend &&
        window.cleanupMicroFrontend('consent-root'),
    });
  },
]).controller('ConsentCtrl', [
  '$scope',
  function ($scope) {
    $scope.$watch('mfeServiceReady', mfeServiceReady => {
      if (mfeServiceReady) {
        window.postMessage(
          {
            message: 'RENDER_CONSENT_MICROFRONTEND',
          },
          '*',
        );
      }
    });
  },
]);
