angular.module('app.compliance').factory('ServiceAgreementApi', [
  'API',
  'Session',
  ($API, Session) => {
    const getIntegrationId = () => {
      const integration = Session.get('account');
      return integration && integration.id;
    };

    return {
      signServiceAgreement: payload =>
        $API
          .all('merchant-agreements/msa')
          .post({ ...payload, integrationId: getIntegrationId() }),
      fetchServiceAgreement: () =>
        $API
          .one(`merchant-agreements/${getIntegrationId()}`)
          .get()
          .then(response => response.data),
    };
  },
]);
