UIDirectives
  .directive('phoneNumberInput', ['CountryService', 'utils', function(CountryService, utils) {
    return {
      restrict: 'E',
      scope: {
        phoneNumber: '=',
        callingCode: '=?',
        formField: '=?',
        onInputEnter: '&?',
        required: '@?',
        defaultCountry: '=?',
      },
      template: `
    <div class="phone-number-input" uib-dropdown auto-close="outsideClick" is-open="isDropdownOpen">
      <div role="menu" uib-dropdown-menu class="dropdown-menu-left phone-number-input-menu no-arrow">
        <div class="padder b-b grey-background padder-v-sm">
          <li data-testid="phone-number-input-list-{{country.countryCode}}" class="list-unstyled b-b" ng-style="{'border-bottom': $last ? '0px': ''}" ng-repeat="country in countriesWithMetadata">
            <a ng-click="setActiveCountry(country)" class="flex">
              <span class="flex-item--fill">
                <img ng-src="assets/img/flags/{{country.countryCode.toLowerCase()}}.svg" class="m-r-sm icon" />
                <span> {{country.name}} </span>
              </span>
              <span class="text-muted"> {{country.callingCodes[0]}} </span>
            </a>
          </li>
        </div>
        <div class="padder padder-v-sm">
          <div class="search-input input-group m-b-sm">
            <span class="input-group-addon no-border no-bg" style="padding-right: 0">
              <i class="icon-magnifier text-xs m-t-xxs text-muted"></i>
            </span>
            <input class="form-control no-border" ng-model="searchCountries" type="text" placeholder="Search your country" />
          </div>
          <div class="searched-item">
            <li class="list-unstyled b-b" ng-style="{'border-bottom': $last ? '0px': ''}" ng-repeat="country in countriesWithoutMetadata | filter: searchCountries">
              <a ng-click="setActiveCountry(country)" class="flex">
                <span class="flex-item--fill flex">
                  <span class="icon-container m-r-sm">
                    <img ng-src="assets/img/flags/{{country.countryCode.toLowerCase()}}.svg" class="m-r-sm icon" />
                  </span>
                  <span> {{country.name}} </span>
                </span>
                <span class="text-muted"> {{country.callingCodes[0]}} </span>
              </a>
            </li>
          </div>
        </div>
      </div>
      <span class="field m-b-xs">
        <div class="country-dropdown b">
          <div data-testid="phone-number-input-toggle" class="flex flex-align--center" uib-dropdown-toggle aria-haspopup="true" aria-expanded="true">
            <img ng-show="activeCountry" data-testid="phone-number-input-toggle-flag" class="icon" ng-src="assets/img/flags/{{activeCountry.countryCode.toLowerCase()}}.svg" alt="{{activeCountry.name}} flag">
            <span ng-show="activeCountry">{{activeCountry.callingCodes[0]}}</span>
            <span class="caret"></span>
          </div>
        </div>
        <input name="phone" id="phone-number-input" data-testid="phone-number-input-input" type="tel" ng-enter="onInputEnter()" ng-required="required" ng-click="is-open" class="form-control input no-default-borders" ng-model="subscriberNumber" placeholder={{activeCountry.metadata.placeholder}} ng-pattern="activeCountry.metadata.phoneNumberPattern">
      </span>
    </div>
    `,
      link: function ($scope, element, attrs, controller) {
        $scope.isDropdownOpen = false;

        $scope.setActiveCountry = (country) => {
          $scope.activeCountry = country;
          $scope.isDropdownOpen = false;
          $scope.callingCode = country.callingCodes[0];
        };

        if (utils.isObject($scope.phoneNumber)) {
          $scope.subscriberNumber = $scope.phoneNumber.subscriber_number;
          $scope.callingCode = $scope.phoneNumber.calling_code;
        } else {
          $scope.subscriberNumber = $scope.phoneNumber;
        }

        $scope.phoneNumber = {};

        CountryService.getCountries().then((countries) => {
          $scope.countriesWithMetadata = [];
          $scope.countriesWithoutMetadata = [];

          countries.forEach((country) => {
            if (country.metadata) {
              $scope.countriesWithMetadata = [...$scope.countriesWithMetadata, country];
            } else {
              $scope.countriesWithoutMetadata = [...$scope.countriesWithoutMetadata, country];
            }
          });

          if ($scope.defaultCountry) {
            $scope.setActiveCountry(CountryService.getCountryByName($scope.defaultCountry));
          }

          $scope.$watch('subscriberNumber', (subscriberNumber) => {

            if (!subscriberNumber) return;

            if (String(subscriberNumber).startsWith('+')) {
              $scope.callingCode = CountryService.getCountryByPhoneNumber(subscriberNumber).callingCodes[0];
              $scope.subscriberNumber = subscriberNumber.replace($scope.callingCode, '');
            } else {
              $scope.phoneNumber.subscriber_number = subscriberNumber;
            }
          });

          $scope.$watch('callingCode', (callingCode) => {
            $scope.phoneNumber.calling_code = callingCode;
            $scope.setActiveCountry(
              CountryService.getCountryByCallingCode(callingCode) || CountryService.getCountryByCallingCode('+234')
            );
          });

          $scope.$watch('phoneNumber', (phoneNumber) => {
            $scope.formField && $scope.formField.$setValidity('phoneInputDropdown', false);

            if (!phoneNumber) return;

            const { subscriber_number } = phoneNumber;

            const subscriberNumberIsValid = subscriber_number && !Number.isNaN(Number(subscriber_number));

            if (subscriberNumberIsValid) {
              $scope.formField && $scope.formField.$setValidity('phoneInputDropdown', true);
            }
          }, true);
        });
      }
    };
  }]);