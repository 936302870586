angular.module('app.localService', [])
  .factory('LocalService', () => ({
    set: (key, val) => localStorage.setItem(key, JSON.stringify(val)),
    get: (key, options = { parse: true }) => {
      if (options.parse) {
        try {
          return JSON.parse(localStorage.getItem(key));
        } catch (e) {
          return null;
        }
      } else {
        return localStorage.getItem(key);
      }
    },
    unset: key => localStorage.removeItem(key),
  }));
