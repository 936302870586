UIDirectives.directive('dropdownList', [function () {
  return {
    restrict: 'E',
    scope: {
      listData: '=',
      listTextKey: '@',
      listSubTextKey: '@',
      onClickOutside: '&',
      onClickListItem: '=onClickListItem'
    },
    template: `
    <div class="dropdown-list">
      <ul click-outside="onClickOutside()">
        <li class="flex" data-testid="dropdown-list-{{listItem.id}}" ng-repeat="listItem in listData" ng-click="onClickListItem(listItem)">
            <div class="flex-item--fill">
              <p class="text-bold m-b-none"> {{ listItem[listTextKey] }} </p>
              <p class="text-muted-new m-b-none">{{ listItem[listSubTextKey] }}</p>
            </div>
            <div style="width: 25px;" class="flex flex-justify--center flex-align--center">
              <p class="text-muted-new text-md m-b-none">
                <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 1v10M1 6h10" stroke="#C1C1C1" stroke-linecap="round" stroke-linejoin="round"></path></svg>
              </p>
            </div>
        </li>
      </ul>
    </div>
    `
  };
}]);