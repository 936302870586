AccessModule.directive('downloadApp', ['AnalyticsService', 'DeviceService', '$stateParams', (AnalyticsService, DeviceService, $stateParams) => ({
  restrict: 'E',
  scope: {
    copyTitle: '@',
    copyAbout: '@',
    linkPrimary: '@',
    linkPrimaryText: '@',
    linkSecondary: '@',
    linkSecondaryText: '@',
    buttonDismiss: '@',
  },
  template: `
    <div class="download-app" ng-if="platform && showModal">
      <div ng-if="!closed" class="download-app-overlay animated fadeIn" ng-click="dismiss()" ng-class="{'fadeOut': closing}"></div>
      <div class="download-app-content animated fadeInUp" ng-class="{'slideOutDownFull': closing}">
          <div class="download-app-icon">
              <img src="assets/img/flag-icon.gif" alt="Animated flag" />
          </div>
          <p class="font-bold">{{copyTitle}}</p>
          <p>{{copyAbout}}</p>
          <div class="download-app-cta">
              <a target="_blank" href="{{linkPrimary}}" rel="noopenner noreferrer" class="button button--primary button--wide" ng-if="linkPrimary" ng-click="logEvent('Clicked Download app')">
                  {{linkPrimaryText}}&nbsp;<device />
              </a>
              <a target="_blank" href="{{linkSecondary}}" rel="noopenner noreferrer" class="button button--wide" ng-if="linkSecondary" ng-click="logEvent('Clicked Learn more about app')">
                  {{linkSecondaryText}}
              </a>
              <button class="button button--wide" ng-click="dismiss()" ng-if="buttonDismiss" ng-click="logEvent('Clicked Dismiss app')">
                  {{buttonDismiss}}
              </button>
          </div>
        </div>
      </div>
    </div>
  `,
  link: ($scope) => {
    const { platform } = DeviceService;
    $scope.platform = platform;
    const { logEvent } = AnalyticsService;
    $scope.logEvent = logEvent;
    const disabledRoutes = ['app.settings.compliance'];
    $scope.showModal = !disabledRoutes.includes($stateParams.next);
    $scope.dismiss = () => {
      $scope.closing = true;
      setTimeout(() => {
        $scope.closed = true;
      }, 1000);
    };
  },
})]);
