UIDirectives.directive('supportWidget', () => ({
    restrict: 'A',
    template: `
      <div id="support-widget-root"></div>
    `,
    link: ($scope) => {
        $scope.$watch('mfeServiceReady', mfeServiceReady => {
            if (mfeServiceReady) {
                window.postMessage({
                    message: 'RENDER_SUPPORT_WIDGET_MICROFRONTEND',
                });
            }
        });
    },
}));