angular
  .module('app.compliance')
  .factory('ComplianceContact', [
    'API',
    'Session',
    ($API, Session) => {
      const getIntegrationId = () => {
        const integration = Session.get('account');
        return integration && integration.id;
      };
      return {
        fetchContact: () => $API.one(`integration/${getIntegrationId()}/contact`).get()
          .then(response => response.data),
        saveContactData: updatePayload => $API.one(`integration/${getIntegrationId()}/contact`)
          .patch(updatePayload),
      };
    },
  ]);
