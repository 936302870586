angular.module('app.subaccounts', [])

  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('subaccounts', {
        abstract: true,
        url: '/subaccounts',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'subaccounts.list',
        reloadOnSearch: false,
        data: {
          permission: 'subaccount-view',
          authenticable: true
        }
      })

      .state('subaccounts.list', {
        url: '?page&search&sort_by',
        reloadOnSearch: false,
        pageTrack: '/settings/subaccounts',
        title: 'Subaccounts',
        templateUrl: '/modules/subaccounts/list-next.html',
        controller: 'SubaccountListNext',
        onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('subaccount-list-root'),
      })
    }
  ])

  .controller('SubaccountListNext', ['$scope', function ($scope) {
    $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
      if (mfeServiceReady) {
        window.postMessage({
          message: 'RENDER_SUBACCOUNT_LIST',
        }, '*');
      }
    });
  }]);
