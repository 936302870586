var FilesModule = angular.module('app.file-view', ['ui.router']);

FilesModule.config(['$stateProvider',
  function ($stateProvider) {
    $stateProvider.state('files', {
      abstract: true,
      url: '/files',
      templateUrl: 'components/layout/base.html',
      data: {
        authenticable: true,
      },
    });
  }
]);