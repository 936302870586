angular.module('app.localeService', [])
  .service('LocaleService', ['API', 'Session', function($API, Session) {

    var module = {
      fetch: function() {
        var integration_id = Session.get('account').id;

        return $API.one('integration/locale/' + integration_id).get().then(function(response){
          return response.data.locale;
        });
      },
    };

    return module;
  }]);