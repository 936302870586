UIDirectives.directive('status', function () {
  return {
    restrict: 'A',
    link: function ($scope, element, attrs) {
      const status = attrs['status']
        ? attrs['status'].toLowerCase()
        : 'Unknown';

      const successStates = [
        true,
        'verified',
        'paid',
        'active',
        'success',
        'subscribed',
        'approved',
        'complete',
        'resolved',
        'processed',
      ];
      const failureStates = [
        false,
        'failed',
        'unpaid',
        'inactive',
        'unverified',
        'unsubscribed',
        'expired',
        'archived',
        'overdue',
        'reversed',
        'cancelled',
      ];
      const warningStates = ['reversal-pending', 'processing', 'pending_update', 'pending_cancellation'];

      if (successStates.includes(status)) {
        element.addClass('text-success');
      } else if (failureStates.includes(status)) {
        element.addClass('text-danger');
      } else if (warningStates.includes(status)) {
        element.addClass('text-warning-v2');
      } else {
        element.addClass('text-muted');
      }
    },
  };
});
