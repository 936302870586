angular.module('app.compliance').controller('ComplianceOwnerController', [
  '$scope',
  '$location',
  '$rootScope',
  'ComplianceOwner',
  'Notification',
  '$state',
  'AnalyticsService',
  'CountryService',
  'AddressService',
  'ComplianceContact',
  'ComplianceProfile',
  'FileService',
  'Onboarding3Service',
  (
    $scope,
    $location,
    $rootScope,
    ComplianceOwner,
    Notification,
    $state,
    AnalyticsService,
    CountryService,
    AddressService,
    ComplianceContact,
    ComplianceProfile,
    FileService,
    Onboarding3Service,
  ) => {
    Onboarding3Service.redirectToOnboarding3IfEnabled();
    const { logEvent } = AnalyticsService;    
    
    $scope.ownerStatus = $scope.complianceStatusData.modules.find(module => ['OWNER'].includes(module.code));
    const { formatAddress } = AddressService;

    $scope.formatAddress = formatAddress;
    $scope.useEditMode = ($state.params && $state.params.edit) || !$scope.ownerStatus.completed;

    const formatOwner = (owner) => {
      const businessOwner = { ...owner };

      if (!businessOwner.addressDoc || !businessOwner.addressDoc.locations) {
        businessOwner.addressDoc = {
          locations: [],
        };
      }

      if (!businessOwner.identityDoc || !businessOwner.identityDoc.locations) {
        businessOwner.identityDoc = {
          locations: [],
        };
      }
      
      businessOwner.addressDoc.locations = FileService.formatDocumentLocationsForFileUploader(businessOwner.addressDoc.locations);
      businessOwner.identityDoc.locations = FileService.formatDocumentLocationsForFileUploader(businessOwner.identityDoc.locations);

      return businessOwner;
    }

    const country = $rootScope.Business && $rootScope.Business.attributes && $rootScope.Business.attributes.country && $rootScope.Business.attributes.country.iso_code;

    Promise.all([
      ComplianceOwner.fetchOwner(),
      ComplianceContact.fetchContact(),
      ComplianceProfile.fetchSavedBusinessProfile(),
      ComplianceProfile.fetchBusinessProfile(country),
    ])
      .then(([owner, contactData, businessProfileData, businessProfile]) => {
        $scope.ownerData = owner ? formatOwner(owner) : {};
        $scope.contactData = contactData || {};
        $scope.ownerData.useBusinessAddress = true;
        $scope.businessProfileData = businessProfileData;
        $scope.country = businessProfileData.fieldNames && businessProfileData.fieldNames.country;
        $scope.requiresProofOfAddress = businessProfile && businessProfile.meta && businessProfile.meta.requiresPoa;
        if (country !== 'GH') {
          $scope.showProofOfAddress = true;
        }
        $rootScope.$on('showDocumentSection', (event, value) => {
          if (country === 'GH') {
            $scope.showProofOfAddress = value;
          }
        });
        logEvent('page_load', { pageName: $state.current.name, state: 'succeeded' });
        $scope.$broadcast('ownerPageReady');
      })
      .catch((error) => {
        logEvent('page_load', { pageName: $state.current.name, state: 'failed' });
        Notification.error('Could not fetch owner information', error);
      });

    CountryService.getCountries().then((response) => {
      $scope.countries = response;
    });

    $scope.$watch('ownerData.country', (country) => {
      if (country) {
        ComplianceOwner.fetchIdentificationDocuments(country).then((documentTypes) => {
          $scope.documentTypes = documentTypes && documentTypes.all;
          $scope.addressDocumentType = documentTypes && documentTypes.address;
          if ($scope.ownerData.identityDoc && $scope.ownerData.identityDoc.code) {
            const code = $scope.ownerData.identityDoc.code;
            $scope.selectedDocumentType = $scope.documentTypes && $scope.documentTypes.find(
              doc => doc.code === code,
            );
          }
        });
      }
    });

    $scope.$watch('ownerData.identityDoc.code', (code) => {
      if (code) {
        $scope.selectedDocumentType = $scope.documentTypes && $scope.documentTypes.find(
          doc => doc.code === code,
        );
      }
    });

    const minimumAge = 18;
    $scope.dobMaximumYear = new Date().getFullYear() - minimumAge;

    $scope.saveOwner = () => {
      logEvent('Attempted to save compliance module', { module: $scope.ownerStatus.code });
      const payload = {
        ...$scope.ownerData,
        ...($scope.ownerData.useBusinessAddress && { address: $scope.contactData.address.main }),
        addressDoc: {
          ...$scope.ownerData.addressDoc,
          location: undefined, // removing the use of the location property
          code: 'ADDRESS',
          locations: FileService.extractFilePathFromUploader($scope.ownerData.addressDoc && $scope.ownerData.addressDoc.locations),
        },
        identityDoc: {
          ...$scope.ownerData.identityDoc,
          location: undefined, // removing the use of the location property
          locations: FileService.extractFilePathFromUploader($scope.ownerData.identityDoc && $scope.ownerData.identityDoc.locations),
        },
        role: 'OWNER',
      };

      ComplianceOwner.saveOwnerData(payload)
        .then(() => {
          $rootScope.$broadcast('savedOwner');
          logEvent('Successfully saved compliance module', { module: $scope.ownerStatus.code });
          $scope.reloadWithParams({ edit: null });
        })
        .catch((error) => {
          $rootScope.$broadcast('savedOwner', false);
          const message = error && error.message;
          logEvent('Error saving compliance module', { module: $scope.ownerStatus.code, message });
          Notification.error('Could not save owner data', error);
        });
    };


    $scope.switchToEditMode = () => {
      $scope.reloadWithParams({ edit: 'true' });
    };

    $scope.$watch('ownerStatus.completed', (value) => {
      if (!value) {
        $location.search('edit', 'true');
      }
    });
  },
]);
