angular.module('app.compliance').factory('ComplianceOwner', [
  'API',
  'Session',
  ($API, Session) => {
    const getIntegrationId = () => {
      const integration = Session.get('account');
      return integration && integration.id;
    };

    const getDefaultCountryCode = () => {
      const integration = Session.get('account');
      return (integration &&
        integration.attributes &&
        integration.attributes.country &&
        integration.attributes.country.iso_code) ||
      'NG';
    };

    const fetchIdentificationDocuments = countryCode => $API
      .one('documents/identification')
      .get({ country: countryCode || getDefaultCountryCode() })
      .then(response => response.data);

    const fetchOwnerDocuments = (id) =>  {
      if (!id) {
        return Promise.resolve();
      }
      return $API.one(`person/${id}/documents`).get().then(response => response.data);
    };

    const fetchOwner =  () => $API
      .one(`integration/${getIntegrationId()}/owners?documents=true`)
      .get()
      .then((owners) => {
        const existingOwner = owners.data && owners.data[0];
        return existingOwner;
      });

    const saveOwnerData = updatePayload => $API.one(`integration/${getIntegrationId()}/person`).patch(updatePayload);

    return {
      fetchIdentificationDocuments,
      fetchOwnerDocuments,
      fetchOwner,
      saveOwnerData,
    };
  },
]);
