angular
  .module('app.virtual-accounts', [])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('virtual-accounts', {
          abstract: true,
          controller: 'VirtualAccountsCtrl',
          pageTrack: '/virtual-accounts',
          redirectTo: 'virtual-accounts.list',
          templateUrl: '/components/layout/base.html',
          title: 'Virtual Accounts',
          url: '/virtual-accounts',
          data: {
            authenticable: true,
          },
          onExit: () => {
            window.cleanupMicroFrontend &&
              window.cleanupMicroFrontend('virtual-accounts-root');
          },
        })
        .state('virtual-accounts.list', {
          reloadOnSearch: false,
          templateUrl: '/modules/virtual-accounts/virtual-accounts-list.html',
          title: 'Virtual Accounts',
          url: '?status&account_number&provider_slug',
        })
        .state('virtual-accounts.one', {
          templateUrl: '/modules/virtual-accounts/virtual-accounts-detail.html',
          title: 'Virtual Accounts',
          url: '/:id',
        })

        .state('approval-requests', {
          abstract: true,
          controller: 'ApprovalRequestsCtrl',
          pageTrack: '/approval-requests',
          redirectTo: 'approval-requests.list',
          templateUrl: '/components/layout/base.html',
          title: 'Approval Requests',
          url: '/approval-requests',
          data: {
            authenticable: true,
          },
          onExit: () => {
            window.cleanupMicroFrontend &&
              window.cleanupMicroFrontend('approval-requests-root');
          },
        })
        .state('approval-requests.list', {
          reloadOnSearch: false,
          templateUrl: '/modules/virtual-accounts/approval-requests-list.html',
          title: 'Approval Requests',
          url: '?decision&receiver_account_number&amount',
        })
        .state('approval-requests.one', {
          templateUrl:
            '/modules/virtual-accounts/approval-requests-detail.html',
          title: 'Approval Requests',
          url: '/:id',
        });
    },
  ])
  .controller('VirtualAccountsCtrl', [
    '$scope',
    '$state',
    createController(
      'RENDER_VIRTUAL_ACCOUNTS_MICROFRONTEND',
      'virtual-accounts.list',
    ),
  ])
  .controller('ApprovalRequestsCtrl', [
    '$scope',
    '$state',
    createController(
      'RENDER_APPROVAL_REQUESTS_MICROFRONTEND',
      'approval-requests.list',
    ),
  ]);

function createController(renderMessage, redirectState) {
  return ($scope, $state) => {
    const handleIntegrationChange = event => {
      if (event.data === 'INTEGRATION_SWITCHED') {
        $state.go(redirectState);
      }
    };
    window.addEventListener('message', handleIntegrationChange);
    $scope.$on('$destroy', () =>
      window.removeEventListener('message', handleIntegrationChange),
    );

    const mountMicrofrontend = () => {
      if ($scope.mfeServiceReady) {
        window.postMessage(
          {
            message: renderMessage,
          },
          '*',
        );
      }
    };
    $scope.$watch('mfeServiceReady', mountMicrofrontend);
    $scope.$on('$stateChangeSuccess', mountMicrofrontend);
  };
}
