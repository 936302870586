UIDirectives.directive('warningBanner', [
  'AnalyticsService',
  '$rootScope',
  '$state',
  'PendingDisputes',
  (
    AnalyticsService,
    $rootScope,
    $state,
    PendingDisputes,
  ) => ({
    restrict: 'AE',
    replace: true,
    template: `
     <a
        ng-if="banner"
        class="banner alert text-center block {{banner.color ? banner.color : 'alert-danger' }}"
        ng-click="click(banner)"
        uib-tooltip="{{banner.tooltip}}"
        tooltip-placement="bottom"
        data-testid="warningBanner"
      >
        <span ng-switch="banner.priority" class="m-r-xs">
          <span ng-switch-when="4"></span>
          <i ng-switch-when="3" class="fa fa-ban"></i>
          <i ng-switch-when="2" class="fa fa-warning"></i>
          <i ng-switch-default class="fa fa-info"></i>
        </span>
        <span data-testid="warningBannerText" ng-bind-html="banner.text"></span>
        <br />
        <span ng-if="banner.subText">{{banner.subText}}</span>
      </a>
    `,
    link($scope) {
      const { Business, User } = $rootScope;
      const { logEvent } = AnalyticsService;

      const PRIORITIES = {
        INFO: 1,
        WARNING: 2,
        DISABLED: 3,
        ANNOUNCEMENT: 4,
      };

      const dataRequests = [];
      let pendingDisputeCount;
      const pendingDisputeCheck = () =>
        PendingDisputes.fetch()
          .then(() => {
            pendingDisputeCount = PendingDisputes.meta.total;
          })
          .catch(() => {});
      dataRequests.push(pendingDisputeCheck());
      $rootScope.$on('$stateChangeSuccess', () => pendingDisputeCheck());
      const isDormantAccount = Business.is_dormant;
      const isDisabledForCompliance =
        Business.is_disabled &&
        Business.disable_reason === 'INCOMPLETE_COMPLIANCE_DOCUMENTS';
      const isPayoutOnHoldForCompliance =
        Business.payouts_on_hold &&
        Business.payouts_on_hold_reason === 'INCOMPLETE_COMPLIANCE_DOCUMENTS';
      const isDisabledOtherwise =
        Business.is_disabled && !isDisabledForCompliance;

      const showManagedAccountsAgreementBanner = () =>
        Business.is_live &&
        Business.agreements &&
        Business.agreements.settlement_managed_account &&
        Business.agreements.settlement_managed_account.visible &&
        Business.agreements.settlement_managed_account.agreements &&
        !Business.agreements.settlement_managed_account.agreements.length; // not rejected or accepted

      Promise.all(dataRequests)
        .then(() => setBanner())
        .catch(() => {});

      $rootScope.$on('sessionUpdated', () => {
        setBanner();
      });

      const setBanner = () => {
        const warnings = [
          {
            active: isDisabledForCompliance,
            text: "Your payments have been temporarily deactivated because you've not provided some of your KYC documents. Kindly click on 'Compliance' to submit them & get reactivated.",
            link: { to: 'app.compliance' },
            priority: PRIORITIES.DISABLED,
          },
          {
            active: isDisabledOtherwise,
            text: 'Payments are currently disabled for this business',
            priority: PRIORITIES.DISABLED,
          },
          {
            active: false,
            text: 'Payouts are currently disabled for this business',
            tooltip: Business.payouts_on_hold_reason,
            priority: PRIORITIES.INFO,
          },
          {
            active: pendingDisputeCount,
            text: 'Please resolve pending transaction disputes',
            link: { to: 'transactions.disputes' },
            priority: PRIORITIES.WARNING,
          },
          {
            active: isPayoutOnHoldForCompliance,
            text: 'Payouts on Hold. Please update compliance documents for this business',
            link: { to: 'app.compliance' },
            priority: PRIORITIES.WARNING,
          },
          {
            active: showManagedAccountsAgreementBanner(),
            text: 'Click here to sign the consent letter',
            link: {
              to: 'app.consent',
              params: {
                type: 'settlement_managed_account',
                referrer: 'manual_settlement',
              },
            },
            priority: PRIORITIES.WARNING,
          },
          {
            active: !User.mfa_enabled && Business.attributes.mfa_is_required,
            text: 'This business requires you to enable 2FA on your account',
            link: { to: 'app.settings.profile', params: { toggle_mfa: true } },
            priority: PRIORITIES.WARNING,
          },
          {
            active: !User.mfa_enabled && !Business.attributes.mfa_is_required,
            text: 'Setup 2FA to further protect your account',
            link: { to: 'app.settings.profile', params: { toggle_mfa: true } },
            priority: PRIORITIES.WARNING,
          },
          {
            active: isDormantAccount,
            text: "Please note that your account is dormant because you haven't transacted in a while.  If you'd like to reactivate your account, please complete a successful transaction.",
            link: {
              to: 'https://paystack.com/contact/support?q=/contact/support',
            },
            priority: PRIORITIES.WARNING,
          }
        ];
        const banners = warnings
          .filter(({ active }) => active)
          .sort((a, b) => b.priority - a.priority);

        $scope.banner = banners.length && banners[0];
        $rootScope.bannerIsVisible = Boolean($scope.banner);
      };

      $rootScope.$on('$stateChangeSuccess', () => setBanner());

      $scope.click = banner => {
        logEvent('Clicked warning banner', { banner });
        const { articleId, link } = banner;
        if (link) {
          const { to: linkTo, params = {} } = link;
          const isExternalLink = linkTo.startsWith('https://');
          if (isExternalLink) {
            window.open(linkTo, '__blank', 'noreferrer');
          } else {
            $state.go(linkTo, params);
          }
        } else if (articleId) {
          $rootScope.openHelpDesk({ id: articleId });
        }
      };
    },
  }),
]);
