angular
  .module('app.compliancev3', ['ui.router'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('compliancev3', {
          url: '/compliancev3',
          abstract: true,
          template: '<ui-view></ui-view>',
          data: {
            authenticable: true,
          },
          redirectTo: 'compliancev3.documents',
        })
        .state('compliancev3.documents', {
          title: 'Compliance Documents',
          url: '/documents',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.business-activation', {
          title: 'Business Activation',
          url: '/business-activation?goLiveStatus',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.business', {
          url: '/business',
          template: '<ui-view></ui-view>',
          redirectTo: 'compliancev3.business.profile',
        })
        .state('compliancev3.business.profile', {
          title: 'Business Profile',
          url: '/profile',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.business.contact', {
          title: 'Business Contact',
          url: '/contact',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.business.address', {
          title: 'Business Address',
          url: '/address',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.business.verify', {
          title: 'Business Verify',
          url: '/verify',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.registration', {
          url: '/registration',
          template: '<ui-view></ui-view>',
          redirectTo: 'compliancev3.registration.information',
        })
        .state('compliancev3.registration.information', {
          title: 'Registration Information',
          url: '/information',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.registration.verify', {
          title: 'Registration Verify',
          url: '/verify',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.people', {
          url: '/people',
          template: '<ui-view></ui-view>',
          redirectTo: 'compliancev3.people.overview',
        })
        .state('compliancev3.people.profile', {
          title: 'People Profile',
          url: '/:id/profile',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.people.identity', {
          title: 'People Identity',
          url: '/:id/identity',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.people.address', {
          title: 'People Address',
          url: '/:id/address',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.people.verify', {
          title: 'People Verify',
          url: '/:id/verify',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.people.invite', {
          title: 'People Invite',
          url: '/:id/invite',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.people.overview', {
          title: 'People Overview',
          url: '/overview',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.people.structure', {
          title: 'People Structure',
          url: '/structure',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.account', {
          url: '/account',
          template: '<ui-view></ui-view>',
          redirectTo: 'compliancev3.account.details',
        })
        .state('compliancev3.account.details', {
          title: 'Account Details',
          url: '/details',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.agreement', {
          url: '/agreement',
          template: '<ui-view></ui-view>',
          redirectTo: 'compliancev3.agreement.accept',
        })
        .state('compliancev3.agreement.accept', {
          title: 'Merchant Service Agreement',
          url: '/accept',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.summary', {
          url: '/summary',
          template: '<ui-view></ui-view>',
          redirectTo: 'compliancev3.summary.legal',
        })
        .state('compliancev3.summary.legal', {
          title: 'Summary Legal',
          url: '/legal',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        })
        .state('compliancev3.summary.recap', {
          title: 'Summary Recap',
          url: '/recap',
          templateUrl: 'modules/compliance/v3/compliance-root.html',
          controller: 'ComplianceV3Ctrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('compliance-root'),
        });
    },
  ])
  .controller('ComplianceV3Ctrl', [
    '$scope',
    'Onboarding3Service',
    function ($scope, Onboarding3Service) {
      Onboarding3Service.redirectToLegacyComplianceIfOnboarding3Disabled();
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage(
            {
              message: 'RENDER_COMPLIANCE_MICROFRONTEND',
            },
            '*',
          );
        }
      });
    },
  ]);
