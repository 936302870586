angular.module('app.compliance').factory('ComplianceStatus', [
  'API',
  'Session',
  ($API, Session) => {
    const getIntegrationId = () => {
      const integration = Session.get('account');
      return integration && integration.id;
    };
    return {
      getComplianceStatus: () => $API
        .one(`integration/${getIntegrationId()}/compliance_status`)
        .get()
        .then(response => response.data),
    };
  },
]);
