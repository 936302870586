UIDirectives.directive('breadcrumbs', ['$state', $state => ({
  restrict: 'E',
  replace: true,
  scope: {
    pageTitle: '=',
  },
  template: `
    <div class="breadcrumbs">
      <a
        ng-repeat-start="crumb in breadcrumbs"
        class="breadcrumb-link"
        ui-sref="{{crumb.link}}"
        ui-sref-opts="{{crumb.params}}"
        ng-if="!crumb.current"
        data-testid="breadcrumb-link-{{$index}}"
      >
        {{crumb.title}}
      </a>
      <span ng-if="crumb.current" class="breadcrumb-page">{{crumb.title}}</span>
      <span ng-repeat-end ng-if="!$last" class="breadcrumb-divider">
        <svg width="5" height="7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l3 2.5L1 6" stroke="#B7C0C6" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </span>
    </div>
  `,
  link: ($scope) => {
    const getCrumb = (route) => {
      const { self, url } = route;
      const { title, name, redirectTo } = self;
      const isChildView = url && url.params && url.params.id;
      return {
        link: redirectTo || name,
        title: isChildView ? $state.params.id : title,
        params: isChildView ? $state.params : null,
        current: name === $state.current.name,
      };
    };
    const breadcrumbs = [];
    let parent = $state.$current.parent;
    while (parent && parent.data) {
      breadcrumbs.unshift(getCrumb(parent));
      parent = parent.parent;
    }
    if ($state.$current.title) {
      const pageCrumb = getCrumb($state.$current);
      pageCrumb.title = $scope.pageTitle || pageCrumb.title;
      breadcrumbs.push(pageCrumb);
    }
    $scope.breadcrumbs = breadcrumbs;
  },
})]);