UIDirectives.directive('ngMin', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attr, ctrl) {
      scope.$watch(attr.ngMin, function () {
        $timeout(function () {
          ctrl.$validate();
        });
      });

      ctrl.$validators.ngMin = function (modelValue, viewValue) {
        const min = parseFloat(attr.ngMin) || 0;
        const sanitizedViewValue = viewValue ? viewValue.replace(/,/g, '') : '';

        if ((parseFloat(sanitizedViewValue) < min)) {
          return false;
        }

        return true;
      }
    }
  };
}]);