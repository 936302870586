UIDirectives
  .directive('phoneCodeDropdown', ['CountryService', '$timeout', function(CountryService, $timeout) {
    return {
      restrict: 'E',
      scope: {
        callingCode: '=?',
      },
      template: `
    <form>
    <div class="phone-code-input" uib-dropdown auto-close="outsideClick" is-open="isDropdownOpen">
      <div role="menu" uib-dropdown-menu class="dropdown-menu-left phone-code-input-menu no-arrow">
        <div class="padder padder-v-sm">
          <div class="search-container">
            <input id="countrySearch" class="form-control no-border input-v2" ng-model="searchCountries" type="text" placeholder="Search country" />
            <span class="no-bg search-icon">
              <i class="icon-magnifier text-xs m-t-xxs text-muted"></i>
            </span>
          </div>
          <div class="searched-item">
            <li class="list-unstyled item-container" ng-style="{'border-bottom': $last ? '0px': ''}" ng-repeat="country in countries | filter: searchCountries">
              <a ng-click="setActiveCountry(country)" class="flex flex-justify--space-between m-t-xs m-b-xs">
                <span class="flex-item--fill flex item-name">
                  {{country.name}}
                </span>
                <span class="text-muted item-info"> {{country.callingCodes[0]}} </span>
              </a>
            </li>
          </div>
        </div>
      </div>
      <span class="field">
        <div class="country-dropdown">
          <div data-testid="phone-number-toggle" class="flex flex-align--center" style="padding: 0 12px;" uib-dropdown-toggle aria-haspopup="true" aria-expanded="true">
            <span>{{callingCode}}</span>
            <span class="caret"></span>
          </div>
        </div>
      </span>
    </div>
    </form>
    `,
      link: function ($scope) {
        $scope.isDropdownOpen = false;

        $scope.setActiveCountry = (country) => {
          if (country) {
            $scope.activeCountry = country;
            $scope.isDropdownOpen = false;
            $scope.callingCode = country.callingCodes[0];
          }
        };

        $scope.$watch('isDropdownOpen', (value) => {
          if (value) {
            $timeout(() => {
              const searchInput = document.getElementById('countrySearch');
              if (!searchInput) {
                return;
              }
              searchInput.focus();
            });
          }
        });

        // TODO: Replace with a value from the API
        const activeCountriesOnPaystack = ['NG', 'GH', 'ZA', 'RW', 'CI', 'EG', 'KE'];

        CountryService.getCountries().then((countries) => {
          $scope.countries = countries.reduce((arrangedCountries, country) => {
            if (activeCountriesOnPaystack.includes(country.countryCode)) {
              return [country, ...arrangedCountries];
            }
            return [...arrangedCountries, country];
          }, []);

          $scope.$watch('callingCode', (callingCode) => {
            $scope.setActiveCountry(
              CountryService.getCountryByCallingCode(callingCode) || CountryService.getCountryByCallingCode('+234')
            );
          });
        });
      }
    };
  }]);