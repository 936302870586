angular
  .module('app.corporateCards', [])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('corporate-cards-balance', {
          title: 'Balance',
          url: '/corporate-cards/balance',
          pageTrack: '/corporate-cards/balance',
          abstract: true,
          templateUrl: '/components/layout/base.html',
          redirectTo: 'corporate-cards-balance.list',
          data: {
            permission: 'corporate-cards-view',
            authenticable: true,
          },
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('corporate-cards-root'),
        }).state('corporate-cards-balance.list', {
          url: '?currency',
          reloadOnSearch: false,
          templateUrl: 'modules/corporate-cards/root.html',
          controller: 'corporateCardsCtrl',
        })
        .state('corporate-cards-budgets', {
          title: 'Budgets',
          url: '/corporate-cards/budgets',
          pageTrack: '/corporate-cards/budgets',
          abstract: true,
          templateUrl: '/components/layout/base.html',
          redirectTo: 'corporate-cards-budgets.list',
          data: {
            permission: 'corporate-cards-view',
            authenticable: true,
          },
          onExit: () =>
              window.cleanupMicroFrontend &&
              window.cleanupMicroFrontend('corporate-cards-root'),
        }).state('corporate-cards-budgets.list', {
          url: '?page&perPage&owned_by&status&name&search',
          reloadOnSearch: false,
          templateUrl: 'modules/corporate-cards/root.html',
          controller: 'corporateCardsCtrl',
        }).state('corporate-cards-budgets.one', {
          url: '/:id',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        }).state('corporate-cards-budgets.one.owners', {
          url: '/owners',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        }).state('corporate-cards-budgets.one.spenders', {
          url: '/spenders?name&page&search',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        }).state('corporate-cards-budgets.one.transactions', {
          url: '/transactions',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        })
        .state('corporate-cards-spenders', {
          title: 'Budgets > Spender',
          url: '/corporate-cards/spenders?budget&page&perPage&name&search&status&user&user_name&budget_name',
          pageTrack: '/corporate-cards/spenders',
          abstract: true,
          templateUrl: '/components/layout/base.html',
          redirectTo: 'corporate-cards-budgets.list',
          data: {
            permission: 'corporate-cards-view',
            authenticable: true,
          },
          onExit: () =>
              window.cleanupMicroFrontend &&
              window.cleanupMicroFrontend('corporate-cards-root'),
        })
        .state('corporate-cards-spenders.one', {
          url: '/:id',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        }).state('corporate-cards-spenders.one.transactions', {
          url: '/transactions',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        }).state('corporate-cards-spenders.one.cards', {
          url: '/cards?page&search',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        })
        .state('corporate-cards-cards', {
          title: 'Cards',
          url: '/corporate-cards/cards',
          pageTrack: '/corporate-cards/cards',
          abstract: true,
          templateUrl: '/components/layout/base.html',
          redirectTo: 'corporate-cards-cards.list',
          data: {
            permission: 'corporate-cards-view',
            authenticable: true,
          },
          onExit: () =>
              window.cleanupMicroFrontend &&
              window.cleanupMicroFrontend('corporate-cards-root'),
        }).state('corporate-cards-cards.list', {
        url: '?page&perPage&status&user&budget&spender&name&search',
        reloadOnSearch: false,
        templateUrl: 'modules/corporate-cards/root.html',
        controller: 'corporateCardsCtrl',
        }).state('corporate-cards-cards.one', {
          url: '/:id',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        }).state('corporate-cards-cards.one.detail', {
          url: '/detail',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        }).state('corporate-cards-cards.one.transactions', {
          url: '/transactions',
          controller: 'corporateCardsCtrl',
          templateUrl: 'modules/corporate-cards/root.html',
        })
        .state('corporate-cards-transactions', {
          title: 'Transactions',
          url: '/corporate-cards/transactions',
          pageTrack: '/corporate-cards/transactions',
          abstract: true,
          templateUrl: '/components/layout/base.html',
          redirectTo: 'corporate-cards-transactions.list',
          data: {
            permission: 'corporate-cards-view',
            authenticable: true,
          },
          onExit: () =>
              window.cleanupMicroFrontend &&
              window.cleanupMicroFrontend('corporate-cards-root'),
          }).state('corporate-cards-transactions.list', {
          url: '?page&perPage&user&budget&status&spender&card&type&from&to',
          reloadOnSearch: false,
          templateUrl: 'modules/corporate-cards/root.html',
          controller: 'corporateCardsCtrl',
        }).state('corporate-cards-transactions.one', {
          url: '/:id',
          templateUrl: 'modules/corporate-cards/root.html',
          controller: 'corporateCardsCtrl',
        })
    },
  ])
  .controller('corporateCardsCtrl', [
    'Session', '$scope', '$state',
    function (Session, $scope, $state) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        const features = Session.get('features') || {};
        if (!features.corporate_cards_enabled) {
          $state.go('app.404')
        }
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_CORPORATE_CARDS_MICROFRONTEND',
          }, '*');
        }
      });
    },
  ]);
