angular.module('app.compliance').controller('ComplianceDocumentsController', [
  '$scope',
  '$rootScope',
  'RegistrationDocuments',
  'Notification',
  '$state',
  'AnalyticsService',
  'ComplianceOwner',
  'ngDialog',
  'Addresses',
  'Session',
  '$location',
  'FileService',
  'ComplianceProfile',
  'Onboarding3Service',
  '$i18next',
  'DEFAULTS',
  (
    $scope,
    $rootScope,
    RegistrationDocuments,
    Notification,
    $state,
    AnalyticsService,
    ComplianceOwner,
    ngDialog,
    Addresses,
    Session,
    $location,
    FileService,
    ComplianceProfile,
    Onboarding3Service,
    $i18next,
    DEFAULTS,
  ) => {
    Onboarding3Service.redirectToOnboarding3IfEnabled();
    const { logEvent } = AnalyticsService;
    $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

    $scope.documentsStatus =
      $scope.complianceStatusData &&
      $scope.complianceStatusData.modules.find(
        module => module.code === 'DOCUMENTS',
      );

    $scope.documentActions = {
      remove: $i18next.t('complianceDocuments.removeFile'),
      view: $i18next.t('complianceDocuments.viewFile'),
    };

    const moduleStatus =
      $scope.documentsStatus && $scope.documentsStatus.completed;
    $scope.useEditMode = ($state.params && $state.params.edit) || !moduleStatus;
    const integration = Session.get('account');
    $scope.businessName = integration && integration.business_name;

    const minimumAge = 18;
    $scope.dobMaximumYear = new Date().getFullYear() - minimumAge;

    const formatPersonData = person => {
      const personData = { ...person };

      if (!personData.addressDoc || !personData.addressDoc.locations) {
        personData.addressDoc = {
          locations: [],
        };
      }

      if (!personData.identityDoc || !personData.identityDoc.locations) {
        personData.identityDoc = {
          locations: [],
        };
      }

      personData.addressDoc.locations =
        FileService.formatDocumentLocationsForFileUploader(
          personData.addressDoc.locations,
        );
      personData.identityDoc.locations =
        FileService.formatDocumentLocationsForFileUploader(
          personData.identityDoc.locations,
        );

      return personData;
    };

    const country =
      $rootScope.Business &&
      $rootScope.Business.attributes &&
      $rootScope.Business.attributes.country &&
      $rootScope.Business.attributes.country.iso_code;

    $scope.isEgyptianBusiness = country === 'EG';
    $scope.showOwnershipPercentage = !$scope.isEgyptianBusiness;

    Promise.all([
      ComplianceOwner.fetchIdentificationDocuments(),
      Addresses.fetchCountries(),
      RegistrationDocuments.fetchDocuments(),
      RegistrationDocuments.fetchOwners(),
      RegistrationDocuments.fetchDirectors(),
      ComplianceProfile.fetchBusinessProfile(country),
    ])
      .then(
        ([
          documentTypes,
          countries,
          documentsData,
          owners,
          directors,
          businessProfile,
        ]) => {
          const { Business } = $rootScope;
          $scope.countryCode =
            (Business.attributes.country &&
              Business.attributes.country.iso_code) ||
            Business.attributes.country_code;
          $scope.documentTypes = documentTypes && documentTypes.all;
          $scope.addressDocumentType = documentTypes && documentTypes.address;
          $scope.countries = countries;
          $scope.directors = directors.data.map(formatPersonData);
          $scope.customFields = directors.meta.custom_fields;
          $scope.owners = owners.data.map(formatPersonData);
          $scope.selectedDocumentType =
            $scope.personData &&
            $scope.personData.identityDoc &&
            $scope.documentTypes.find(
              doc => doc.code === $scope.personData.identityDoc.code,
            );
          const { data: savedDocuments, meta: documentsMetadata } =
            documentsData;
          $scope.documents = savedDocuments || [];
          const {
            submitted,
            minOwnership,
            minDirectors,
            minOwners,
            minIndividualOwnership,
          } = documentsMetadata || {};

          $scope.ownersSectionDescriptionNoOwners = $i18next.t(
            `complianceDocuments.${
              minIndividualOwnership
                ? 'individualBeneficialOwnersSectionDescriptionNoOwners'
                : 'beneficialOwnersSectionDescriptionNoOwners'
            }`,
            { ownershipThreshold: minOwnership },
          );
          $scope.submittedDocuments = submitted;
          $scope.minDirectors = minDirectors;
          $scope.minOwners = minOwners;
          $scope.ownershipThreshold = minOwnership;
          $scope.requiresProofOfAddress =
            businessProfile &&
            businessProfile.meta &&
            businessProfile.meta.requiresPoa;
          $rootScope.$on('showDocumentSection', (event, value) => {
            $scope.showProofOfAddress = value;
          });
          $scope.totalOwnership = owners.data.reduce(
            (totalOwnership, owner) => {
              if (owner.percentOwned) {
                return totalOwnership + owner.percentOwned;
              }
              return totalOwnership;
            },
            0,
          );
          // make sure translation files are loaded
          $scope.$watch('i18nextReady', status => {
            if (status) {
              logEvent('page_load', {
                pageName: $state.current.name,
                state: 'succeeded',
              });
              $scope.$broadcast('documentsPageReady');
            }
          });
        },
      )
      .catch(error => {
        logEvent('page_load', {
          pageName: $state.current.name,
          state: 'failed',
        });
        Notification.error('Could not fetch compliance documents', error);
      });

    $scope.$watch('personData.country', countryCode => {
      if (countryCode) {
        const country =
          $scope.countries &&
          $scope.countries.find(({ iso2 }) => countryCode === iso2);
        if (country) {
          ComplianceOwner.fetchIdentificationDocuments(country.iso2).then(
            documentTypes => {
              $scope.documentTypes = documentTypes && documentTypes.all;
              $scope.addressDocumentType =
                documentTypes && documentTypes.address;
            },
          );
          const addressCountry =
            $scope.personData.address && $scope.personData.address.country;
          if (!addressCountry) {
            if (!$scope.personData.address) {
              $scope.personData.address = {};
            }
            $scope.personData.address.country = country.name;
          }
        }
      }
    });

    $scope.$watch('personData.identityDoc.code', code => {
      if (code) {
        $scope.selectedDocumentType =
          $scope.documentTypes &&
          $scope.documentTypes.find(doc => doc.code === code);
      }
    });

    const defaultCallingCode =
      ($rootScope.Business.attributes.country &&
        $rootScope.Business.attributes.country.calling_code) ||
      $rootScope.Business.attributes.calling_code;
    const ownerData = {
      phone: {
        calling_code: defaultCallingCode,
      },
    };
    const directorData = {
      phone: {
        calling_code: defaultCallingCode,
      },
    };

    $scope.saveUploadedDocuments = () => {
      $scope.unsavedDocuments =
        ($scope.documents || []).filter(document => {
          const documentsUploaded =
            (document.documentData && document.documentData.locations) || [];
          const documentsUploadedAndSaved =
            document.documents &&
            document.documents.length &&
            document.documents[0].locations
              ? document.documents[0].locations
              : [];
          return documentsUploaded.length !== documentsUploadedAndSaved.length;
        }) || [];

      if (!$scope.unsavedDocuments.length) {
        return;
      }

      Promise.all($scope.saveAllDocuments())
        .then(() => {
          logEvent('Successfully saved compliance module', {
            module: $scope.documentsStatus.code,
          });
        })
        .catch(error => {
          const message = error && error.message;
          logEvent('Error saving compliance module', {
            module: $scope.documentsStatus.code,
            message,
          });
        });
    };

    $scope.addOwner = () => {
      $scope.saveUploadedDocuments();
      $scope.addPerson('OWNER', ownerData, !$scope.isEgyptianBusiness);
    };

    $scope.addDirector = () => {
      $scope.saveUploadedDocuments();
      $scope.addPerson('DIRECTOR', directorData);
    };

    $scope.getDocumentsWithNoFilesUploaded = () =>
      ($scope.documents || []).filter(({ documentData, type }, index) => {
        const locations = (documentData && documentData.locations) || [];
        return !locations.length && type !== 'text';
      });

    $scope.disableSaveDocuments = () => {
      const isDirectorSectionEmpty = !($scope.minDirectors > 0
        ? $scope.directors.length
        : !$scope.minDirectors);
      const isOwnerSectionEmpty = !($scope.minOwners > 0
        ? $scope.owners.length
        : !$scope.minOwners);
      const isAnyDocumentsSectionEmpty =
        $scope.getDocumentsWithNoFilesUploaded().length;
      const disableDocumentsSaveButton = $rootScope.disableSaveButtonIfInputIsInvalid;

      return (
        isDirectorSectionEmpty ||
        isOwnerSectionEmpty ||
        isAnyDocumentsSectionEmpty ||
        disableDocumentsSaveButton
      );
    };

    $scope.addPerson = (role, personData, showPercentage = false) => {
      $scope.personData = personData;

      ngDialog.open({
        template: 'modules/compliance/views/documents/modals/add-person.html',
        controller: 'ComplianceDocumentsController',
        showClose: false,
        closeByDocument: true,
        scope: $scope,
        data: { role, showPercentage },
      });
    };

    const handlePersonSaveSuccess = role => {
      $rootScope.$broadcast('savedPerson');
      logEvent('Successfully compliance saved person', { role });
    };

    const handlePersonSaveError = (error, role) => {
      $rootScope.$broadcast('savedPerson', false);
      const message = error && error.message;
      logEvent('Error saving compliance person', { message, role });
      Notification.error(`Could not save ${role.toLowerCase()}`, error);
    };

    const buildPersonPayload = ({ phone, ...otherPersonDetails }, role) => ({
      ...otherPersonDetails,
      addressDoc: {
        ...otherPersonDetails.addressDoc,
        code: 'ADDRESS',
        location: undefined, // removing the use of the location property
        locations: FileService.extractFilePathFromUploader(
          otherPersonDetails.addressDoc &&
            otherPersonDetails.addressDoc.locations,
        ),
      },
      identityDoc: {
        ...otherPersonDetails.identityDoc,
        location: undefined, // removing the use of the location property
        locations: FileService.extractFilePathFromUploader(
          otherPersonDetails.identityDoc &&
            otherPersonDetails.identityDoc.locations,
        ),
      },
      phone: {
        callingCode: phone && phone.calling_code,
        number: phone && phone.number,
      },
      role,
    });

    $scope.savePerson = role => {
      const payload = buildPersonPayload($scope.personData, role);
      RegistrationDocuments.savePerson(payload)
        .then(() => {
          handlePersonSaveSuccess(role);
          $scope.closeThisDialog();
          $state.reload();
        })
        .catch(error => {
          handlePersonSaveError(error, role);
        });
    };

    $scope.chooseSomeone = person => {
      const role = 'OWNER';
      const payload = buildPersonPayload(person, role);
      RegistrationDocuments.savePerson(payload)
        .then(() => {
          handlePersonSaveSuccess(role);
          $state.reload();
        })
        .catch(error => {
          handlePersonSaveError(error, role);
        });
    };

    $scope.saveAllDocuments = () =>
      $scope.documents.map(
        ({ code, type, documentData: { locations, value } }) => {
          const payload = { code };
          const isFileDocument = type === 'file';

          if (isFileDocument) {
            payload.locations =
              FileService.extractFilePathFromUploader(locations);
          } else {
            payload.value = value;
          }

          return RegistrationDocuments.saveDocument(payload);
        },
      );

    $scope.saveAllOwners = () => {
      logEvent('Attempted to save compliance module', {
        module: $scope.documentsStatus.code,
      });
      const role = 'OWNER';
      const ownerSavePromises = ($scope.owners || []).map(person => {
        const payload = buildPersonPayload(person, role);
        return RegistrationDocuments.savePerson(payload);
      });

      Promise.all([...ownerSavePromises, ...$scope.saveAllDocuments()])
        .then(() => {
          $rootScope.$broadcast('savedPerson');
          logEvent('Successfully saved compliance module', {
            module: $scope.documentsStatus.code,
          });
          Notification.success(
            'Documents saved!',
            'Successfully saved compliance module',
          );
          $scope.reloadWithParams({ edit: null });
        })
        .catch(error => {
          const message = error && error.message;
          handlePersonSaveError(error, role);
          logEvent('Error saving compliance module', {
            module: $scope.documentsStatus.code,
            message,
          });
        });
    };

    $scope.switchToEditMode = () => {
      $scope.reloadWithParams({ edit: 'true' });
    };

    $scope.$watch('documentsStatus.completed', value => {
      if (!value) {
        $location.search('edit', 'true');
      }
    });
  },
]);
