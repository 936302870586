angular.module('app.terminals', []).config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('physical-terminals', {
        abstract: true,
        templateUrl: 'components/layout/base.html',
        redirectTo: 'physical-terminals.list',
        url: '/terminals/physical',
        data: {
          authenticable: true
        },
        onExit: () => {
          window.cleanupMicroFrontend && window.cleanupMicroFrontend(
            'physical-terminals-root',
          );
        },
      }).state('physical-terminals.list', {
        title: 'Terminals',
        url: '?search&status',
        pageTrack: '/terminals',
        templateUrl: 'modules/terminals/physical-terminals.html',
        controller: 'PhysicalTerminalsCtrl',
        reloadOnSearch: false,
        onExit: () => {
          window.removeEventListener('message', window.terminalCountHandler);
        }
      }).state('physical-terminals.list.one', {
        url: '/:id',
        pageTrack: '/terminals',
        templateUrl: 'modules/terminals/physical-terminals.html',
      }).state('physical-terminals.purchase', {
        url: '/purchase',
        pageTrack: '/terminals/purchase',
        templateUrl: 'modules/terminals/physical-terminals.html',
      })

      .state('virtual-terminals', {
        abstract: true,
        templateUrl: 'components/layout/base.html',
        redirectTo: 'virtual-terminals.list',
        url: '/terminals/virtual',
        title: 'Virtual Terminals',
        data: {
          authenticable: true
        },
        onExit: () => {
          window.cleanupMicroFrontend && window.cleanupMicroFrontend(
            'virtual-terminals-root',
          );
        },
      }).state('virtual-terminals.list', {
        title: 'Virtual Terminals',
        url: '?search&status',
        templateUrl: 'modules/terminals/virtual-terminals-list.html',
        controller: 'VirtualTerminalsCtrl',
        reloadOnSearch: false,
      }).state('virtual-terminals.one', {
        url: '/:id',
        title: 'Virtual Terminals',
        controller: 'VirtualTerminalsCtrl',
        templateUrl: 'modules/terminals/virtual-terminal-detail.html',
      }).state('virtual-terminals.one.tab', {
        url: '/:tab',
        templateUrl: 'modules/terminals/virtual-terminal-detail.html',
      })
  },
])

.controller('PhysicalTerminalsCtrl', ['$scope', '$timeout', '$state', function ($scope, $timeout, $state) {
  const handleIntegrationChange = (event) => {
    if (event.data === 'INTEGRATION_SWITCHED') {
      $state.go('physical-terminals.list');
    }
  }
  window.addEventListener('message', handleIntegrationChange);
  $scope.$on('$destroy', () => window.removeEventListener('message', handleIntegrationChange));

  $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
    if (mfeServiceReady) {
      window.postMessage({
        message: 'RENDER_PHYSICAL_TERMINALS_MICROFRONTEND',
      }, '*');
    }
  });

  window.terminalCountHandler = ({ data }) => {
    if (data.message === 'MFE_TERMINAL_LIST_COUNT') {
      $timeout(() => {
        $scope.terminalsCount = data.count;
      });
    }
  };
  window.addEventListener('message', window.terminalCountHandler);
}])

.controller('VirtualTerminalsCtrl', ['$scope', '$state', function ($scope, $state) {
  const handleIntegrationChange = (event) => {
    if (event.data === 'INTEGRATION_SWITCHED') {
      $state.go('virtual-terminals.list');
    }
  }
  window.addEventListener('message', handleIntegrationChange);
  $scope.$on('$destroy', () => window.removeEventListener('message', handleIntegrationChange));

  $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
    if (mfeServiceReady) {
      window.postMessage({
        message: 'RENDER_VIRTUAL_TERMINALS_MICROFRONTEND',
      }, '*');
    }
  });
}])
