UIDirectives.directive('ngMax', ['$timeout', function ($timeout) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, elem, attr, ctrl) {
      scope.$watch(attr.ngMax, function () {
        $timeout(function () {
          ctrl.$validate();
        });
      });

      ctrl.$validators.ngMax = function (modelValue, viewValue) {
        const max = parseFloat(attr.ngMax);
        const sanitizedViewValue = viewValue ? viewValue.replace(/,/g, '') : '';

        if (parseFloat(sanitizedViewValue) > max) {
          return false;
        }

        return true;
      };
    }
  };
}]);