angular.module('app.compliance').factory('BusinessActivation', [
  'API',
  'Session',
  ($API, Session) => {
    const getIntegrationId = () => {
      const integration = Session.get('account');
      return integration && integration.id;
    };
    return {
      activate: () =>
        $API
          .one(`integration/${getIntegrationId()}/activate`)
          .get()
          .then(response => response.data),
    };
  },
]);
