const StorefrontsModule = angular.module('app.storefronts', ['ui.router']);

StorefrontsModule.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('storefronts', {
        url: '/storefronts',
        title: 'Storefronts',
        abstract: true,
        reloadOnSearch: false,
        templateUrl: 'components/layout/base.html',
        redirectTo: 'storefronts.list',
        data: {
          permission: 'product-view',
          authenticable: true,
        },
        onEnter: function () {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'hidden';
        },
        onExit: function () {
          document.body.style.overflow = null;
        },
      })
      .state('storefronts.list', {
        url: '?search&status&orders&orderAmount&orderCondition&revenue&revenueAmount&revenueCondition',
        pageTrack: '/storefronts',
        title: 'Storefronts',
        templateUrl: 'modules/storefronts/views/one/list.html',
        controller: 'StorefrontListCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('storefront-list-root'),
      })
      .state('storefronts.one', {
        url: '/:id',
        pageTrack: '/storefronts',
        title: 'Storefronts',
        templateUrl: 'modules/storefronts/views/one/one.html',
        redirectTo: 'storefronts.one.orders',
        controller: 'StorefrontDetailCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('storefront-detail-root'),
      })
      .state('storefronts.one.after-purchase', {
        url: '/after-purchase',
        pageTrack: '/storefronts',
        title: 'Storefronts',
      })
      .state('storefronts.one.shipping', {
        url: '/delivery',
        pageTrack: '/storefronts',
        title: 'Storefronts',
      })
      .state('storefronts.one.orders', {
        url: '/orders?email&region&from&to&status&delivered&refunded&delivered_at&current&page&index&products&search&is_viewed',
        pageTrack: '/storefronts',
        reloadOnSearch: false,
      })
      .state('storefronts.one.orders.one', {
        url: '/:orderId',
        pageTrack: '/storefronts',
        reloadOnSearch: false,
        redirectTo: 'storefronts.one.orders.one.overview',
      })
      .state('storefronts.one.orders.one.overview', {
        url: '/overview',
        pageTrack: '/storefronts',
        reloadOnSearch: false,
      })
      .state('storefronts.orders.one.shipping', {
        url: '/shipping',
        pageTrack: '/storefronts',
        reloadOnSearch: false,
      })
      .state('storefronts.one.products', {
        url: '/products?search&page',
        pageTrack: '/storefronts',
        title: 'Storefronts',
      })
      .state('storefronts.one.link', {
        url: '/link',
        pageTrack: '/storefronts',
        title: 'Storefronts',
      })
      .state('storefronts.one.discount', {
        url: '/discount',
        pageTrack: '/storefronts',
        title: 'Storefronts',
      })
      .state('storefronts.one.discount_detail', {
        url: '/discount/:unique_code',
        pageTrack: '/storefronts',
        title: 'Storefronts',
      });
  },
]);
