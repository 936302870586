ProductModule
  .filter('trustUrl', [
    '$sce',
    function ($sce) {
      return function (recordingUrl) {
        return $sce.trustAsResourceUrl(recordingUrl);
      };
    },
  ])
  .filter('truncateText', [
    function() {
      return function (text, count = 60) {
        if(!text) {
          return '';
        }

        return text.length >= count ? `${text.substring(0, count)}...` : text;
      };
    }
  ])
  .filter('generateThumbnail', [
    function () {
      return function (path) {
        const pos = path.lastIndexOf('.');
        const extension = path.split('.').pop();
        const imgPath = (path.substr(0, pos < 0 ? path.length : pos) + '.' + extension)
          .replace('upload/', 'upload/w_250/');

        return imgPath;
      };
    },
  ])
  .filter('generateThumbnailForList', [
    function () {
      return function (path) {
        const pos = path.lastIndexOf('.');
        const extension = path.split('.').pop().toLowerCase();
        const availableExtensions = ['png', 'jpg', 'jpeg'];

        // switch to jpeg if extension is not included in availableExtensions
        const extensionToUse = availableExtensions.includes(extension) ? `.${extension}` : '.jpeg';
        const imgPath = (path.substr(0, pos < 0 ? path.length : pos) + extensionToUse)
          .replace('upload/', 'upload/w_250/');

        return imgPath;
      };
    },
  ])
  .filter('generateThumbnailForCarousel', [
    function() {
      return function (path) {
        const pos = path.lastIndexOf('.');
        const extension = path.split('.').pop();
        const imgPath = (path.substr(0, pos < 0 ? path.length : pos) + '.' + extension)
          .replace('upload/', 'upload/if_iw_gt_1000,w_1000/if_else,w_iw/');

        return imgPath;
      };
    }
  ]);
