UIDirectives.directive('ngLoading', [
  function () {
    //directive to show loading state
    return {
      restrict: 'AE',
      scope: true,
      compile: function (tElem, attrs) {
        //Add the controls to element
        tElem.addClass('loading-button');
        var buttonContent = tElem.html();
        tElem.html('<span class="default-state">' + buttonContent + '</span>');
        tElem.append('<div class="loading-state spinner">\r\n  <div class="b1 se"><\/div>\r\n  <div class="b2 se"><\/div>\r\n  <div class="b3 se"><\/div>\r\n  <div class="b4 se"><\/div>\r\n  <div class="b5 se"><\/div>\r\n  <div class="b6 se"><\/div>\r\n  <div class="b7 se"><\/div>\r\n  <div class="b8 se"><\/div>\r\n  <div class="b9 se"><\/div>\r\n  <div class="b10 se"><\/div>\r\n  <div class="b11 se"><\/div>\r\n  <div class="b12 se"><\/div>\r\n<\/div><span class="loading-success"><i class="fa fa-check animated fadeInUp"><\/i><\/span><span class="loading-failure"><i class="fa fa-times animated fadeInUp"><\/i><\/span>');
        return function (scope, element, attrs) {
          var watching;
          var load = function (val) {
            element.addClass('ng-loading');
            element.prop('disabled', true);
            watching = true;
          };
          scope.$on(attrs.ngLoading, function (event, val) {
            if (!watching) return;
            watching = false;
            element.removeClass('ng-loading');
            if (val == undefined) {
              element.removeClass('ng-loading');
              element.prop('disabled', false);
              return;
            } else if (val == true) {
              element.addClass('ng-loading-success');
            } else if (val == false) {
              element.addClass('ng-loading-failure');
            }
            setTimeout(function () {
              element.removeClass('ng-loading-success ng-loading-failure ng-loading');
              element.prop('disabled', false);
            }, 700);
          });
          element.on('click', function () {
            element.addClass('ng-loading');
            load();
          });
        };
      }
    };
  }
]);