angular.module('app.direct-debit')
  .factory('PaymentsData', ['API', function($API) {
    var module = {
      data: [],
      meta: {},
      params: {},
      fetch: function() {
        return $API.one('directdebit/transaction').get().then(function(response) {
          module.data = response.data;
          module.meta = response.meta;
          return response;
        });
      }
    };

    return module;
  }]);