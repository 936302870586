angular.module('app.dateService', [])
  .service('DateService', [function () {
    const module = {
      format: (stateParams) => {
        const params = angular.copy(stateParams);
        if (params.period === 'today') {
          params.from = moment().startOf('day').format();
          params.to = null;
        }

        if (params.period === 'day') {
          const day = new Date(params.for);
          params.from = moment(day).startOf('day').format();
          params.to = moment(day).endOf('day').format();
        }

        if (params.period === 'range') {
          if (params.from) {
            const from = new Date(params.from);
            params.from = moment(from).startOf('day').format();
          }

          if (params.to) {
            const to = new Date(params.to);
            params.to = moment(to).endOf('day').format();
          }
        }

        return params;
      }
    };

    return module;
  }]);