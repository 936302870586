angular.module('app.home', ['ui.router'])
  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('app.home', {
        abstract: true,
        url: '/dashboard',
        template: '<ui-view></ui-view>',
        redirectTo: 'app.home.dashboard',
        pageTrack: '/dashboard',
        data: {
          permission: 'metrics-*',
          authenticable: true
        }
      }).state('app.home.dashboard', {
        title: 'Dashboard',
        url: '?period&from&to&currency',
        templateUrl: 'modules/home/index.html',
        controller: 'DashboardCtrl',
        permission: 'metrics-*',
        fallback: 'transactions.list',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('home-page-root'),
      });
    }
  ])

  .controller('DashboardCtrl', ['$scope', 'Auth', 'Onboarding3Service', 'DEFAULTS',
    function ($scope, Auth, Onboarding3Service, DEFAULTS) {
      if (Onboarding3Service.isOnboarding3Enabled()) {
        Auth.refreshBusiness().catch(() => {});
      }

      $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage(
              {
                message: 'RENDER_HOME_PAGE_MICROFRONTEND',
              },
              '*',
          );
        }
      });
    }
  ]);
