TransactionSplitsModule.factory('TransactionSplitsData', ['API', '$rootScope', function($API, $rootScope) {
  const integrationId = $rootScope.Business.id;

  return {
    fetch(query) {
      return $API.one('split').get(query);
    },
    createSplit(payload) {
      return $API.all('split').post(payload);
    },
    updateSplit(splitId, payload) {
      return $API.one(`split/${splitId}`).customPUT(payload);
    },
    activateSplit(splitId) {
      return $API.one(`split/${splitId}`).customPUT({ active: true });
    },
    deactivateSplit(splitId) {
      return $API.one(`split/${splitId}`).customPUT({ active: false });
    },
    fetchSingleSplit(splitId) {
      return $API.one('split', splitId).get();
    },
    fetchTransactionsBySplitCode(split_code) {
      return $API.one('transaction').get({ split_code, perPage: 10 });
    },
    fetchSettlementAccount() {
      return $API.one('integration/view_settlement_accounts', integrationId).get();
    },
    fetchSubaccount(subaccountCode) {
      return $API.one('subaccount', subaccountCode).get();
    },
    searchSubaccounts(searchTerm) {
      return $API.one('subaccount').get({ search: searchTerm, perPage: 25 });
    },
    createSubaccount(payload) {
      return $API.all('subaccount').post(payload);
    },
    updateSubaccount(subaccountId, payload) {
      return $API.one('subaccount', subaccountId).customPUT(payload);
    },
    addSubaccountToSplit({ splitId, payload }) {
      return $API.all(`split/${splitId}/subaccount/add`).post(payload);
    },
    removeSubaccountFromSplit({ splitId, payload }) {
      return $API.all(`split/${splitId}/subaccount/remove`).post(payload);
    }
  };
}]);