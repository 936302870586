const SubscribersModule = angular.module('app.subscribers', ['ui.router']);

SubscribersModule.config([
  '$stateProvider',
  function ($stateProvider) {
    $stateProvider
      .state('subscribers', {
        abstract: true,
        url: '/subscribers',
        templateUrl: 'components/layout/base.html',
        redirectTo: 'subscribers.list',
        reloadOnSearch: false,
        data: {
          permission: 'plan-view',
          authenticable: true,
        },
      })
      .state('subscribers.list', {
        url: '?search&status&plan&page&index&lifetimeValueAmount&lifetimeValueCondition&currency&subscriptionsCountAmount&subscriptionsCountCondition',
        pageTrack: '/subscribers',
        templateUrl: 'modules/subscribers/views/list.html',
        controller: 'SubscriberListCtrl',
        onExit: () =>
          window.cleanupMicroFrontend &&
          window.cleanupMicroFrontend('subscribers-list-root'),
      })
      .state('subscribers.list.one', {
        url: '/:id',
        pageTrack: '/subscribers',
        templateUrl: 'modules/subscribers/views/list.html',
        controller: 'SubscriberListCtrl',
      });
  },
]);
