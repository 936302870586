angular.module('app.balance-history', [])
    .config([
        '$stateProvider',
        function ($stateProvider) {
            $stateProvider
                .state('balance-history', {
                    abstract: true,
                    templateUrl: 'components/layout/base.html',
                    redirectTo: 'balance-history.list',
                    url: '/balance/history',
                    data: {
                        authenticable: true
                    },
                    onExit: () => {
                        window.cleanupMicroFrontend && window.cleanupMicroFrontend(
                            'balance-history-page',
                        );
                    },
                })
                .state('balance-history.list', {
                    title: 'Balance',
                    url: '?page&type&currency&from&to&period&for&use_cursor&next&previous&per_page',
                    pageTrack: '/balance',
                    templateUrl: 'modules/balance-history/list.html',
                    controller: 'BalanceHistoryCtrl',
                    reloadOnSearch: false,
                    onExit: () => {
                        window.removeEventListener('message', window.terminalCountHandler);
                    }
                })
                .state('offline-topups', {
                    abstract: true,
                    templateUrl: 'components/layout/base.html',
                    redirectTo: 'offline-topups.list',
                    url: '/balance/offline-topups',
                    title: 'Offline Topups',
                    data: {
                        authenticable: true
                    },
                    onExit: () => {
                        window.cleanupMicroFrontend && window.cleanupMicroFrontend(
                            'offline-topups-page',
                        );
                    },
                })
                .state('offline-topups.list', {
                    title: 'Offline Topups',
                    url: '?page&reference&status&from&to&period&for&use_cursor&next&previous&per_page',
                    templateUrl: 'modules/balance-history/offline-topups-list.html',
                    controller: 'OfflineTopupsCtrl',
                    reloadOnSearch: false,
                })
                .state('offline-topups.one', {
                    url: '/:id',
                    title: 'Offline Topups',
                    controller: 'OfflineTopupsCtrl',
                    templateUrl: 'modules/balance-history/offline-topups-detail.html',
                })
        },
    ])
    .controller('BalanceHistoryCtrl', ['$scope', 'Session', function ($scope, Session) {
        $scope.isOfflineTopupsEnabled = () => {
            const features = Session.get('features') || {};
            return features.offline_topup_enabled || false;
        }
        $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
            if (mfeServiceReady) {
                window.postMessage({
                    message: 'RENDER_BALANCE_HISTORY_PAGE_MICROFRONTEND',
                }, '*');
            }
        });
    }])

    .controller('OfflineTopupsCtrl', ['$scope', 'Session', function ($scope, Session) {
        $scope.isOfflineTopupsEnabled = () => {
            const features = Session.get('features') || {};
            return features.offline_topup_enabled || false;
        }
        $scope.$watch('mfeServiceReady', (mfeServiceReady) => {
            if (mfeServiceReady) {
                window.postMessage({
                    message: 'RENDER_OFFLINE_TOPUPS_PAGE_MICROFRONTEND',
                }, '*');
            }
        });
    }])

