(function() {
  'use strict';

  UIDirectives.directive('psButton', PsButtonDirective);

  function PsButtonDirective() {
    return {
      restrict: 'E',
      templateUrl: 'components/directives/button/button.html',
      replace: true,
      transclude: true,
      /* scope: false, */
      link: postLink,
    };
  }

  function postLink(scope, element, attrs) {
    if (_.has(attrs, ['ngDisabled', 'disabled'])) {
      element.on('click', function(e) {
        e.preventDefault();
        e.stopImmediatePropagation();
      });

      if (!element.hasClass('button-disabled')) {
        element.addClass('button-disabled');
        element.prop('disabled', true);
      }
    }
  }
})();
