angular.module('app.onboarding3Service', [])
.service('Onboarding3Service', 
  [
    '$rootScope',
    'Session',
    '$state',
  function(
    $rootScope, 
    Session,
    $state,
  ) {
    const isOnboarding3Enabled = () => {
      const features = Session.get('features') || {};
      return features.onboarding_v3_enabled || false;
    }

    const isOnboardingComplete = () => {
      const businessData = $rootScope.Business.attributes;
      return !!(
        businessData.country.iso_code &&
        businessData.legal_business_name &&
        $rootScope.Business.business_type &&
        businessData.industry_code &&
        businessData.industry_categories &&
        businessData.registration_type &&
        businessData.ownership_type
      );
    };

    const redirectToOnboarding3IfEnabled = () => {
      if (isOnboarding3Enabled()) {
        if (!isOnboardingComplete()) {
          $state.go('onboardingv3.businessProfile', null, { location: 'replace' });
        } else {
          $state.go('compliancev3.documents', null, { location: 'replace' });
        }
      }
    }

    const redirectToLegacyComplianceIfOnboarding3Disabled = () => {
      if (!isOnboarding3Enabled()) {
        $state.go('app.compliance', null, { location: 'replace' });
      }
    }

    const redirectToLegacyGetStartedIfOnboarding3Disabled = () => {
      if (!isOnboarding3Enabled()) {
        $state.go('app.onboarding', null, { location: 'replace' });
      }
    }

    const redirectToOnboarding3GetStartedIfEnabled = () => {
      if (isOnboarding3Enabled()) {
        $state.go('onboardingv3.getStarted.home', null, { location: 'replace' });
      }
    }

    return {
      isOnboarding3Enabled,
      isOnboardingComplete,
      redirectToOnboarding3IfEnabled,
      redirectToLegacyComplianceIfOnboarding3Disabled,
      redirectToOnboarding3GetStartedIfEnabled,
      redirectToLegacyGetStartedIfOnboarding3Disabled,
    };
  },
]);
