angular
  .module('app.compliance')
  .directive('complianceStatus', [
    'AnalyticsService', 'ComplianceStatus', 'Session', function (AnalyticsService, ComplianceStatus, Session) {
      return {
        restrict: 'E',
        replace: true,
        scope: {
          status: '=',
        },
        templateUrl: '/modules/compliance/views/status.html',
        link: ($scope) => {
          const { logEvent } = AnalyticsService;
          $scope.logEvent = logEvent;
          const integration = Session.get('account');
          $scope.url = integration.logo_path;
          $scope.businessName = integration.business_name;
        },
      };
    }
  ]);
