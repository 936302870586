angular
  .module('app.personCompliance', ['ui.router'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('personCompliance', {
          url: '/person-compliance',
          abstract: true,
          template: '<ui-view></ui-view>',
          data: {
            authenticable: false,
          },
          controller: 'PersonComplianceCtrl',
          redirectTo: 'personCompliance.profile',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('person-compliance-root'),
        })
        .state('personCompliance.profile', {
          title: 'Profile',
          url: '/profile?token',
          templateUrl: 'modules/person-compliance/person-compliance.html',
          controller: 'PersonComplianceCtrl',
        })
        .state('personCompliance.identity', {
          title: 'Identity',
          url: '/identity?token',
          templateUrl: 'modules/person-compliance/person-compliance.html',
          controller: 'PersonComplianceCtrl',
        })
        .state('personCompliance.address', {
          title: 'Address',
          url: '/address?token',
          templateUrl: 'modules/person-compliance/person-compliance.html',
          controller: 'PersonComplianceCtrl',
        })
        .state('personCompliance.verify', {
          title: 'Verify',
          url: '/verify?token',
          templateUrl: 'modules/person-compliance/person-compliance.html',
          controller: 'PersonComplianceCtrl',
        });
    },
  ])
  .controller('PersonComplianceCtrl', [
    '$scope',
    function ($scope) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage(
            {
              message: 'RENDER_PERSON_COMPLIANCE',
            },
            '*',
          );
        }
      });
    },
  ]);
