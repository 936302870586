UIDirectives.directive('icon', ['InlineIcons', '$sce', function(InlineIcons, $sce) {
  return {
    restrict: 'A',
    scope: {
      icon: '@'
    },
    replace: true,
    template: '<i ng:bind-html="renderedIcon" aria-label="{{ariaLabel}}"></i>',
    link: ($scope, element, attrs) => {
      $scope.renderedIcon = $sce.trustAsHtml(InlineIcons[$scope.icon]);

      if (!attrs.ariaLabel) {
        $scope.ariaLabel = `${$scope.icon} icon`;
      }

      if (!attrs.class) {
        const parentElement = element.parent()[0];

        // Selectively update our icon styles based on the parent class
        if (parentElement && parentElement.classList.contains('btn-success')) {
          element.addClass('icon-white');
        } else {
          element.addClass('icon-grey');
        }
      }
    }
  };
}]);