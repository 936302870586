angular.module('app.onboarding', ['ui.router'])

  .config(['$stateProvider',
    function ($stateProvider) {
      $stateProvider.state('app.onboarding', {
        url: '/get-started',
        template: '<ui-view></ui-view>',
        redirectTo: 'app.onboarding.home',
        data: {
          permission: 'onboarding-*'
        }
      }).state('app.onboarding.home', {
        title: 'Get Started',
        url: '',
        pageTrack: '/get-started',
        templateUrl: 'modules/onboarding/index.html',
        controller: 'OnboardingHomeCtrl',
        onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('get-started-home-page-root'),
      }).state('app.onboarding.setup', {
        title: 'How to Use',
        url: '/setup?active&framework&library',
        pageTrack: '/setup',
        templateUrl: 'modules/onboarding/setup.html',
        controller: 'OnboardingSetupCtrl',
      }).state('old-activation-page', {
        url: '/onboarding/activate',
        redirectTo: 'app.compliance',
      }).state('old-setup-page', {
        url: '/onboarding/setup',
        redirectTo: 'app.onboarding.setup',
      });
    }
  ])

  .controller('OnboardingHomeCtrl', ['$scope',
    function ($scope) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_GET_STARTED_HOME_PAGE_MICROFRONTEND',
          }, '*');
        }
      });
    }
  ])

  .controller('OnboardingTruecallerCtrl', ['$state', '$scope', '$window', '$rootScope', '$interval', 'API', 'Auth', 'Notification',
    function ($state, $scope, $window, $rootScope, $interval, $API, Auth, Notification) {
      $scope.phone = angular.copy($rootScope.User.phone);
      $scope.callingCode = $rootScope.User.calling_code;

      $scope.error = {
        message: '',
        visible: false,
        showTip: true
      };

      $scope.starterIntegrationLimits = {
        NG: {
          limit: '2,000,000',
          extraLimit: '3,000,000',
        },
        GH: {
          limit: '30,000',
          extraLimit: '45,000',
        },
      };

      $scope.countryCode = $rootScope.Business.attributes.country.iso_code;
      $scope.countryCurrencyCode = $rootScope.Business.attributes.country.default_currency_code;

      $scope.refresh = () => {
        $window.location.reload();
      };

      $scope.verify = () => {
        $scope.error.message = '';
        $scope.error.visible = false;

        const formattedPhone = `${$scope.phone.calling_code}${$scope.phone.subscriber_number}`;

        Auth.update('user', {
          phone: $scope.phone.subscriber_number,
          calling_code: $scope.phone.calling_code
        })
          .then(() => $API.all('user/verifications').post({
            verification_type: 'truecaller',
            phone: formattedPhone
          }))
          .then(() => {
            $scope.verificationStarted = true;
            $scope.countdown = 30;
            var countdownTimer = $interval(function(){
              $scope.countdown -= 1;
              if ($scope.countdown === 0) $interval.cancel(countdownTimer);
            }, 1000);
          }).catch(function(error){
            $scope.error.message = error.data.message;
            $scope.error.visible = true;
            $rootScope.$broadcast('verificationStarted', false);
          });
      };
    }
  ])

  .controller('OnboardingSetupCtrl', ['$state', '$stateParams', '$scope', '$rootScope', 'API', 'LocalAPI', 'Onboarding3Service',
    function ($state, $stateParams, $scope, $rootScope, $API, $LocalAPI, Onboarding3Service) {
      Onboarding3Service.redirectToOnboarding3GetStartedIfEnabled();
      
      $LocalAPI.one('options').get().then(function (options) {
        $scope.options = options;
      });

      var eCommerce = {
        slug: 'eCommerce',
        title: 'E-Commerce Plugins',
        description: 'For sites built on platforms such as WordPress, Shopify, etc',
        expanded: $stateParams.active == 'eCommerce'
      };

      var api = {
        slug: 'api',
        title: 'API, Libraries and SDKs',
        description: 'Integrate Paystack with a custom website or app',
        expanded: $stateParams.active == 'api'
      };

      var paymentPages = {
        slug: 'paymentPages',
        title: 'Get paid without a website',
        description: 'Simple tools to start selling quickly',
        expanded: $stateParams.active == 'paymentPages'
      };

      if ($rootScope.User.is_developer) {
        $scope.tabs = [api, eCommerce, paymentPages];
      } else {
        $scope.tabs = [paymentPages, eCommerce, api];
      }

      $scope.select = function (tab) {
        var slug = tab.slug === $state.params.active ? null : tab.slug;
        $state.go($state.current, {
          active: slug
        }, {
          reload: false
        });
      };
    }
  ])

  .controller('OnboardingNoWebsiteCtrl', ['$state', '$scope', '$rootScope', 'API', 'Notification', 'ngDialog',
    function ($state, $scope, $rootScope, $API, Notification, ngDialog) {
      $scope.init = function () {
        $API.one('page').get({
          perPage: 1
        }).then(function (pages) {
          $scope.hasPaymentPage = pages.data.length;
          return $API.one('paymentrequest').get({
            perPage: 1
          });
        }).then(function (invoices) {
          $scope.hasInvoice = invoices.data.length;
          return $API.one('transaction').get({
            perPage: 1
          });
        });
      };

      $scope.choosePage = () => {
        ngDialog.open({
          templateUrl: '/modules/pages/modals/choose-new-page.html',
          width: 550,
          scope: $scope
        });
      };

      $scope.createPage = function (type) {
        var params = {
          template: 'createPageFromOnboarding',
          controller: 'NewPageCtrl',
          showClose: true,
          closeByDocument: false,
          data: {
            type,
          },
          resolve: {
            plans: ['API', '$stateParams', function ($API, $stateParams) {
              return $API.one('plan').get($stateParams);
            }],
            products: ['API', function ($API) {
              return $API.one('product').get().then(response => response);
            }],
          }
        };

        ngDialog.open(params).closePromise.then(function (data) {
          $scope.init();
        });
      };

      $scope.loadNewRequest = function () {
        ngDialog.open({
          template: '/modules/invoices/modals/new-request.html',
          resolve: {
            customers: ['API', function ($API) {
              return $API.one('customer').get();
            }]
          },
          trapFocus: false,
          showClose: true,
          controller: 'NewRequestCtrl',
          width: 500
        });
      };
    }
  ])

  .controller('OnboardingAPIeCommerceCtrl', ['$state', '$stateParams', '$scope', '$rootScope', 'API', 'Notification', 'Session',
    function ($state, $stateParams, $scope, $rootScope, $API, Notification, Session) {
      $scope.Notification = Notification;

      if (Session.hasPermission('user-view')) {
        $API.one('integration/users').get().then(({ data }) => {
          $scope.developersInvited = data.filter(user => user.is_developer).map(developer => developer.email);
        });
      }

      $scope.select = function (framework) {
        $scope.frameworkSelected = framework;
        $state.go($state.current, {
          framework: framework ? framework.slug : null
        }, {
          reload: false
        });
      };
      $scope.frameworks = [{
        name: 'WordPress',
        slug: 'wordpress'
      }, {
        name: 'Magento',
        slug: 'magento'
      }, {
        name: 'Shopify',
        slug: 'shopify'
      }, {
        name: 'OpenCart',
        slug: 'opencart'
      }, {
        name: 'Drupal',
        slug: 'drupal'
      }, {
        name: 'Joomla',
        slug: 'joomla'
      }, {
        name: 'Custom',
        slug: 'custom'
      }];

      $scope.frameworkSelected = $stateParams.framework ? _.findWhere($scope.frameworks, {
        slug: $stateParams.framework
      }) : null;

      if (!$rootScope.User.is_developer) {
        $scope.frameworks.push({
          name: "I don't know",
          slug: 'other'
        });
      }

      $scope.libraries = [{
        name: 'PHP',
        slug: 'php',
        url: 'https://github.com/yabacon/paystack-php'
      }, {
        name: 'Laravel',
        slug: 'laravel',
        url: 'https://github.com/unicodeveloper/laravel-paystack'
      }, {
        name: 'Node JS',
        slug: 'nodejs',
        url: 'https://github.com/kehers/paystack'
      }, {
        name: 'Python',
        slug: 'python',
        url: 'https://github.com/edwardpopoola/pypaystack'
      }, {
        name: 'Ruby',
        slug: 'ruby',
        url: 'https://github.com/ojengwa/paystackr'
      }, {
        name: 'Java',
        slug: 'java',
        url: 'https://github.com/SeunAdelekan/PaystackJava'
      }, {
        name: 'Android',
        slug: 'android',
        url: 'https://github.com/PaystackHQ/paystack-android'
      }, {
        name: 'iOS',
        slug: 'ios',
        url: 'https://github.com/PaystackHQ/paystack-ios'
      }, {
        name: 'Angular',
        slug: 'angular',
        url: 'https://libraries.io/bower/angular-paystack'
      }, {
        name: 'React',
        slug: 'react',
        url: 'https://github.com/iamraphson/react-paystack'
      }, {
        name: 'All Libraries',
        slug: 'more',
        url: 'https://developers.paystack.co/docs/libraries-and-plugins'
      }];
    }
  ])

  .controller('OnboardingUserInviteCtrl', ['$state', '$scope', '$rootScope', 'API', 'Auth', 'Notification', 'RoleService',
    function ($state, $scope, $rootScope, $API, Auth, Notification, RoleService) {
      RoleService.fetch().then(function(){
        $scope.roles = RoleService.data();
        $scope.developer = {
          role: RoleService.id('developer-support')
        };
      });

      function inviteUser(payload, callback) {
        $scope._payload = payload;
        $API.all('integration/add_user').post(payload).then(function (response) {
          return Auth.update('account');
        }).then(function () {
          var account = $rootScope.Business;
          Notification.success('User Invited', payload.email + ' has been sent instructions to join your business on Paystack');
          if (callback) callback();
          $state.reload();
        }).catch(function (error) {
          $scope.$broadcast('userInvited', false);
          Notification.error('Could not add invite to account', error);
        });
      }

      $scope.users = [];
      $API.one('integration/users').get().then(function(response) {
        $scope.users = response.data;
      });
      $scope.isAlreadyInvited = function(user_email) {
        return _.findWhere($scope.users, {
          email: user_email
        });
      };
      $scope.inviteDeveloper = function () {
        inviteUser({
          integration: $rootScope.Business.id,
          email: $scope.developer.email,
          role: $scope.developer.role,
          is_developer: true,
          onboarding: true
        });
      };

      $scope.admin = {};
      $scope.inviteAdmin = function (callback) {
        inviteUser({
          integration: $rootScope.Business.id,
          email: $scope.admin.email,
          is_developer: $scope.admin.is_developer,
          role: RoleService.id('admin'),
          onboarding: true
        }, callback);
      };
    }
  ]);
