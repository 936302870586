angular.module('app.search', ['ui.router'])

  .config(['$stateProvider',
    function($stateProvider) {
      $stateProvider.state('app.search', {
        url: '/search?model&query',
        pageTrack: '/search',
        title: 'Search',
        templateUrl: 'modules/search/list.html',
        controller: 'SearchCtrl',
        resolve: {
          results: ['API', '$stateParams', '$rootScope', function($API, $stateParams, $rootScope) {
            var params = _.omit($stateParams, 'page');
            var queryWasMade = _.some(_.values(params));

            var searchParams = _.clone($stateParams);
            searchParams.search = searchParams.query;
            searchParams.model = searchParams.model || 'customers';
            searchParams.domain = $rootScope.User.display_state;
            if (queryWasMade) return $API.one('integration/search').get(searchParams);
            else return {};
          }]
        }
      });
    }
  ])

  .controller('SearchCtrl', ['$state', '$scope', '$rootScope', 'results', function($state, $scope, $rootScope, results) {
    $scope.params = $state.params;
    $scope.results = results.data;
    $scope.meta = results.meta;

    $scope.search = function() {
      $state.go($state.current, $scope.params, {
        reload: true,
        inherit: true,
        notify: true
      });
    };

    $scope.switchTo = function(model) {
      $scope.params.model = model;
      $scope.search();
    };
  }]);
