UIDirectives.directive('multipleInput', ['$timeout', function ($timeout) {
  return {
    restrict: 'E',
    scope: {
      emails: '=model',
      placeholder: '@',
      type: '@',
      validationErrorMessage: '@'
    },
    template: `
      <div class="m-t-sm multiple_input">
          <div class="inner-addon right-addon m-b-xs" ng-repeat="datum in data track by $index" ng-class="{multiple_input_duplicate: $index === duplicate}">
              <i class="icon fa fa-times text-muted clickable" ng-click="removeData($index)"></i>
              <input type="{{type}}" class="form-control" ng-model="datum" ng-change="updateData(datum, $index)"/>
          </div>
          <div class="inner-addon right-addon" ng-class="{multiple_input_fade: fadeInput, multiple_input_error: foundDuplicate}">
              <i class="icon fa fa-plus text-muted clickable" ng-click="addData(newData)"></i>
              <input id="multiple_input_last_child" type="{{type}}" class="form-control" ng-model="newData" placeholder="{{inputPlaceholder}}" ng-focus="addFadeClass('focus')" ng-blur="addFadeClass('blur')" ng-change="clearValidationError()"/>
              <p ng-if="foundDuplicate" class="multiple_input_validation_error">{{validationErrorMessage}}</p>
          </div>
      </div>
    `,
    link: function (scope, element, attrs, controller) {
      scope.data = [];
      scope.foundDuplicate = false;
      scope.inputPlaceholder = scope.data.length > 0 ? 'Add another value' : scope.placeholder;

      scope.addData = (newData) => {
        if (!newData) {
          return;
        }

        if (scope.data.includes(newData)) {
          scope.foundDuplicate = true;
          scope.duplicate = scope.data.findIndex(element => element === newData);
          return;
        }

        scope.data.push(newData);
        scope.newData = null;
        scope.$broadcast('dataUpdated');
      };

      scope.removeData = (index) => {
        scope.data.splice(index, 1);

        scope.$broadcast('dataUpdated');
      };

      scope.addFadeClass = (action) => {
        scope.fadeInput = action === 'focus' ? false : scope.data.length > 0;
      };

      scope.updateData = (datum, index) => {
        const input = document.getElementById('multiple_input_last_child');

        scope.removeData(index);
        scope.newData = datum;
        scope.foundDuplicate = false;
        scope.duplicate = null;

        $timeout(function () {
          input.focus();
        });
      };

      scope.clearValidationError = () => {
        scope.foundDuplicate = false;
        scope.duplicate = null;
      };

      scope.$on('dataUpdated', function () {
        scope.inputPlaceholder = scope.data.length > 0 ? 'Add another value' : scope.placeholder;
        scope.addFadeClass();
      });

      scope.addFadeClass();
    }
  };
}]);