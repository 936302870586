UIDirectives
  .directive('noData', function () {
    return {
      restrict: 'E',
      scope: {
        show: '=',
        showDescription: '=',
        mainText: '@',
        description: '@',
        imageSrc: '@',
        imageAlt: '@',
      },
      template: `
      <div class="no-data text-center" ng-show="show">
        <div class="m-b-md"><img ng-src="{{src}}" alt="{{alt}}"></div>
        <h3 class="m-t-none">{{mainText}}</h3>
        <p ng-show="showDescriptionText">{{description}}</p>
      </div>
    `,
      link: function ($scope, element, attrs, controller) {
        $scope.src = $scope.imageSrc || 'assets/img/empty.svg';
        $scope.alt = $scope.imageAlt || 'Empty Folder';
        $scope.showDescriptionText = $scope.showDescription === undefined ? true : $scope.showDescription;
      }
    };
  });