angular.module('app.urlService', [])
  .service('URLService', ['$stateParams', 'Session', function ($stateParams, Session) {
    var module = {
      format: function (params, isMoment) {
        var params = params || $stateParams;
        var allowedPeriods = ['today', 'week', 'month', '30', '90', 'year', 'all', 'custom'];
        if (!_.contains(allowedPeriods, params.period)) {
          params.period = '30';
        }

        switch (params.period) {
          case 'today':
            params = {
              from: moment().startOf('day'),
              to: moment().endOf('day')
            };
            break;
          case 'week':
            params = {
              from: moment().startOf('week').startOf('day'),
              to: moment().endOf('day')
            };
            break;
          case 'month':
            params = {
              from: moment().startOf('month').startOf('day'),
              to: moment().endOf('day')
            };
            break;
          case '30':
            params = {
              from: moment().subtract(29, 'days').startOf('day'),
              to: moment().endOf('day')
            };
            break;
          case '90':
            params = {
              from: moment().subtract(89, 'days').startOf('day'),
              to: moment().endOf('day')
            };
            break;
          case 'year':
            params = {
              from: moment().startOf('year').startOf('day'),
              to: moment().endOf('day')
            };
            break;
          case 'all':
            params = {
              from: moment(Session.get('account').createdAt),
              to: moment().endOf('day')
            };
            break;
          case 'custom':
            params = {
              from: moment(new Date(params.from)).startOf('day'),
              to: moment(new Date(params.to)).endOf('day')
            };
            break;
        }

        var months = moment.duration(params.to.diff(params.from)).asMonths();
        var days = moment.duration(params.to.diff(params.from)).asDays();
        var hours = moment.duration(params.to.diff(params.from)).asHours();
        var weeks = moment.duration(params.to.diff(params.from)).asWeeks();

        if (days > 90) {
          params.period = 'month';
          params.period_count = months.toFixed(2);
        } else if (days > 2 && days <= 30) {
          params.period = 'day';
          params.period_count = days.toFixed(2);
        } else if (days > 30 && days <= 90) {
          params.period = 'week';
          params.period_count = weeks.toFixed(2);
        } else {
          params.period = 'hour';
          params.period_count = hours.toFixed(2);
        }

        if (!isMoment) {
          params.from = params.from.format('l LT');
          params.to = params.to.format('l LT');
        }

        return params;
      },

      formatMoment: function(query) {
        return module.format(query, true);
      },

      getDurations: function(params) {
        var range = module.format(params, true);
        var durations = [];

        do {
          var startDate = angular.copy(range.from).startOf(range.period);
          range.from.add(1, range.period);
          var endDate = angular.copy(startDate).endOf(range.period);
          if (endDate.isAfter(range.to)) endDate = range.to;

          durations.push({
            from: startDate,
            to: endDate
          });
        }
        while (range.from.isBefore(range.to));

        return durations;
      },

      getQuery: () => {
        const params = angular.copy($stateParams);
        if (params.period === 'today') {
          params.from = moment().startOf('day').format();
          params.to = null;
        }

        if (params.period === 'day') {
          const day = new Date(params.for);
          params.from = moment(day).startOf('day').format();
          params.to = moment(day).endOf('day').format();
        }

        if (params.period === 'range') {
          if (params.from) {
            const from = new Date(params.from);
            params.from = moment(from).startOf('day').format();
          }

          if (params.to) {
            const to = new Date(params.to);
            params.to = moment(to).endOf('day').format();
          }
        }

        return params;
      },
    };

    return module;
  }]);