ProductModule.factory('MediaUploadService', [
  'API',
  'ProductConstants',
  'DigitalProductConstants',
  'utils',
  'Notification',
  'validateService',
  function ($API, ProductConstants, DigitalProductConstants, utils, Notification, validateService) {
    const {
      pipe,
    } = utils;

    const validateFileTypes = (files) => {
      const validation = validateService.process(
        files,
        { formats: ProductConstants.AVAILABLE_FORMATS },
      );

      return validation.is_valid;
    };

    const validateDigitalFileTypes = (files) => {
      const validation = validateService.process(
        files,
        {
          formats: DigitalProductConstants.AVAILABLE_FORMATS,
          maxSize: DigitalProductConstants.MAX_FILE_SIZE_KB
        }
      );

      return validation.is_valid;
    };

    /**
     * @param files
     * @param productFileType standard | digital
     */
    const validateFileSizes = (files, productFileType) => {
      const validFiles =
        productFileType === 'digital'
          ? files.filter(file => file.size <= DigitalProductConstants.MAX_FILE_SIZE_BYTES)
          : files.filter(file => file.size <= ProductConstants.MAX_FILE_SIZE);

      if (validFiles.length !== files.length) {
        Notification.error('Could not upload file', 'One or more files are too large. Allowed maximum file size is 20 MB');
      }

      return validFiles;
    };

    /**
     * @param fileList
     * @param productFileType standard | digital
     */
    const getValidFiles = (fileList, productFileType) => {
      const availableFiles = Array.from(fileList);

      const isValid =
        productFileType === 'digital'
          ? validateDigitalFileTypes(availableFiles)
          : validateFileTypes(availableFiles);

      if (!isValid) {
        return Notification.error('Could not upload file', 'One or more file types are not valid');
      }

      return validateFileSizes(availableFiles, productFileType);
    };

    const uploadFile = file => $API
      .all('upload_url')
      .post({
        upload_filename: file.name || `image-${uuidv4()}`,
        upload_path: '/products'
      })
      .then(response => response.data);

    const getSignedUrls = files => Promise.all(
      files.map(file => uploadFile(file))
    );

    const uploadDigitalFile = file => $API
      .all('product/digital/upload_url')
      .post({
        upload_filename: file.name || `image-${utils.uuid()}`,
      })
      .then(response => response.data);

    const getDigitalProductSignedFileUrls = files => Promise.all(
      files.map(file => uploadDigitalFile(file))
    );

    const getProductFiles = product => (product.files && product.files.length ? product.files : []);

    const removeFilesAboveTotalLimit = ({
      fileList,
      maxFileUploadCount
    }) => {
      if (fileList.length > maxFileUploadCount) {
        fileList = fileList.slice(0, maxFileUploadCount);
        Notification.error('Could not upload all files', `You can only add ${maxFileUploadCount} media files`);
      }
      return fileList;
    };

    const removeFilesAboveCurrentLimit = (fileList, maxFileUploadCount, productFiles) => {
      if (productFiles.length + fileList.length > maxFileUploadCount) {
        const availableUploadCount = Math.max(0, maxFileUploadCount - productFiles.length);
        fileList = fileList.slice(0, availableUploadCount);
        Notification.error('Could not upload all files', `You can only add ${maxFileUploadCount} media files`);
      }
      return fileList;
    };

    const removeUnnecessaryFiles = (filesToUpload, product, {
      maxFileUploadCount,
      productFileType
    }) => {
      const files = (productFileType === 'digital')
        ?  product.digital_assets || []
        : getProductFiles(product);

      return pipe(
        removeFilesAboveTotalLimit,
        formattedFiles => removeFilesAboveCurrentLimit(formattedFiles, maxFileUploadCount, files),
      )({fileList: filesToUpload, maxFileUploadCount, files});
    };

    return {
      validateFileTypes,
      validateFileSizes,
      getValidFiles,
      validateDigitalFileTypes,
      uploadFile,
      getSignedUrls,
      getProductFiles,
      removeFilesAboveTotalLimit,
      removeFilesAboveCurrentLimit,
      removeUnnecessaryFiles,
      getDigitalProductSignedFileUrls,
    };
  }
]);
