angular
  .module('app.transfers', ['ui.router', 'autocomplete'])
  .config([
    '$stateProvider',
    function ($stateProvider) {
      $stateProvider
        .state('transfers', {
          abstract: true,
          url: '/transfers',
          title: 'Transfers',
          templateUrl: 'components/layout/base.html',
          redirectTo: 'transfers.list',
          data: {
            permission: 'transfer-view',
            authenticable: true,
          },
        })
        .state('transfers.list', {
          title: 'Transfers',
          controller: 'TransferListCtrl',
          templateUrl: 'modules/transfers/list.html',
          url: '?page&amount&recipient&currency&new&status&from&to&reference&period&for&use_cursor&next&previous&per_page&request',
          onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('transfers-root'),
        })
        .state('transfers.bulk-upload', {
          title: 'Bulk Upload Transfers',
          controller: 'TransferBulkUploadCtrl',
          templateUrl: 'modules/transfers/bulk-upload/view.html',
          url: '/bulk-upload',
        })
        .state('transfers.bulk-upload.start', {
          title: 'Bulk Upload Transfers',
          controller: 'TransferBulkUploadCtrl',
          url: '/start',
        })
        .state('transfers.bulk-upload.progress', {
          title: 'Bulk Upload Transfers',
          reloadOnSearch: false,
          controller: 'TransferBulkUploadCtrl',
          url: '/progress',
        })
        .state('transfers.new', {
          url: '/new?withdraw&currency',
          reloadOnSearch: false,
          title: 'New Transfer',
          templateUrl: 'modules/transfers/new.html',
          controller: 'NewTransferNextCtrl',
          permission: 'transfer-*',
          onExit: () => window.cleanupMicroFrontend && window.cleanupMicroFrontend('transfers-root'),
        })
        .state('transfers.one', {
          url: '/:id?confirm',
          title: 'Transfers',
          templateUrl: 'modules/transfers/one.html',
          controller: 'TransferDetailCtrl',
          onExit: () =>
            window.cleanupMicroFrontend &&
            window.cleanupMicroFrontend('transfers-root'),
        });
    },
  ])
  .controller('TransferListCtrl', [
    '$scope', 'DEFAULTS',
    function (
      $scope, DEFAULTS,
    ) {
      $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_TRANSFERS_MICROFRONTEND',
          }, '*');
        }
      });
    },
  ])
  .controller('TransferBulkUploadCtrl', [
    '$scope',
    '$location',
    function (
        $scope,
        $location,
    ) {
      $scope.showBreadCrumbs = !$location.url().includes(['start', 'progress']);

      $scope.$on('$locationChangeSuccess', () => {
        const url = $location.url();
        $scope.showBreadCrumbs = url.includes(['start', 'progress']);
      })

      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_TRANSFERS_MICROFRONTEND',
          }, '*');
        }
      });
    },
  ])
  .controller('TransferDetailCtrl', [
    '$scope',
    function ($scope) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_TRANSFERS_MICROFRONTEND',
          }, '*');
        }
      });
    },
  ])
  .controller('NewTransferNextCtrl', [
    '$scope',
    function (
        $scope,
    ) {
      $scope.$watch('mfeServiceReady', mfeServiceReady => {
        if (mfeServiceReady) {
          window.postMessage({
            message: 'RENDER_TRANSFERS_MICROFRONTEND',
          }, '*');
        }
      });
    },
  ]);
