angular.module('app.preferencesService', [])
  .run(['$rootScope', '$state', '$stateParams', function($rootScope, $state, $stateParams) {
    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) {
      const user = $rootScope.User;
      const preferences = user && user.dashboard_preferences;
      const paginationPreferences = preferences && preferences.pagination;
      const stateParamsSupportCursorPagination = Object.keys(toParams).includes('use_cursor');

      if (paginationPreferences && paginationPreferences.use_cursor && stateParamsSupportCursorPagination) {
        angular.extend(toParams, { use_cursor: true });
      }
    });
  }])
  .service('PreferencesService', ['API', '$rootScope', function($API, $rootScope) {
    return {
      getPaginationPreferences: () => {
        const user = $rootScope.User;
        const preferences = user && user.dashboard_preferences;
        return preferences && preferences.pagination;
      },
      updateTimezonePreference: payload => $API.one('user/dashboard_preferences').customPUT(payload),
      updatePaginationPreferences: payload => $API.one('user/dashboard_preferences').customPUT(payload)
    };
  }]);
