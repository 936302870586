angular.module('app.compliance').controller('ComplianceProfileController', [
  '$scope',
  '$location',
  '$window',
  '$state',
  '$rootScope',
  'ComplianceProfile',
  'Notification',
  'AnalyticsService',
  'Session',
  'Onboarding3Service',
  'Auth',
  '$i18next',
  'DEFAULTS',
  (
    $scope,
    $location,
    $window,
    $state,
    $rootScope,
    ComplianceProfile,
    Notification,
    AnalyticsService,
    Session,
    Onboarding3Service,
    Auth,
    $i18next,
    DEFAULTS,
  ) => {
    Onboarding3Service.redirectToOnboarding3IfEnabled();
    const { logEvent } = AnalyticsService;
    $scope.logEvent = logEvent;
    $scope.knowledgeBaseUrl = DEFAULTS.knowledgeBaseUrl;

    const { Business } = $rootScope;
    $scope.profileStatus = $scope.complianceStatusData.modules.find(
      module => module.code === 'PROFILE',
    );

    $scope.agreementStatus = $scope.complianceStatusData.modules.find(module =>
      ['SERVICE_AGREEMENT'].includes(module.code),
    );

    $scope.useEditMode =
      ($state.params && $state.params.edit) || !$scope.profileStatus.completed;
    const integration = Session.get('account');
    $scope.default_currency = integration.default_currency;
    $scope.integrationIsLive = integration.is_live;
    const country =
      integration && integration.attributes && integration.attributes.country;
    $scope.countryName = country && country.name;

    const setCategoryData = () => {
      const savedCategoryCode =
        $scope.savedBusinessProfile &&
        $scope.savedBusinessProfile.categories[0];
      $scope.categoryData =
        $scope.categories &&
        $scope.categories.find(({ code }) => code === savedCategoryCode);
    };

    const getBusinessTypesForCountry = (
      businessCountryCode,
      { countries, businessTypesData },
    ) => {
      const countryData =
        countries.find(country => country.value === businessCountryCode) || {};
      const DEFAULT_BUSINESS_TYPES = ['starter', 'registered'];
      return (countryData.businessTypes || DEFAULT_BUSINESS_TYPES).map(
        businessType => ({
          ...businessTypesData[businessType],
          value: businessType,
        }),
      );
    };

    const getBusinessTypeTooltip = () =>
      $i18next.t('complianceProfile.form.instructions.businessType', {
        context: $scope.savedBusinessProfile.country,
      });

    ComplianceProfile.fetchSavedBusinessProfile()
      .then(savedBusinessProfile => {
        $scope.savedBusinessProfile = savedBusinessProfile || {};
        if (!$scope.savedBusinessProfile.country) {
          $scope.savedBusinessProfile.country = country && country.iso_code;
        }
        return ComplianceProfile.fetchBusinessProfile(
          $scope.savedBusinessProfile.country,
        );
      })
      .then(businessProfileData => {
        $scope.profileFormData = businessProfileData || {};
        // Pending API
        const starterBusinessLimits = {
          NG: 'NGN 2,000,000',
          GH: 'GHS 30,000',
          ZA: 'ZAR 80,000',
          RW: 'RWF 3,000,000',
        };
        $scope.profileFormData.starterBusinessLimit =
          starterBusinessLimits[$scope.savedBusinessProfile.country];
        $scope.businessTypes = getBusinessTypesForCountry(
          $scope.savedBusinessProfile.country,
          {
            countries: businessProfileData.countries,
            businessTypesData: businessProfileData.businessTypesMap,
          },
        );
        $scope.businessTypeTooltip = getBusinessTypeTooltip();
        $scope.$watch('savedBusinessProfile.industry', () => {
          const industries =
            $scope.profileFormData.industries &&
            $scope.profileFormData.industries.find(
              industry =>
                industry.code === $scope.savedBusinessProfile.industry,
            );
          $scope.categories = industries && industries.categories;
          setCategoryData();
        });
        $scope.showRegistrationTypes =
          Array.isArray(businessProfileData.registrationTypes) &&
          businessProfileData.registrationTypes.length > 1;
        if (!$scope.showRegistrationTypes) {
          $scope.savedBusinessProfile.registrationType =
            businessProfileData.registrationTypes[0] &&
            businessProfileData.registrationTypes[0].code;
        }
        $scope.$watch(
          'savedBusinessProfile.registrationType',
          (newValue, oldValue) => {
            const registrationTypes =
              $scope.profileFormData.registrationTypes &&
              $scope.profileFormData.registrationTypes.find(
                type =>
                  type.code === $scope.savedBusinessProfile.registrationType,
              );
            $scope.ownershipTypes =
              registrationTypes && registrationTypes.ownershipTypes;

            if (newValue !== oldValue && $scope.ownershipTypes) {
              if ($scope.ownershipTypes.length === 0) {
                $scope.savedBusinessProfile.ownershipType = undefined;
              } else if ($scope.ownershipTypes.length) {
                $scope.savedBusinessProfile.ownershipType =
                  $scope.ownershipTypes[0] && $scope.ownershipTypes[0].code;
              }
            }
          },
        );
        logEvent('page_load', {
          pageName: $state.current.name,
          state: 'succeeded',
        });
        $scope.$broadcast('profilePageReady');
      })
      .catch(error => {
        logEvent('page_load', {
          pageName: $state.current.name,
          state: 'failed',
        });
        Notification.error('Could not fetch business profile', error);
      });

    $scope.saveProfile = () => {
      logEvent('Attempted to save compliance module', {
        module: $scope.profileStatus.code,
      });
      const { fieldNames, ...payload } = $scope.savedBusinessProfile;
      ComplianceProfile.saveBusinessProfile(payload)
        .then(() => Auth.getFeatureFlags)
        .then(() => {
          $rootScope.$broadcast('savedBusinessProfile');
          logEvent('Successfully saved compliance module', {
            module: $scope.profileStatus.code,
          });
          $scope.reloadWithParams({ edit: null });
        })
        .catch(error => {
          $rootScope.$broadcast('savedBusinessProfile', false);
          const message = error && error.message;
          logEvent('Error saving compliance module', {
            module: $scope.profileStatus.code,
            message,
          });
          Notification.error('Could not save business profile', error);
        });
    };

    $scope.switchToEditMode = () => {
      $scope.reloadWithParams({ edit: 'true' });
    };

    $scope.showContactOptions = guideName => {
      $rootScope.openHelpDesk({ tab: 'contact' });
      logEvent(`Clicked Need Help from ${guideName} guide`);
    };

    $scope.onIndustryChange = source => logEvent('Set industry', { source });

    $scope.setIndustry = industry => {
      $scope.savedBusinessProfile.industry = industry.code;
      $scope.onIndustryChange('guide');
    };

    $scope.onCategoryChange = source => {
      setCategoryData();
      logEvent('Set category', { source });
    };

    $scope.setCategory = category => {
      $scope.savedBusinessProfile.categories[0] = category.code;
      $scope.onCategoryChange('guide');
    };

    $scope.$watch('profileStatus.completed', value => {
      if (!value) {
        $location.search('edit', 'true');
      }
    });
  },
]);
